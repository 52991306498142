import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  Replay as Reload,
  AddCircleOutline as Add,
  Edit,
} from "@material-ui/icons";

import SearchButton from "components/ui/searchbutton/SearchButton";
import FleetGrid from "components/ui/grid/FleetGrid";
import ConfirmDialog from "components/ui/confirmdialog/ConfirmDialog";
import CompanyDialog from "./CompanyDialog";

import { API_CPanel } from "apihandler/CPanel";
import { DEFAULTS, LABELS } from "common/consts";

class Companies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: "slno", title: "Sl No." },
        { name: "name", title: "Company" },
        { name: "logo", title: "Logo" },
        { name: "totalUsers", title: "Total Users" },
        { name: "totalVehicles", title: "Total Vehicles" },
        { name: "options", title: "Options" },
      ],
      rows: null,
      filteredRows: [],
    };

    this._tableColumnWidths = [
      { columnName: "slno", width: 100 },
      { columnName: "name", width: 300 },
      { columnName: "logo", width: 400 },
      { columnName: "totalUsers", width: 150 },
      { columnName: "totalVehicles", width: 150 },
      { columnName: "options", width: 100 },
    ];

    this._tableColumnExtensions = [
      { columnName: "slno", align: "center" },
      { columnName: "name", align: "left" },
      { columnName: "logo", align: "left" },
      { columnName: "totalUsers", align: "center" },
      { columnName: "totalVehicles", align: "center" },
      { columnName: "options", align: "left" },
    ];

    this._sortingDisabledColumns = [
      { columnName: "logo", sortingEnabled: false },
      { columnName: "options", sortingEnabled: false },
    ];
  }

  componentDidMount() {
    this.loadGrid();
  }

  loadGrid = () => {
    let self = this;
    API_CPanel.getCompanies()
      .then((res) => {
        if (res.Result === 200) {
          let rows = res.Response;
          for (let r = 0; r < rows.length; r++) {
            rows[r].options = this.getOptions({
              id: rows[r].companyId,
              name: rows[r].companyName,
              logo: rows[r].logo,
            });
            rows[r].slno = Number(r + 1);
            rows[r].id = rows[r].companyId;
            rows[r].name = rows[r].companyName;
          }

          // Check if any text in Search Box
          let searchQry = document
            .getElementById("cpanelCompanySearch")
            .value.trim()
            .toUpperCase();
          let filteredRows = [];
          if (searchQry.length > 0) {
            filteredRows = rows.filter((row) => {
              return row.name.toUpperCase().indexOf(searchQry) > -1;
            });
          }

          self.setState({
            rows: rows,
            filteredRows: filteredRows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setFilteredGridData = (rows) => {
    let list = [];
    for (let i = 0; i < rows.length; i++) {
      rows[i].slno = i + 1;
      list.push(rows[i]);
    }
    this.setState({
      filteredRows: list,
    });
  };

  getOptions = (record) => {
    return (
      <div className="options">
        <Tooltip
          title={LABELS.EDIT}
          placement={DEFAULTS.TOOLTIP_PLACEMENT}
          onClick={() => this.editDialog(record)}
        >
          <IconButton className="smallIcon">
            <Edit />
          </IconButton>
        </Tooltip>
        {/* <Tooltip
          title={LABELS.DELETE}
          placement={DEFAULTS.TOOLTIP_PLACEMENT}
          onClick={() => this.onDelete(id, name)}
        >
          <IconButton className="smallIcon">
            <Delete />
          </IconButton>
        </Tooltip> */}
      </div>
    );
  };

  toggleDialog = () => {
    this._dialog.toggleDialog({ add: true });
  };

  editDialog = (record) => {
    this._dialog.toggleDialog(record);
  };

  onSearch = (e) => {
    let qry = e.target.value.trim();
    if (qry.length > 2) {
      this._grid.onSearch(qry);
    } else if (qry.length === 0) {
      this.setState({
        filteredRows: [],
      });
    }
  };

  reload = () => {
    this.setState({
      rows: null,
      filteredRows: [],
    });

    let self = this;
    setTimeout(() => {
      self.loadGrid();
    }, 1000 * DEFAULTS.GRID_RELOAD_TIMEOUT);
  };

  onDelete = (id, name) => {
    this._dialogConfirm.toggleConfirmDialog(id, name);
  };

  deleteAll = () => {
    this._dialogConfirm.toggleConfirmDialog("");
  };

  render() {
    const { rows, columns, filteredRows } = this.state;

    console.log("Companies: ", rows);

    return (
      <div id="companiesWrapper">
        <ul className="gridActionBtns">
          <li>
            <SearchButton
              id="cpanelCompanySearch"
              placeholder={LABELS.SEARCH_BY_COMPANY_NAME}
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
              onChange={this.onSearch}
            />
          </li>
          <li>
            <Tooltip
              title={LABELS.RELOAD}
              placement={DEFAULTS.TOOLTIP_PLACEMENT}
            >
              <IconButton onClick={this.reload}>
                <Reload />
              </IconButton>
            </Tooltip>
          </li>
          <li>
            <Tooltip title={LABELS.ADD} placement={DEFAULTS.TOOLTIP_PLACEMENT}>
              <IconButton onClick={this.toggleDialog}>
                <Add />
              </IconButton>
            </Tooltip>
          </li>
          {/* <li>
            <Tooltip
              title={LABELS.DELETE}
              placement={DEFAULTS.TOOLTIP_PLACEMENT}
            >
              <IconButton onClick={this.deleteAll}>
                <Delete />
              </IconButton>
            </Tooltip>
          </li> */}
        </ul>

        <CompanyDialog
          childRef={(ref) => (this._dialog = ref)}
          loadGrid={this.loadGrid}
        />

        <FleetGrid
          id="cpanelCompanies"
          childRef={(ref) => (this._grid = ref)}
          height={window.innerHeight - 200}
          rows={filteredRows.length > 0 ? filteredRows : rows}
          columns={columns}
          setFilteredGridData={this.setFilteredGridData}
          tableColumnWidths={this._tableColumnWidths}
          tableColumnExtensions={this._tableColumnExtensions}
          sortingDisabledColumns={this._sortingDisabledColumns}
          sortColumn={"slno"}
          showSortingControls={true}
        />

        <ConfirmDialog
          childRef={(ref) => (this._dialogConfirm = ref)}
          onOk={this.onDelete}
          id=""
          name="Company"
        />
      </div>
    );
  }
}

export default Companies;
