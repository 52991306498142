import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Map,
  ZoomControl,
  ScaleControl,
  TileLayer,
  FeatureGroup,
  withLeaflet,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import PrintControlDefault from "react-leaflet-easyprint";

import FleetMapMarkers from "./Markers";
import DataPoints from "./DataPoints";
import Trackline from "./Trackline";
import Zones from "./poi/Zones";
import Points from "./poi/Points";
import Routes from "./poi/Routes";
import TmpMarkers from "./TmpMarkers";
import EditZone from "./poi/EditZone";
import MovingPoint from "./poi/MovingPoint";
import DrawRoute from "./poi/DrawRoute";

import {
  CONFIG,
  DEFAULTS,
  LOCAL_STORAGE,
  FORMATS,
  PRIVILEGES,
} from "common/consts";
import { commonJs } from "common/common";
import { dateFunction } from "common/datefunctions";
import { MAP_ACTIONS } from "actions/MapActions";

import "./styles/fleetmap.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import "leaflet/dist/leaflet.css";

const PrintControl = withLeaflet(PrintControlDefault);

class FleetMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapPosition: {
        lat: this.props.mapReducer.initialLat,
        lng: this.props.mapReducer.initialLng,
      },
      zoom: this.props.mapReducer.zoom,
    };
  }

  addIncidentLocation = (lat, lng, zoom) => {
    console.log("addIncidentLocation: ", lat, lng, zoom);
    this.updateRegion(lat, lng, zoom);
  };

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    document.addEventListener(
      "fullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "mozfullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "MSFullscreenChange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "webkitfullscreenchange",
      this.onFullScreenChange,
      false
    );
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
    document.removeEventListener(
      "fullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.removeEventListener(
      "mozfullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.removeEventListener(
      "MSFullscreenChange",
      this.onFullScreenChange,
      false
    );
    document.removeEventListener(
      "webkitfullscreenchange",
      this.onFullScreenChange,
      false
    );
  }

  onFullScreenChange = (e) => {
    let clslist = document.getElementById("mapContainer").classList;
    console.log("onFullScreenChange: ", clslist);
    if (window.innerHeight === window.screen.height) {
      clslist.add("fullscreen");
    } else {
      clslist.contains("fullscreen");
    }
    console.log("onFullScreenChange");
  };

  mapClick = (e) => {
    if (document.querySelector("div#addPoint")) {
      if (!document.querySelector("div#addPoint").getAttribute("latlng")) {
        // Add the Point Marker to MAP
        console.log("this._points: ", this);
        this._points.addPointMarker(e.latlng.lat, e.latlng.lng);
        let latLng = e.latlng.lat + "," + e.latlng.lng;
        document.querySelector("div#addPoint").setAttribute("latlng", latLng);
      }
    }
  };

  showRoute = () => {
    console.log("showRoute...");
  };

  shareVehicleLocation = () => {
    console.log("shareVehicleLocation...");
  };

  trackVehicle = () => {
    console.log("trackVehicle");
  };

  printMap = () => {
    let fileName =
      "FleetMap " + dateFunction.getDate("today", "from", FORMATS.DATE_ONLY);
    console.log("printMap: ", fileName);
    this._printControl.printMap("A4Landscape", fileName);
  };

  getTileUrl = (mapType) => {
    let url = CONFIG.OSM_TILE_URL;
    const { mapTraffic } = this.props.mapReducer;
    switch (mapType) {
      case 1:
        url = mapTraffic
          ? CONFIG.GOOGLE_STREETS_MAP_WITH_TRAFFIC_URL
          : CONFIG.GOOGLE_STREETS_MAP_URL;
        break;
      case 2:
        url = mapTraffic
          ? CONFIG.GOOGLE_SATELLITE_MAP_WITH_TRAFFIC_URL
          : CONFIG.GOOGLE_SATELLITE_MAP_URL;
        break;
      case 3:
        url = mapTraffic
          ? CONFIG.GOOGLE_HYBRID_MAP_WITH_TRAFFIC_URL
          : CONFIG.GOOGLE_HYBRID_MAP_URL;
        break;
      case 4:
        url = CONFIG.MAP_BOX_TILE_URL_STREETS;
        break;
      case 5:
        url = CONFIG.MAP_BOX_TILE_URL_SATELLITE;
        break;
      default:
        break;
    }
    return url;
  };

  onDragMap = (e) => {
    const center = e.target.getCenter();
    const zoom = e.target.getZoom();
    let mapLocalStorage = commonJs.getItemFromLocal(LOCAL_STORAGE.MAP);
    if (mapLocalStorage === null) {
      mapLocalStorage = {};
    }
    mapLocalStorage.initialLat = center.lat;
    mapLocalStorage.initialLng = center.lng;
    mapLocalStorage.zoom = zoom;
    commonJs.setItemToLocal(LOCAL_STORAGE.MAP, mapLocalStorage);
    if (this._zones !== undefined && this._zones !== null) {
      this._zones.updateZoom(zoom);
    }
    if (this._points !== undefined && this._points !== null) {
      this._points.updateZoom(zoom);
    }
  };

  updateRegion = (lat, lng, zoom) => {
    this.setState({
      mapPosition: { lat, lng },
      zoom: zoom,
    });
  };

  _onEditPath = (p) => {
    let latLngs = [];
    for (let i in p.layers._layers) {
      latLngs = p.layers._layers[i]._latlngs;
    }
    if (document.querySelector("div#addZone")) {
      if (latLngs.length > 0) {
        latLngs = JSON.stringify(latLngs[0]);
      }
      document.querySelector("div#addZone").setAttribute("vertices", latLngs);
    } else if (document.querySelector("div#addRoute")) {
      latLngs = JSON.stringify(latLngs);
      document.querySelector("div#addRoute").setAttribute("route", latLngs);
    }
  };

  _onCreate = (d) => {
    if (d.layerType === "polygon") {
      if (document.querySelector("div#addZone")) {
        let vertices = d.layer._latlngs;
        if (vertices.length > 0) {
          vertices = JSON.stringify(vertices[0]);
        }
        document
          .querySelector("div#addZone")
          .setAttribute("vertices", vertices);
      }
    } else if (d.layerType === "polyline") {
      if (document.querySelector("div#addRoute")) {
        let latLngs = d.layer._latlngs;
        if (latLngs.length > 0) {
          latLngs = JSON.stringify(latLngs);
        }
        console.log(latLngs);
        document.querySelector("div#addRoute").setAttribute("route", latLngs);
      }
    }
  };

  _onDeleted = () => {};

  trackFitBounds = () => {
    this._map.leafletElement.fitBounds(
      this._mapTracklineGroup.leafletElement.getBounds()
    );
  };

  mapFitBounds = () => {
    console.log(this._mapGroup.leafletElement.getBounds());
    this._map.leafletElement.fitBounds(
      this._mapGroup.leafletElement.getBounds()
    );
  };

  zoneFitBounds = (bounds) => {
    if (!bounds) {
      let layers = this._mapDrawControlGroup.leafletElement._layers;
      for (let i in layers) {
        bounds = layers[i]._bounds;
      }
    }
    if (bounds) {
      this._map.leafletElement.fitBounds(bounds);
    }
  };

  onZoomEnd = (e) => {
    // if (this._routes) {
    //   this._routes.updateZoom(e.target._zoom);
    // }
    // if (this._points) {
    //   this._points.updateZoom(e.target._zoom);
    // }
    // if (this._zones) {
    //   this._zones.updateZoom(e.target._zoom);
    // }
  };

  render() {
    const { mapReducer, loginReducer, showTrack, shareVehicle } = this.props;
    const {
      mapType,
      mapLabels,
      mapClusters,
      scale,
      mapZones,
      mapPoints,
      mapRoutes,
      signalFilter,
      loadStatusFilter,
      mapVehicles,
    } = mapReducer;
    const { privileges, role } = loginReducer;

    const fullScreen = commonJs.isFullScreen();

    const tileUrl = this.getTileUrl(mapType);

    const { mapPosition, zoom } = this.state;

    const mPos = {
      lat: Number(mapPosition.lat),
      lng: Number(mapPosition.lng),
    };
    const showPoi = commonJs.hasPrivilege(PRIVILEGES.POI, role, privileges);

    return (
      <div
        id="mapContainer"
        className={
          "mapContainer" +
          (fullScreen ? " fullscreen" : "") +
          (mapLabels ? "" : " mapLabels")
        }
      >
        <Map
          center={mPos}
          zoom={zoom}
          maxZoom={DEFAULTS.MAX_ZOOM}
          minZoom={DEFAULTS.MIN_ZOOM}
          viewport={null}
          onClick={this.mapClick}
          className="fleetMap"
          onMoveend={this.onDragMap}
          onzoomend={this.onZoomEnd}
          ref={(ref) => {
            this._map = ref;
          }}
        >
          <PrintControl
            ref={(ref) => {
              this._printControl = ref;
            }}
            position="bottomright"
            sizeModes={["Current", "A4Portrait", "A4Landscape"]}
            hideControlContainer={false}
          />

          <PrintControl
            position="bottomright"
            sizeModes={["Current", "A4Portrait", "A4Landscape"]}
            hideControlContainer={false}
            title="Export as PNG"
            exportOnly
          />

          <ZoomControl position="bottomright" className="mapZoomControl" />

          {scale && (
            <ScaleControl position="topright" className="mapScaleControl" />
          )}

          <TileLayer url={tileUrl} />

          <FeatureGroup
            ref={(ref) => {
              this._mapGroup = ref;
            }}
          >
            <FleetMapMarkers
              showClusters={mapClusters}
              signalFilter={signalFilter}
              loadStatusFilter={loadStatusFilter}
              mapVehicles={mapVehicles}
              showTrack={showTrack}
              shareVehicle={shareVehicle}
            />
          </FeatureGroup>

          <FeatureGroup
            ref={(ref) => {
              this._mapTracklineGroup = ref;
            }}
          >
            <Trackline />
            <DataPoints />
          </FeatureGroup>

          <TmpMarkers />

          {/* POI Edit Controls */}
          <FeatureGroup
            ref={(ref) => {
              this._mapDrawControlGroup = ref;
            }}
          >
            <EditControl
              position="topright"
              onEdited={this._onEditPath}
              onCreated={this._onCreate}
              onDeleted={this._onDeleted}
              draw={
                {
                  // rectangle: false,
                }
              }
              ref={(ref) => {
                this._mapDrawControl = ref;
              }}
            />
            <EditZone />
            <DrawRoute />
          </FeatureGroup>

          {/* Zones */}
          {mapZones && showPoi && (
            <Zones
              zoom={zoom}
              childRef={(ref) => {
                this._zones = ref;
              }}
            />
          )}

          {/* Points */}
          {mapPoints && showPoi && (
            <Points zoom={zoom} childRef={(ref) => (this._points = ref)} />
          )}

          {/* Routes */}
          {mapRoutes && showPoi && (
            <Routes zoom={zoom} childRef={(ref) => (this._routes = ref)} />
          )}

          {/* POI Point */}
          <MovingPoint />

          {/* TOCHECK:: Hidden for now, Given in Live Track <MovingMarkerButton /> */}
        </Map>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMapPosition: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_MAP_POSITION,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FleetMap);
