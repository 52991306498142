import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  Replay as Reload,
  AddCircleOutline as Add,
  Delete,
  Edit,
} from "@material-ui/icons";
import { withSnackbar } from "notistack";

import SearchButton from "components/ui/searchbutton/SearchButton";
import FleetGrid from "components/ui/grid/FleetGrid";
import ConfirmDialog from "components/ui/confirmdialog/ConfirmDialog";
import PackagesDialog from "./PackagesDialog";

import { DEFAULTS, LABELS, NOTIFY } from "common/consts";
import { commonJs } from "common/common";
import { API_CPanel } from "apihandler/CPanel";

class Packages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: "slno", title: "Sl No." },
        { name: "package", title: "Package Name" },
        { name: "description", title: "Package Description" },
        { name: "role", title: "User Role" },
        { name: "privileges", title: "User Privileges" },
        { name: "options", title: "Options" },
      ],
      rows: null,
      filteredRows: [],
      roles: [],
      privileges: [],
    };

    this._tableColumnWidths = [
      { columnName: "slno", width: 100 },
      { columnName: "package", width: 200 },
      { columnName: "description", width: 300 },
      { columnName: "role", width: 200 },
      { columnName: "privileges", width: 400 },
      { columnName: "options", width: 100 },
    ];

    this._tableColumnExtensions = [
      { columnName: "slno", align: "center" },
      { columnName: "package", align: "left" },
      { columnName: "description", align: "left" },
      { columnName: "role", align: "left" },
      { columnName: "privileges", align: "left" },
      { columnName: "options", align: "left" },
    ];
  }

  componentDidMount() {
    this.getMaster();
  }

  getMaster = () => {
    let self = this;
    API_CPanel.getAllUserRoles().then((res) => {
      let roles = [];
      if (res.Result === 200) {
        roles = res.Response.map((r) => {
          return { id: r.id, name: r.role };
        });
      }
      self.getPrivileges(roles);
    });
  };

  getPrivileges = (roles = []) => {
    let self = this;
    API_CPanel.getAllUserPrivileges().then((res) => {
      let list = [];
      if (res.Result === 200) {
        list = res.Response.map((p) => {
          return { id: p.id, name: p.privilege };
        });
      }
      self.setState({
        roles,
        privileges: list,
      });
      self.loadGrid();
    });
  };

  loadGrid = () => {
    let self = this;
    API_CPanel.getAllPackages()
      .then((res) => {
        if (res.Result === 200) {
          let rows = res.Response;
          for (let r = 0; r < rows.length; r++) {
            rows[r].slno = r + 1;
            rows[r].name = rows[r].package;
            rows[r].options = self.getOptions(rows[r]);
          }

          // Check if any text in Search Box
          let searchQry = document
            .getElementById("cpanelPackageSearch")
            .value.trim()
            .toUpperCase();
          let filteredRows = [];
          if (searchQry.length > 0) {
            console.log("rows: ", rows);
            filteredRows = rows.filter((row) => {
              return row.name.toUpperCase().indexOf(searchQry) > -1;
            });
          }

          self.setState({
            rows: rows,
            filteredRows: filteredRows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setFilteredGridData = (rows) => {
    this.setState({
      filteredRows: rows,
    });
  };

  getOptions = (record) => {
    const { id, name } = record;
    return (
      <div className="options">
        <Tooltip
          title={LABELS.EDIT}
          placement={DEFAULTS.TOOLTIP_PLACEMENT}
          onClick={() => this.editDialog(record)}
        >
          <IconButton className="smallIcon">
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={LABELS.DELETE}
          placement={DEFAULTS.TOOLTIP_PLACEMENT}
          onClick={() => this.onDeletePackage(id, name)}
        >
          <IconButton className="smallIcon">
            <Delete />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  toggleDialog = () => {
    this._dialog.toggleDialog({ add: true });
  };

  editDialog = (record) => {
    this._dialog.toggleDialog(record);
  };

  onSearch = (e) => {
    let qry = e.target.value.trim();
    if (qry.length > DEFAULTS.SEARCH_STR_LENGTH) {
      this._grid.onSearch(qry);
    } else if (qry.length === 0) {
      this.setState({
        filteredRows: [],
      });
    }
  };

  reload = () => {
    this.setState({
      rows: null,
      filteredRows: [],
    });

    let self = this;
    setTimeout(() => {
      self.loadGrid();
    }, 1000 * DEFAULTS.GRID_RELOAD_TIMEOUT);
  };

  onDeletePackage = (id, name) => {
    this._dialogConfirm.toggleConfirmDialog(id, name);
  };

  deletePackage = (id) => {
    let self = this;
    API_CPanel.deletePackage({ id }).then((res) => {
      self._dialogConfirm.toggleConfirmDialog("close");
      if (res.Result === 200) {
        commonJs.showNotify(
          self.props,
          NOTIFY.SUCCESS,
          "Package is deleted successfully..!"
        );
        return;
      } else {
        commonJs.showNotify(
          self.props,
          NOTIFY.ERROR,
          "Error: Package is not deleted..!"
        );
        return;
      }
    });
  };

  deleteAll = () => {
    this._dialogConfirm.toggleConfirmDialog("");
  };

  render() {
    const { rows, columns, filteredRows, roles, privileges } = this.state;
    return (
      <div id="PrivilegesWrapper">
        <ul className="gridActionBtns">
          <li>
            <SearchButton
              id="cpanelPackageSearch"
              placeholder={LABELS.SEARCH_BY_PACKAGE}
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
              onChange={this.onSearch}
            />
          </li>
          <li>
            <Tooltip
              title={LABELS.RELOAD}
              placement={DEFAULTS.TOOLTIP_PLACEMENT}
            >
              <IconButton onClick={this.reload}>
                <Reload />
              </IconButton>
            </Tooltip>
          </li>
          <li>
            <Tooltip title={LABELS.ADD} placement={DEFAULTS.TOOLTIP_PLACEMENT}>
              <IconButton onClick={this.toggleDialog}>
                <Add />
              </IconButton>
            </Tooltip>
          </li>
          {/* <li>
            <Tooltip
              title={LABELS.DELETE}
              placement={DEFAULTS.TOOLTIP_PLACEMENT}
            >
              <IconButton onClick={this.deleteAll}>
                <Delete />
              </IconButton>
            </Tooltip>
          </li> */}
        </ul>

        <PackagesDialog
          childRef={(ref) => (this._dialog = ref)}
          loadGrid={this.loadGrid}
          roles={roles}
          privileges={privileges}
        />

        <FleetGrid
          rows={filteredRows.length > 0 ? filteredRows : rows}
          columns={columns}
          id="cpanelPrivileges"
          childRef={(ref) => (this._grid = ref)}
          height={window.innerHeight - 200}
          setFilteredGridData={this.setFilteredGridData}
          tableColumnWidths={this._tableColumnWidths}
          tableColumnExtensions={this._tableColumnExtensions}
        />

        <ConfirmDialog
          childRef={(ref) => (this._dialogConfirm = ref)}
          onOk={this.deletePackage}
          id=""
          name="Privilege"
        />
      </div>
    );
  }
}

export default withSnackbar(Packages);
