import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  Replay as Reload,
  AddCircleOutline as Add,
  Edit,
} from "@material-ui/icons";

import SearchButton from "components/ui/searchbutton/SearchButton";
import FleetGrid from "components/ui/grid/FleetGrid";
import ConfirmDialog from "components/ui/confirmdialog/ConfirmDialog";
import RolesDialog from "./RolesDialog";

import { API_CPanel } from "apihandler/CPanel";
import { DEFAULTS, LABELS } from "common/consts";

class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: "slno", title: "Sl No." },
        { name: "role", title: "Role" },
        { name: "options", title: "Options" },
      ],
      rows: null,
      filteredRows: [],
    };

    this._tableColumnWidths = [
      { columnName: "slno", width: 100 },
      { columnName: "role", width: 300 },
      { columnName: "options", width: 100 },
    ];

    this._tableColumnExtensions = [
      { columnName: "slno", align: "center" },
      { columnName: "role", align: "left" },
      { columnName: "options", align: "left" },
    ];

    this.loadGrid(true);
  }

  componentDidMount() {}

  loadGrid = (initialLoad = false) => {
    let self = this;
    API_CPanel.getAllUserRoles()
      .then((res) => {
        if (res.Result === 200) {
          let rows = res.Response;
          for (let r = 0; r < rows.length; r++) {
            rows[r].options = this.getOptions({
              id: rows[r].id,
              name: rows[r].role,
            });
            rows[r].slno = r + 1;
            rows[r].name = rows[r].role;
          }

          // Check if any text in Search Box
          let filteredRows = [];
          if (!initialLoad) {
            let searchQry = document
              .getElementById("cpanelRoleSearch")
              .value.trim()
              .toUpperCase();
            if (searchQry.length > 0) {
              filteredRows = rows.filter((row) => {
                return row.name.toUpperCase().indexOf(searchQry) > -1;
              });
            }
          }

          self.setState({
            rows: rows,
            filteredRows: filteredRows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setFilteredGridData = (rows) => {
    console.log("setFilteredGridData");
    this.setState({
      filteredRows: rows,
    });
  };

  getOptions = (record) => {
    return (
      <div className="options">
        <Tooltip
          title={LABELS.EDIT}
          placement={DEFAULTS.TOOLTIP_PLACEMENT}
          onClick={() => this.editDialog(record)}
        >
          <IconButton className="smallIcon">
            <Edit />
          </IconButton>
        </Tooltip>
        {/* <Tooltip
          title={LABELS.DELETE}
          placement={DEFAULTS.TOOLTIP_PLACEMENT}
          onClick={() => this.onDelete(id, name)}
        >
          <IconButton className="smallIcon">
            <Delete />
          </IconButton>
        </Tooltip> */}
      </div>
    );
  };

  toggleDialog = () => {
    this._dialog.toggleDialog({ add: true });
  };

  editDialog = (record) => {
    this._dialog.toggleDialog(record);
  };

  onSearch = (e) => {
    let qry = e.target.value.trim();
    if (qry.length > 2) {
      this._grid.onSearch(qry);
    } else if (qry.length === 0) {
      this.setState({
        filteredRows: [],
      });
    }
  };

  reload = () => {
    this.setState({
      rows: null,
      filteredRows: [],
    });

    let self = this;
    setTimeout(() => {
      self.loadGrid();
    }, 1000 * DEFAULTS.GRID_RELOAD_TIMEOUT);
  };

  onDelete = (id, name) => {
    this._dialogConfirm.toggleConfirmDialog(id, name);
  };

  deleteAll = () => {
    this._dialogConfirm.toggleConfirmDialog("");
  };

  render() {
    const { rows, columns, filteredRows } = this.state;

    console.log("Roles: ", rows);

    return (
      <div id="rolesWrapper">
        <ul className="gridActionBtns">
          <li>
            <SearchButton
              id="cpanelRoleSearch"
              placeholder={LABELS.SEARCH_BY_ROLE}
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
              onChange={this.onSearch}
            />
          </li>
          <li>
            <Tooltip
              title={LABELS.RELOAD}
              placement={DEFAULTS.TOOLTIP_PLACEMENT}
            >
              <IconButton onClick={this.reload}>
                <Reload />
              </IconButton>
            </Tooltip>
          </li>
          <li>
            <Tooltip title={LABELS.ADD} placement={DEFAULTS.TOOLTIP_PLACEMENT}>
              <IconButton onClick={this.toggleDialog}>
                <Add />
              </IconButton>
            </Tooltip>
          </li>
          {/* <li>
            <Tooltip
              title={LABELS.DELETE}
              placement={DEFAULTS.TOOLTIP_PLACEMENT}
            >
              <IconButton onClick={this.deleteAll}>
                <Delete />
              </IconButton>
            </Tooltip>
          </li> */}
        </ul>

        <RolesDialog
          childRef={(ref) => (this._dialog = ref)}
          loadGrid={this.loadGrid}
        />

        <FleetGrid
          rows={filteredRows.length > 0 ? filteredRows : rows}
          columns={columns}
          id="cpanelRoles"
          childRef={(ref) => (this._grid = ref)}
          height={window.innerHeight - 200}
          setFilteredGridData={(rows) => this.setFilteredGridData(rows)}
          tableColumnWidths={this._tableColumnWidths}
          tableColumnExtensions={this._tableColumnExtensions}
        />

        <ConfirmDialog
          childRef={(ref) => (this._dialogConfirm = ref)}
          onOk={this.onDelete}
          id=""
          name="Role"
        />
      </div>
    );
  }
}

export default Roles;
