import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import "./autocomplete.css";

class AutoComplete extends Component {
  render() {
    const {
      label,
      list,
      id,
      style,
      onChange,
      multiple,
      size,
      disabled,
      defaultValue,
    } = this.props;
    return (
      <Autocomplete
        id={id}
        className="autocomplete-box"
        options={list}
        value={defaultValue}
        autoSelect={true}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name || ""}
        style={style}
        onChange={(event, value) => onChange(value)}
        multiple={multiple}
        size={size}
        disabled={disabled || false}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            size={size}
            margin="normal"
            fullWidth
          />
        )}
        classes={{
          popper: "autocompletePopper",
        }}
      />
    );
  }
}

export default AutoComplete;
