import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { Visibility, Edit, Delete } from "@material-ui/icons";
import { connect } from "react-redux";
// React Grid
import { SortingState, IntegratedSorting } from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

import { POI_ACTIONS } from "actions/PoiActions";
import { API_Map } from "apihandler/Map";
import { ROLES } from "common/consts";

const getRowId = (row) => row.id;

var timer = null;

class ZonesGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      direction: "asc",
      zones: {
        rows: [],
        filteredRows: [],
        columns: [],
      },
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.loadZones();
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  zoneSearch = (searchValue) => {
    const { zones } = this.state;
    const { rows, columns } = zones;
    clearTimeout(timer);
    var self = this;
    timer = setTimeout(() => {
      console.log("onZoneSearch: ", searchValue);
      let qry = searchValue.toUpperCase();
      let list = rows.filter((row) => {
        return row.name.toUpperCase().indexOf(qry) > -1;
      });
      self.setState({
        zones: { rows: rows, filteredRows: list, columns: columns },
      });
    }, 500);
  };

  getZoneOnFilter = (zoneId) => {
    const { zones } = this.state;
    const { rows } = zones;
    return rows.filter((r) => r.id === zoneId);
  };

  zoneVisibilityToggle = (zone) => {
    const { vertices } = zone;
    if (vertices.length > 0) {
      let bounds = vertices.map((v) => {
        return { lat: v.latitude, lng: v.longitude };
      });
      this.props.zoneFitBounds(bounds);
    }
  };

  generateZoneVisibleIcon = (zone) => {
    return (
      <Tooltip title="View" placement="bottom">
        <IconButton
          onClick={() => this.zoneVisibilityToggle(zone)}
          className="smallIcon"
        >
          <Visibility />
        </IconButton>
      </Tooltip>
    );
  };

  generateZoneOptions = (zone) => {
    const { id, name } = zone;
    const { role } = this.props.loginReducer;
    return (
      <div className="options">
        {role !== ROLES.SUB_USER && <Tooltip
          title="Edit"
          placement="bottom"
          onClick={() => this.props.toggleAddZoneDialog(zone)}
        >
          <IconButton className="smallIcon">
            <Edit />
          </IconButton>
        </Tooltip>}
        {role !== ROLES.SUB_USER && <Tooltip
          title="Delete"
          placement="bottom"
          onClick={() => this.props.deleteZone(id, name)}
        >
          <IconButton className="smallIcon">
            <Delete />
          </IconButton>
        </Tooltip>}
      </div>
    );
  };

  loadReloadZones = () => {
    this.setState({
      zones: {
        loading: false,
        sorting: "asc",
        columns: [],
        rows: [],
        filteredRows: [],
      },
    });

    this.loadZones();
  };

  loadZones = () => {
    let self = this;
    API_Map.getAllZones().then((res) => {
      if (res.Result === 200) {
        let cols = [
          { name: "view", title: "Visible" },
          { name: "name", title: "Name" },
          { name: "options", title: "Options" },
        ];
        let rows = [];
        let qry = "";
        if (document.querySelector("input#zoneSearch")) {
          qry = document.querySelector("input#zoneSearch").value.toUpperCase();
        }
        let filteredRows = [];
        let fRows = res.Response.filter((row) => {
          return row.name.toUpperCase().indexOf(qry) > -1;
        });
        for (let i in fRows) {
          filteredRows.push({
            id: fRows[i].id,
            view: this.generateZoneVisibleIcon(fRows[i]),
            slno: Number(i) + 1,
            name: fRows[i].name,
            options: this.generateZoneOptions(fRows[i]),
          });
        }

        res = res.Response;
        for (let i in res) {
          rows.push({
            id: res[i].id,
            view: this.generateZoneVisibleIcon(res[i]),
            slno: Number(i) + 1,
            name: res[i].name,
            options: this.generateZoneOptions(res[i]),
          });
        }
        self.setState({
          zones: {
            loading: false,
            sorting: "asc",
            columns: cols,
            rows: rows,
            filteredRows: filteredRows,
          },
        });

        // For Reducer
        self.props.setZones({
          zones: res,
        });
      }
    });
  };

  render() {
    const { rows, columns, filteredRows } = this.state.zones;

    const tableColumnExtensions = [
      { columnName: "view", width: 80, align: "center" },
      { columnName: "name", width: 200, align: "left" },
      { columnName: "options", width: 80, align: "center" },
    ];

    let searchOn = false;
    if (document.getElementById("zoneSearch") != null) {
      searchOn =
        document.getElementById("zoneSearch").value.trim() === ""
          ? false
          : true;
    }

    return columns.length > 0 ? (
      <div id="zonesGrid" className="dataRGrid">
        <Grid
          rows={searchOn ? filteredRows : rows}
          columns={columns}
          getRowId={getRowId}
        >
          <SortingState
            defaultSorting={[{ columnName: "name", direction: "asc" }]}
            columnSortingEnabled={true}
            //   onSortingChange={this.changeZoneSort}
            columnExtensions={[
              { columnName: "view", sortingEnabled: false },
              { columnName: "options", sortingEnabled: false },
            ]}
          />
          <IntegratedSorting />
          <VirtualTable
            columnExtensions={tableColumnExtensions}
            height={window.innerHeight - 180}
          />
          <TableHeaderRow showSortingControls />
        </Grid>
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: 100,
          alignItems: "center",
        }}
      >
        <p style={{ color: "#a1a1a1", fontSize: 14 }}>Loading...</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    poiReducer: state.poiReducer,
    loginReducer:state.loginReducer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setZones: (values) => {
      dispatch({
        type: POI_ACTIONS.SET_ZONES,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ZonesGrid);
