import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import FleetDialog from "../../../ui/dialogbox/FleetDialog";
import { commonJs } from "../../../../common/common";
import { NOTIFY } from "../../../../common/consts";
import { API_CPanel } from "../../../../apihandler/CPanel";

import { CPANEL_ACTIONS } from "../../../../actions/CpanelActions";

class RolesDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {},
      showDialog: false,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  toggleDialog = (record) => {
    const { showDialog } = this.state;
    if (record.id === undefined) {
      console.log("toggleDialog Add:  ", record, !showDialog);
      // this.props.setPointEdit({
      //   pointId: "",
      // });
      if (record.add) {
        this.setState({
          showDialog: !showDialog,
          record: {},
        });
      } else {
        // Close
        this.setState({
          showDialog: !showDialog,
        });
      }
    } else {
      // Edit -> Open
      this.setState({
        showDialog: !showDialog,
        record: record,
      });
      console.log("Edit: ", record);
    }
  };

  saveRecord = () => {
    const role = document.getElementById("masterRoleName").value.trim();

    let self = this;

    if (role === "") {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Role should not be empty..!"
      );
      return false;
    }

    const args = {
      id: this.state.record.id || 0,
      role: role,
    };

    console.log("save Record: ", args);

    this.props.setLoading({
      loading: true,
    });

    API_CPanel.manageRole(args)
      .then((res) => {
        console.log("manageRole: ", res);
        if (res.Result === 200) {
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            "Role saved successfully..!"
          );
          self.props.loadGrid();
          self.setState({
            showDialog: false,
          });
          self.props.setLoading({
            loading: false,
          });
        } else {
          commonJs.showNotify(self.props, NOTIFY.WARNING, res.Reason);
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });

    return;
  };

  render() {
    const { record, showDialog } = this.state;

    return (
      <FleetDialog
        id="rolesDialog"
        open={showDialog}
        onCloseSettings={this.toggleDialog}
        title={record.id ? "Edit Role" : "Add Role"}
        maxWidth="xs"
        fullWidth={false}
        cancelButton={true}
        cancelAction={this.toggleDialog}
        saveButton={true}
        saveAction={this.saveRecord}
        saveBtnLabel="OK"
        disableBackdropClick={true}
        disableBackdrop={true}
        classNameBody="zonePopupBody"
        classNameWrapper="zonePopupWrapper"
        draggable
      >
        <div className="grid">
          <div className="row">
            <TextField
              id="masterRoleName"
              label="Role Name"
              classes={{ root: "txtfield" }}
              autoFocus={true}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={record.name || ""}
            />
          </div>
          {/* <div className="row">
            <TextField
              id="masterRoleDesc"
              label="Role Description"
              classes={{ root: "txtfield" }}
              autoFocus={true}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={record.description || ""}
              multiline={true}
              rows={3}
            />
          </div> */}
        </div>
      </FleetDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (values) => {
      dispatch({
        type: CPANEL_ACTIONS.SET_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(RolesDialog));
