import React, { Component } from "react";
import {
  Map,
  ZoomControl,
  ScaleControl,
  TileLayer,
  FeatureGroup,
  Tooltip,
  Marker,
} from "react-leaflet";
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import {
  Paper,
  Button,
  ClickAwayListener,
  Grow,
  Popper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { DriftMarker } from "leaflet-drift-marker";
import { connect } from "react-redux";

import PageLoader from "components/ui/loaders/PageLoader";
import MapPopup from "components/map/fleetmap/MapPopup";
import Img from "components/ui/image/Img";

import {
  DEFAULTS,
  CONFIG,
  LOCAL_STORAGE,
  UNITS,
  ALERTS,
  PRIVILEGES,
} from "common/consts";
import { commonJs } from "common/common";
import { API_Map } from "apihandler/Map";
import { API_UserLogin } from "apihandler/UserLogin";

import "react-leaflet-markercluster/dist/styles.min.css";
import "leaflet/dist/leaflet.css";
import "../map/fleetmap/styles/fleetmap.css";
import "./livetrack.css";
import gmap from "images/bottomBarIcons/gmap.png";

const createClusterCustomIcon = (cluster) => {
  const count = cluster.getChildCount();
  let size = "LargeXL";
  if (count < 10) {
    size = "Small";
  } else if (count > 9 && count < 100) {
    size = "Medium";
  } else if (count > 99 && count < 1000) {
    size = "Large";
  }
  const options = {
    cluster: `markerCluster${size}`,
  };
  return L.divIcon({
    html: `<div>
          <span class="markerClusterLabel">${count}</span>
        </div>`,
    className: `${options.cluster}`,
  });
};

class LiveTrack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      pageStatus: "Loading...",
      vehicles: null,
      mapPosition: {
        lat: DEFAULTS.DEFAULT_LAT,
        lng: DEFAULTS.DEFAULT_LNG,
      },
      zoom: 18,
      mapType: DEFAULTS.DEFAULT_MAP_TPARTY,
      vehicleInfo: [],
      mapTypeOpen: false,
    };
    this._timer = null;
  }

  componentDidMount() {
    let imeis = commonJs.getUrlParameter("objects");
    let apiKey = commonJs.getUrlParameter("key");
    let self = this;

    if (apiKey === null) {
      this.setState({
        loading: false,
        pageStatus: "Error: " + ALERTS.API_KEY_MISSING,
      });
      return;
    }
    if (imeis === null && apiKey === "live") {
      this.setState({
        loading: false,
        pageStatus: "Error: " + ALERTS.API_OBJECTS_MISSING,
      });
      return;
    }

    if (imeis === null) {
      imeis = [];
    } else {
      imeis = imeis.split(",");
    }

    if (imeis.length === 0 && apiKey === "live") {
      this.setState({
        loading: false,
        pageStatus: "Error: " + ALERTS.API_OBJECTS_MISSING,
      });
      return;
    }

    let args = {
      apiKey,
      imeis,
    };

    if (apiKey === "live") {
      // Live Track
      API_UserLogin.setToken(localStorage.token);
      API_UserLogin.checkLogin().then((res) => {
        console.log("res: ", res);
        if (res.Response) {
          self.loadVehicles(args);
          self._timer = setInterval(() => {
            self.loadVehicles(args);
          }, DEFAULTS.VEHICLES_TIMER * 1000);
        } else {
          self.setState({
            loading: false,
            pageStatus: "Error: Session Expired..!",
          });
        }
      });
    } else {
      self.loadVehicles(args);
      self._timer = setInterval(() => {
        self.loadVehicles(args);
      }, DEFAULTS.VEHICLES_TIMER * 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this._timer);
    this._timer = null;
  }

  loadVehicles = (args = {}) => {
    let self = this;
    API_Map.getVehiclesNoSession(args)
      .then((res) => {
        if (res.Result === 200) {
          self.setState({
            vehicles: res.Response,
          });
        }
      })
      .catch((err) => {});
  };

  getMapMarker = ({ status, angle, imei, mapicon, arrow, icon }) => {
    let iconUrl = CONFIG.BASE_URL + icon;
    let customMarker = null;
    let width = CONFIG.MAP_ARROW_SIZE;
    if (mapicon === "arrow") {
      if (status === 0 || status === 1) {
        iconUrl = "https://fleetlogix.vaschan.com/img/markers/arrow_red.png";
      } else if (status === 2) {
        iconUrl = "https://fleetlogix.vaschan.com/img/markers/arrow_yellow.png";
      } else if (status === 3) {
        iconUrl = "https://fleetlogix.vaschan.com/img/markers/arrow_green.png";
      }
    } else {
      iconUrl = CONFIG.BASE_URL + arrow;
      width = CONFIG.MAP_CUSTOM_ARROW_SIZE;
    }

    const html =
      "<div class='movingMarker' style='transform: rotate(" +
      angle.toString() +
      "deg);'><img src='" +
      iconUrl +
      "' alt=''  width='" +
      width +
      "px'/></div>";

    customMarker = L.divIcon({
      html: html,
      iconSize: new L.Point(16, 16),
      iconAnchor: null,
      popupAnchor: [0, -3],
      className: "mapMarker",
    });

    return customMarker;
  };

  getTileUrl = (mapType) => {
    var url = CONFIG.OSM_TILE_URL;
    switch (mapType) {
      case 1:
        url = CONFIG.GOOGLE_STREETS_MAP_URL;
        break;
      case 2:
        url = CONFIG.GOOGLE_SATELLITE_MAP_URL;
        break;
      case 3:
        url = CONFIG.GOOGLE_HYBRID_MAP_URL;
        break;
      case 4:
        url = CONFIG.MAP_BOX_TILE_URL_STREETS;
        break;
      case 5:
        url = CONFIG.MAP_BOX_TILE_URL_SATELLITE;
        break;
      default:
        break;
    }
    return url;
  };

  onDragMap = (e) => {
    const center = e.target.getCenter();
    const zoom = e.target.getZoom();
    let mapLocalStorage = commonJs.getItemFromLocal(LOCAL_STORAGE.MAP);
    if (mapLocalStorage === null) {
      mapLocalStorage = {};
    }
    mapLocalStorage.initialLat = center.lat;
    mapLocalStorage.initialLng = center.lng;
    mapLocalStorage.zoom = zoom;
    commonJs.setItemToLocal(LOCAL_STORAGE.MAP, mapLocalStorage);
  };

  mapClick = (e) => {
    // if (document.querySelector("div#addPoint")) {
    //   if (!document.querySelector("div#addPoint").getAttribute("latlng")) {
    //     // Add the Point Marker to MAP
    //     this._points.addPointMarker(e.latlng.lat, e.latlng.lng);
    //   }
    // }
  };

  markerClick = (imei) => {
    var self = this;
    let apiKey = commonJs.getUrlParameter("key");
    let args = { imei };
    if (apiKey === "live") {
      API_Map.getVehicleData(args).then((res) => {
        if (res.Result === 200) {
          self.setState({
            vehicleInfo: res.Response,
          });
        }
      });
    } else {
      args.apiKey = apiKey;
      API_Map.getVehicleDataNoSession(args).then((res) => {
        if (res.Result === 200) {
          self.setState({
            vehicleInfo: res.Response,
          });
        }
      });
    }
  };

  setMapTypeFilter = (indx) => {
    this.setState({
      mapType: indx,
    });
  };

  onMapTypeToggle = () => {
    const { mapTypeOpen } = this.state;
    this.setState({ mapTypeOpen: !mapTypeOpen });
  };

  onMapTypeClose = (event) => {
    if (this.anchorEl3.contains(event.target)) {
      return;
    }
    this.setState({ mapTypeOpen: false });
  };

  render() {
    const {
      vehicles,
      mapPosition,
      zoom,
      mapType,
      vehicleInfo,
      mapTypeOpen,
      loading,
      pageStatus,
    } = this.state;
    const { privileges, role } = this.props.loginReducer;
    const hasMarkerAnimation = commonJs.hasPrivilege(
      PRIVILEGES.MARKER_ANIMATION,
      role,
      privileges
    );

    const tileUrl = this.getTileUrl(mapType);

    const mapTypes = DEFAULTS.DEFAULT_MAPTYPES_LIST;

    return vehicles ? (
      <div id="liveTrack">
        <div id="mapContainer" className={"mapContainer"}>
          <Map
            center={mapPosition}
            zoom={zoom}
            maxZoom={DEFAULTS.MAX_ZOOM}
            minZoom={DEFAULTS.MIN_ZOOM}
            viewport={null}
            onClick={this.mapClick}
            className="fleetMap"
            onMoveend={this.onDragMap}
            ref={(ref) => {
              this._map = ref;
            }}
          >
            <ZoomControl position="bottomright" className="mapZoomControl" />
            <ScaleControl position="topright" className="mapScaleControl" />
            <TileLayer url={tileUrl} />
            {/* With Marker Animation */}
            {hasMarkerAnimation && vehicles[0] && (
              <DriftMarker
                // if position changes, marker will drift its way to new position
                position={[vehicles[0].latitude, vehicles[0].longitude]}
                // time in ms that marker will take to reach its destination
                duration={30 * 1000}
                icon={this.getMapMarker(vehicles[0])}
                keepAtCenter={true}
              >
                <MapPopup
                  vehicle={vehicles[0]}
                  data={vehicleInfo}
                  options={false}
                />
                <Tooltip direction="right" offset={[10, -2]} permanent>
                  <span>
                    {vehicles[0].name +
                      " (" +
                      vehicles[0].speed.toFixed(1) +
                      " " +
                      UNITS.SPEED +
                      ")"}
                  </span>
                </Tooltip>
              </DriftMarker>
            )}
            :
            {!hasMarkerAnimation && (
              <FeatureGroup
                ref={(ref) => {
                  this._mapGroup = ref;
                }}
              >
                {" "}
                <MarkerClusterGroup
                  iconCreateFunction={createClusterCustomIcon}
                  showCoverageOnHover={true}
                >
                  {vehicles.map((vehicle) => {
                    return (
                      <Marker
                        position={[vehicle.latitude, vehicle.longitude]}
                        icon={this.getMapMarker(vehicle)}
                        key={"mapMarker-" + vehicle.imei}
                        ref={vehicle.imei}
                        onclick={() => this.markerClick(vehicle.imei)}
                        className="mapMarker"
                      >
                        <MapPopup
                          vehicle={vehicle}
                          data={vehicleInfo}
                          options={false}
                        />
                        <Tooltip direction="right" offset={[10, -2]} permanent>
                          <span>
                            {vehicle.name +
                              " (" +
                              vehicle.speed.toFixed(1) +
                              " " +
                              UNITS.SPEED +
                              ")"}
                          </span>
                        </Tooltip>
                      </Marker>
                    );
                  })}
                </MarkerClusterGroup>
              </FeatureGroup>
            )}
          </Map>
        </div>
        <div id="bottomBar">
          {/* Map Type Filter */}
          <Button
            buttonRef={(node) => {
              this.anchorEl3 = node;
            }}
            aria-owns={mapTypeOpen ? "mapTypeList" : undefined}
            aria-haspopup="true"
            onClick={this.onMapTypeToggle}
          >
            Map Type
            <span className="mapTypeIcon">
              {mapTypeOpen ? <ArrowDropDown /> : <ArrowDropUp />}
            </span>
          </Button>
          <ClickAwayListener onClickAway={this.onMapTypeClose}>
            <Popper
              open={mapTypeOpen}
              anchorEl={this.anchorEl3}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="mapTypeList"
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <List className="mapTypeList">
                      {mapTypes.map((value, index) => {
                        let checked = false;
                        if (mapType === index) {
                          checked = true;
                        }
                        return (
                          <ListItem
                            key={"maptype-" + index}
                            role={undefined}
                            dense
                            button
                            onClick={() => this.setMapTypeFilter(index)}
                            className="mapTypeItem"
                          >
                            <ListItemAvatar>
                              <Img src={gmap} width="15" />
                            </ListItemAvatar>
                            <ListItemText
                              primary={value}
                              className="mapTypeLabel"
                            />
                            <ListItemSecondaryAction className="mapTypeItemAction">
                              <Checkbox
                                onClick={() => this.setMapTypeFilter(index)}
                                checked={checked}
                                tabIndex={-1}
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </ClickAwayListener>
        </div>
      </div>
    ) : loading ? (
      <PageLoader />
    ) : (
      <div id="liveTrack">
        <p id="msg">{pageStatus}</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveTrack);
