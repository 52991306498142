import { SdkClient } from "fgxsdk";
import { CONFIG } from "common/consts";

SdkClient.setUrl(CONFIG.BASE_URL);

export const API_Reports = {
  //Signal Report: Shows the vehicle signal data
  getSignalReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      imeis: ["73383121230"], // If empty -> Check for all vehicles
      fromDate: "2020-07-01",
      toDate: "2020-07-05",
    };
    return {
      Result: 200,
      Response: {
        cols: [
          "Sl No.",
          "Vehicle No.",
          "Group Name",
          "01",
          "02",
          "03",
          "04",
          "05",
        ],
        rows: [
          ["1", "KA02AB-9292-TEMP", "Chaitanya", "t", "f", "f", "t", "t"],
          ["2", "KA02AF-3472-TEMP", "Chaitanya", "t", "t", "t", "t", "f"],
        ],
        hasNext: true,
      },
    };
  },

  getTravelHistoryReport: async (args) => {
    //  args = {
    //   pageNo: 1,
    //   pageLength: 30,
    //   imeis: "73383121230",
    //   fromDate: "2020-07-01",
    //   toDate: "2020-07-05",
    // };
    return {
      Result: 200,
      Response: {
        cols: [
          "Sl No.",
          "Date",
          "Vehicle",
          "Route Length (Kms)",
          "More Duration",
          "Stop Duration",
          "No Signal Duration",
        ],
        rows: [
          [
            "1",
            "2020-07-01",
            "KA02AB-9292-TEMP",
            "0.00",
            "0s",
            "3h 17min 14s",
            "-",
          ],
          [
            "2",
            "2020-07-02",
            "KA02AF-3472-TEMP",
            "0.00",
            "0s",
            "4h 17min 10s",
            "-",
          ],
        ],
        hasNext: true,
      },
    };
  },

  getRouteDeviationReport: async (args) => {
    // args = {
    //   pageNo: 1,
    //   pageLength: 30,
    //   imei: "73383121230",
    //   fromDate: "2020-07-01",
    //   toDate: "2020-07-05",
    //   routeId: "11012",
    // };
    return {
      Result: 200,
      Response: {
        cols: ["Status"],
        rows: [["-"], ["-"]],
        hasNext: true,
      },
    };
  },

  // Non - Performance Report
  getNonPerformanceReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      fromDate: "2020-08-01 00:00:00",
      toDate: "2020-08-05 00:00:00",
      fromKms: 0,
      toKms: 10,
    };
    return {
      Result: 200,
      Response: {
        cols: [
          "Sl No.",
          "Vehicle Name",
          "Vehicle Type",
          "Travel Distance",
          "Route View",
        ],
        rows: [
          ["1", "KA51AA8849", "EIC11.10-21.6C", "8.38", ""],
          ["2", "KA51AA8849", "EIC11.10-21.6C", "8.38", ""],
        ],
        hasNext: true,
      },
    };
  },

  //Groupwise Temperature By Hour Report
  getGroupwiseTempByHourReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      fromDate: "2020-08-01 00:00:00",
      toDate: "2020-08-05 00:00:00",
      vehicleId: "73383121230",
      everyMin: "30min",
    };
    return {
      Result: 200,
      Response: {
        cols: [
          "Sl No.",
          "Vehicle No",
          "00:00",
          "00:30",
          "01:00",
          "01:30",
          "02:00",
          "02:30",
          "03:00",
          "03:30",
          "04:00",
          "04:30",
          "05:00",
          "05:30",
          "06:00",
          "06:30",
          "07:00",
          "07:30",
          "08:00",
          "08:30",
          "09:00",
          "09:30",
          "10:00",
          "10:30",
          "11:00",
          "11:30",
          "12:00",
          "12:30",
          "13:00",
          "13:30",
          "14:00",
          "14:30",
          "15:00",
          "15:30",
          "16:00",
          "16:30",
          "17:00",
          "17:30",
          "18:00",
          "18:30",
          "19:00",
          "19:30",
          "20:00",
          "20:30",
          "21:00",
          "21:30",
          "22:00",
          "22:30",
          "23:00",
          "23:30",
          "Max",
          "Min",
        ],
        rows: [
          ["1", "KA51AA8849", "", "", "", "", "", ""],
          ["2", "KA51AA8849", "", "", "", "", ""],
        ],
        hasNext: true,
      },
    };
  },

  // ETA Summary Report
  getNonPerformanceReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      fromDate: "2020-08-01 00:00:00",
      toDate: "2020-08-05 00:00:00",
    };
    return {
      Result: 200,
      Response: {
        cols: [
          "Sl No.",
          "Unique ID",
          "Route Code",
          "Route Dispatch Date",
          "Chunk/Task ID",
          "Geofence Entry Time",
          "Geofence Exit Time",
          "Stoppage inside Geofence Time",
        ],
        rows: [
          [
            "1",
            "1011",
            "",
            "",
            "",
            "2020-07-05 13:42:59",
            "2020-07-05 15:26:31",
            "",
          ],
          [
            "2",
            "1012",
            "",
            "",
            "",
            "2020-07-06 13:42:59",
            "2020-07-06 15:26:31",
            "",
          ],
        ],
        hasNext: true,
      },
    };
  },

  // Fuel Timeline Report
  getFuelTimelineReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      imei: "73383121230",
      fromDate: "2020-08-01 00:00:00",
      toDate: "2020-08-31 23:59:59",
      stopTime: 15,
    };
    return {
      Result: 200,
      Response: {
        cols: ["Sl No.", "Date", "Fuel Value"],
        rows: [
          ["1", "2020-08-02 00:00:00", "370.17"],
          ["2", "2020-08-02 00:15:00", "370.00"],
          ["3", "2020-08-02 00:17:00", "400.00"],
          ["4", "2020-08-02 00:20:00", "500.22"],
          ["5", "2020-08-02 00:25:00", "590.90"],
        ],
        hasNext: true,
      },
    };
  },

  // Temperature Graph Report
  getTemperatureGraphReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      imei: "73383121230",
      fromDate: "2020-08-01 00:00:00",
      toDate: "2020-08-31 23:59:59",
      stopTime: 15,
    };
    return {
      Result: 200,
      Response: {
        cols: ["Sl No.", "Date", "Temperature (in C)"],
        rows: [
          ["1", "2020-08-02 00:00:00", "15.4"],
          ["2", "2020-08-02 00:15:00", "20.3"],
          ["3", "2020-08-02 00:20:00", "30.2"],
          ["4", "2020-08-02 00:30:00", "30.6"],
        ],
        hasNext: true,
      },
    };
  },

  // getGraphs: async (args) => {
  //   return {
  //     Result: 200,
  //     Response: {
  //       data: [
  //         {
  //           date: "1167609600000",
  //           value: "0.7537",
  //         },
  //         {
  //           date: "1167696000000",
  //           value: "0.7537",
  //         },
  //         {
  //           date: "1167782400000",
  //           value: "0.7559",
  //         },
  //         {
  //           date: "1167868800000",
  //           value: "0.7631",
  //         },

  //         { date: "1167955200000", value: " 0.7644" },
  //         { date: "1168214400000", value: "0.769" },
  //       ],
  //     },
  //   };
  // },
};
