import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Breadcrumbs } from "@material-ui/core";
import {
  DirectionsBus as AllVehiclesIcon,
  People as UsersIcon,
  Business as CompaniesIcon,
} from "@material-ui/icons";

import { CPANEL_DESCRIPTION } from "common/consts";
import { REPORT_ACTIONS } from "actions/ReportActions";
import { API_CPanel } from "apihandler/CPanel";
import DashboardPopup from "./DashboardPopup/index";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      totalUsers: 0,
      activeVehicles: 0,
      inactiveVehicles: 0,
      totalCompanies: 0,
      showPopup: false,
      popupTitle: "",
    };
  }

  componentDidMount() {
    document.getElementById("root").classList.add("scroll");
    document.getElementById("root").classList.remove("login");
    this.getDashboardData();
  }

  togglePopup = (showPopup = false, popupTitle = "") => {
    this.setState({ showPopup, popupTitle });
  };

  onMenuClick = (menu) => {
    if (menu === "home") {
      window.open("/", "_self");
    }
  };

  getDashboardData = () => {
    let self = this;
    self.props.setLoading({
      loading: true,
    });
    // API_UserLogin.setToken(localStorage.token);
    API_CPanel.getCpanelSummary()
      .then((res) => {
        console.log("getCpanelSummary", res);
        if (res.Result === 200) {
          const {
            totalUsers,
            activeVehicles,
            inactiveVehicles,
            totalCompanies,
          } = res.Response;

          self.setState({
            totalUsers,
            activeVehicles,
            inactiveVehicles,
            totalCompanies,
          });
          self.props.setLoading({
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const {
      totalUsers,
      activeVehicles,
      inactiveVehicles,
      totalCompanies,
      showPopup,
      id,
      name,
      popupTitle,
    } = this.state;
    const { length } = this.props;
    return (
      <div className="cpanelPageWrapper">
        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
          <Link
            color="inherit"
            href="/cpanel"
            onClick={() => this.onMenuClick("home")}
          >
            Home
          </Link>
          <p>Dashboard</p>
        </Breadcrumbs>
        <p className="reportDescription">{CPANEL_DESCRIPTION.DASHBOARD}</p>

        <div className="summaryWrapper" style={{ flexDirection: "column" }}>
          <div
            className="summaryBox bgBlue"
            onClick={() => this.togglePopup(!showPopup, "Total Devices")}
          >
            <div className="summaryBoxHeader">
              <AllVehiclesIcon />
            </div>
            <div className="summaryBoxBody">
              <h4>{activeVehicles + inactiveVehicles}</h4>
              <p>Total Devices</p>
            </div>
          </div>
          <div
            className="summaryBox bgGreen"
            onClick={() => this.togglePopup(!showPopup, "Active Devices")}
          >
            <div className="summaryBoxHeader">
              <AllVehiclesIcon />
            </div>
            <div className="summaryBoxBody">
              <h4>{activeVehicles}</h4>
              <p>Active Devices</p>
            </div>
          </div>
          <div
            className="summaryBox bgRed"
            onClick={() => this.togglePopup(!showPopup, "Inactive Devices")}
          >
            <div className="summaryBoxHeader">
              <AllVehiclesIcon />
            </div>
            <div className="summaryBoxBody">
              <h4>{inactiveVehicles}</h4>
              <p>Inactive Devices</p>
            </div>
          </div>
          <div
            className="summaryBox bgPurple"
            onClick={() => this.togglePopup(!showPopup, "Total Users")}
          >
            <div className="summaryBoxHeader">
              <UsersIcon />
            </div>
            <div className="summaryBoxBody">
              <h4>{totalUsers}</h4>
              <p>Total Users</p>
            </div>
          </div>
          <div
            className="summaryBox bgOrange"
            onClick={() => this.togglePopup(!showPopup, "Total Companies")}
          >
            <div className="summaryBoxHeader">
              <CompaniesIcon />
            </div>
            <div className="summaryBoxBody">
              <h4>{totalCompanies}</h4>
              <p>Total Companies</p>
            </div>
          </div>
        </div>

        {showPopup && (
          <DashboardPopup
            title={popupTitle}
            total={length}
            togglePopup={this.togglePopup}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cpanelReducer: state.cpanelReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (values) => {
      dispatch({
        type: REPORT_ACTIONS.SET_LOADING,
        values,
      });
    },
    // setDashboard: (values) => {
    //   dispatch({
    //     type: CPANEL_ACTIONS.SET_DASHBOARD,
    //     values,
    //   });
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
