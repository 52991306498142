import { MAP_CONTROLS_ACTIONS } from "../actions/MapControlsActions";
import pointImgIcon from "../images/icons/points/home.png";

let initialState = {
  zoneVertices: [],
  pointId: "",
  pointLatLng: {},
  routeId: "",
  routeLatLng: [],
  pointImg: { imageIndex: 18, image: pointImgIcon },
};

const mapControlsReducer = (state = initialState, action) => {
  const { type, values } = action;
  switch (type) {
    case MAP_CONTROLS_ACTIONS.SET_POINT_IMG:
      return {
        ...state,
        pointImg: values.pointImg,
      };
    case MAP_CONTROLS_ACTIONS.SET_ZONE_EDIT:
      return {
        ...state,
        zoneVertices: values.zoneVertices,
      };
    case MAP_CONTROLS_ACTIONS.SET_POINT_EDIT:
      return {
        ...state,
        pointId: values.pointId,
      };
    case MAP_CONTROLS_ACTIONS.SET_POINT_LATLNG:
      return {
        ...state,
        pointLatLng: values.pointLatLng,
      };
    case MAP_CONTROLS_ACTIONS.SET_ROUTE_EDIT:
      return {
        ...state,
        routeId: values.routeId,
      };
    case MAP_CONTROLS_ACTIONS.SET_ROUTE_LATLNG:
      return {
        ...state,
        routeLatLng: values.routeLatLng,
      };
    default:
      break;
  }
  return state;
};

export default mapControlsReducer;
