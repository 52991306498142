import React, { Component } from "react";
import {
  SwipeableDrawer,
  ListSubheader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  Assessment as DashboardIcon,
  Assignment as SubMenuIcon,
  AcUnit as SensorIcon,
  LocalShipping as TruckIcon,
  FilterTiltShift as PoiIcon,
  Equalizer as AnalysisIcon,
  TrendingUp as PerformanceIcon,
} from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRoad as KmIcon,
  faRoute as TravelIcon,
} from "@fortawesome/free-solid-svg-icons";

import {
  LABELS,
  DEFAULTS,
  REPORT_NAMES,
  ROLES,
  PRIVILEGES,
} from "common/consts";
import { commonJs } from "common/common";

class ReportLeftBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leftFixedMenu: true,
      sideBarMenu: "",
    };
  }

  onToggleMenu = (menu = "") => {
    const sideBarMenu = this.state.sideBarMenu === menu ? "" : menu;
    this.setState({ sideBarMenu });
  };

  onMenuClick = (menu = "") => {
    this.props.onMenuChange(menu);
    this.onToggleMenu("");
    if (menu === "home") {
      window.open("/", "_self");
    }
  };

  getMainMenuPrivileges = (mainMenu = "") => {
    const { role, privileges } = this.props.loginReducer;
    console.log("getMainMenuPrivileges: ", privileges, role);
    let result = [];
    switch (mainMenu) {
      case "km-reports":
        let kmSummaryReport = commonJs.hasPrivilege(
          PRIVILEGES.REPORT_KM_SUMMARY,
          role,
          privileges
        );
        if (kmSummaryReport === true) {
          result.push(PRIVILEGES.REPORT_KM_SUMMARY);
        }
        break;
      case "sensor-reports":
        let temperatureReport = commonJs.hasPrivilege(
          PRIVILEGES.REPORT_TEMP,
          role,
          privileges
        );
        let fuelReport = commonJs.hasPrivilege(
          PRIVILEGES.REPORT_FUEL,
          role,
          privileges
        );
        if (temperatureReport === true) {
          result.push(PRIVILEGES.REPORT_TEMP);
        }
        if (fuelReport === true) {
          result.push(PRIVILEGES.REPORT_FUEL);
        }
        break;
      case "poi-reports":
        let zoneInOutReport = commonJs.hasPrivilege(
          PRIVILEGES.REPORT_ZONE_IN_OUT,
          role,
          privileges
        );
        if (zoneInOutReport === true) {
          result.push(PRIVILEGES.REPORT_ZONE_IN_OUT);
        }
        break;
      case "travel-reports":
        let travelReport = commonJs.hasPrivilege(
          PRIVILEGES.REPORT_TRAVEL,
          role,
          privileges
        );
        let dailyTravelReport = commonJs.hasPrivilege(
          PRIVILEGES.REPORT_DAILY_TRAVEL,
          role,
          privileges
        );
        let journeyReport = commonJs.hasPrivilege(
          PRIVILEGES.REPORT_DRIVE_STOP,
          role,
          privileges
        );
        if (travelReport === true) {
          result.push(PRIVILEGES.REPORT_TRAVEL);
        }
        if (dailyTravelReport === true) {
          result.push(PRIVILEGES.REPORT_DAILY_TRAVEL);
        }
        if (journeyReport === true) {
          result.push(PRIVILEGES.REPORT_DRIVE_STOP);
        }
        break;
      case "analysis-reports":
        break;
      case "performance-reports":
        break;
      default:
        break;
    }
    return result;
  };

  render() {
    const { sideBarMenu } = this.state;
    const { role } = this.props.loginReducer;
    const { menu } = this.props;
    const dashboardBtn =
      role === ROLES.ADMIN ||
      role === ROLES.SUPER_ADMIN ||
      role === ROLES.MANAGER;
    const kmReportPrivileges = this.getMainMenuPrivileges("km-reports");
    const sensorReportPrivileges = this.getMainMenuPrivileges("sensor-reports");
    const poiReportPrivileges = this.getMainMenuPrivileges("poi-reports");
    const travelReportPrivileges = this.getMainMenuPrivileges("travel-reports");
    const analysisReportPrivileges = this.getMainMenuPrivileges(
      "analysis-reports"
    );
    const performanceReportPrivileges = this.getMainMenuPrivileges(
      "performance-reports"
    );
    console.log("kmReportPrivileges: ", kmReportPrivileges);
    return (
      <div className="report-leftbar">
        <div className={"leftFixedMenu"}>
          <ul className="topmenu">
            {dashboardBtn && (
              <li onClick={() => this.onMenuClick("dashboard")}>
                <Tooltip title={LABELS.DASHBOARD} placement="right">
                  <IconButton
                    classes={{ root: "menucolor" }}
                    className={
                      menu === "dashboard" && sideBarMenu === "" ? "active" : ""
                    }
                  >
                    <DashboardIcon />
                  </IconButton>
                </Tooltip>
              </li>
            )}
            <li onClick={() => this.onToggleMenu("vehicle-reports")}>
              <Tooltip title={"Vehicle Reports"} placement="right">
                <IconButton
                  classes={{ root: "menucolor" }}
                  className={sideBarMenu === "vehicle-reports" ? "active" : ""}
                >
                  <TruckIcon />
                </IconButton>
              </Tooltip>
            </li>
            {kmReportPrivileges.length > 0 && (
              <li onClick={() => this.onToggleMenu("km-reports")}>
                <Tooltip title={"Km Reports"} placement="right">
                  <IconButton
                    classes={{ root: "menucolor" }}
                    className={sideBarMenu === "km-reports" ? "active" : ""}
                  >
                    <FontAwesomeIcon icon={KmIcon} />
                  </IconButton>
                </Tooltip>
              </li>
            )}
            {sensorReportPrivileges.length > 0 && (
              <li onClick={() => this.onToggleMenu("sensor-reports")}>
                <Tooltip title={"Sensor Reports"} placement="right">
                  <IconButton
                    classes={{ root: "menucolor" }}
                    className={sideBarMenu === "sensor-reports" ? "active" : ""}
                  >
                    <SensorIcon />
                  </IconButton>
                </Tooltip>
              </li>
            )}
            {poiReportPrivileges.length > 0 && (
              <li onClick={() => this.onToggleMenu("poi-reports")}>
                <Tooltip title={"POI Reports"} placement="right">
                  <IconButton
                    classes={{ root: "menucolor" }}
                    className={sideBarMenu === "poi-reports" ? "active" : ""}
                  >
                    <PoiIcon />
                  </IconButton>
                </Tooltip>
              </li>
            )}
            {travelReportPrivileges.length > 0 && (
              <li onClick={() => this.onToggleMenu("travel-reports")}>
                <Tooltip title={"Travel Reports"} placement="right">
                  <IconButton
                    classes={{ root: "menucolor" }}
                    className={sideBarMenu === "travel-reports" ? "active" : ""}
                  >
                    <FontAwesomeIcon icon={TravelIcon} />
                  </IconButton>
                </Tooltip>
              </li>
            )}
            {analysisReportPrivileges.length > 0 && (
              <li onClick={() => this.onToggleMenu("analysis-reports")}>
                <Tooltip title={"Analysis Reports"} placement="right">
                  <IconButton
                    classes={{ root: "menucolor" }}
                    className={
                      sideBarMenu === "analysis-reports" ? "active" : ""
                    }
                  >
                    <AnalysisIcon />
                  </IconButton>
                </Tooltip>
              </li>
            )}
            {performanceReportPrivileges.length > 0 && (
              <li onClick={() => this.onToggleMenu("performance-reports")}>
                <Tooltip title={"Performance Reports"} placement="right">
                  <IconButton
                    classes={{ root: "menucolor" }}
                    className={
                      sideBarMenu === "performance-reports" ? "active" : ""
                    }
                  >
                    <PerformanceIcon />
                  </IconButton>
                </Tooltip>
              </li>
            )}
          </ul>
        </div>

        {/* Performance Reports */}
        <SwipeableDrawer
          open={sideBarMenu === "performance-reports"}
          onClose={() => this.onMenuToggle("performance-reports", false)}
          onOpen={() => this.onMenuToggle("performance-reports", true)}
          variant={DEFAULTS.LEFT_BAR_VARIENT}
          className={
            "leftdrawer " +
            (sideBarMenu === "performance-reports" ? "open" : "")
          }
          classes={{ paper: "leftdrawer-menu", modal: "fleetmodal" }}
        >
          <div className="reportSideBarWrapper">
            <List
              component="nav"
              subheader={
                <ListSubheader component="div">
                  {"Performance Reports"}
                </ListSubheader>
              }
              className="menu"
            ></List>
          </div>
        </SwipeableDrawer>

        {/* Analysis Reports */}
        <SwipeableDrawer
          open={sideBarMenu === "analysis-reports"}
          onClose={() => this.onMenuToggle("analysis-reports", false)}
          onOpen={() => this.onMenuToggle("analysis-reports", true)}
          variant={DEFAULTS.LEFT_BAR_VARIENT}
          className={
            "leftdrawer " + (sideBarMenu === "analysis-reports" ? "open" : "")
          }
          classes={{ paper: "leftdrawer-menu", modal: "fleetmodal" }}
        >
          <div className="reportSideBarWrapper">
            <List
              component="nav"
              subheader={
                <ListSubheader component="div">
                  {"Analysis Reports"}
                </ListSubheader>
              }
              className="menu"
            ></List>
          </div>
        </SwipeableDrawer>

        {/* Travel Reports */}
        <SwipeableDrawer
          open={sideBarMenu === "travel-reports"}
          onClose={() => this.onMenuToggle("travel-reports", false)}
          onOpen={() => this.onMenuToggle("travel-reports", true)}
          variant={DEFAULTS.LEFT_BAR_VARIENT}
          className={
            "leftdrawer " + (sideBarMenu === "travel-reports" ? "open" : "")
          }
          classes={{ paper: "leftdrawer-menu", modal: "fleetmodal" }}
        >
          <div className="reportSideBarWrapper">
            <List
              component="nav"
              subheader={
                <ListSubheader component="div">
                  {"Travel Reports"}
                </ListSubheader>
              }
              className="menu"
            >
              {travelReportPrivileges.indexOf(PRIVILEGES.REPORT_DRIVE_STOP) >
                -1 && (
                <ListItem
                  button
                  onClick={() => this.onMenuClick("journey-report")}
                  className={
                    "report-menu " + (menu === "journey-report" ? "active" : "")
                  }
                >
                  <ListItemIcon className="menuicon">
                    <SubMenuIcon />
                  </ListItemIcon>
                  <ListItemText primary={REPORT_NAMES.JOURNEY_REPORT} />
                </ListItem>
              )}
              {travelReportPrivileges.indexOf(PRIVILEGES.REPORT_DAILY_TRAVEL) >
                -1 && (
                <ListItem
                  button
                  onClick={() => this.onMenuClick("dailytravel-report")}
                  className={
                    "report-menu " +
                    (menu === "dailytravel-report" ? "active" : "")
                  }
                >
                  <ListItemIcon className="menuicon">
                    <SubMenuIcon />
                  </ListItemIcon>
                  <ListItemText primary={REPORT_NAMES.DIALY_TRAVEL_REPORT} />
                </ListItem>
              )}
              {travelReportPrivileges.indexOf(PRIVILEGES.REPORT_TRAVEL) >
                -1 && (
                <ListItem
                  button
                  onClick={() => this.onMenuClick("travel-report")}
                  className={
                    "report-menu " + (menu === "travel-report" ? "active" : "")
                  }
                >
                  <ListItemIcon className="menuicon">
                    <SubMenuIcon />
                  </ListItemIcon>
                  <ListItemText primary={REPORT_NAMES.TRAVEL_REPORT} />
                </ListItem>
              )}
            </List>
          </div>
        </SwipeableDrawer>

        {/* POI Reports */}
        <SwipeableDrawer
          open={sideBarMenu === "poi-reports"}
          onClose={() => this.onMenuToggle("poi-reports", false)}
          onOpen={() => this.onMenuToggle("poi-reports", true)}
          variant={DEFAULTS.LEFT_BAR_VARIENT}
          className={
            "leftdrawer " + (sideBarMenu === "poi-reports" ? "open" : "")
          }
          classes={{ paper: "leftdrawer-menu", modal: "fleetmodal" }}
        >
          <div className="reportSideBarWrapper">
            <List
              component="nav"
              subheader={
                <ListSubheader component="div">{"POI Reports"}</ListSubheader>
              }
              className="menu"
            >
              {poiReportPrivileges.indexOf(PRIVILEGES.REPORT_ZONE_IN_OUT) >
                -1 && (
                <ListItem
                  button
                  onClick={() => this.onMenuClick("zone-in-out-report")}
                  className={
                    "report-menu " +
                    (menu === "zone-in-out-report" ? "active" : "")
                  }
                >
                  <ListItemIcon className="menuicon">
                    <SubMenuIcon />
                  </ListItemIcon>
                  <ListItemText primary={REPORT_NAMES.ZONEINOUT_REPORT} />
                </ListItem>
              )}
              {/* <ListItem
                button
                onClick={() => this.onMenuClick("trip-report")}
                className={
                  "report-menu " + (menu === "trip-report" ? "active" : "")
                }
              >
                <ListItemIcon className="menuicon">
                  <SubMenuIcon />
                </ListItemIcon>
                <ListItemText primary={REPORT_NAMES.TRIP_REPORT} />
              </ListItem> */}
            </List>
          </div>
        </SwipeableDrawer>

        {/* Sensor Reports */}
        <SwipeableDrawer
          open={sideBarMenu === "sensor-reports"}
          onClose={() => this.onMenuToggle("sensor-reports", false)}
          onOpen={() => this.onMenuToggle("sensor-reports", true)}
          variant={DEFAULTS.LEFT_BAR_VARIENT}
          className={
            "leftdrawer " + (sideBarMenu === "sensor-reports" ? "open" : "")
          }
          classes={{ paper: "leftdrawer-menu", modal: "fleetmodal" }}
        >
          <div className="reportSideBarWrapper">
            <List
              component="nav"
              subheader={
                <ListSubheader component="div">
                  {"Sensor Reports"}
                </ListSubheader>
              }
              className="menu"
            >
              {sensorReportPrivileges.indexOf(PRIVILEGES.REPORT_TEMP) > -1 && (
                <ListItem
                  button
                  onClick={() => this.onMenuClick("temperature-report")}
                  className={
                    "report-menu " +
                    (menu === "temperature-report" ? "active" : "")
                  }
                >
                  <ListItemIcon className="menuicon">
                    <SubMenuIcon />
                  </ListItemIcon>
                  <ListItemText primary={REPORT_NAMES.TEMP_REPORT} />
                </ListItem>
              )}
              {sensorReportPrivileges.indexOf(PRIVILEGES.REPORT_FUEL) > -1 && (
                <ListItem
                  button
                  onClick={() => this.onMenuClick("fuel-report")}
                  className={
                    "report-menu " + (menu === "fuel-report" ? "active" : "")
                  }
                >
                  <ListItemIcon className="menuicon">
                    <SubMenuIcon />
                  </ListItemIcon>
                  <ListItemText primary={REPORT_NAMES.FUEL_REPORT} />
                </ListItem>
              )}
            </List>
          </div>
        </SwipeableDrawer>

        {/* KM Reports */}
        <SwipeableDrawer
          open={sideBarMenu === "km-reports"}
          onClose={() => this.onMenuToggle("km-reports", false)}
          onOpen={() => this.onMenuToggle("km-reports", true)}
          variant={DEFAULTS.LEFT_BAR_VARIENT}
          className={
            "leftdrawer " + (sideBarMenu === "km-reports" ? "open" : "")
          }
          classes={{ paper: "leftdrawer-menu", modal: "fleetmodal" }}
        >
          <div className="reportSideBarWrapper">
            <List
              component="nav"
              subheader={
                <ListSubheader component="div">{"Km Reports"}</ListSubheader>
              }
              className="menu"
            >
              {kmReportPrivileges.indexOf(PRIVILEGES.REPORT_KM_SUMMARY) >
                -1 && (
                <ListItem
                  button
                  onClick={() => this.onMenuClick("km-summary-report")}
                  className={
                    "report-menu " +
                    (menu === "km-summary-report" ? "active" : "")
                  }
                >
                  <ListItemIcon className="menuicon">
                    <SubMenuIcon />
                  </ListItemIcon>
                  <ListItemText primary={REPORT_NAMES.KM_REPORT} />
                </ListItem>
              )}
            </List>
          </div>
        </SwipeableDrawer>

        {/* Vehicles Reports */}
        <SwipeableDrawer
          open={sideBarMenu === "vehicle-reports"}
          onClose={() => this.onMenuToggle("vehicle-reports", false)}
          onOpen={() => this.onMenuToggle("vehicle-reports", true)}
          variant={DEFAULTS.LEFT_BAR_VARIENT}
          className={
            "leftdrawer " + (sideBarMenu === "vehicle-reports" ? "open" : "")
          }
          classes={{ paper: "leftdrawer-menu", modal: "fleetmodal" }}
        >
          <div className="reportSideBarWrapper">
            <List
              component="nav"
              subheader={
                <ListSubheader component="div">
                  {"Vehicles Reports"}
                </ListSubheader>
              }
              className="menu"
            >
              <ListItem
                button
                onClick={() => this.onMenuClick("vehicles-information-report")}
                className={
                  "report-menu " +
                  (menu === "vehicles-information-report" ? "active" : "")
                }
              >
                <ListItemIcon className="menuicon">
                  <SubMenuIcon />
                </ListItemIcon>
                <ListItemText primary={REPORT_NAMES.VEHICLE_INFO_REPORT} />
              </ListItem>
            </List>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportLeftBar);
