import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  Replay as Reload,
  AddCircleOutline as Add,
  Edit,
} from "@material-ui/icons";

import SearchButton from "components/ui/searchbutton/SearchButton";
import FleetGrid from "components/ui/grid/FleetGrid";
import ConfirmDialog from "components/ui/confirmdialog/ConfirmDialog";

import CompanyDialog from "./PrivilegesDialog";
import { API_CPanel } from "apihandler/CPanel";
import { DEFAULTS, LABELS } from "common/consts";

class Privileges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: "slno", title: "Sl No." },
        { name: "id", title: "ID" },
        { name: "privilege", title: "Privilege" },
        { name: "options", title: "Options" },
      ],
      rows: null,
      filteredRows: [],
    };
  }

  componentDidMount() {
    this.loadGrid();
  }

  loadGrid = () => {
    let self = this;
    API_CPanel.getAllUserPrivileges()
      .then((res) => {
        if (res.Result === 200) {
          let rows = res.Response;
          for (let r = 0; r < rows.length; r++) {
            rows[r].options = this.getOptions({
              id: rows[r].id,
              name: rows[r].privilege,
            });
            rows[r].slno = r + 1;
            rows[r].name = rows[r].privilege;
          }

          // Check if any text in Search Box
          let searchQry = document
            .getElementById("cpanelPrivilegeSearch")
            .value.trim()
            .toUpperCase();
          let filteredRows = [];
          if (searchQry.length > 0) {
            filteredRows = rows.filter((row) => {
              return row.name.toUpperCase().indexOf(searchQry) > -1;
            });
          }

          self.setState({
            rows: rows,
            filteredRows: filteredRows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setFilteredGridData = (rows) => {
    this.setState({
      filteredRows: rows,
    });
  };

  getOptions = (record) => {
    return (
      <div className="options">
        <Tooltip
          title={LABELS.EDIT}
          placement={DEFAULTS.TOOLTIP_PLACEMENT}
          onClick={() => this.editDialog(record)}
        >
          <IconButton className="smallIcon">
            <Edit />
          </IconButton>
        </Tooltip>
        {/* <Tooltip
          title={LABELS.DELETE}
          placement={DEFAULTS.TOOLTIP_PLACEMENT}
          onClick={() => this.onDelete(id, name)}
        >
          <IconButton className="smallIcon">
            <Delete />
          </IconButton>
        </Tooltip> */}
      </div>
    );
  };

  toggleDialog = () => {
    this._dialog.toggleDialog({ add: true });
  };

  editDialog = (record) => {
    this._dialog.toggleDialog(record);
  };

  onSearch = (e) => {
    let qry = e.target.value.trim();
    if (qry.length > 2) {
      this._grid.onSearch(qry);
    } else if (qry.length === 0) {
      this.setState({
        filteredRows: [],
      });
    }
  };

  reload = () => {
    this.setState({
      rows: null,
      filteredRows: [],
    });

    let self = this;
    setTimeout(() => {
      self.loadGrid();
    }, 1000 * DEFAULTS.GRID_RELOAD_TIMEOUT);
  };

  onDelete = (id, name) => {
    this._dialogConfirm.toggleConfirmDialog(id, name);
  };

  deleteAll = () => {
    this._dialogConfirm.toggleConfirmDialog("");
  };

  render() {
    const { rows, columns } = this.state;

    console.log("Privileges: ", rows);

    return (
      <div id="PrivilegesWrapper">
        <ul className="gridActionBtns">
          <li>
            <SearchButton
              id="cpanelPrivilegeSearch"
              placeholder={LABELS.SEARCH_BY_PRIVILEGE}
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
              onChange={this.onSearch}
            />
          </li>
          <li>
            <Tooltip
              title={LABELS.RELOAD}
              placement={DEFAULTS.TOOLTIP_PLACEMENT}
            >
              <IconButton onClick={this.reload}>
                <Reload />
              </IconButton>
            </Tooltip>
          </li>
          <li>
            <Tooltip title={LABELS.ADD} placement={DEFAULTS.TOOLTIP_PLACEMENT}>
              <IconButton onClick={this.toggleDialog}>
                <Add />
              </IconButton>
            </Tooltip>
          </li>
          {/* <li>
            <Tooltip
              title={LABELS.DELETE}
              placement={DEFAULTS.TOOLTIP_PLACEMENT}
            >
              <IconButton onClick={this.deleteAll}>
                <Delete />
              </IconButton>
            </Tooltip>
          </li> */}
        </ul>

        <CompanyDialog
          childRef={(ref) => (this._dialog = ref)}
          loadGrid={this.loadGrid}
        />

        <FleetGrid
          rows={rows}
          columns={columns}
          id="cpanelPrivileges"
          childRef={(ref) => (this._grid = ref)}
          height={window.innerHeight - 200}
          setFilteredGridData={this.setFilteredGridData}
        />

        <ConfirmDialog
          childRef={(ref) => (this._dialogConfirm = ref)}
          onOk={this.onDelete}
          id=""
          name="Privilege"
        />
      </div>
    );
  }
}

export default Privileges;
