import React, { Component } from "react";
import { IconButton, Tooltip, TextField } from "@material-ui/core";
import {
  Replay as Reload,
  AddCircleOutline as Add,
  Delete,
  Edit,
  Check as ActiveIcon,
  Close as NotActiveIcon,
} from "@material-ui/icons";

import FleetDialog from "../../ui/dialogbox/FleetDialog";
import FleetGrid from "../../ui/grid/FleetGrid";
import SearchButton from "../../ui/searchbutton/SearchButton";
import UiDatePicker from "../../ui/uidatepicker/UiDatePicker";
import AddUserVehicleDialog from "./AddUserVehicleDialog";
import ConfirmDialog from "../../ui/confirmdialog/ConfirmDialog";
import { DEFAULTS } from "../../../common/consts";
import { API_Settings } from "../../../apihandler/Settings";

class UserSubAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {},
      addVehicleDialog: false,
      draggable: true,
      columns: [
        { name: "slno", title: "Sl No." },
        { name: "userName", title: "User Name" },
        { name: "email", title: "Email" },
        { name: "vehicles", title: "Vehicles" },
        { name: "activeText", title: "Active" },
        { name: "options", title: "Options" },
      ],
      rows: null,
      filteredRows: [],
    };

    this._tableColumnWidths = [
      { columnName: "slno", width: 100 },
      { columnName: "userName", width: 200 },
      { columnName: "email", width: 200 },
      { columnName: "vehicles", width: 100 },
      { columnName: "activeText", width: 100 },
      { columnName: "options", width: 100 },
    ];

    this._tableColumnExtensions = [
      { columnName: "slno", align: "center" },
      { columnName: "userName", align: "left" },
      { columnName: "email", align: "left" },
      { columnName: "vehicles", align: "center" },
      { columnName: "activeText", align: "center" },
      { columnName: "options", align: "center" },
    ];

    this._timer = null;
  }

  componentDidMount() {
    // this.loadGrid();
  }

  loadGrid = () => {
    const userId = this.props.userInfo.id;
    let self = this;
    let args = { userId };
    console.log("loadGrid: ", args);
    API_Settings.getAllSubAccounts(args).then((res) => {
      if (res.Result === 200) {
        let rows = res.Response;
        for (let r = 0; r < rows.length; r++) {
          rows[r].slno = r + 1;
          rows[r].id = rows[r].userId;
          rows[r].name = rows[r].userName;
          rows[r].options = self.getOptions(rows[r]);
          rows[r].activeText = rows[r].active ? (
            <ActiveIcon style={{ fontSize: 18, color: "#008000" }} />
          ) : (
            <NotActiveIcon style={{ fontSize: 18, color: "#ff0000" }} />
          );
        }

        // Check if any text in Search Box
        let searchQry = document
          .getElementById("cpanelSubAccSearch")
          .value.trim()
          .toUpperCase();
        let filteredRows = [];
        if (searchQry.length > 0) {
          filteredRows = rows.filter((row) => {
            return row.name.toUpperCase().indexOf(searchQry) > -1;
          });
        }

        self.setState({
          rows: rows,
          filteredRows: filteredRows,
        });
      }
    });
  };

  getOptions = (record) => {
    const { id, name } = record;
    return (
      <div className="options">
        {/* <Tooltip
          title="Edit"
          placement="bottom"
          onClick={() => this.toggleDialog(record)}
        >
          <IconButton className="smallIcon">
            <Edit />
          </IconButton>
        </Tooltip> */}
        <Tooltip
          title="Delete"
          placement="bottom"
          onClick={() => this.onDelete(id, name)}
        >
          <IconButton className="smallIcon">
            <Delete />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  openAddVehicleDialog = () => {
    this.setState({
      addVehicleDialog: true,
      draggable: false,
    });
  };

  closeAddVehicleDialog = () => {
    this.setState({
      addVehicleDialog: false,
      draggable: true,
    });
  };

  toggleDialog = () => {
    this.props.toggleDialog();
  };

  onSearch = (e) => {
    let self = this;
    let qry = e.target.value.trim();
    clearTimeout(this._timer);
    this._timer = setTimeout(() => {
      if (qry === "") {
        self.setState({
          filteredRows: [],
        });
      } else {
        const { rows } = self.state;
        qry = qry.toUpperCase();
        let list = rows.filter((row) => {
          return row.name.toUpperCase().indexOf(qry) > -1;
        });
        if (list.length === 0) {
          list = [{ noRecords: true }];
        }
        self.setState({
          filteredRows: list,
        });
      }
    }, 1500);
  };

  reload = () => {
    this.setState({
      rows: null,
      filteredRows: [],
    });

    let self = this;
    setTimeout(() => {
      self.loadGrid();
    }, 1000 * DEFAULTS.GRID_RELOAD_TIMEOUT);
  };

  onDelete = (id, name) => {
    this._dialogConfirm.toggleConfirmDialog(id, name);
  };

  deleteAll = () => {
    this._dialogConfirm.toggleConfirmDialog("");
  };

  render() {
    const { columns, rows, filteredRows, draggable, record } = this.state;
    const { userInfo } = this.props;

    let finalRows = rows;
    if (filteredRows.length > 0) {
      if (filteredRows[0].noRecords) {
        finalRows = [];
      } else {
        finalRows = filteredRows;
      }
    }

    return (
      <FleetDialog
        id="userSubAccounts"
        open={true}
        onCloseSettings={this.toggleDialog}
        title={"User Sub Accounts : " + userInfo.name}
        maxWidth="md"
        fullWidth={true}
        cancelButton={true}
        cancelAction={this.toggleDialog}
        saveButton={true}
        saveAction={this.saveRecord}
        saveBtnLabel="OK"
        disableBackdropClick={true}
        disableBackdrop={true}
        classNameWrapper="profileSettingsWrapper"
        draggable={draggable}
      >
        <ul className="gridActionBtns popupActionBtns">
          <li>
            <SearchButton
              id="cpanelSubAccSearch"
              placeholder="Search By Sub Account"
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
              onChange={this.onSearch}
            />
          </li>
          <li>
            <Tooltip title="Reload" placement="bottom">
              <IconButton onClick={this.reload}>
                <Reload />
              </IconButton>
            </Tooltip>
          </li>
          {/* <li>
            <Tooltip title="Delete" placement="bottom">
              <IconButton onClick={this.deleteAll}>
                <Delete />
              </IconButton>
            </Tooltip>
          </li> */}
        </ul>
        <FleetGrid
          rows={finalRows}
          columns={columns}
          id="userSubAccountsGrid"
          childRef={(ref) => (this._grid = ref)}
          tableColumnWidths={this._tableColumnWidths}
          tableColumnExtensions={this._tableColumnExtensions}
        />

        <ConfirmDialog
          childRef={(ref) => (this._dialogConfirm = ref)}
          onOk={this.onDelete}
          id=""
          name="Sub Account"
        />
      </FleetDialog>
    );
  }
}

export default UserSubAccounts;
