import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import FleetDialog from "../../../ui/dialogbox/FleetDialog";
import AutoComplete from "../../../ui/autocomplete/AutoComplete";

import { commonJs } from "../../../../common/common";
import { LABELS, NOTIFY } from "../../../../common/consts";
import { API_CPanel } from "../../../../apihandler/CPanel";
import { CPANEL_ACTIONS } from "../../../../actions/CpanelActions";

class PackagesDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {},
      showDialog: false,
      selectedRole: {},
      selectedPrivileges: [],
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  toggleDialog = (record) => {
    const { showDialog } = this.state;
    if (record.id === undefined) {
      if (record.add) {
        this.setState({
          showDialog: !showDialog,
          record: {},
        });
      } else {
        // Close
        this.setState({
          showDialog: !showDialog,
        });
      }
    } else {
      // Edit -> Open
      console.log("edit: ", record);
      let selectedRole = { id: record.roleId, name: record.role };
      let sp = [];
      let privileges = record.privileges.split(",");
      for (let i = 0; i < privileges.length; i++) {
        sp.push({
          id: record.privilegeIds[i],
          name: privileges[i],
        });
      }
      this.setState({
        showDialog: !showDialog,
        record: record,
        selectedRole,
        selectedPrivileges: sp,
      });
    }
  };

  saveRecord = () => {
    const { record, selectedRole, selectedPrivileges } = this.state;
    const id = record.id || 0;
    const packageName = document.getElementById("pkgName").value.trim();
    const description = document.getElementById("pkgDescription").value.trim();
    let self = this;

    console.log("selectedRole: ", selectedRole);

    if (packageName === "") {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Package name should not be empty..!",
        <button className="snackbar-btn">{LABELS.DISMISS}</button>
      );
      return false;
    }
    if (!selectedRole.id) {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Please select package Role..!",
        <button className="snackbar-btn">{LABELS.DISMISS}</button>
      );
      return false;
    }
    if (selectedPrivileges.length === 0) {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Please select package Privileges..!",
        <button className="snackbar-btn">{LABELS.DISMISS}</button>
      );
      return false;
    }

    let privileges = selectedPrivileges.map((p) => {
      return p.id;
    });
    const args = {
      id,
      package: packageName,
      description,
      role: selectedRole.id,
      privileges: privileges,
    };

    console.log("saveRecord", args);

    this.props.setLoading({
      loading: true,
    });

    API_CPanel.managePackage(args)
      .then((res) => {
        console.log("managePackage: ", res);
        if (res.Result === 200) {
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            "Package saved successfully..!",
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
          self.props.loadGrid();
          self.setState({
            showDialog: false,
          });
          self.props.setLoading({
            loading: false,
          });
        } else {
          commonJs.showNotify(self.props, NOTIFY.ERROR, res.Reason);
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
    return;
  };

  onChangeRole = (val) => {
    let v = val;
    if (!val) {
      v = {};
    }
    this.setState({
      selectedRole: v,
    });
  };

  onChangePrivileges = (val) => {
    let v = val;
    if (!val) {
      v = {};
    }
    this.setState({
      selectedPrivileges: v,
    });
  };

  render() {
    const { record, showDialog, selectedPrivileges, selectedRole } = this.state;
    const { roles, privileges } = this.props;
    console.log("roles: ", roles, privileges);
    return (
      <FleetDialog
        id="packagesDialog"
        open={showDialog}
        onCloseSettings={this.toggleDialog}
        title={record.id ? "Edit Package" : "Add Package"}
        maxWidth="xs"
        fullWidth={false}
        cancelButton={true}
        cancelAction={this.toggleDialog}
        saveButton={true}
        saveAction={this.saveRecord}
        saveBtnLabel="OK"
        disableBackdropClick={true}
        disableBackdrop={true}
        classNameBody="zonePopupBody"
        classNameWrapper="zonePopupWrapper"
        draggable
      >
        <div className="grid">
          <div className="row">
            <TextField
              id="pkgName"
              label="Package Name"
              classes={{ root: "txtfield" }}
              autoFocus={true}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={record.package || ""}
            />
          </div>
          <div className="row">
            <TextField
              id="pkgDescription"
              label="Package Description"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={record.description || ""}
              multiline={true}
              rows={3}
            />
          </div>
          <div className="row">
            <AutoComplete
              label={"Role"}
              list={roles}
              style={{ marginBottom: 30, width: "100%" }}
              onChange={this.onChangeRole}
              defaultValue={selectedRole}
              multiple={false}
              size="small"
            />
          </div>
          <div className="row">
            <AutoComplete
              label={"Privileges"}
              list={privileges}
              style={{ marginBottom: 30, width: "100%" }}
              onChange={this.onChangePrivileges}
              defaultValue={selectedPrivileges}
              multiple={true}
              size="small"
            />
          </div>
        </div>
      </FleetDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (values) => {
      dispatch({
        type: CPANEL_ACTIONS.SET_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(PackagesDialog));
