import React, { Component } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  ClickAwayListener,
} from "@material-ui/core";
import { connect } from "react-redux";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { commonJs } from "common/common";
import { DEFAULTS, FORMATS, LOCAL_STORAGE, PRIVILEGES } from "common/consts";
import { dateFunction } from "common/datefunctions";
import { PAGE_ACTIONS } from "actions/PageActions";
import { TRACK_ACTIONS } from "actions/TrackActions";
import { MAP_ACTIONS } from "actions/MapActions";

import "./trackmenu.css";

class TrackMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClose = (imei) => {
    document.getElementById("trackRecordsMenu").classList.remove("active");
    document.getElementById("voptions-" + imei).classList.remove("active");
  };

  openTrackRecordsMenu = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  openEditVehicle = () => {
    const { imei, pageReducer } = this.props;
    const { vehicleEditPopup } = pageReducer;
    this.closeMenu(imei);
    this.props.toggleEditVehicle({
      vehicleEditPopup: !vehicleEditPopup,
      selectedVehicle: imei,
    });
  };

  closeMenu = (imei) => {
    document.getElementById("voptions-" + imei).classList.toggle("active");
  };

  showTrack = (filter, imei) => {
    console.log("showTrack: ", filter, imei);
    const { frmval, toval } = dateFunction.getFromToVal(
      filter,
      FORMATS.SQL_DATE
    );
    this.props.setTrackFilter({
      fdt: frmval,
      tdt: toval,
      selectedTrackDuration: filter,
      stopTime: DEFAULTS.DEFAULT_TRACK_STOPTIME,
    });
    this.props.setSelectedVehicle({
      selectedVehicle: imei,
    });
    this.props.setLoading({
      loadingPopover: true,
    });
    var self = this;
    setTimeout(() => {
      let mapTabCache = localStorage[LOCAL_STORAGE.MAP_TAB];
      if (mapTabCache === null || mapTabCache === undefined) {
        mapTabCache = {
          activeTab: 2,
        };
      } else {
        mapTabCache = JSON.parse(mapTabCache);
        mapTabCache.activeTab = 2;
      }
      localStorage[LOCAL_STORAGE.MAP_TAB] = JSON.stringify(mapTabCache);
      self.props.switchTab("track", "track");
    }, 500);
  };

  shareVehicle = () => {
    const { imei } = this.props;
    this.props.setSelectedVehicle({
      selectedVehicle: imei,
    });
    this.props.shareVehicle();
  };

  liveTrackVehicle = () => {
    const { imei } = this.props;
    window.open("/livetrack?key=live&objects=" + imei, "_blank");
  };

  render() {
    const { open } = this.state;
    const { closeTrackMenu, imei, loginReducer } = this.props;
    const { privileges, role } = loginReducer;

    const showTrackOptions = commonJs.hasPrivilege(
      PRIVILEGES.TRACK,
      role,
      privileges
    );
    const showEditVehicle = commonJs.hasPrivilege(
      PRIVILEGES.EDIT_VEHICLE,
      role,
      privileges
    );
    const showShareVehicle = commonJs.hasPrivilege(
      PRIVILEGES.SHARE_VEHICLE,
      role,
      privileges
    );

    return (
      <ClickAwayListener onClickAway={() => closeTrackMenu}>
        <div className="menulist" id={"voptions-" + imei}>
          <List component="nav">
            {showTrackOptions && (
              <ListItem
                button
                className="menuListItem subMenu"
                onClick={this.openTrackRecordsMenu}
              >
                <ListItemText
                  inset
                  primary="Track Records"
                  className="menuListItemText"
                />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            )}
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              className="subMenuList"
            >
              <List component="div" disablePadding>
                {DEFAULTS.TRACK_DURATIONS.map((d) => {
                  return (
                    <ListItem
                      button
                      className="menuListItem"
                      key={"menu-" + d.value}
                      onClick={() => this.showTrack(d.value, imei)}
                    >
                      <ListItemText
                        inset
                        primary={d.title}
                        className="menuListItemText"
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Collapse>
            <ListItem button className="menuListItem">
              <ListItemText
                inset
                primary="Live Track"
                className="menuListItemText"
                onClick={this.liveTrackVehicle}
              />
            </ListItem>
            {showShareVehicle && (
              <ListItem button className="menuListItem">
                <ListItemText
                  inset
                  primary="Share"
                  className="menuListItemText"
                  onClick={this.shareVehicle}
                />
              </ListItem>
            )}
            {showEditVehicle && (
              <ListItem button className="menuListItem">
                <ListItemText
                  inset
                  primary="Edit Vehicle"
                  className="menuListItemText"
                  onClick={this.openEditVehicle}
                />
              </ListItem>
            )}
          </List>
        </div>
      </ClickAwayListener>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageReducer: state.pageReducer,
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleEditVehicle: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_EDIT_VEHICLE_MENU,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_POPOVER_LOADING,
        values,
      });
    },
    setTrackFilter: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_TRACK_FILTER,
        values,
      });
    },
    setSelectedVehicle: (values) => {
      dispatch({
        type: MAP_ACTIONS.SELECT_VEHICLE,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackMenu);
