import React, { Component } from "react";

import Img from "components/ui/image/Img";

import { CONFIG, LOCAL_STORAGE } from "common/consts";

import fleetLogo from "images/logo/logo.png";

class ReportTopbar extends Component {
  render() {
    let logo = fleetLogo;
    let userName = "";
    if (localStorage[LOCAL_STORAGE.USER_SETTINGS]) {
      let localJson = JSON.parse(localStorage[LOCAL_STORAGE.USER_SETTINGS]);
      //   logo = CONFIG.BASE_URL + localJson.logo;
      userName = localJson.userName;
    }
    return (
      <div id="reportTopBar">
        <div id="topLeftBox">
          <h4 className="mapTabTitle">
            {CONFIG.SITE_NAME} <small>v{CONFIG.VERSION}</small>
          </h4>
        </div>
        <div
          id="topRightBox"
          style={{
            height: "100%",
            position: "relative",
            paddingLeft: "15px",
            paddingTop: "4px",
          }}
        >
          <div id="reportLogo">
            <Img src={logo} width={30} height={30} />
          </div>
          <div id="reportUserInfo">
            <h4>{userName}</h4>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportTopbar;
