import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  Assessment as DashboardIcon,
  Dns as MastersIcon,
  SupervisorAccount as UsersIcon,
  LocalShipping as VehiclesIcon,
} from "@material-ui/icons";

import { LABELS } from "common/consts";

class CpanelLeftBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leftFixedMenu: true,
      sideBarMenu: this.props.menu,
    };
  }

  onToggleMenu = (menu = "") => {
    this.setState({ sideBarMenu: menu });
  };

  onMenuClick = (menu = "") => {
    this.props.onMenuChange(menu);
    this.onToggleMenu(menu);
    if (menu === "home") {
      window.open("/", "_self");
    }
  };

  render() {
    const { sideBarMenu } = this.state;
    return (
      <div className="report-leftbar">
        <div className={"leftFixedMenu"}>
          <ul className="topmenu">
            <li onClick={() => this.onMenuClick("dashboard")}>
              <Tooltip title={LABELS.DASHBOARD} placement="right">
                <IconButton
                  classes={{ root: "menucolor" }}
                  className={sideBarMenu === "dashboard" ? "active" : ""}
                >
                  <DashboardIcon />
                </IconButton>
              </Tooltip>
            </li>
            <li onClick={() => this.onMenuClick("masters")}>
              <Tooltip title={LABELS.MASTERS} placement="right">
                <IconButton
                  classes={{ root: "menucolor" }}
                  className={sideBarMenu === "masters" ? "active" : ""}
                >
                  <MastersIcon />
                </IconButton>
              </Tooltip>
            </li>
            <li onClick={() => this.onMenuClick("users")}>
              <Tooltip title={LABELS.USERS} placement="right">
                <IconButton
                  classes={{ root: "menucolor" }}
                  className={sideBarMenu === "users" ? "active" : ""}
                >
                  <UsersIcon />
                </IconButton>
              </Tooltip>
            </li>
            <li onClick={() => this.onMenuClick("vehicles")}>
              <Tooltip title={LABELS.VEHICLES_LIST} placement="right">
                <IconButton
                  classes={{ root: "menucolor" }}
                  className={sideBarMenu === "vehicles" ? "active" : ""}
                >
                  <VehiclesIcon />
                </IconButton>
              </Tooltip>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default CpanelLeftBar;
