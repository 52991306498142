import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  Replay as ReloadIcon,
  AddCircleOutline as AddIcon,
  Delete,
  Edit,
} from "@material-ui/icons";

import DialogEtaVehicles from "./DialogEtaVehicles";
import FleetGrid from "../../../../ui/grid/FleetGrid";
import SearchButton from "../../../../ui/searchbutton/SearchButton";
import ConfirmDialog from "../../../../ui/confirmdialog/ConfirmDialog";

class EtaVehicles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: "slno", title: "Sl No." },
        { name: "vehiclename", title: "Vehicle Name" },
        { name: "tripname", title: "Trip Name" },
        { name: "routename", title: "Route Name" },
        { name: "options", title: "Options" },
      ],
      rows: [
        {
          id: "111",
          slno: "1",
          vehiclename: "Vehicle 1",
          tripname: "Trip 1",
          routename: "Route 2",
          options: this.getOptions({ id: "1102", name: "Vehicle 1" }),
        },
        {
          id: "112",
          slno: "2",
          vehiclename: "Vehicle 2",
          tripname: "Trip 2",
          routename: "Route 1",
          options: this.getOptions({ id: "1101", name: "Vehicle 2" }),
        },
      ],
    };
  }

  getOptions = (rec) => {
    const { id, name } = rec;
    return (
      <div className="options">
        <Tooltip
          title="Edit"
          placement="bottom"
          onClick={() => this._etaVehiclesDialog.toggleDialog(rec)}
        >
          <IconButton className="smallIcon">
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Delete"
          placement="bottom"
          onClick={() => this.deleteRecord(id, name)}
        >
          <IconButton className="smallIcon">
            <Delete />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  onSearch = (e) => {
    console.log("this._etaVehiclesGrid: ", this._etaVehiclesGrid);
    // this._etaVehiclesGrid.onSearch(e.target.value.trim());
  };

  deleteRecord = (id, name) => {
    this._dialogConfirm.toggleConfirmDialog(id, name);
  };

  deleteAllPoints = () => {
    this._dialogConfirm.toggleConfirmDialog("");
  };

  reload = () => {
    this._etaVehiclesGrid.loadReloadPoints();
  };

  addRoute = () => {
    this._etaVehiclesDialog.toggleDialog({ add: true });
  };

  render() {
    const { columns, rows } = this.state;

    return (
      <div id="etaVehicles">
        <ul id="gridFilters">
          <li>
            <SearchButton
              id="vehicleNameSearch"
              placeholder="Search By Vehicle or Trip or Route Name"
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
              onChange={this.onSearch}
            />
          </li>
          <li className="">
            <Tooltip title="Reload" placement="bottom">
              <IconButton onClick={this.reload}>
                <ReloadIcon />
              </IconButton>
            </Tooltip>
          </li>
          <li className="">
            <Tooltip title="Add Route" placement="bottom">
              <IconButton onClick={this.addRoute}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </li>
          <li className="">
            <Tooltip title="Delete" placement="bottom">
              <IconButton onClick={this.deleteAllPoints}>
                <Delete />
              </IconButton>
            </Tooltip>
          </li>
        </ul>

        <FleetGrid
          columns={columns}
          rows={rows}
          childRef={(ref) => (this._etaVehiclesGrid = ref)}
        />

        <DialogEtaVehicles
          childRef={(ref) => (this._etaVehiclesDialog = ref)}
        />

        <ConfirmDialog
          childRef={(ref) => (this._dialogConfirm = ref)}
          showConfirmDialog={false}
          id="confirmEtaVehicleDelete"
          name="Vehicle Name"
        />
      </div>
    );
  }
}

export default EtaVehicles;
