import React, { Component } from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";

import "./checkbox.css";

class CheckBoxGroup extends Component {
  render() {
    const { list, handleFilterChange, treeView } = this.props;
    return !treeView ? (
      <div className="checkList scrollBar">
        {list.map((checkbox) => {
          return (
            <FormControlLabel
              key={"chkboxgroup-" + checkbox.id}
              id={"chkboxgroup-" + checkbox.id}
              control={
                <Checkbox
                  value={checkbox.id}
                  checked={checkbox.checked || false}
                  onChange={() => handleFilterChange(checkbox)}
                />
              }
              label={checkbox.name}
            />
          );
        })}
      </div>
    ) : (
      <div className="checkList scrollBar">
        {list.map((checkbox) => {
          return (
            <div>
              <FormControlLabel
                key={"chkboxgroup-" + checkbox.id}
                id={"chkboxgroup-" + checkbox.id}
                control={
                  <Checkbox
                    value={checkbox.id}
                    checked={checkbox.checked}
                    indeterminate={checkbox.indeterminate}
                    onChange={() => handleFilterChange(checkbox, {}, "main")}
                  />
                }
                label={checkbox.name + " ( " + checkbox.count + " )"}
              />
              <div style={{ marginLeft: 18 }}>
                {checkbox.groups.map((g, i) => {
                  return (
                    <FormControlLabel
                      key={"chkbox-" + g.id}
                      id={"chkbox-" + g.id}
                      control={
                        <Checkbox
                          value={g.id}
                          checked={g.checked}
                          indeterminate={g.indeterminate}
                          onChange={() =>
                            handleFilterChange(checkbox, g, "sub")
                          }
                        />
                      }
                      label={g.name + " ( " + g.count + " )"}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default CheckBoxGroup;
