import React, { Component } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import jQuery from "jquery";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.flash";
import "pdfmake";

import FleetDialog from "components/ui/dialogbox/FleetDialog";

import { UNITS } from "common/consts";
import { dateFunction } from "common/datefunctions";

import "../styles/reports.css";

var $ = jQuery;

class ReportPopupDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    let self = this;
    setTimeout(() => {
      self.loadReport();
    }, 1500);
  }

  loadReport = () => {
    const { reportType, records, poiVehicles } = this.props;
    let exportTitle = "All Vehicles";
    let columns = [
      {
        title: "Sl No.",
      },
      {
        title: "Vehicle Name",
      },
      {
        title: "Vehicle Type",
      },
      {
        title: "Speed",
      },
      {
        title: "Date Time",
      },
      {
        title: "Address",
      },
    ];
    let rows = [];

    if (reportType === "all") {
      columns.push({
        title: "Driver Name",
      });
      columns.push({
        title: "Driver No.",
      });
      const { vehicles } = this.props;
      for (let v = 0; v < vehicles.length; v++) {
        rows.push([
          v + 1,
          vehicles[v].name,
          vehicles[v].vin,
          vehicles[v].speed > 0
            ? "<span class='running'>" +
              vehicles[v].speed.toFixed(1) +
              " " +
              UNITS.SPEED +
              "</span>"
            : "<span class='stopped'>" +
              vehicles[v].speed.toFixed(1) +
              " " +
              UNITS.SPEED +
              "</span>",
          vehicles[v].objectlogtime,
          "<a target='_blank' class='hyperlink' href='https://www.google.com/maps?q=" +
            vehicles[v].latitude +
            "," +
            vehicles[v].longitude +
            "'>" +
            vehicles[v].address +
            "</a>",
          vehicles[v].drivername,
          vehicles[v].driverno,
        ]);
      }
    } else if (reportType === "running") {
      exportTitle = "Moving Vehicles";
      let c = 1;
      const { vehicles } = this.props;
      for (let v = 0; v < vehicles.length; v++) {
        if (vehicles[v].status === 3) {
          rows.push([
            c,
            vehicles[v].name,
            vehicles[v].vin,
            vehicles[v].speed > 0
              ? "<span class='running'>" +
                vehicles[v].speed.toFixed(1) +
                " " +
                UNITS.SPEED +
                "</span>"
              : "<span class='stopped'>" +
                vehicles[v].speed.toFixed(1) +
                " " +
                UNITS.SPEED +
                "</span>",
            vehicles[v].objectlogtime,
            "<a target='_blank' class='hyperlink' href='https://www.google.com/maps?q=" +
              vehicles[v].latitude +
              "," +
              vehicles[v].longitude +
              "'>" +
              vehicles[v].address +
              "</a>",
          ]);
          c++;
        }
      }
    } else if (reportType === "idle") {
      exportTitle = "Idle Vehicles";
      let c = 1;
      columns.push({
        title: "Idle-Since",
      });
      const { vehicles } = this.props;
      for (let v = 0; v < vehicles.length; v++) {
        if (vehicles[v].status === 2) {
          rows.push([
            c,
            vehicles[v].name,
            vehicles[v].vin,
            vehicles[v].speed > 0
              ? "<span class='running'>" +
                vehicles[v].speed.toFixed(1) +
                " " +
                UNITS.SPEED +
                "</span>"
              : "<span class='stopped'>" +
                vehicles[v].speed.toFixed(1) +
                " " +
                UNITS.SPEED +
                "</span>",
            vehicles[v].objectlogtime,
            "<a target='_blank' class='hyperlink' href='https://www.google.com/maps?q=" +
              vehicles[v].latitude +
              "," +
              vehicles[v].longitude +
              "'>" +
              vehicles[v].address +
              "</a>",
            vehicles[v].idletime,
          ]);
          c++;
        }
      }
    } else if (reportType === "stopped") {
      exportTitle = "Stopped Vehicles";
      let c = 1;
      const { vehicles } = this.props;
      for (let v = 0; v < vehicles.length; v++) {
        if (vehicles[v].status === 1) {
          rows.push([
            c,
            vehicles[v].name,
            vehicles[v].vin,
            vehicles[v].speed > 0
              ? "<span class='running'>" +
                vehicles[v].speed.toFixed(1) +
                " " +
                UNITS.SPEED +
                "</span>"
              : "<span class='stopped'>" +
                vehicles[v].speed.toFixed(1) +
                " " +
                UNITS.SPEED +
                "</span>",
            vehicles[v].objectlogtime,
            "<a target='_blank' class='hyperlink' href='https://www.google.com/maps?q=" +
              vehicles[v].latitude +
              "," +
              vehicles[v].longitude +
              "'>" +
              vehicles[v].address +
              "</a>",
            vehicles[v].idletime,
          ]);
          c++;
        }
      }
    } else if (reportType === "nosignal") {
      exportTitle = "No Signal Vehicles";
      let c = 1;
      const { vehicles } = this.props;
      for (let v = 0; v < vehicles.length; v++) {
        if (vehicles[v].status === 0) {
          rows.push([
            c,
            vehicles[v].name,
            vehicles[v].vin,
            vehicles[v].speed > 0
              ? "<span class='running'>" +
                vehicles[v].speed.toFixed(1) +
                " " +
                UNITS.SPEED +
                "</span>"
              : "<span class='stopped'>" +
                vehicles[v].speed.toFixed(1) +
                " " +
                UNITS.SPEED +
                "</span>",
            vehicles[v].objectlogtime,
            "<a target='_blank' class='hyperlink' href='https://www.google.com/maps?q=" +
              vehicles[v].latitude +
              "," +
              vehicles[v].longitude +
              "'>" +
              vehicles[v].address +
              "</a>",
          ]);
          c++;
        }
      }
    } else if (reportType === "docexpiry") {
      exportTitle = this.props.title;
      columns = [
        {
          title: "Sl No.",
        },
        {
          title: "Vehicle Name",
        },
        {
          title: "Expiry Date",
        },
        {
          title: "Days",
        },
        {
          title: "Status",
        },
      ];
      for (let r = 0; r < records.length; r++) {
        let rslt = dateFunction.getDateDiff(records[r].expiryDate);
        rows.push([
          r + 1,
          records[r].vehicleName,
          records[r].expiryDate,
          rslt.days,
          rslt.days < 0
            ? "<span class='stopped'>Expired</span>"
            : "<span class='running'>Not Expired</span>",
        ]);
      }
    } else if (reportType === "inpoi") {
      const { poiId, title } = this.props;
      exportTitle = title;
      columns = [
        {
          title: "Sl No.",
        },
        {
          title: "Vehicle Name",
        },
        {
          title: "Vehicle Type",
        },
        {
          title: "Zone Name",
        },
        {
          title: "Date Time",
        },
        {
          title: "In Zone Since",
        },
        {
          title: "Location",
        },
      ];
      let r = 0;
      for (let poi in poiVehicles) {
        let showRecord = true;
        if (poiId !== null) {
          if (poiId !== poi) {
            showRecord = false;
          } else {
            exportTitle =
              "Vehicles In Zones Report ( " + poiVehicles[poi].name + " )";
          }
        }
        if (showRecord) {
          let zoneName = poiVehicles[poi].name;
          let vlist = poiVehicles[poi].vehicles;
          for (let i = 0; i < vlist.length; i++) {
            let since = dateFunction.getDateSince(vlist[i].idletime);
            rows.push([
              r + 1,
              vlist[i].name,
              vlist[i].vin,
              zoneName,
              vlist[i].idletime,
              since,
              "<a target='_blank' class='hyperlink' href='https://www.google.com/maps?q=" +
                vlist[i].latitude +
                "," +
                vlist[i].longitude +
                "'>" +
                vlist[i].address +
                "</a>",
            ]);
            r++;
          }
        }
      }
    } else if (reportType === "idlelist") {
      const { idleId, title, vehicles } = this.props;
      console.log("idleId: ", idleId);
      exportTitle = title;
      columns = [
        {
          title: "Sl No.",
        },
        {
          title: "Vehicle Name",
        },
        {
          title: "Vehicle Type",
        },
        {
          title: "Time",
        },
        {
          title: "Days",
        },
        {
          title: "Hours",
        },
        {
          title: "Mins.",
        },
        {
          title: "Location",
        },
      ];
      let r = 0;
      for (let i = 0; i < vehicles.length; i++) {
        if (vehicles[i].status !== 3 && vehicles[i].status !== 0) {
          let dt1 = new Date();
          let dtt = new Date(vehicles[i].objectlogtime);
          dtt = moment(dtt);
          dtt = dt1 - dtt;
          let hrs = dtt / (3600 * 1000);
          let since = dateFunction.getDateDiff(vehicles[i].objectlogtime);
          let showRecord = false;
          if (idleId === 0) {
            if (hrs > 24) {
              showRecord = true;
            }
          } else if (idleId === 1) {
            if (hrs >= 16 && hrs <= 24) {
              showRecord = true;
            }
          } else if (idleId === 2) {
            if (hrs > 6 && hrs < 16) {
              showRecord = true;
            }
          } else if (idleId === 3) {
            if (hrs <= 6) {
              showRecord = true;
            }
          }
          if (showRecord) {
            rows.push([
              r + 1,
              vehicles[i].name,
              vehicles[i].vin,
              vehicles[i].idletime,
              since.days,
              since.hrs,
              since.mins,
              "<a target='_blank' class='hyperlink' href='https://www.google.com/maps?q=" +
                vehicles[i].latitude +
                "," +
                vehicles[i].longitude +
                "'>" +
                vehicles[i].address +
                "</a>",
            ]);
            r++;
          }
        }
      }
    } else if (reportType === "yesterday") {
      const { yesterdayId, title, records } = this.props;
      console.log("yesterdayId: ", yesterdayId, records);
      exportTitle = title;
      columns = [
        {
          title: "Sl No.",
        },
        {
          title: "Vehicle Name",
        },
        {
          title: "Distance (In Kms)",
        },
      ];
      let r = 0;
      for (let i = 0; i < records.length; i++) {
        let vname = records[i][1];
        let kms = Number(records[i][2]);
        let showRecord = false;
        if (yesterdayId === 0) {
          if (kms > 250) {
            showRecord = true;
          }
        } else if (yesterdayId === 1) {
          if (kms <= 250 && kms > 100) {
            showRecord = true;
          }
        } else if (yesterdayId === 2) {
          if (kms <= 100 && kms > 10) {
            showRecord = true;
          }
        } else if (yesterdayId === 3) {
          if (kms <= 10) {
            showRecord = true;
          }
        }
        if (showRecord) {
          rows.push([r + 1, vname, kms.toFixed(1)]);
          r++;
        }
      }
    }

    if ($.fn.dataTable.isDataTable("#reportGrid")) {
      let table = $("#reportGrid").DataTable();
      table.destroy();
    }
    $("#reportGrid").empty();

    $("#reportGrid").DataTable({
      dom: "Bfrtip",
      buttons: [
        {
          extend: "copy",
          className: "reportBtn",
          title: exportTitle,
        },
        {
          extend: "pdf",
          className: "reportBtn",
          title: exportTitle,
        },
        {
          extend: "excel",
          className: "reportBtn",
          title: exportTitle,
        },
        {
          extend: "csv",
          className: "reportBtn",
          title: exportTitle,
          text: "CSV",
        },
        {
          extend: "print",
          className: "reportBtn",
          title: exportTitle,
          customize: function (win) {
            $(win.document.body).find("h1").remove();
            $(win.document.body).find("table").attr("cellpadding", "3");
            $(win.document.body).find("table").css("font-size", "10px");
            $(win.document.body)
              .find("table")
              .css(
                "cssText",
                "border-bottom: none;border-left: none;border-right: none;"
              );
            $(win.document.body).find("td").css("font-size", "8px");
            $(win.document.body).find("th").css("font-size", "8px");
            $(win.document.body)
              .find("tbody td")
              .css(
                "cssText",
                "border: 1px solid #000;border-top: 0;border-right: 0;"
              );
            $(win.document.body)
              .find("tbody td:last-child")
              .css("cssText", "border: 1px solid #000;border-top: 0;");
            $(win.document.body)
              .find("table th")
              .css("cssText", "border: 1px solid #000;border-right: 0;");
            $(win.document.body)
              .find("table th:last-child")
              .css("cssText", "border: 1px solid #000;");
          },
        },
      ],
      paging: false,
      scrollY: 400,
      columns: columns,
      data: rows,
    });

    this.setState({
      loading: false,
    });
  };

  toggleDialog = () => {
    this.props.toggleDialog();
  };

  render() {
    const { reportType, title } = this.props;
    const { loading } = this.state;

    return (
      <FleetDialog
        open={true}
        onCloseSettings={this.toggleDialog}
        title={title || "Report"}
        maxWidth={"md"}
        fullWidth={true}
        saveButton={false}
        cancelButton={false}
        cancelAction={this.toggleDialog}
        classNameWrapper={"reportPopupWrapper " + reportType}
        draggable={false}
      >
        <div className="reportPopup-wrapper">
          {loading && (
            <div className="loaderWrapper">
              <CircularProgress className="primaryLoader" />
            </div>
          )}
          <table
            id="reportGrid"
            className="mdl-data-table width100"
            cellPadding="3"
            cellSpacing="0"
          />
        </div>
      </FleetDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportPopupDialog);
