import firebase from "firebase";

const config = {
  apiKey: "AIzaSyAzamZ6fGtA-lA1bVv4BWDe9Dv6RIueAOU",
  authDomain: "acegps-2021.firebaseapp.com",
  databaseURL: "https://acegps-2021-default-rtdb.firebaseio.com",
  projectId: "acegps-2021",
  storageBucket: "acegps-2021.appspot.com",
  messagingSenderId: "841355990056",
  appId: "1:841355990056:web:bef160874bd473b1bc111d",
};

firebase.initializeApp(config);

let messaging;

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker
//   `messaging.onBackgroundMessage` handler.
messaging.onMessage((payload) => {
  console.log("[Web Push]: serviceWorker: Message received. ", payload);
});

export { messaging };
