import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import SortIcon from "@material-ui/icons/Sort";
import {
  AddCircleOutline,
  RemoveCircleOutline,
  Visibility,
} from "@material-ui/icons";

// Custom Components
import Img from "components/ui/image/Img";
import SearchButton from "components/ui/searchbutton/SearchButton";
import FilterGroup from "./FilterGroup";

import { MAP_ACTIONS } from "actions/MapActions";
import { commonJs } from "common/common";
import { PAGE_ACTIONS } from "actions/PageActions";
import { COMMON_ACTIONS } from "actions/CommonActions";
import { TRACK_ACTIONS } from "actions/TrackActions";
import { DEFAULTS } from "common/consts";

// Icons
import FilterByIcon from "images/leftBarImages/filter.svg";

import "../maptab.css";

var timer = null;

class VehiclesFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleSort: false,
      vehicleFilter: false,
    };
  }

  loadExpandIcon = () => {
    return <AddCircleOutline />;
  };

  loadLeftIcon = () => {
    return <Visibility />;
  };

  loadMinimizeIcon = () => {
    return <RemoveCircleOutline />;
  };

  toggleVehicleFilter = () => {
    const { vehicleFilter } = this.state;
    this.setState({
      vehicleFilter: !vehicleFilter,
    });
  };

  onVehicleSearch = () => {
    clearTimeout(timer);
    let self = this;
    const searchValue = document.getElementById("vehicleSearch").value.trim();
    timer = setTimeout(() => {
      let qry = searchValue.toUpperCase().trim();
      const {
        tmpVehicles,
        signalFilter,
        loadStatusFilter,
      } = self.props.mapReducer;
      // Filter Signal & Vehicle Status
      let list = commonJs.getVehicleFilteredList(
        tmpVehicles,
        signalFilter,
        loadStatusFilter
      );

      let groupIds = [];
      // const groupsList = document.querySelectorAll(
      //   "#groupFilter .checkList.scrollBar>label input"
      // );
      // for (let g = 0; g < groupsList.length; g++) {
      //   if (groupsList[g].checked) {
      //     groupIds.push(Number(groupsList[g].value));
      //   }
      // }

      // Filter By Group
      // list = list.filter((v) => {
      //   return groupIds.indexOf(v.groupId) > -1;
      // });

      // Filter By Name
      list = list.filter((v) => {
        return v.name ? v.name.toUpperCase().indexOf(qry) > -1 : false;
      });

      if (list.length === 0 && qry !== "") {
        // If no match found
        list.push(0);
      }

      self.props.setFilteredVehicles({
        filteredVehicles: list,
      });
    }, 500);
  };

  toggleVehicleSort = () => {
    const { vehicleSort } = this.state;
    var self = this;
    this.setState(
      {
        vehicleSort: !vehicleSort,
      },
      () => {
        setTimeout(() => {
          self.doSort(self.state.vehicleSort);
        }, 1000);
      }
    );
  };

  doSort = (type) => {
    const { mapReducer } = this.props;
    const {
      signalFilter,
      loadStatusFilter,
      tmpVehicles,
      filteredVehicles,
    } = mapReducer;
    let vehicles = filteredVehicles;
    if (filteredVehicles.length === 0) {
      vehicles = commonJs.getVehicleFilteredList(
        tmpVehicles,
        signalFilter,
        loadStatusFilter
      );
    }
    vehicles.sort(function (a, b) {
      var nameA = a.name.toLowerCase();
      var nameB = b.name.toLowerCase();
      if (!type) {
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
      } else {
        if (nameA > nameB)
          //sort string descending
          return -1;
        if (nameA < nameB) return 1;
      }
      return 0; //Equal: default return value (no sorting)
    });
    this.props.setFilteredVehicles({
      filteredVehicles: vehicles,
    });
  };

  closeSortFilter = () => {
    this.setState({
      vehicleSort: false,
    });
  };

  closeGroupsFilter = () => {
    this.setState({
      vehicleFilter: false,
    });
  };

  render() {
    const { vehicleSort, vehicleFilter } = this.state;
    const { signalFilter, loadStatusFilter } = this.props.mapReducer;
    let filterOn =
      signalFilter !== DEFAULTS.DEFAULT_VEHICLE_STATUS ||
      loadStatusFilter !== DEFAULTS.DEFAULT_VEHICLE_LOAD_STATUS;
    const allGroups = document.querySelectorAll(
      "#groupFilter .checkList.scrollBar>label input"
    );
    const checkedGroups = document.querySelectorAll(
      "#groupFilter .checkList.scrollBar>label input:checked"
    );
    if (!filterOn) {
      filterOn = allGroups.length !== checkedGroups.length;
    }
    return (
      <React.Fragment>
        <div id="vehicleSearchBox">
          <SearchButton
            id="vehicleSearch"
            ref={(input) => {
              this.vehicleSearch = input;
            }}
            placeholder="Search By Vehicle Name"
            buttonClass="searchBtn"
            inputClass="SearchInput"
            iconwidth="18"
            iconheight="18"
            onChange={this.onVehicleSearch}
          />
        </div>
        <ul className="vehicleFilters">
          <li>
            <Button
              className={"pad5" + (filterOn ? " active" : "")}
              onClick={this.toggleVehicleFilter}
            >
              Filters
              <Img
                src={FilterByIcon}
                width="18"
                height="18"
                className="filterIcon"
              />
            </Button>
            <div
              id="vehicleFilterBox"
              className={vehicleFilter ? " active" : ""}
            >
              <FilterGroup />
            </div>
          </li>
          <li>
            <Button className={"pad5 sortBy"} onClick={this.toggleVehicleSort}>
              Sort By
              <SortIcon className={vehicleSort ? "desc" : "asc"} />
            </Button>
          </li>
        </ul>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFilteredVehicles: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_FILTERED_VEHICLES,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_MAP_LOADING,
        values,
      });
    },
    resetVehicles: (values) => {
      dispatch({
        type: MAP_ACTIONS.RESET_VEHICLES,
        values,
      });
    },
    setVehicles: (values) => {
      dispatch({
        type: COMMON_ACTIONS.SET_VEHICLES,
        values,
      });
    },
    resetTrackData: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_TRACK_DATA,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehiclesFilter);
