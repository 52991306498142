import { commonJs } from "common/common";
import { CONFIG } from "common/consts";
import { SdkClient } from "fgxsdk";

export const API_Settings = {
  getUserInfo: async (args) => {
    const response = await commonJs.callApi(
      "/v1/settings/userinfo",
      "GET",
      args
    );
    return response;
  },

  // User Vehicles => All Vehicles
  getAllUserVehicles: async (args) => {
    const response = await commonJs.callApi(
      "/v1/settings/uservehicles",
      "GET",
      args
    );
    return response;
    args = {
      pageNo: 1,
      pageLength: 100,
    };
    return {
      Result: 200,
      Response: [
        {
          vehicleName: "Vehicle No.1",
          imei: 997978989898,
          active: true,
          activeTill: "2020-12-31",
        },
      ],
    };
  },

  // Sub User Vehicles => Vehicles which are shared with sub accounts
  getAllSubUserVehicles: async () => {
    return {
      Result: 200,
      Response: [
        {
          userName: "testuser 1",
          vehicleName: "KA-5010",
          accessFrom: "2020-01-20 00:00:00",
          accessTo: "2030-01-20 00:00:00",
          userId: 1002,
          imei: "974387573498",
        },
        {
          userName: "testuser 2",
          vehicleName: "KA-5011",
          accessFrom: "2020-01-01 00:00:00",
          accessTo: "2030-01-01 00:00:00",
          userId: 1001,
          imei: "974387573498",
        },
      ],
    };
  },
  managerSubUserVehicle: async (args) => {
    const response = await commonJs.callApi(
      "/v1/settings/uservehicle",
      "POST",
      args
    );
    return response;
    args = {
      managerId: 1,
      userId: 1001,
      imei: "974387573498",
      accessFrom: "01-01-2020",
      accessTo: "01-01-2030",
    };
    return {
      Result: 200,
      Response: "OK",
    };
  },

  // User Sub Accounts
  getAllSubAccounts: async (args) => {
    const response = await commonJs.callApi(
      "/v1/settings/subaccounts",
      CONFIG.API_METHODS.GET,
      args
    );
    return response;
    args = {
      pageNo: 1,
      pageLength: 100,
      status: -1, // -1 => All, 1 => Active, 0 => In Active
    };
    return {
      Result: 200,
      Response: [
        {
          userId: 1001,
          userName: "test",
          email: "test@gmail.com",
          active: true,
          vehicles: 2,
          poi: "10/5",
          expiresOn: "01-01-2030",
        },
        {
          userId: 1002,
          userName: "test2",
          email: "test@gmail.com",
          active: true,
          vehicles: 4,
          poi: "10/5/2",
          expiresOn: "01-01-2030",
        },
      ],
    };
  },
  manageSubAccount: async (args) => {
    const response = await commonJs.callApi(
      "/v1/settings/subaccount",
      CONFIG.API_METHODS.POST,
      args
    );
    return response;
    args = {
      emailId: "demo@gmail.com",
      password: "123456",
      active: true,
      privileges: ["al", "mp", "trk", "rp"],
      vehicles: ["9876543210", "9876543211"],
      zones: [1001, 1002],
      points: [1010],
      routes: [1020, 1021],
      expiresOn: "2030-01-01",
    };
    return {
      Result: 200,
      Response: "OK",
    };
  },
  deleteSubAccount: async (args) => {
    const response = await commonJs.callApi(
      "/v1/settings/deletesubaccount",
      CONFIG.API_METHODS.POST,
      args
    );
    return response;
    args = {
      accountId: 1,
      managerId: 2,
    };
    return {
      Result: 200,
      Response: "OK",
    };
  },

  // User Groups
  addUserGroup: async (args) => {
    return SdkClient.saveGroup(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("saveGroup: ", err);
        throw new Error(err);
      });
  },
  getAllUserGroups: async (args) => {
    return SdkClient.getGroups(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("getGroups: ", err);
        throw new Error(err);
      });
  },
  deleteUserGroup: async (args) => {
    const response = await commonJs.callApi(
      "/v1/settings/deletegroup",
      CONFIG.API_METHODS.POST,
      args
    );
    return response;
  },

  changePassword: async (args = {}) => {
    const response = await commonJs.callApi(
      "/v1/settings/changepassword",
      CONFIG.API_METHODS.POST,
      args
    );
    return response;
    return {
      Result: 200,
      Response: "OK",
    };
  },

  editVehicleAccuracyDetails: async (args) => {
    const response = await commonJs.callApi(
      "/v1/settings/editvehicleacc",
      CONFIG.API_METHODS.POST,
      args
    );
    return response;
    args = {
      imei: "356173061896648",
      stops: "gps",
      minMovingSpeed: "6",
      minDiffPoints: "0.0005",
      minGpslev: "5",
      maxHdop: "3",
      minFuelFillingsDiff: "11",
      minFuelTheftsDiff: "11",
    };
    return {
      Result: 200,
      Response: {},
    };
  },

  editVehicleBasicDetails: async (args = {}) => {
    const response = await commonJs.callApi(
      "/v1/settings/editvehiclebasic",
      CONFIG.API_METHODS.POST,
      args
    );
    return response;
    args = {
      name: "0661",
      imei: "356173061896648",
      model: "",
      vehicleType: "",
      vehicleSize: "",
      plateno: "",
      simno: "",
      driverName: "",
      driverNo: "",
      targetKms: "",
      groupId: "0",
      mapIcon: "arrow",
      iconUrl: "/img/markers/objects/31.png",
      tailColor: "#00e500",
      tailPoints: "7",
      gpsDevice: "",
      engineHoursType: "acc",
      engineHours: "",
      odometerType: "gps",
      odometer: "10000.5",
      routeId: "",
      trailerId: "",
      loadStatus: "",
      simno2: "",
    };
    return {
      Result: 200,
      Response: {},
    };
  },
  editVehicleFuelConsumptionDetails: async (args) => {
    const response = await commonJs.callApi(
      "/v1/settings/editvehiclefuel",
      CONFIG.API_METHODS.POST,
      args
    );
    return response;
    args = {
      imei: "356173061896648",
      measurement: "l100km",
      fuelTankCapacity: "",
      voltage: "0",
      costForLitre: "0",
      summerRate: "0",
      winterRate: "0",
      winterFromMonth: "12",
      winterFromDay: "1",
      winterToMonth: "3",
      winterToDay: "1",
    };
    return {
      Result: 200,
      Response: {},
    };
  },
  editVehicleDocumentsDetails: async (args) => {
    const response = await commonJs.callApi(
      "/v1/settings/editvehicledoc",
      CONFIG.API_METHODS.POST,
      args
    );
    return response;
    args = {
      imei: "356173061896648",
      ownerName: "",
      inchargeName: "",
      companyName: "",
      seriesName: "",
      controllerName: "",
      vehicleLength: "32ft",
      ton: "",
      tyre: "MRF",
      chassisNumber: "",
      engineNumber: "",
      driverServiceTime: "",
      driverLicenseNumber: "",
      driverLicenseExpiryDate: "2023-10-25",
      tyre: "MRF",
      insuranceCompanyName: "",
      insuranceType: "",
      policyNumber: "",
      permitNumber: "",
      minimumCapacity: "",
      maximumCapacity: "",
      goodsPermitDate: "2023-10-25",
      goodsTaxDate: "2023-10-25",
      roadTaxUpto: "2023-10-25",
      insuranceUpto: "2023-10-25",
      permitUpto: "2023-10-25",
      registrationUpto: "2023-10-25",
      vehicleFitnessUpto: "2023-10-25",
    };
    return {
      Result: 200,
      Response: {},
    };
  },

  // Shared Vehicles
  getAllSharedVehicles: async (args = {}) => {
    const response = await commonJs.callApi(
      "/v1/settings/sharevehicles",
      CONFIG.API_METHODS.GET,
      args
    );
    return response;
    return {
      Result: 200,
      Response: [
        {
          slno: "1",
          id: "1001",
          userId: "1111",
          userName: "demouser",
          imei: "866405034708655",
          vehicleName: "NL01AB-6084",
          alias: "",
          expireDate: "2020-12-31",
        },
        {
          slno: "2",
          id: "1002",
          userId: "1111",
          userName: "demouser",
          imei: "866405034708655",
          vehicleName: "NL01AB-5084",
          alias: "Load Vehicle 1",
          expireDate: "2020-12-31",
        },
      ],
    };
  },
  manageShareVehicle: async (args = {}) => {
    const response = await commonJs.callApi(
      "/v1/settings/sharevehicle",
      CONFIG.API_METHODS.POST,
      args
    );
    return response;

    args = {
      alias: "rwfe",
      imei: "3172711044",
      userId: "01",
      expireDate: "2020-08-31 23:59:59",
    };
    return {
      Result: 200,
      Response: "OK",
    };
  },
  deleteSharedVehicle: async (args = {}) => {
    const response = await commonJs.callApi(
      "/v1/settings/deletesharedvehicle",
      CONFIG.API_METHODS.POST,
      args
    );
    return response;

    args = {
      id: "10210",
    };
    return {
      Result: 200,
      Response: "OK",
    };
  },

  updateUserInfo: async (args) => {
    return SdkClient.updateUserInfo(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("updateUserInfo: ", err);
        throw new Error(err);
      });
  },
  manageSensorInfo: async (args) => {
    const response = await commonJs.callApi(
      "/v1/settings/savevehiclesensor",
      CONFIG.API_METHODS.POST,
      args
    );
    return response;
    return SdkClient.manageSensorInfo(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("manageSensorInfo: ", err);
        throw new Error(err);
      });
  },
  deleteSensor: async (args) => {
    return SdkClient.deleteSensor(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("deleteSensor: ", err);
        throw new Error(err);
      });
  },
  getSensorInfo: async (args) => {
    return SdkClient.listSensor(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("listSensor: ", err);
        throw new Error(err);
      });
  },
  getVehicleSensors: async (args = {}) => {
    const response = await commonJs.callApi(
      "/v1/settings/vehiclesensors",
      CONFIG.API_METHODS.GET,
      args
    );
    return response;
  },
  manageMainGroup: async (args = {}) => {
    const response = await commonJs.callApi(
      "/v1/settings/maingroup",
      CONFIG.API_METHODS.POST,
      args
    );
    return response;
  },
  getMainGroups: async (args = {}) => {
    const response = await commonJs.callApi(
      "/v1/settings/maingroups",
      CONFIG.API_METHODS.GET,
      args
    );
    return response;
  },
  deleteMainGroup: async (args = {}) => {
    const response = await commonJs.callApi(
      "/v1/settings/deletemaingroup",
      CONFIG.API_METHODS.POST,
      args
    );
    return response;
  },
  getVehicleInfo: async (args = {}) => {
    const response = await commonJs.callApi(
      "/v1/settings/getvehicleinfo",
      CONFIG.API_METHODS.GET,
      args
    );
    return response;
  },
  addVehicle: async (args = {}) => {
    const response = await commonJs.callApi(
      "/v1/settings/addvehicle",
      CONFIG.API_METHODS.POST,
      args
    );
    return response;
  },
};
