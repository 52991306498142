import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import { connect } from "react-redux";
// React Grid
import { SortingState, IntegratedSorting } from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

import { API_Settings } from "apihandler/Settings";

const getRowId = (row) => row.id;

var timer = null;

class UserGroupsGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      direction: "asc",
      recs: {
        rows: [],
        filteredRows: [],
        columns: [],
      },
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.loadRecords();
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  onSearch = (searchValue) => {
    const { recs } = this.state;
    const { rows, columns } = recs;
    clearTimeout(timer);
    var self = this;
    timer = setTimeout(() => {
      console.log("onSearch: ", searchValue);
      let qry = searchValue.toUpperCase();
      var list = rows.filter((row) => {
        return row.name.toUpperCase().indexOf(qry) > -1;
      });
      list = list.map((l, i) => {
        l.slno = i + 1;
        return l;
      });
      self.setState({
        recs: { rows: rows, filteredRows: list, columns: columns },
      });
    }, 500);
  };

  generateOptions = (rec) => {
    const { id, name } = rec;
    return (
      <div className="options">
        <Tooltip
          title="Edit"
          placement="bottom"
          onClick={() => this.props.togglePopupDialog(rec)}
        >
          <IconButton className="smallIcon">
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Delete"
          placement="bottom"
          onClick={() => this.props.onDeleteRecord(id, name)}
        >
          <IconButton className="smallIcon">
            <Delete />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  loadReloadRecords = () => {
    this.setState({
      recs: {
        loading: false,
        sorting: "asc",
        columns: [],
        rows: [],
        filteredRows: [],
      },
    });
    this.loadRecords();
  };

  loadRecords = () => {
    var self = this;
    let args = { pageNo: 1, pageLength: 100000 };
    API_Settings.getAllUserGroups(args).then((res) => {
      console.log("getAllUserGroups: ", res);
      if (res.Result === 200) {
        let cols = [
          { name: "slno", title: "Sl No." },
          { name: "name", title: "Group Name" },
          { name: "vehicles", title: "Vehicles" },
          { name: "description", title: "Description" },
          { name: "options", title: "Options" },
        ];
        let rows = [];
        res = res.Response;
        for (var i in res) {
          rows.push({
            id: res[i].id,
            slno: Number(i) + 1,
            name: res[i].name,
            vehicles: this.getVehiclesCount(res[i].id),
            description: res[i].description,
            options: this.generateOptions(res[i]),
          });
        }
        self.setState({
          recs: {
            loading: false,
            sorting: "asc",
            columns: cols,
            rows: rows,
            filteredRows: [],
          },
        });
      }
    });
  };

  getVehiclesCount = (groupId = 0) => {
    const { tmpVehicles } = this.props.mapReducer;
    return tmpVehicles.filter((v) => v.groupId === groupId).length;
  };

  render() {
    const { rows, columns, filteredRows } = this.state.recs;

    const tableColumnExtensions = [
      { columnName: "slno", width: 80, align: "center" },
      { columnName: "name", width: 200, align: "left" },
      { columnName: "options", width: 80, align: "center" },
    ];

    let searchOn = false;
    if (document.getElementById("userGroupSearch") != null) {
      searchOn =
        document.getElementById("userGroupSearch").value.trim() === ""
          ? false
          : true;
    }

    const gridHeight = window.innerHeight / 1.5;

    return columns.length > 0 ? (
      <div id="userGroupsGrid" className="dataRGrid">
        <Grid
          rows={searchOn ? filteredRows : rows}
          columns={columns}
          getRowId={getRowId}
        >
          <SortingState
            defaultSorting={[{ columnName: "name", direction: "asc" }]}
            columnSortingEnabled={true}
            //   onSortingChange={this.changeZoneSort}
            columnExtensions={[
              { columnName: "view", sortingEnabled: false },
              { columnName: "options", sortingEnabled: false },
            ]}
          />
          <IntegratedSorting />
          <VirtualTable
            columnExtensions={tableColumnExtensions}
            height={gridHeight}
          />
          <TableHeaderRow showSortingControls />
        </Grid>
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: gridHeight,
          alignItems: "center",
        }}
      >
        <p style={{ color: "#a1a1a1", fontSize: 14 }}>Loading...</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupsGrid);
