import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { Replay as Reload, Add, Delete } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import SearchButton from "../../ui/searchbutton/SearchButton";
import ConfirmDialog from "../../ui/confirmdialog/ConfirmDialog";

import SubAccountsGrid from "./SubAccountsGrid";
import DialogAddSubAccount from "./DialogAddSubAccount";

import { API_Settings } from "../../../apihandler/Settings";
import { commonJs } from "common/common";
import { NOTIFY } from "common/consts";

class SubAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, popupDialog: false, showDialog: true };
  }

  reloadGrid = () => {
    this._subAccountListGrid.loadReloadRecords();
  };

  onSearch = (e) => {
    let val = e.target.value.trim();
    this._subAccountListGrid.onSearch(val);
  };

  togglePopup = (rec) => {
    const { popupDialog } = this.state;
    if (rec.id) {
      let self = this;
      this.setState({
        popupDialog: !popupDialog,
        showDialog: false,
      });
      setTimeout(() => {
        if (self._subAccountDialog) {
          self._subAccountDialog.setData(rec);
        }
        self.setState({
          showDialog: true,
        });
      }, 5000);
    } else {
      this.setState(
        {
          popupDialog: !popupDialog,
          showDialog: true,
        },
        () => {
          if (!popupDialog) {
            this._subAccountDialog.generateAuthUrl();
          }
        }
      );
    }
  };

  onDeleteRecord = (id, name) => {
    this._confirmDialog.toggleConfirmDialog(id, name);
  };

  onDeleteSuccess = (id) => {
    if (id) {
      const { uid } = this.props.loginReducer;
      const self = this;
      API_Settings.deleteSubAccount({
        accountId: id,
        managerId: uid,
      })
        .then((res) => {
          if (res.Result === 200) {
            commonJs.showNotify(
              self.props,
              NOTIFY.SUCCESS,
              "Record deleted successfully..!",
              commonJs.getDefaultDismissBtn()
            );
            self._confirmDialog.toggleConfirmDialog("close");
            self.reloadGrid();
          } else {
            commonJs.showNotify(
              self.props,
              NOTIFY.ERROR,
              res.Reason,
              commonJs.getDefaultDismissBtn()
            );
          }
        })
        .catch((err) => {
          console.log("Not");
        });
    }
  };

  toggleLoadingDialog = (showDialog = false) => {
    this.setState({
      showDialog,
    });
  };

  render() {
    const { loading, popupDialog, showDialog } = this.state;

    return (
      <div id="subAccountsListGrid" className="settingsGrid">
        <ul>
          <li className="searchDiv">
            <SearchButton
              id="subAccountSearch"
              placeholder="Search By User Name"
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
              onChange={this.onSearch}
            />
          </li>
          <li>
            <Tooltip title="Reload" placement="bottom">
              <IconButton onClick={this.reloadGrid}>
                <Reload />
              </IconButton>
            </Tooltip>
          </li>
          <li title="Add Vehicle">
            <Tooltip title="Add Vehicle" placement="bottom">
              <IconButton onClick={this.togglePopup}>
                <Add />
              </IconButton>
            </Tooltip>
          </li>
          {/* <li>
            <Tooltip title="Delete All Vehicles" placement="bottom">
              <IconButton onClick={() => this.onDeleteRecord("all")}>
                <Delete />
              </IconButton>
            </Tooltip>
          </li> */}
        </ul>

        {!loading ? (
          <SubAccountsGrid
            childRef={(ref) => (this._subAccountListGrid = ref)}
            togglePopupDialog={this.togglePopup}
            onDeleteRecord={this.onDeleteRecord}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: window.innerHeight / 1.5,
              alignItems: "center",
            }}
          >
            <p style={{ color: "#a1a1a1", fontSize: 14 }}>Loading...</p>
          </div>
        )}

        {popupDialog && (
          <DialogAddSubAccount
            childRef={(ref) => (this._subAccountDialog = ref)}
            togglePopup={this.togglePopup}
            showDialog={showDialog}
            reloadGrid={this.reloadGrid}
            toggleLoadingDialog={this.toggleLoadingDialog}
          />
        )}

        {!showDialog && (
          <div className="popover-loading">
            <CircularProgress className={"primary"} />
          </div>
        )}

        <ConfirmDialog
          childRef={(ref) => (this._confirmDialog = ref)}
          onOk={this.onDeleteSuccess}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(SubAccounts));
