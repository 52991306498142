import { TRACK_ACTIONS } from "../actions/TrackActions";
import { dateFunction } from "../common/datefunctions";
import { DEFAULTS } from "../common/consts";

let initialState = {
  selectedTrackDuration: "today",
  fdt: dateFunction.getDate("today", "from", "YYYY-MM-DD HH:mm:ss"),
  tdt: dateFunction.getDate("today", "to", "YYYY-MM-DD HH:mm:ss"),
  stopTime: DEFAULTS.DEFAULT_TRACK_STOPTIME,
  routeDetails: {},
  routePoints: [],
  routeTmpPoints: [],
  routeSummary: [],
  routeParkings: [],
};

const trackReducer = (state = initialState, action) => {
  const { type, values } = action;
  switch (type) {
    case TRACK_ACTIONS.SET_SKIP_STOPS:
      return {
        ...state,
        routePoints: values.routePoints,
        routeTmpPoints: values.routeTmpPoints,
      };
    case TRACK_ACTIONS.SET_SELECTED_TRACK_DURATION:
      return {
        ...state,
        selectedTrackDuration: values.selectedTrackDuration,
      };
    case TRACK_ACTIONS.SET_TRACK_DATE:
      return {
        ...state,
        fdt: values.fdt,
        tdt: values.tdt,
      };
    case TRACK_ACTIONS.SET_TRACK_FROM_DATE:
      return {
        ...state,
        fdt: values.dt,
      };
    case TRACK_ACTIONS.SET_TRACK_TO_DATE:
      return {
        ...state,
        tdt: values.dt,
      };
    case TRACK_ACTIONS.SET_TRACK_STOP_TIME:
      return {
        ...state,
        stopTime: values.stopTime,
      };
    case TRACK_ACTIONS.SET_TRACK_DATA:
      return {
        ...state,
        routeDetails: values.routeDetails,
        routePoints: values.routePoints,
        routeSummary: values.routeSummary,
        trackBottomBar: values.trackBottomBar,
        routeParkings: values.routeParkings,
      };
    case TRACK_ACTIONS.SET_TRACK_FILTER:
      return {
        ...state,
        fdt: values.fdt,
        tdt: values.tdt,
        stopTime: values.stopTime,
        selectedTrackDuration: values.selectedTrackDuration,
      };
    default:
      break;
  }
  return state;
};

export default trackReducer;
