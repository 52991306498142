import React, { Component } from "react";
import { TextField, Switch, FormControlLabel } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import FleetDialog from "components/ui/dialogbox/FleetDialog";
import AutoComplete from "components/ui/autocomplete/AutoComplete";

import { DEFAULTS, NOTIFY, SUCCESS_MSG, WARNINGS } from "common/consts";
import { commonJs } from "common/common";
import { MAP_ACTIONS } from "actions/MapActions";
import { MAP_CONTROLS_ACTIONS } from "actions/MapControlsActions";
import { API_CPanel } from "apihandler/CPanel";

class UserDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {},
      showDialog: false,
      userNotifications: DEFAULTS.USER_NOTIFICATION_CONF,
      selectedUserNotification: [],
      selectedPackage: {},
      selectedCompany: {},
      selectedPrivileges: [],
      selectedRole: {},
      selectedManager: {},
      dateLimit: new Date(new Date().getFullYear() + "-12-31"),
      vehicleAddByUser: [
        { id: "false", name: "No" },
        { id: "trial", name: "Trial" },
        { id: "limited", name: "Limited" },
        { id: "unlimited", name: "Unlimited" },
      ],
      selectedVehicleAddByUser: {},
      api: false,
      userActive: true,
      googleMap: false,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  toggleDialog = (record) => {
    const { showDialog } = this.state;
    if (record.id === undefined) {
      console.log("toggleDialog Add:  ", record, !showDialog);
      if (record.add) {
        // Adding a new point
        this.setState({
          showDialog: !showDialog,
          record: {},
          selectedUserNotification: [],
          selectedPackage: {},
          selectedCompany: {},
          selectedRole: {},
          selectedManager: {},
          selectedPrivileges: [],
          api: false,
          userActive: true,
          googleMap: false,
        });
      } else {
        // Close
        this.setState({
          showDialog: !showDialog,
        });
      }
    } else {
      // Edit -> Open
      const { companies, packages, roles, privileges, managers } = this.props;

      let selectedCompany = {};
      console.log("companies: ", record);
      let company = companies.filter((c) => c.id === record.companyId);
      if (company.length > 0) {
        selectedCompany = { id: company[0].id, name: company[0].name };
      }

      let selectedPackage = {};
      let pkg = packages.filter((c) => c.id === record.packageId);
      if (pkg.length > 0) {
        selectedPackage = { id: pkg[0].id, name: pkg[0].name };
      }

      let selectedRole = {};
      let role = roles.filter((c) => c.id === record.roleId);
      if (role.length > 0) {
        selectedRole = { id: role[0].id, name: role[0].name };
      }

      let selectedManager = {};
      let manager = managers.filter((c) => c.id === record.manager);
      if (manager.length > 0) {
        selectedManager = { id: manager[0].id, name: manager[0].name };
      }

      let selectedPrivileges = [];
      let list = record.privileges;
      for (let i = 0; i < list.length; i++) {
        let privilege = privileges.filter((p) => p.id === list[i]);
        if (privilege.length > 0) {
          selectedPrivileges.push({
            id: privilege[0].id,
            name: privilege[0].name,
          });
        }
      }

      let selectedUserNotification = record.userNotifications.map((un) => {
        return { id: un, name: un };
      });

      this.setState({
        showDialog: !showDialog,
        record: record,
        selectedCompany,
        selectedPackage,
        selectedRole,
        selectedManager,
        selectedPrivileges,
        selectedUserNotification,
        userActive: record.active,
        api: record.api,
      });
    }
  };

  saveRecord = () => {
    const {
      record,
      selectedPackage,
      selectedRole,
      selectedPrivileges,
      selectedCompany,
      selectedManager,
      selectedUserNotification,
      api,
      userActive,
      googleMap,
    } = this.state;
    const userName = document.getElementById("cpanelUsername").value.trim();
    const email = document.getElementById("cpanelUserEmail").value.trim();
    const password = document.getElementById("cpanelUserPassword").value.trim();

    const apiKey = document.getElementById("cpanelUserApiKey").value.trim();
    const vehicleLimit = Number(
      document.getElementById("cpanelUserVehicleLimit").value
    );

    let info = {
      name: document.getElementById("cpanelUserContactName").value.trim(),
      company: selectedCompany.name,
      address: document.getElementById("cpanelUserContactAddress").value.trim(),
      post_code: document
        .getElementById("cpanelUserContactPostcode")
        .value.trim(),
      city: document.getElementById("cpanelUserContactCity").value.trim(),
      country: document.getElementById("cpanelUserContactCountry").value.trim(),
      phone1: document.getElementById("cpanelUserContactPhone").value.trim(),
      email: document.getElementById("cpanelUserContactEmail").value.trim(),
    };

    const maxPoints = Number(
      document.getElementById("cpanelUserMaxPoints").value
    );
    const maxRoutes = Number(
      document.getElementById("cpanelUserMaxRoutes").value
    );
    const maxZones = Number(
      document.getElementById("cpanelUserMaxZones").value
    );

    const remarks = document.getElementById("cpanelUserRemarks").value.trim();
    const userLatLng = document.getElementById("cpanelUserLatLng").value.trim();

    const privileges = selectedPrivileges.map((p) => {
      return p.id;
    });
    const userNotifications = selectedUserNotification.map((n) => {
      return n.id;
    });

    const args = {
      id: record.id || 0,
      userName: userName,
      email: email,
      password: password,
      packageId: Number(selectedPackage.id),
      roleId: selectedRole.id,
      privileges: privileges,
      companyId: selectedCompany.id,
      manager: selectedManager.id,
      latLng: userLatLng,
      active: userActive.toString(),
      userNotifications: userNotifications,

      api: api.toString(),
      apiKey: apiKey,

      objectsAdd: "false",
      objectsNum: vehicleLimit,
      objectsLogtime: "",

      info: JSON.stringify(info),

      maxPoint: maxPoints.toString(),
      maxRoutes: maxRoutes.toString(),
      maxZones: maxZones.toString(),

      remarks: remarks,
      googleMap: Number(googleMap),
    };
    console.log("manageUser", args);

    if (args.companyId === undefined) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_SELECT_COMPANY
      );
      return false;
    }
    if (args.manager === undefined) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_SELECT_MANAGER
      );
      return false;
    }
    if (args.packageId === undefined) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_SELECT_PACKAGE
      );
      return false;
    }
    if (args.roleId === undefined) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        WARNINGS.PLEASE_SELECT_ROLE
      );
      return false;
    }

    let self = this;
    API_CPanel.manageUser(args)
      .then((res) => {
        if (res.Result === 200) {
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            SUCCESS_MSG.RECORD_SAVED
          );
        } else {
          commonJs.showNotify(self.props, NOTIFY.ERROR, "Record not saved..!");
        }
      })
      .catch((err) => {
        commonJs.showNotify(self.props, NOTIFY.ERROR, "Record not saved..!");
      });
    return;
  };

  onChangePackage = (val) => {
    if (val) {
      this.setState({
        selectedPackage: val,
      });
      this.setRoleAndPrivilege(val);
    } else {
      this.setState({
        selectedPackage: {},
        selectedRole: {},
        selectedPrivileges: [],
      });
    }
  };

  setRoleAndPrivilege = (selectedPkg = "basic") => {
    // get roles & privileges
    API_CPanel.getPackageInfo({ id: selectedPkg }).then((res) => {
      if (res.Result === 200) {
        const { roles, privileges } = this.props;
        let resRole = roles.filter((r) => r.id === res.Response.roleId);
        let sRole = {};
        if (resRole.length > 0) {
          sRole = resRole[0];
        }
        let resPrivileges = res.Response.privileges;
        let sPrivileges = [];
        for (let p = 0; p < resPrivileges.length; p++) {
          let pname = privileges.filter((v) => {
            return v.id === resPrivileges[p];
          });
          if (pname.length > 0) {
            sPrivileges.push({ id: resPrivileges[p], name: pname[0].name });
          }
        }
        this.setState({
          selectedRole: sRole,
          selectedPrivileges: sPrivileges,
        });
      }
    });
  };

  onChangePrivilege = (val) => {
    this.setState({
      selectedPrivileges: val,
    });
  };

  onChangeRole = (val) => {
    this.setState({
      selectedRole: val,
    });
    document.getElementById("cpanelUserVehicleLimit").value = "";
  };

  onChangeCompany = (val) => {
    this.setState({
      selectedCompany: val,
    });
  };

  onChangeManager = (val) => {
    this.setState({
      selectedManager: val,
    });
  };

  onChangeUserNotification = (val) => {
    this.setState({
      selectedUserNotification: val,
    });
  };

  onChangeApi = (val) => {
    this.setState({
      api: val,
    });
    if (!val) {
      document.getElementById("cpanelUserApiKey").value = "";
    }
  };

  onChangeActive = (val) => {
    this.setState({
      userActive: val,
    });
  };

  onChangeMap = (val) => {
    this.setState({
      googleMap: val,
    });
  };

  handleDateChange = (date) => {
    this.setState({
      dateLimit: date,
    });
  };

  onChangeUserAddVehicleSettings = (val) => {
    this.setState({
      selectedVehicleAddByUser: val,
    });
  };

  render() {
    const {
      record,
      showDialog,
      selectedCompany,
      selectedRole,
      selectedPrivileges,
      selectedManager,
      selectedPackage,
      api,
      userNotifications,
      selectedUserNotification,
      userActive,
      googleMap,
    } = this.state;

    const {
      companies,
      roles,
      packages,
      privileges,
      managers,
      mapReducer,
    } = this.props;

    let userInfo = {};
    if (record.info) {
      userInfo = JSON.parse(record.info);
    }

    console.log("edituser: ", mapReducer);

    return (
      <FleetDialog
        id="addCPanelUser"
        open={showDialog}
        onCloseSettings={this.toggleDialog}
        title={record.id ? "Edit User" : "Add User"}
        maxWidth="md"
        fullWidth={true}
        cancelButton={true}
        cancelAction={this.toggleDialog}
        saveButton={true}
        saveAction={this.saveRecord}
        saveBtnLabel="OK"
        disableBackdropClick={true}
        disableBackdrop={true}
        classNameWrapper="profileSettingsWrapper"
        draggable
      >
        <div className="grid">
          <div className="row">
            <div className="width25">
              <TextField
                id="cpanelUsername"
                label="User Name"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={record.name || ""}
                autoFocus={true}
              />
            </div>
            <div className="width25">
              <TextField
                id="cpanelUserEmail"
                label="User Email"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={record.email || ""}
              />
            </div>
            <div className="width25">
              <TextField
                id="cpanelUserPassword"
                label="New Password"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={""}
                type={"text"}
              />
            </div>
          </div>
          <div className="row">
            <div className="width25">
              <AutoComplete
                label={"Company"}
                list={companies}
                id="cpanelUserCompany"
                style={{ marginBottom: 20, width: "100%" }}
                onChange={this.onChangeCompany}
                defaultValue={selectedCompany}
                multiple={false}
                size="small"
              />
            </div>
            <div className="width25">
              <AutoComplete
                label={"Package"}
                list={packages}
                id="cpanelUserPackages"
                style={{ marginBottom: 20, width: "100%" }}
                onChange={this.onChangePackage}
                defaultValue={selectedPackage}
                multiple={false}
                size="small"
              />
            </div>
            <div className="width25">
              <AutoComplete
                label={"Role"}
                list={roles}
                id="cpanelUserRoles"
                style={{ marginBottom: 20, width: "100%" }}
                onChange={this.onChangeRole}
                defaultValue={selectedRole}
                multiple={false}
                size="small"
              />
            </div>
          </div>
          <div className="row">
            <div className="width25" style={{ width: "62%" }}>
              <AutoComplete
                label={"Privileges"}
                list={privileges}
                id="cpanelUserPrivileges"
                style={{ marginBottom: 20, width: "100%" }}
                onChange={this.onChangePrivilege}
                defaultValue={selectedPrivileges}
                multiple={true}
                size="small"
              />
            </div>
            <div className="width25">
              <AutoComplete
                label={"Manager"}
                list={managers}
                id="cpanelUserManager"
                style={{ marginBottom: 20, width: "100%" }}
                onChange={this.onChangeManager}
                defaultValue={selectedManager}
                multiple={false}
                size="small"
              />
            </div>
          </div>
          <div className="row">
            <div className="width25" style={{ width: "62%" }}>
              <AutoComplete
                label={"Select Notifications"}
                list={userNotifications}
                id="cpanelUserNotifications"
                style={{ marginBottom: 20, width: "100%" }}
                onChange={this.onChangeUserNotification}
                defaultValue={selectedUserNotification}
                multiple={true}
                size="small"
              />
            </div>
            <div className="width25">
              <TextField
                id="cpanelUserLatLng"
                label="User Lat Lng"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={record.latlng || DEFAULTS.USER_LAT_LNG}
                type={"text"}
              />
            </div>
          </div>

          <div className="row">
            <div
              className="width25"
              style={{ marginTop: -10, marginBottom: 15 }}
            >
              <FormControlLabel
                control={
                  <Switch
                    classes={{
                      thumb: "switchThumb",
                      track: "switchTrack",
                      root: "switchRoot",
                    }}
                    id="cpanelUserActive"
                    defaultChecked={userActive}
                    onChange={() => this.onChangeActive(!userActive)}
                  />
                }
                label="Active"
                className="switch"
              />
            </div>
          </div>

          <div className="row">
            <label className="label labelBorder">For developers</label>
          </div>
          <div className="row">
            <div className="width25">
              <FormControlLabel
                control={
                  <Switch
                    classes={{
                      thumb: "switchThumb",
                      track: "switchTrack",
                      root: "switchRoot",
                    }}
                    id="cpanelUserApi"
                    defaultChecked={api}
                    onChange={() => this.onChangeApi(!api)}
                  />
                }
                label="Allow to use API"
                className="switch"
              />
            </div>
            <div className="width25" style={{ marginTop: 2 }}>
              <TextField
                id="cpanelUserApiKey"
                label="Api Key"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={api ? record.apiKey : ""}
                disabled={!api}
              />
            </div>
            <div className="width25">
              <FormControlLabel
                control={
                  <Switch
                    classes={{
                      thumb: "switchThumb",
                      track: "switchTrack",
                      root: "switchRoot",
                    }}
                    defaultChecked={
                      record.googleMap ? record.googleMap === 1 : googleMap
                    }
                    onChange={() => this.onChangeMap(!googleMap)}
                  />
                }
                label="Enable Google Map"
                className="switch"
              />
            </div>
          </div>

          <div className="row">
            <label className="label labelBorder">Vehicle add by user</label>
          </div>
          <div className="row">
            {/* <div className="width25">
              <AutoComplete
                label={"Add Vehicle From Settings"}
                list={userNotifications}
                id="cpanelUserAddFromSettings"
                style={{ marginBottom: 20, width: "100%" }}
                onChange={this.onChangeUserAddVehicleSettings}
                defaultValue={selectedVehicleAddByUser}
                multiple={true}
                size="small"
              />
            </div> */}
            <div className="width25">
              <TextField
                id="cpanelUserVehicleLimit"
                label="Vehicle Limit"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={record.objectsNum || ""}
                disabled={selectedRole.name === "manager" ? false : true}
              />
            </div>
            {/* <div
              className="width25 singleDatePickerWrapper"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: -20,
                position: "relative",
                right: 4,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <label className="label" style={{ width: 100 }}>
                  {"Date limit : "}
                </label>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format={FORMATS.DATE_PICKER_FORMAT}
                  margin="normal"
                  id="cpanelUserDateLimit"
                  value={dateLimit}
                  placeholder=""
                  onChange={this.handleDateChange}
                />
              </MuiPickersUtilsProvider>
            </div> */}
          </div>

          <div className="row">
            <label className="label labelBorder ">Contact information</label>
          </div>
          <div className="row">
            <div className="width25">
              <TextField
                id="cpanelUserContactName"
                label="Full Name"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={userInfo.name || ""}
              />
            </div>
            <div className="width25">
              <TextField
                id="cpanelUserContactEmail"
                label="Email ID"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={userInfo.email || ""}
              />
            </div>
            <div className="width25">
              <TextField
                id="cpanelUserContactCity"
                label="City"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={userInfo.city || ""}
              />
            </div>
          </div>
          <div className="row">
            <div className="width25">
              <TextField
                id="cpanelUserContactCountry"
                label="County/State"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={userInfo.country || ""}
              />
            </div>
            <div className="width25">
              <TextField
                id="cpanelUserContactPhone"
                label="Phone number"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={userInfo.phone1 || ""}
              />
            </div>
            <div className="width25">
              <TextField
                id="cpanelUserContactPostcode"
                label="Pincode"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={userInfo.post_code || ""}
              />
            </div>
          </div>
          <div className="row">
            <div className="width25" style={{ width: "62%" }}>
              <TextField
                id="cpanelUserContactAddress"
                label="Address"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={userInfo.address || ""}
                multiline={true}
                rows={3}
              />
            </div>
          </div>

          <div className="row">
            <label className="label labelBorder">Other</label>
          </div>
          <div className="row">
            <div className="width25">
              <TextField
                id="cpanelUserMaxPoints"
                label="Max. number of Points"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={record.maxPoints || ""}
              />
            </div>
            <div className="width25">
              <TextField
                id="cpanelUserMaxRoutes"
                label="Max. number of Routes"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={record.maxRoutes || ""}
              />
            </div>
            <div className="width25">
              <TextField
                id="cpanelUserMaxZones"
                label="Max. number of Zones"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={record.maxZones || ""}
              />
            </div>
          </div>

          <div className="row">
            <div className="width25" style={{ width: "62%" }}>
              <TextField
                id="cpanelUserRemarks"
                label="Remarks"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={record.remarks || ""}
                multiline={true}
                rows={3}
              />
            </div>
          </div>
        </div>
      </FleetDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleAddPoi: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_POI_CONTROL,
        values,
      });
    },
    setPointEdit: (values) => {
      dispatch({
        type: MAP_CONTROLS_ACTIONS.SET_POINT_EDIT,
        values,
      });
    },
    setPointLatLng: (values) => {
      dispatch({
        type: MAP_CONTROLS_ACTIONS.SET_POINT_LATLNG,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(UserDialog));
