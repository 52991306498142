import React, { Component } from "react";
import { connect } from "react-redux";
import { SortingState, IntegratedSorting } from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";
import { IconButton, Link, Tooltip } from "@material-ui/core";
import { CloudDownload as ExportIcon } from "@material-ui/icons";

import SearchButton from "components/ui/searchbutton/SearchButton";

import { CONFIG, LABELS } from "common/consts";
import { commonJs } from "common/common";

const getRowId = (row) => row.vehicleno;

var timer = null;

class LiveVehiclesInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      direction: "asc",
      records: {
        rows: [],
        filteredRows: [],
        columns: [
          { name: "vehicleno", title: "Vehicle No." },
          { name: "fixtime", title: "Time" },
          { name: "location", title: "Location" },
          { name: "status", title: "Status" },
          { name: "since", title: "Since (Days)" },
        ],
        searchText: "",
      },
    };
  }

  onVehicleSearch = () => {
    clearTimeout(timer);
    var self = this;
    timer = setTimeout(() => {
      self.setState({
        rows: [],
      });
    }, 500);
  };

  getVehiclePosition = (vehicle) => {
    return (
      <Link
        title={vehicle.address}
        target="_blank"
        href={
          CONFIG.GOOGLE_MAP_REDIRECTION_LINK +
          vehicle.latitude +
          "," +
          vehicle.longitude
        }
      >
        {vehicle.address}
      </Link>
    );
  };

  exportGrid = () => {
    const { columns } = this.state.records;
    let rows = this._rows;
    // Generate CSV Text
    let csvContent = "data:text/csv;charset=utf-8,";
    // Generate Column Titles
    for (let c = 0; c < columns.length; c++) {
      csvContent += columns[c].title + ",";
    }
    csvContent += "\n";
    // Generate Rows Data
    for (let i = 0; i < rows.length; i++) {
      let row = rows[i];
      for (let c = 0; c < columns.length; c++) {
        let columnId = columns[c].name;
        if (columnId === "id") {
          continue;
        } else {
          // If location => get from object
          if (columnId === "location") {
            csvContent += row[columnId].props.title + ",";
          } else {
            csvContent += row[columnId] + ",";
          }
        }
      }
      csvContent += "\n";
    }
    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "live-vehicles-information.csv");
    document.body.appendChild(link);
    link.click();
  };

  render() {
    const { columns, filteredRows } = this.state.records;
    const { vehiclesList } = this.props.commonReducer;

    this._rows = [];
    let c = 0;
    let searchText = "";
    if (document.getElementById("liveVehicleNameSearch")) {
      searchText = document
        .getElementById("liveVehicleNameSearch")
        .value.trim()
        .toUpperCase();
    }
    for (let i = 0; i < vehiclesList.length; i++) {
      if (vehiclesList[i].name.toUpperCase().indexOf(searchText) > -1) {
        let statusInfo = commonJs.getVehicleStatusInfo(vehiclesList[i]);
        this._rows.push({
          vehicleno: vehiclesList[i].name,
          fixtime: vehiclesList[i].objectlogtime,
          location: this.getVehiclePosition(vehiclesList[i]),
          since: statusInfo.since,
          status: statusInfo.status,
        });
        c++;
      }
    }

    const tableColumnExtensions = [
      { columnName: "vehicleno", width: 160, align: "left" },
      { columnName: "fixtime", width: 140, align: "left" },
      { columnName: "location", width: 400, align: "left" },
    ];

    console.log("liveVehicles: rows: ", this._rows);

    return columns.length > 0 ? (
      <div id="liveVehiclesInfoGrid" className="dataRGrid">
        <ul>
          <li>
            <SearchButton
              id="liveVehicleNameSearch"
              placeholder="Search By Vehicle Name"
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
              onChange={this.onVehicleSearch}
            />
          </li>
          <li style={{ position: "relative", top: 10 }}>
            <Tooltip title={LABELS.EXPORT_CSV_OR_EXCEL} placement="bottom">
              <IconButton onClick={this.exportGrid}>
                <ExportIcon />
              </IconButton>
            </Tooltip>
          </li>
        </ul>
        <Grid
          rows={filteredRows.length > 0 ? filteredRows : this._rows}
          columns={columns}
          getRowId={getRowId}
        >
          <SortingState
            defaultSorting={[{ columnName: "vehicleno", direction: "asc" }]}
            columnSortingEnabled={true}
            columnExtensions={[
              { columnName: "location", sortingEnabled: false },
            ]}
          />
          <IntegratedSorting />
          <VirtualTable
            columnExtensions={tableColumnExtensions}
            height={window.innerHeight - 180}
          />
          <TableHeaderRow showSortingControls />
        </Grid>
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: 100,
          alignItems: "center",
        }}
      >
        <p style={{ color: "#a1a1a1", fontSize: 14 }}>Loading...</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commonReducer: state.commonReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveVehiclesInfo);
