import React, { Component } from "react";

import Icon1 from "images/icons/objects/ambulance.png";
import Icon2 from "images/icons/objects/bike.png";
import Icon3 from "images/icons/objects/boat.png";
import Icon4 from "images/icons/objects/bus.png";
import Icon5 from "images/icons/objects/cargreen.png";
import Icon6 from "images/icons/objects/carorange.png";
import Icon7 from "images/icons/objects/carred.png";
import Icon8 from "images/icons/objects/carwhite.png";
import Icon9 from "images/icons/objects/crane.png";
import Icon10 from "images/icons/objects/fireengine.png";

import Icon11 from "images/icons/objects/jcb.png";
import Icon12 from "images/icons/objects/jeep.png";
import Icon13 from "images/icons/objects/person.png";
import Icon14 from "images/icons/objects/pet.png";
import Icon15 from "images/icons/objects/police.png";
import Icon16 from "images/icons/objects/reefer.png";
import Icon17 from "images/icons/objects/rmc.png";
import Icon18 from "images/icons/objects/swm.png";
import Icon19 from "images/icons/objects/tank.png";
import Icon20 from "images/icons/objects/tanker.png";
import Icon21 from "images/icons/objects/taxi.png";
import Icon22 from "images/icons/objects/tipper.png";
import Icon23 from "images/icons/objects/tractor.png";
import Icon24 from "images/icons/objects/truck.png";

class IconsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icons: [
        {
          img: Icon1,
          value: "/img/markers/objects/ambulance.png",
        },
        {
          img: Icon2,
          value: "/img/markers/objects/bike.png",
        },
        {
          img: Icon3,
          value: "/img/markers/objects/boat.png",
        },
        {
          img: Icon4,
          value: "/img/markers/objects/bus.png",
        },
        {
          img: Icon5,
          value: "/img/markers/objects/cargreen.png",
        },
        {
          img: Icon6,
          value: "/img/markers/objects/carorange.png",
        },
        {
          img: Icon7,
          value: "/img/markers/objects/carred.png",
        },
        {
          img: Icon8,
          value: "/img/markers/objects/carwhite.png",
        },
        {
          img: Icon9,
          value: "/img/markers/objects/crane.png",
        },
        {
          img: Icon10,
          value: "/img/markers/objects/fireengine.png",
        },
        {
          img: Icon11,
          value: "/img/markers/objects/jcb.png",
        },
        {
          img: Icon12,
          value: "/img/markers/objects/jeep.png",
        },
        {
          img: Icon13,
          value: "/img/markers/objects/person.png",
        },
        {
          img: Icon14,
          value: "/img/markers/objects/pet.png",
        },
        {
          img: Icon15,
          value: "/img/markers/objects/police.png",
        },
        {
          img: Icon16,
          value: "/img/markers/objects/reefer.png",
        },
        {
          img: Icon17,
          value: "/img/markers/objects/rmc.png",
        },
        {
          img: Icon18,
          value: "/img/markers/objects/swm.png",
        },
        {
          img: Icon19,
          value: "/img/markers/objects/tank.png",
        },
        {
          img: Icon20,
          value: "/img/markers/objects/tanker.png",
        },
        {
          img: Icon21,
          value: "/img/markers/objects/taxi.png",
        },
        {
          img: Icon22,
          value: "/img/markers/objects/tipper.png",
        },
        {
          img: Icon23,
          value: "/img/markers/objects/tractor.png",
        },
        {
          img: Icon24,
          value: "/img/markers/objects/truck.png",
        },
      ],
    };
  }

  onClickIcon = (icon) => {
    this.props.onIconChange(icon);
  };

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  getIconImage = (path) => {
    let img = [];
    const { icons } = this.state;
    img = icons.filter((icon) => {
      return icon.value === path ? icon : false;
    });
    return img;
  };

  render() {
    const { icons } = this.state;
    const { iconValue, iconPopup } = this.props;

    return (
      iconPopup && (
        <div id="iconsList">
          <ul>
            {icons.map((icon, indx) => {
              return (
                <li key={"vicon-" + indx}>
                  <img
                    path={icon.value}
                    src={icon.img}
                    onClick={() => this.onClickIcon(icon)}
                    alt=""
                    className={icon.value === iconValue ? "active" : ""}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      )
    );
  }
}

export default IconsList;
