import React, { Component } from "react";
import { Marker, Tooltip } from "react-leaflet";
import L from "leaflet";

import { IMAGES } from "../../../../common/images";

class PointMarker extends Component {
  getPointMarker = (url) => {
    let markerUrl = url;
    const customMarker = L.icon({
      iconUrl: markerUrl,
      iconRetinaUrl: markerUrl,
      iconAnchor: null,
      popupAnchor: [0, -3],
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(22, 24),
      className: "pointMarker",
    });
    return customMarker;
  };

  onPointDragEnd = (e) => {
    const { lat, lng } = e.target._latlng;
    if (document.querySelector("div#addPoint")) {
      let latLng = lat + "," + lng;
      document.querySelector("div#addPoint").setAttribute("latlng", latLng);
    }
  };

  render() {
    const { point, pointId, pointImg } = this.props;
    let iconUrl = point.iconUrl;
    if (!IMAGES.points[iconUrl]) {
      IMAGES.points[iconUrl] = IMAGES.points[18];
    }
    let url = IMAGES.points[iconUrl].image;
    if (pointId === point.id) {
      url = pointImg.image;
    }

    return (
      <Marker
        position={[point.latitude, point.longitude]}
        icon={this.getPointMarker(url)}
        className={"point"}
        draggable={pointId === point.id}
        ondragend={this.onPointDragEnd}
      >
        <Tooltip
          direction="right"
          offset={[10, -2]}
          className={"pointLabel"}
          permanent
        >
          <span>{point.name}</span>
        </Tooltip>
      </Marker>
    );
  }
}

export default PointMarker;
