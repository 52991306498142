import { POI_ACTIONS } from "../actions/PoiActions";

let initialState = {
  showZones: false, // Will Show/Hide Zones in Map & Map Tab
  showPoints: false, // Will Show/Hide Points in Map & Map Tab
  showRoutes: false, // Will Show/Hide Routes in Map & Map Tab
  zones: [],
  points: [],
  routes: [],
};

const poiReducer = (state = initialState, action) => {
  const { type, values } = action;
  switch (type) {
    case POI_ACTIONS.SET_ROUTES:
      return {
        ...state,
        routes: values.routes,
      };
    case POI_ACTIONS.SET_ZONES:
      return {
        ...state,
        zones: values.zones,
      };
    case POI_ACTIONS.TOGGLE_SHOW_ZONES:
      return {
        ...state,
        showZones: values.showZones,
      };
    case POI_ACTIONS.SET_POINTS:
      return {
        ...state,
        points: values.points,
      };
    case POI_ACTIONS.TOGGLE_SHOW_POINTS:
      return {
        ...state,
        showPoints: values.showPoints,
      };
    case POI_ACTIONS.TOGGLE_SHOW_ROUTES:
      return {
        ...state,
        showRoutes: values.showRoutes,
      };
    case POI_ACTIONS.TOGGLE_ALL_POIS:
      return {
        ...state,
        showZones: values.showZones,
        showPoints: values.showPoints,
        showRoutes: values.showRoutes,
      };
    default:
      break;
  }
  return state;
};

export default poiReducer;
