import React, { Component } from "react";
import {
  TextField,
  Switch,
  FormControlLabel,
  CircularProgress,
  Checkbox,
} from "@material-ui/core";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import FleetDialog from "../../ui/dialogbox/FleetDialog";
import UiDatePicker from "../../ui/uidatepicker/UiDatePicker";
import MultiSelectionBox from "../../ui/selectionbox/MultiSelectionBox";

import { commonJs } from "../../../common/common";
import {
  NOTIFY,
  FORMATS,
  PRIVILEGES,
  LABELS,
  CONFIG,
} from "../../../common/consts";
import { dateFunction } from "../../../common/datefunctions";
import { API_Map } from "../../../apihandler/Map";
import { API_Settings } from "apihandler/Settings";
import { PAGE_ACTIONS } from "actions/PageActions";

class DialogAddSubAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recordId: "",
      subAccountEmail: "",
      subAccountPassword: "",
      isActive: true,
      privileges: [
        {
          id: PRIVILEGES.TRACK,
          name: "Track",
        },
        {
          id: PRIVILEGES.REPORTS,
          name: "Reports",
        },
        {
          id: PRIVILEGES.EDIT_VEHICLE,
          name: "Edit Vehicle",
        },
        {
          id: PRIVILEGES.POI,
          name: "POI",
        },
      ],
      selectedPrivileges: [],
      selectedZones: [],
      selectedPoints: [],
      selectedRoutes: [],
      selectedVehicles: [],
      expireDt: dateFunction.getDate("thismonth", "to", FORMATS.SQL_DATE),
      zones: null,
      points: null,
      routes: null,
      authUrl: "",
      isActiveAuthUrl: true,
      record: {},
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.getZones();
    this.getPoints();
    this.getRoutes();
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  toggleIsActive = (isActive = true) => {
    this.setState({
      isActive,
    });
  };

  getZones = () => {
    let self = this;
    API_Map.getAllZones().then((res) => {
      if (res.Result === 200) {
        self.setState({
          zones: res.Response,
        });
      }
    });
  };

  getPoints = () => {
    let self = this;
    API_Map.getAllPoints().then((res) => {
      if (res.Result === 200) {
        self.setState({
          points: res.Response,
        });
      }
    });
  };

  getRoutes = () => {
    let self = this;
    API_Map.getAllRoutes().then((res) => {
      if (res.Result === 200) {
        self.setState({
          routes: res.Response,
        });
      }
    });
  };

  saveRecord = () => {
    console.log("saveRecord: ", this.state);

    const subAccountEmail = document
      .getElementById("subAccountEmail")
      .value.trim();
    const subAccountUsername = document
      .getElementById("subAccountUsername")
      .value.trim();
    const subAccountPassword = document
      .getElementById("subAccountPassword")
      .value.trim();

    const {
      recordId,
      isActive,
      selectedPrivileges,
      expireDt,
      isActiveAuthUrl,
    } = this.state;
    const { uid } = this.props.loginReducer;

    // Form Validation
    if (subAccountUsername === "") {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Username should not be empty..!",
        commonJs.getDefaultDismissBtn()
      );
      return false;
    } else if (subAccountPassword === "" && recordId == "") {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Password should not be empty..!",
        commonJs.getDefaultDismissBtn()
      );
      return false;
    } else if (subAccountEmail === "") {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Email ID should not be empty..!",
        commonJs.getDefaultDismissBtn()
      );
      return false;
    } else if (selectedPrivileges.length === 0) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Please select privileges..!",
        commonJs.getDefaultDismissBtn()
      );
      return false;
    }
    if (subAccountPassword.length < 6 && recordId == "") {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Password minimum length should be 6..!",
        commonJs.getDefaultDismissBtn()
      );
      return false;
    }

    const authUrl = document.getElementById("accessUrl").value.trim();
    if (isActiveAuthUrl && authUrl === "") {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Please enter auth URL..!",
        commonJs.getDefaultDismissBtn()
      );
      return false;
    }

    let zones= this._zonesList._selectedValues.toString();
    let points= this._pointsList._selectedValues.toString();
    let routes= this._routesList._selectedValues.toString();
    if(selectedPrivileges.indexOf(PRIVILEGES.POI)===-1){
      zones= ''
      points= ''
      routes= ''
    }

    let args = {
      managerId: uid,
      accountId: Number(recordId),
      userName: subAccountUsername,
      emailId: subAccountEmail,
      password: subAccountPassword,
      active: isActive,
      privileges: selectedPrivileges,
      expiresOn: expireDt,
      zones,
      points,
      routes,
      vehicles: this._vehicleList._selectedValues.toString(),
      isAuthUrlActive: isActiveAuthUrl,
      authUrl,
    };
    const self = this;
    self.props.setLoading({
      loadingPopover: true,
    });
    API_Settings.manageSubAccount(args).then((res) => {
      self.props.setLoading({
        loadingPopover: false,
      });
      if (res.Result === 200) {
        commonJs.showNotify(
          self.props,
          NOTIFY.SUCCESS,
          "Record saved successfully..!",
          commonJs.getDefaultDismissBtn()
        );
        self.props.reloadGrid();
        self.props.togglePopup({});
      } else {
        commonJs.showNotify(
          self.props,
          NOTIFY.ERROR,
          "Error: " + res.Reason,
          commonJs.getDefaultDismissBtn()
        );
      }
    });
  };

  setData = (rec = {}) => {
    const { privileges, active, expiresOn } = rec;
    const isActiveAuthUrl = privileges["au_expire"] === "true";
    const expireDt = expiresOn;
    const authUrl = CONFIG.WEBSITE_URL + "/login?au=" + privileges["au"];
    const selectedPrivileges = [];
    if (privileges.object_control) {
      selectedPrivileges.push(PRIVILEGES.EDIT_VEHICLE);
    }
    if (privileges.reports) {
      selectedPrivileges.push(PRIVILEGES.REPORTS);
    }
    if (privileges.history) {
      selectedPrivileges.push(PRIVILEGES.TRACK);
    }
    let selectedZones = [];
    if (privileges.zone) {
      selectedZones = privileges.zone.split(",");
      if (selectedZones.length > 0) {
        selectedZones = selectedZones.map((z) => {
          return Number(z);
        });
      }
      selectedPrivileges.push(PRIVILEGES.POI);
    }
    let selectedPoints = [];
    if (privileges.marker) {
      selectedPoints = privileges.marker.split(",");
      if (selectedPoints.length > 0) {
        selectedPoints = selectedPoints.map((z) => {
          return Number(z);
        });
      }
    }
    let selectedRoutes = [];
    if (privileges.zone) {
      selectedRoutes = privileges.route.split(",");
      if (selectedRoutes.length > 0) {
        selectedRoutes = selectedRoutes.map((z) => {
          return Number(z);
        });
      }
    }
    let selectedVehicles = [];
    if (privileges.imei) {
      selectedVehicles = privileges.imei.split(",");
    }
    this.setState({
      recordId: rec.id,
      record: rec,
      isActiveAuthUrl,
      expireDt,
      authUrl,
      selectedPrivileges,
      selectedZones,
      selectedPoints,
      selectedRoutes,
      selectedVehicles,
      isActive: active,
    });
  };

  onChangePrivilege = (val) => {
    let selectedPrivileges = this.state.selectedPrivileges;
    if (selectedPrivileges.indexOf(val) > -1) {
      selectedPrivileges = selectedPrivileges.filter((p) => {
        return p !== val;
      });
    } else {
      selectedPrivileges.push(val);
    }
    this.setState({
      selectedPrivileges,
    });
  };

  handleDateChange = (type, dt) => {
    console.log("handleDateChange: ", type, dt);
    let dtformat = "YYYY-MM-DD HH:mm";
    dt = dateFunction.getFormatedDate(dt, FORMATS.SQL_DATE, dtformat);
    this.setState({
      expireDt: dt,
    });
  };

  generateAuthUrl = () => {
    const { uid } = this.props.loginReducer;
    let authUrl = commonJs.generateAuthUrl(uid);
    this.setState({
      authUrl,
    });
  };

  toggleIsActiveAuthUrl = (val) => {
    this.setState({
      isActiveAuthUrl: val,
    });
  };

  render() {
    const {
      recordId,
      privileges,
      expireDt,
      zones,
      points,
      routes,
      authUrl,
      isActiveAuthUrl,
      selectedPrivileges,
      record,
      selectedZones,
      selectedPoints,
      selectedRoutes,
      selectedVehicles,
      isActive,
    } = this.state;

    const { showDialog, mapReducer } = this.props;

    const vehicleList = mapReducer.tmpVehicles.map((v) => {
      return { id: v.imei, name: v.name };
    });

    console.log("selectedZones: ", selectedZones);

    return (
      showDialog && (
        <FleetDialog
          id="addSubAccount"
          open={true}
          onCloseSettings={this.props.togglePopup}
          title={recordId ? "Edit Sub Account" : "Add Sub Account"}
          maxWidth="md"
          fullWidth={false}
          cancelButton={true}
          saveButton={true}
          cancelAction={this.props.togglePopup}
          saveAction={this.saveRecord}
          saveBtnLabel="OK"
          disableBackdropClick={false}
          disableBackdrop={false}
          classNameBody="zonePopupBody"
          classNameWrapper="zonePopupWrapper settingsDialogBox"
          draggable
        >
          <div className="grid">
            <div className="row">
              <div className="col w45">
                <TextField
                  id="subAccountUsername"
                  label="Username"
                  classes={{ root: "txtfield" }}
                  autoFocus={true}
                  variant="outlined"
                  size="small"
                  onMouseDown={(e) => e.stopPropagation()}
                  defaultValue={record.userName || ""}
                />
              </div>
              <div className="col w45">
                <TextField
                  id="subAccountPassword"
                  label="Password"
                  classes={{ root: "txtfield" }}
                  variant="outlined"
                  size="small"
                  onMouseDown={(e) => e.stopPropagation()}
                  defaultValue={""}
                  type="text"
                />
              </div>
            </div>
            <div className="row">
              <div className="col w45">
                <TextField
                  id="subAccountEmail"
                  label="Email"
                  classes={{ root: "txtfield" }}
                  variant="outlined"
                  size="small"
                  onMouseDown={(e) => e.stopPropagation()}
                  defaultValue={record.email || ""}
                />
              </div>
              <div
                className="col w45"
                style={{
                  marginTop: -12,
                  marginBottom: 8,
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      classes={{
                        thumb: "switchThumb",
                        track: "switchTrack",
                        root: "switchRoot",
                      }}
                      id="isActive"
                      defaultChecked={isActive}
                    />
                  }
                  label="Active"
                  className="switch"
                  onChange={() => this.toggleIsActive(!isActive)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col w45">
                <div>
                  <label className="formLabel">
                    {LABELS.PRIVILEGES + " :"}
                  </label>
                </div>
                <div style={{ marginBottom: 10 }}>
                  {privileges.map((p) => {
                    return (
                      <FormControlLabel
                        key={"chkbox-" + p.id}
                        className={"checkBox"}
                        control={
                          <Checkbox
                            id={p.id}
                            value={p.id}
                            checked={selectedPrivileges.indexOf(p.id) > -1}
                            onChange={() => this.onChangePrivilege(p.id)}
                          />
                        }
                        label={p.name}
                      />
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col w33 mB15 multiSelectBox">
                <label className="formLabel">{LABELS.ZONES + " :"}</label>
                {zones ? (
                  <MultiSelectionBox
                    list={zones}
                    childRef={(ref) => (this._zonesList = ref)}
                    height={150}
                    defaultAllSelected={false}
                    selectedValues={selectedZones}
                  />
                ) : (
                  <CircularProgress className={"primary miniloader"} />
                )}
              </div>
              <div className="col w33 mB15 multiSelectBox">
                <label className="formLabel">{LABELS.POINTS + " :"}</label>
                {points ? (
                  <MultiSelectionBox
                    list={points}
                    childRef={(ref) => (this._pointsList = ref)}
                    height={150}
                    defaultAllSelected={false}
                    selectedValues={selectedPoints}
                  />
                ) : (
                  <CircularProgress className={"primary miniloader"} />
                )}
              </div>
              <div className="col w33 mB15 multiSelectBox">
                <label className="formLabel">{LABELS.ROUTES + " :"}</label>
                {routes ? (
                  <MultiSelectionBox
                    list={routes}
                    childRef={(ref) => (this._routesList = ref)}
                    height={150}
                    defaultAllSelected={false}
                    selectedValues={selectedRoutes}
                  />
                ) : (
                  <CircularProgress className={"primary miniloader"} />
                )}
              </div>
            </div>

            <div className="row">
              <div className="col w50">
                <div>
                  <label className="formLabel">
                    {LABELS.VEHICLES_LIST + " :"}
                  </label>
                </div>
                <MultiSelectionBox
                  list={vehicleList}
                  childRef={(ref) => (this._vehicleList = ref)}
                  defaultAllSelected={false}
                  selectedValues={selectedVehicles}
                />
              </div>
            </div>

            <div className="row" style={{ marginTop: 15, marginBottom: 15 }}>
              <div className="col w45">
                <FormControlLabel
                  control={
                    <Switch
                      classes={{
                        thumb: "switchThumb",
                        track: "switchTrack",
                        root: "switchRoot",
                      }}
                      defaultChecked={isActiveAuthUrl}
                    />
                  }
                  label="Is URL Active"
                  className="switch"
                  onChange={() => this.toggleIsActiveAuthUrl(!isActiveAuthUrl)}
                />
              </div>
            </div>

            <div className="row">
              <TextField
                id="accessUrl"
                label="Access URL"
                classes={{ root: "txtfield accessUrl" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={authUrl === "" ? " " : authUrl}
                disabled={true}
              />
            </div>

            <div className="row">
              <div className="col w45">
                <UiDatePicker
                  label="Account Expires On:"
                  dt={expireDt}
                  type="from"
                  disablePast={false}
                  disableFuture={false}
                  disabled={false}
                  datepicker={true}
                  handleDateChange={this.handleDateChange}
                />
              </div>
            </div>
          </div>
        </FleetDialog>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_POPOVER_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DialogAddSubAccount));
