import React, { Component } from "react";
import {
  Popover,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { GetApp as DownloadIcon } from "@material-ui/icons";

class GraphExportOptions extends Component {
  render() {
    const { showExportFiter } = this.props;

    return (
      <React.Fragment>
        <Button
          variant="contained"
          color="primary"
          onClick={() => this.props.toggleFilter("export")}
          buttonRef={(node) => {
            this._anchorExport = node;
          }}
        >
          <DownloadIcon />
        </Button>
        <Popover
          className="graphExportPopover"
          open={showExportFiter}
          anchorEl={this._anchorExport}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={() => this.props.toggleFilter("export")}
        >
          <List component="nav" className="dashboardFilterMenu">
            <ListItem
              button
              onClick={() => this.props.exportChart("image/png")}
            >
              <ListItemText primary="PNG Image" />
            </ListItem>
            <ListItem
              button
              onClick={() => this.props.exportChart("image/jpeg")}
            >
              <ListItemText primary="JPEG Image" />
            </ListItem>
            <ListItem
              button
              onClick={() => this.props.exportChart("image/svg")}
            >
              <ListItemText primary="SVG Image" />
            </ListItem>
            <ListItem
              button
              onClick={() =>
                this.props.exportChart("application/pdf", "l", 280)
              }
            >
              <ListItemText primary="PDF Document" />
            </ListItem>
            <ListItem button onClick={() => this.props.exportChart("print")}>
              <ListItemText primary="Print Chart" />
            </ListItem>
          </List>
        </Popover>
      </React.Fragment>
    );
  }
}

export default GraphExportOptions;
