import React, { Component } from "react";
import { Tabs, Tab } from "@material-ui/core";
import { connect } from "react-redux";

import { PAGE_ACTIONS } from "../../../../../../../actions/PageActions";
import FleetDialog from "../../../../../../ui/dialogbox/FleetDialog";
import EditVehicle from "./EditVehicle";
import FuelConsumption from "./fuelconsumption/FuelConsumption";
import Accuracy from "./accuracy/Accuracy";
import VehicleSensors from "./VehicleSensors";
import VehicleDocs from "./docs/VehicleDocs";
import { API_Settings } from "apihandler/Settings";

class VehicleSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      draggable: false,
      sensorPopup: false,
      sensor: {},
      vehicleSettings: {},
    };
  }

  getSettings = () => {
    const { selectedVehicle } = this.props.pageReducer;
    const self = this;
    self.props.setLoading({
      loadingPopover: true,
    });
    API_Settings.getVehicleInfo({ imei: selectedVehicle }).then((res) => {
      self.props.setLoading({
        loadingPopover: false,
      });
      if (res.Result === 200) {
        const vehicleSettings = res.Response;
        self.setState({
          vehicleSettings,
        });
      }
    });
  };

  componentDidMount() {
    this.getSettings();
  }

  showVehicleMenu = (imei) => {
    document.getElementById("voptions-" + imei).classList.toggle("active");
  };

  closeEditVehiclePopup = () => {
    this.props.toggleEditVehicle({ vehicleEditPopup: false });
  };

  saveSettings = () => {
    const vname = document.getElementById("ev-VehicleName").value.trim();
    const vimei = document.getElementById("ev-VehicleImei").value.trim();
    const vmodel = document.getElementById("ev-VehicleModel").value.trim();
    const vtype = document.getElementById("ev-VehicleType").value.trim();
    const vsize = document.getElementById("ev-VehicleSize").value.trim();
    const vplateno = document.getElementById("ev-VehiclePlateNo").value.trim();
    const vsimno = document.getElementById("ev-VehicleSimNo").value.trim();
    const vdriverName = document.getElementById("ev-DriverName").value.trim();
    const vdriverNo = document.getElementById("ev-DriverNo").value.trim();
    const vtargetKms = document.getElementById("ev-TargetKms").value.trim();
    const groupId = document.getElementById("groupDropdown").value;
    const mapIcon = document.getElementById("mapIconDropdown").value;
    const iconUrl = document
      .getElementById("ev-VehicleIcon")
      .getAttribute("data-value");
    const tailColor = document.getElementById("tailColor").value.trim();
    const tailPoints = document
      .getElementById("ev-VehicleTailPoints")
      .value.trim();
    const gpsDevice = document.getElementById("gpsDevicesDropdown").value;
    const engineHoursType = document.getElementById("engineHoursDropdown")
      .value;
    const engineHours = document
      .getElementById("ev-VehicleEngineHrs")
      .value.trim();
    const odometerType = document.getElementById("odometerDropdown").value;
    const odometer = document.getElementById("ev-VehicleOdometer").value.trim();
    const routeId = document.getElementById("vehicleRoute").value;
    const trailerId = document.getElementById("vehicleTrailer").value;
    let loadStatus = "";
    if (document.querySelector('input[name="ev-VehicleLoadStatus"]:checked')) {
      loadStatus = document.querySelector(
        'input[name="ev-VehicleLoadStatus"]:checked'
      ).value;
    }

    let args = {
      name: vname,
      imei: vimei,
      model: vmodel,
      vehicleType: vtype,
      vehicleSize: vsize,
      plateno: vplateno,
      simno: vsimno,
      driverName: vdriverName,
      driverNo: vdriverNo,
      targetKms: vtargetKms,
      groupId: groupId,
      mapIcon: mapIcon,
      iconUrl: iconUrl,
      tailColor,
      tailPoints,
      gpsDevice,
      engineHoursType,
      engineHours,
      odometerType,
      odometer,
      routeId,
      trailerId,
      loadStatus,
    };

    console.log("saveSettings: ", args);
  };

  toggleTab = (event, value) => {
    this.setState({ activeTab: value });
  };

  toggleDraggable = () => {
    const { draggable } = this.state;
    console.log("toggleDraggable: ", !draggable);
    this.setState({
      draggable: !draggable,
    });
  };

  toggleSensorPopup = (type, sensor) => {
    console.log("toggleSensorPopup: ", type, sensor);
    const { sensorPopup, draggable } = this.state;
    let sensorData = sensor;
    if (sensor === undefined) {
      sensorData = { name: "", param: "", units: "", formula: "" };
    }
    this.setState({
      sensorPopup: !sensorPopup,
      draggable: !draggable,
      sensor: sensorData,
    });
  };

  render() {
    const { vehicleEditPopup, selectedVehicle } = this.props.pageReducer;

    const {
      activeTab,
      draggable,
      sensorPopup,
      sensor,
      vehicleSettings,
    } = this.state;

    const { loadVehicles } = this.props;

    console.log("VehicleSettings: main:", vehicleSettings);

    return (
      vehicleEditPopup && (
        <FleetDialog
          id="editVehicle"
          open={vehicleEditPopup}
          onCloseSettings={this.closeEditVehiclePopup}
          title={"Edit Vehicle"}
          maxWidth="md"
          fullWidth={true}
          // saveButton={true}
          // cancelButton={true}
          cancelAction={this.closeEditVehiclePopup}
          saveAction={this.saveSettings}
          saveBtnLabel="OK"
          classNameWrapper="editVehicleWrapper"
          classNameBody="editVehicleBody"
          draggable={false}
        >
          <Tabs
            value={activeTab}
            onChange={this.toggleTab}
            variant="fullWidth"
            scrollButtons="auto"
            className="editVehicleTab"
            classes={{
              indicator: "editVehicleTabIndicator",
              scrollButtons: "editVehicleNavTabButtons",
            }}
          >
            <Tab label={"Vehicle Details"} classes={{ root: "tab" }} />
            <Tab label={"Fuel Consumption"} classes={{ root: "tab" }} />
            <Tab label={"Accuracy"} classes={{ root: "tab" }} />
            <Tab label={"Sensors"} classes={{ root: "tab" }} />
            {/* <Tab label={"Vehicle Documents"} classes={{ root: "tab" }} /> */}
            {/* <Tab label={"Service"} classes={{ root: "tab" }} /> */}
            {/* <Tab label={"Upload Images"} classes={{ root: "tab" }} /> */}
          </Tabs>

          {vehicleSettings.model === undefined && (
            <div style={{ height: window.innerHeight / 1.5 }}></div>
          )}

          {activeTab === 0 && vehicleSettings.model !== undefined && (
            <EditVehicle
              imei={selectedVehicle}
              vehicleSettings={vehicleSettings}
              closePopup={this.closeEditVehiclePopup}
              loadVehicles={loadVehicles}
            />
          )}

          {activeTab === 1 && vehicleSettings.model !== undefined && (
            <FuelConsumption
              imei={selectedVehicle}
              vehicleSettings={vehicleSettings}
              closePopup={this.closeEditVehiclePopup}
              loadVehicles={loadVehicles}
            />
          )}

          {activeTab === 2 && vehicleSettings.model !== undefined && (
            <Accuracy
              imei={selectedVehicle}
              vehicleSettings={vehicleSettings}
              closePopup={this.closeEditVehiclePopup}
              loadVehicles={loadVehicles}
            />
          )}

          {activeTab === 3 && (
            <VehicleSensors
              imei={selectedVehicle}
              childRef={(ref) => (this._sensorsGrid = ref)}
              toggleDraggable={this.toggleDraggable}
              toggleSensorPopup={this.toggleSensorPopup}
              sensorPopup={sensorPopup}
              sensor={sensor}
              closePopup={this.closeEditVehiclePopup}
              loadVehicles={loadVehicles}
            />
          )}

          {/* {activeTab === 4 && (
            <VehicleDocs
              imei={selectedVehicle}
              closePopup={this.closeEditVehiclePopup}
            />
          )} */}
        </FleetDialog>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageReducer: state.pageReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleEditVehicle: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_EDIT_VEHICLE_MENU,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_POPOVER_LOADING,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleSettings);
