import React, { Component } from "react";
import { Polyline } from "react-leaflet";
import { connect } from "react-redux";

import { MAP_CONTROLS_ACTIONS } from "../../../../actions/MapControlsActions";
import { COLORS } from "../../../../common/consts";

class DrawRoute extends Component {
  render() {
    const { routeLatLng } = this.props.mapControlsReducer;
    return routeLatLng.length ? (
      <Polyline positions={routeLatLng} color={COLORS.PRIMARY} />
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    mapControlsReducer: state.mapControlsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRouteLatLng: (values) => {
      dispatch({
        type: MAP_CONTROLS_ACTIONS.SET_ROUTE_LATLNG,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawRoute);
