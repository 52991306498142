import React, { Component } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

import { CONFIG, SUCCESS_MSG, ERRORS, NOTIFY } from "common/consts";
import { commonJs } from "common/common";
import { API_Settings } from "apihandler/Settings";

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmPassword: false,
      userInfo: {},
      loading: true,
    };
  }

  componentDidMount() {
    this.getUserInfo();
  }

  getUserInfo = () => {
    const { uid } = this.props.loginReducer;
    const self = this;
    API_Settings.getUserInfo({
      userId: uid,
    }).then((res) => {
      if (res.Result === 200) {
        self.setState({
          userInfo: res.Response,
          loading: false,
        });
      }
    });
  };

  toggleChangePasswordDialog = () => {
    console.log("toggleChangePasswordDialog: ", this.props);
  };

  saveAccount = () => {
    const userName = document.getElementById("userName").value.trim();
    const companyName = document.getElementById("companyName").value.trim();
    const postalCode = document
      .getElementById("companyPostalCode")
      .value.trim();
    const city = document.getElementById("companyCity").value.trim();
    const state = document.getElementById("companyState").value.trim();
    const phoneNumber = document.getElementById("companyPhone").value.trim();
    const email = document.getElementById("companyEmail").value.trim();
    const address = document.getElementById("companyAddress").value.trim();
    let args = {
      userName,
      companyName,
      postalCode,
      city,
      state,
      phoneNumber,
      email,
      address,
    };
    let self = this;
    API_Settings.updateUserInfo(args).then((res) => {
      if (res.Result === 200) {
        commonJs.showNotify(
          self.props,
          NOTIFY.SUCCESS,
          SUCCESS_MSG.RECORD_SAVED
        );
        self.getUserInfo();
      } else {
        commonJs.showNotify(self.props, NOTIFY.ERROR, ERRORS.RECORD_NOT_SAVED);
      }
    });
  };

  toggleNewPassword = () => {
    const { confirmPassword } = this.state;
    this.setState({
      confirmPassword: !confirmPassword,
    });
  };

  onChangePassword = () => {
    const { uid } = this.props.loginReducer;
    const currentPassword = document
      .getElementById("currentPassword")
      .value.trim();
    const newPassword = document.getElementById("newPassword").value.trim();
    const args = {
      userId: uid,
      currentPassword,
      newPassword,
    };
    if (newPassword.length < 6) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Password minimum length should be 6..!"
      );
      return;
    }
    if (currentPassword === newPassword) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Current Password & New Password are same..!"
      );
      return;
    }

    this.setState({
      loading: true,
    });
    const self = this;
    API_Settings.changePassword(args).then((res) => {
      if (res.Result === 200) {
        self.setState({
          loading: false,
        });
        commonJs.showNotify(
          self.props,
          NOTIFY.SUCCESS,
          "Password changed successfully..!"
        );
      } else {
        commonJs.showNotify(self.props, NOTIFY.ERROR, res.Reason);
      }
    });
  };

  render() {
    const { confirmPassword, userInfo, loading } = this.state;
    const { loginReducer } = this.props;
    const { logo, userName } = loginReducer;
    return (
      <div id="myAccount" className="grid">
        <div className="row">
          <img
            src={CONFIG.BASE_URL + logo}
            width="150"
            id="myAccountProfile"
            alt=""
          />
        </div>
        {loading ? (
          <div
            style={{
              height: 200,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <CircularProgress className={"primary"} />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "60%" }}>
              <div className="row">
                <label
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    marginTop: 10,
                    color: "#6B6BDE",
                    display: "flex",
                    flex: 1,
                    paddingTop: 8,
                    paddingBottom: 8,
                  }}
                >
                  Profile Details:
                </label>
              </div>
              <div className="row">
                <div className="w50" style={{ paddingRight: 10 }}>
                  <TextField
                    id="userName"
                    placeholder="Name"
                    className="txt"
                    variant="outlined"
                    size="small"
                    onMouseDown={(e) => e.stopPropagation()}
                    defaultValue={userName}
                  />
                </div>
                <div className="w50">
                  <TextField
                    id="companyName"
                    placeholder="Company Name"
                    className="txt"
                    variant="outlined"
                    size="small"
                    onMouseDown={(e) => e.stopPropagation()}
                    defaultValue={userInfo.companyName || ""}
                  />
                </div>
              </div>
              <div className="row">
                <div className="w50" style={{ paddingRight: 10 }}>
                  <TextField
                    id="companyPostalCode"
                    placeholder="Postal Code"
                    className="txt"
                    variant="outlined"
                    size="small"
                    onMouseDown={(e) => e.stopPropagation()}
                    defaultValue={userInfo.postalCode || ""}
                  />
                </div>
                <div className="w50">
                  <TextField
                    id="companyCity"
                    placeholder="City"
                    className="txt"
                    variant="outlined"
                    size="small"
                    onMouseDown={(e) => e.stopPropagation()}
                    defaultValue={userInfo.city || ""}
                  />
                </div>
              </div>
              <div className="row">
                <div className="w50" style={{ paddingRight: 10 }}>
                  <TextField
                    id="companyState"
                    placeholder="State"
                    className="txt"
                    variant="outlined"
                    size="small"
                    onMouseDown={(e) => e.stopPropagation()}
                    defaultValue={userInfo.state || ""}
                  />
                </div>
                <div className="w50">
                  <TextField
                    id="companyPhone"
                    placeholder="Phone Number"
                    className="txt"
                    variant="outlined"
                    size="small"
                    onMouseDown={(e) => e.stopPropagation()}
                    defaultValue={userInfo.phoneNumber || ""}
                  />
                </div>
              </div>
              <div className="row">
                <div className="w50" style={{ width: "48%" }}>
                  <TextField
                    id="companyEmail"
                    placeholder="E-mail"
                    className="txt"
                    variant="outlined"
                    size="small"
                    onMouseDown={(e) => e.stopPropagation()}
                    defaultValue={userInfo.email || ""}
                  />
                </div>
              </div>
              <div className="row">
                <TextField
                  id="companyAddress"
                  placeholder="Address"
                  className="txt"
                  variant="outlined"
                  size="small"
                  multiline
                  rows={3}
                  onMouseDown={(e) => e.stopPropagation()}
                  defaultValue={userInfo.address || ""}
                />
              </div>
              <div className="row" style={{ marginBottom: 20, marginTop: 10 }}>
                <Button
                  variant="outlined"
                  className="font-12 settingsBtn"
                  color="primary"
                  onClick={this.saveAccount}
                >
                  Save
                </Button>
              </div>
            </div>
            <div style={{ width: "35%" }}>
              <div className="row">
                <label
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    marginTop: 10,
                    color: "#6B6BDE",
                    display: "flex",
                    flex: 1,
                    paddingTop: 8,
                    paddingBottom: 8,
                  }}
                >
                  Change Password:
                </label>
              </div>
              <div className="row">
                <div
                  className="w50"
                  style={{ width: "100%", marginTop: 5, marginBottom: 5 }}
                >
                  <TextField
                    id="currentPassword"
                    placeholder="Current Password"
                    className="txt"
                    variant="outlined"
                    size="small"
                    onMouseDown={(e) => e.stopPropagation()}
                    defaultValue={""}
                    type="password"
                  />
                </div>
              </div>
              <div className="row">
                <div className="w50" style={{ width: "100%" }}>
                  <TextField
                    id="newPassword"
                    placeholder="New Password"
                    className="txt"
                    variant="outlined"
                    size="small"
                    onMouseDown={(e) => e.stopPropagation()}
                    defaultValue={""}
                    type={confirmPassword ? "text" : "password"}
                  />
                </div>
              </div>
              <div className="row">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={confirmPassword}
                      onChange={this.toggleNewPassword}
                      value={false}
                      color="primary"
                    />
                  }
                  label="Confirm Password"
                />
              </div>
              <div className="row" style={{ marginBottom: 20, marginTop: 10 }}>
                <Button
                  variant="outlined"
                  className="font-12 settingsBtn"
                  color="primary"
                  onClick={this.onChangePassword}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(MyAccount));
