import { ALERTS_ACTIONS } from "../actions/AlertsActions";

let initialState = {
  alerts: [],
};

const alertsReducer = (state = initialState, action) => {
  const { type, values } = action;
  switch (type) {
    case ALERTS_ACTIONS.SET_ALERTS:
      return {
        ...state,
        alerts: values.alerts,
      };
    default:
      break;
  }
  return state;
};

export default alertsReducer;
