import { combineReducers } from "redux";

import pageReducer from "./PageReducer";
import loginReducer from "./LoginReducer";
import mapReducer from "./MapReducer";
import commonReducer from "./CommonReducer";
import trackReducer from "./TrackReducer";
import reportReducer from "./ReportReducer";
import mapControlsReducer from "./MapControlsReducer";
import alertsReducer from "./AlertsReducer";
import tmpReducer from "./TmpReducer";
import poiReducer from "./PoiReducer";
import trackAnimationReducer from "./TrackAnimationReducer";

import cpanelReducer from "./CpanelReducer";

export default combineReducers({
  pageReducer,
  loginReducer,
  mapReducer,
  commonReducer,
  trackReducer,
  reportReducer,
  mapControlsReducer,
  alertsReducer,
  tmpReducer,
  cpanelReducer,
  poiReducer,
  trackAnimationReducer,
});
