import React, { Component } from "react";
import { Paper, Tabs, Tab } from "@material-ui/core";
import { connect } from "react-redux";

import MyAccount from "./MyAccount";
import VehiclesList from "./vehicleslist";
import SubUserVehicles from "./SubUserVehicles";
import UserGroups from "./groupslist/";
import MainGroups from "./MainGroups/index";
import SubAccounts from "./subaccounts";
import ShareVehicles from "./share";
import FleetDialog from "components/ui/dialogbox/FleetDialog";

import { commonJs } from "common/common";
import { PRIVILEGES, ROLES } from "common/consts";
import { PAGE_ACTIONS } from "actions/PageActions";

import "./styles.css";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      draggable: true,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  handleChange = (event, value) => {
    this.setState({
      activeTab: value,
    });
  };

  toggleSettingsDialog = () => {
    const { settingsMenu } = this.props.pageReducer;
    this.props.toggleSettingsMenu({
      settingsMenu: !settingsMenu,
    });
  };

  openShareVehicles = () => {
    const self = this;
    this.setState(
      {
        activeTab: 6,
      },
      () => {
        self._shareDialog.togglePopup({});
      }
    );
  };

  toggleDraggable = () => {
    const { draggable } = this.state;
    this.setState({
      draggable: !draggable,
    });
  };

  render() {
    const { activeTab } = this.state;
    const { pageReducer, loginReducer } = this.props;
    const { settingsMenu } = pageReducer;
    const { privileges, role } = loginReducer;

    const showShareVehicle = commonJs.hasPrivilege(
      PRIVILEGES.SHARE_VEHICLE,
      role,
      privileges
    );
    const mainUser =
      role === ROLES.SUPER_ADMIN ||
      role === ROLES.ADMIN ||
      role === ROLES.MANAGER;
    // const mainUser = false;

    return (
      <FleetDialog
        open={settingsMenu}
        onCloseSettings={this.toggleSettingsDialog}
        title={"Settings"}
        maxWidth="md"
        fullWidth={true}
        saveButton={true}
        cancelButton={true}
        cancelAction={this.toggleSettingsDialog}
        saveAction={this.saveSettings}
        saveBtnLabel="OK"
        classNameWrapper="profileSettingsWrapper"
        draggable={false}
      >
        <Paper square className="settingsTabContainer">
          <Tabs
            value={activeTab}
            onChange={this.handleChange}
            variant="scrollable"
            scrollButtons="auto"
            className="settingsTab"
            classes={{
              root: "settingsTabs",
              indicator: "settingsTabIndicator",
              scrollButtons: "scrollButtons",
            }}
          >
            <Tab
              label="My Account"
              classes={{
                root: "myAccount",
              }}
              className={activeTab === 0 ? "activeTab" : ""}
              key={"settings-myaccount"}
              disabled={false}
            />
            {mainUser && (
              <Tab
                label="Vehicles List"
                classes={{
                  root: "vehiclesListTab",
                }}
                className={activeTab === 1 ? "activeTab" : ""}
                key={"settings-vehicles"}
                disabled={false}
              />
            )}
            {mainUser && (
              <Tab
                label="Vehicle Users List"
                classes={{
                  root: "vehicleUsersListTab",
                }}
                className={activeTab === 2 ? "activeTab" : ""}
                key={"settings-vehicleusers"}
                disabled={false}
              />
            )}
            {mainUser && (
              <Tab
                label="Vehicle Groups List"
                classes={{
                  root: "vehicleGroupsListTab",
                }}
                className={activeTab === 3 ? "activeTab" : ""}
                key={"settings-vehiclegroups"}
                disabled={false}
              />
            )}
            {mainUser && (
              <Tab
                label="Main Groups List"
                classes={{
                  root: "vehicleGroupsListTab",
                }}
                className={activeTab === 4 ? "activeTab" : ""}
                key={"settings-maingroups"}
                disabled={false}
              />
            )}
            {mainUser && (
              <Tab
                label="Sub Accounts"
                classes={{
                  root: "subAccounts",
                }}
                className={activeTab === 5 ? "activeTab" : ""}
                key={"settings-subaccounts"}
                disabled={false}
              />
            )}
            {showShareVehicle && mainUser && (
              <Tab
                label="Shared Vehicles"
                classes={{
                  root: "shareVehicles",
                }}
                className={activeTab === 6 ? "activeTab" : ""}
                key={"settings-share"}
                disabled={false}
              />
            )}
          </Tabs>

          {activeTab === 0 && <MyAccount />}

          {activeTab === 1 && <VehiclesList />}

          {activeTab === 2 && <SubUserVehicles />}

          {activeTab === 3 && <UserGroups />}

          {activeTab === 4 && <MainGroups />}

          {activeTab === 5 && <SubAccounts />}

          {activeTab === 6 && (
            <ShareVehicles childRef={(ref) => (this._shareDialog = ref)} />
          )}
        </Paper>
      </FleetDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageReducer: state.pageReducer,
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSettingsMenu: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_SETTINGS_MENU,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
