import React, { Component } from "react";
import { dateFunction } from "../../../common/datefunctions";
import { API_Reports } from "../../../apihandler/Reports";
import { FORMATS } from "../../../common/consts";

class YesterdayInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalKms: 0,
      movingDuration: 0,
      idleDuration: 0,
      avgSpeed: 0,
      fleetUtilization: "0%",
      fdt: dateFunction.getDate("thismonth", "from", FORMATS.SQL_DATE),
      tdt: dateFunction.getDate("thismonth", "to", FORMATS.SQL_DATE),
    };
  }

  componentDidMount() {
    let self = this;
    API_Reports.getYesterdayTravelSummary().then((res) => {
      if (res.Result === 200) {
        const {
          totalKms,
          movingDuration,
          idleDuration,
          avgSpeed,
          fleetUtilization,
        } = res.Response;
        self.setState({
          totalKms,
          movingDuration,
          idleDuration,
          avgSpeed,
          fleetUtilization,
        });
      }
    });
  }

  render() {
    const {
      totalKms,
      movingDuration,
      idleDuration,
      avgSpeed,
      fleetUtilization,
    } = this.state;

    return (
      <React.Fragment>
        <div className="reportCardHeader">
          <h4>Yesterday</h4>
        </div>
        <div className="reportCardBody">
          <div className="reportCardList">
            <span className="reportCardListLeft"></span>
            <span className="reportCardListMid">Distance (kms)</span>
            <span className="reportCardListRight">{totalKms}</span>
          </div>
          <div className="reportCardList">
            <span className="reportCardListLeft"></span>
            <span className="reportCardListMid">Moving Time</span>
            <span className="reportCardListRight">
              {dateFunction.convSecondsToString(movingDuration)}
            </span>
          </div>
          <div className="reportCardList">
            <span className="reportCardListLeft"></span>
            <span className="reportCardListMid">Idle Time</span>
            <span className="reportCardListRight">
              {dateFunction.convSecondsToString(idleDuration)}
            </span>
          </div>
          <div className="reportCardList">
            <span className="reportCardListLeft"></span>
            <span className="reportCardListMid">Avg. Speed (km/h)</span>
            <span className="reportCardListRight">{avgSpeed}</span>
          </div>
          <div className="reportCardList">
            <span className="reportCardListLeft"></span>
            <span className="reportCardListMid">Fleet Utilization</span>
            <span className="reportCardListRight">{fleetUtilization}</span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default YesterdayInfo;
