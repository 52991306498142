import React, { Component } from "react";
import { connect } from "react-redux";

import TopProgressBar from "../progressbar/TopProgressBar";
import LoadingPopOver from "./LoadingPopOver";

class LoadingDiv extends Component {
  render() {
    const { loading, loadingPopover } = this.props.pageReducer;
    return (
      <React.Fragment>
        <TopProgressBar className={loading ? "showprogress" : "hideprogress"} />
        {(loading || loadingPopover) && (
          <LoadingPopOver>Please wait...</LoadingPopOver>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageReducer: state.pageReducer,
  };
};

export default connect(mapStateToProps)(LoadingDiv);
