import React, { memo } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

import "./progressbar.css";
import { COLORS } from "../../../common/consts";

const TopProgressBar = (props) => {
  return (
    <LinearProgress
      color={COLORS.PROGRESS_BAR_COLOR}
      className={props.className}
    />
  );
};

export default memo(TopProgressBar);
