import React, { Component } from "react";
import { withSnackbar } from "notistack";

import ReportPopupDialog from "./ReportPopupDialog";

import { API_Map } from "../../../apihandler/Map";
import { COMMON_ACTIONS } from "../../../actions/CommonActions";
import { connect } from "react-redux";
import { commonJs } from "../../../common/common";

class VehiclesSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportType: "",
      reportPopupDialog: false,
      vehicles: null,
      records: [],
    };
    this._timer = null;
    this.poiId = null;
    this.idleId = null;
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);

    let self = this;
    this.loadVehicles();
    this._timer = setInterval(() => {
      self.loadVehicles();
    }, 1000 * 60);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
    clearInterval(this._timer);
  }

  loadVehicles = () => {
    const args = {};
    let self = this;
    args.pageNo = 0;
    args.pageLength = 100000;
    args.orderBy = "ASC";
    args.sortColumn = "";
    API_Map.getAllVehicles(args)
      .then((res) => {
        self.props.setVehicles({
          vehicles: res,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  showDetails = (type) => {
    this.setState({
      reportType: type,
      reportPopupDialog: true,
    });
  };

  toggleDialog = (reportType = "", id = null, records = []) => {
    const { reportPopupDialog } = this.state;
    this.setState({
      reportPopupDialog: !reportPopupDialog,
      reportType,
      records,
    });
    if (reportType === "inpoi") {
      this.poiId = id;
      this.idleId = null;
      this.yesterdayId = null;
    } else if (reportType === "idlelist") {
      this.poiId = null;
      this.idleId = id;
      this.yesterdayId = null;
    } else if (reportType === "yesterday") {
      this.poiId = null;
      this.idleId = null;
      this.yesterdayId = id;
    }
  };

  render() {
    const { reportPopupDialog, reportType, records } = this.state;
    const { vehiclesList } = this.props.commonReducer;
    const { zones } = this.props.poiReducer;
    const vehicles = vehiclesList;

    let title = "";
    let totalCount = 0;
    let runningCount = 0;
    let idleCount = 0;
    let stoppedCount = 0;
    let nosignalCount = 0;
    let avgSpeed = 0;
    let totalSpeed = 0;
    if (vehicles) {
      totalCount = vehicles.length;
      for (var i = 0; i < totalCount; i++) {
        switch (vehicles[i].status) {
          case 0:
            nosignalCount++;
            break;
          case 1:
            stoppedCount++;
            break;
          case 2:
            idleCount++;
            break;
          case 3:
            runningCount++;
            totalSpeed += vehicles[i].speed;
            break;
          default:
            break;
        }
      }
    }

    avgSpeed = Math.round(totalSpeed / runningCount);
    if (isNaN(avgSpeed)) {
      avgSpeed = 0;
    }

    switch (reportType) {
      case "all":
        title = "All Vehicles (" + vehicles.length + ")";
        break;
      case "running":
        title = "Running Vehicles (" + runningCount + ")";
        break;
      case "idle":
        title = "Idle Vehicles (" + idleCount + ")";
        break;
      case "nosignal":
        title = "No Signal Vehicles (" + nosignalCount + ")";
        break;
      case "stopped":
        title = "Stopped Vehicles (" + stoppedCount + ")";
        break;
      default:
        break;
    }

    if (reportType === "inpoi") {
      title = "Vehicles In Zones Report";
    } else if (reportType === "idlelist") {
      title = "Idle Vehicles Report";
    } else if (reportType === "yesterday") {
      title = "Yesterday's Performance Report";
    }

    let poiVehiclesCount = 0;
    let poiVehicles = commonJs.getPoiVehicles(vehicles, zones);
    for (let p in poiVehicles) {
      poiVehiclesCount += poiVehicles[p].vehicles.length;
      if (this.poiId) {
        if (this.poiId === p) {
          title = "Vehicles In Zones Report ( " + poiVehicles[p].name + " )";
        }
      }
    }

    if (this.idleId) {
      if (this.idleId === 0) {
        title = "Idle Vehicles Report ( More than 24 Hours )";
      } else if (this.idleId === 1) {
        title = "Idle Vehicles Report ( 16 to 24 Hours )";
      } else if (this.idleId === 2) {
        title = "Idle Vehicles Report ( 6 to 16 Hours )";
      } else if (this.idleId === 3) {
        title = "Idle Vehicles Report ( Upto 6 Hours )";
      }
    }

    if (this.yesterdayId) {
      if (this.yesterdayId === 0) {
        title = "Yesterday's Performance Report ( More than 250 KM )";
      } else if (this.yesterdayId === 1) {
        title = "Yesterday's Performance Report ( 100 to 250 KM )";
      } else if (this.yesterdayId === 2) {
        title = "Yesterday's Performance Report ( 10 to 100 KM )";
      } else if (this.yesterdayId === 3) {
        title = "Yesterday's Performance Report ( Upto 10 KM )";
      }
    }

    return (
      <React.Fragment>
        <div className="reportCardHeader">
          <h4>
            Today <span>Real-time</span>
          </h4>
        </div>
        <div className="reportCardBody">
          <div
            className="reportCardList total cursor"
            onClick={() => this.toggleDialog("all")}
          >
            <span className="reportCardListLeft"></span>
            <span className="reportCardListMid">Total</span>
            <span className="reportCardListRight">{totalCount}</span>
          </div>
          <div
            className="reportCardList  cursor running"
            onClick={() => this.toggleDialog("running")}
          >
            <span className="reportCardListLeft"></span>
            <span className="reportCardListMid">Travelling</span>
            <span className="reportCardListRight">{runningCount}</span>
          </div>
          <div
            className="reportCardList  cursor idle"
            onClick={() => this.toggleDialog("idle")}
          >
            <span className="reportCardListLeft"></span>
            <span className="reportCardListMid">Idle</span>
            <span className="reportCardListRight">{idleCount}</span>
          </div>
          <div
            className="reportCardList  cursor idle"
            onClick={() => this.toggleDialog("stopped")}
          >
            <span className="reportCardListLeft"></span>
            <span className="reportCardListMid">Stopped</span>
            <span className="reportCardListRight">{stoppedCount}</span>
          </div>
          <div
            className="reportCardList  cursor nosignal"
            onClick={() => this.toggleDialog("nosignal")}
          >
            <span className="reportCardListLeft"></span>
            <span className="reportCardListMid">No Signal</span>
            <span className="reportCardListRight">{nosignalCount}</span>
          </div>
          <div
            className="reportCardList  cursor inpoi"
            onClick={() => this.toggleDialog("inpoi")}
          >
            <span className="reportCardListLeft"></span>
            <span className="reportCardListMid">In Zones</span>
            <span className="reportCardListRight">{poiVehiclesCount}</span>
          </div>
          <div className="reportCardList">
            <span className="reportCardListLeft"></span>
            <span className="reportCardListMid">Avg. Speed (km/h)</span>
            <span className="reportCardListRight">{avgSpeed}</span>
          </div>
        </div>
        {reportPopupDialog && (
          <ReportPopupDialog
            reportType={reportType}
            vehicles={vehicles}
            title={title}
            toggleDialog={this.toggleDialog}
            poiVehicles={poiVehicles}
            poiId={this.poiId}
            idleId={this.idleId}
            yesterdayId={this.yesterdayId}
            records={records}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commonReducer: state.commonReducer,
    poiReducer: state.poiReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setVehicles: (values) => {
      dispatch({
        type: COMMON_ACTIONS.SET_VEHICLES,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(VehiclesSummary));
