import React, { Component } from "react";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.flash";
import "pdfmake";

import ReportPopupDialog from "./ReportPopupDialog";

import { API_Reports } from "apihandler/Reports";

class DocExpiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: {
        drivingLicense: [],
        registration: [],
        vehicleInsurance: [],
        vehicleFitness: [],
        vehiclePermit: [],
        roadTax: [],
      },
      reportType: "",
      reportPopupDialog: false,
      category: "",
    };
  }

  componentDidMount() {
    let self = this;
    API_Reports.getDocExpiryList().then((res) => {
      if (res.Result === 200) {
        self.setState({ records: res.Response });
      }
    });
  }

  toggleDialog = (reportType = "", category = "") => {
    const { reportPopupDialog } = this.state;
    this.setState({
      reportPopupDialog: !reportPopupDialog,
      reportType,
      category,
    });
  };

  render() {
    const { reportPopupDialog, reportType, category, records } = this.state;
    const {
      drivingLicense,
      registration,
      vehicleInsurance,
      vehicleFitness,
      vehiclePermit,
      roadTax,
    } = records;
    let title = "Document Expiry Report: ";
    if (category === "drivingLicense") {
      title += "Driver License ( " + drivingLicense.length + " )";
    } else if (category === "registration") {
      title += "Registration ( " + registration.length + " )";
    } else if (category === "vehicleInsurance") {
      title += "Vehicle Insurance ( " + vehicleInsurance.length + " )";
    } else if (category === "vehicleFitness") {
      title += "Vehicle Fitness ( " + vehicleFitness.length + " )";
    } else if (category === "vehiclePermit") {
      title += "Vehicle Permit ( " + vehiclePermit.length + " )";
    } else if (category === "roadTax") {
      title += "Road Tax ( " + roadTax.length + " )";
    }
    return (
      <React.Fragment>
        <div className="reportCardHeader">
          <h4>Expiry List</h4>
        </div>
        <div className="reportCardBody">
          <div
            className="reportCardList cursor"
            onClick={() => this.toggleDialog("docexpiry", "drivingLicense")}
          >
            <span className="reportCardListLeft"></span>
            <span className="reportCardListMid">Driver License</span>
            <span className="reportCardListRight">{drivingLicense.length}</span>
          </div>
          <div
            className="reportCardList cursor"
            onClick={() => this.toggleDialog("docexpiry", "registration")}
          >
            <span className="reportCardListLeft"></span>
            <span className="reportCardListMid">Registration</span>
            <span className="reportCardListRight">{registration.length}</span>
          </div>
          <div
            className="reportCardList cursor"
            onClick={() => this.toggleDialog("docexpiry", "vehicleInsurance")}
          >
            <span className="reportCardListLeft"></span>
            <span className="reportCardListMid">Vehicle Insurance</span>
            <span className="reportCardListRight">
              {vehicleInsurance.length}
            </span>
          </div>
          <div
            className="reportCardList cursor"
            onClick={() => this.toggleDialog("docexpiry", "vehicleFitness")}
          >
            <span className="reportCardListLeft"></span>
            <span className="reportCardListMid">Vehicle Fitness</span>
            <span className="reportCardListRight">{vehicleFitness.length}</span>
          </div>
          <div
            className="reportCardList cursor"
            onClick={() => this.toggleDialog("docexpiry", "vehiclePermit")}
          >
            <span className="reportCardListLeft"></span>
            <span className="reportCardListMid">Vehicle Permit</span>
            <span className="reportCardListRight">{vehiclePermit.length}</span>
          </div>
          <div
            className="reportCardList cursor"
            onClick={() => this.toggleDialog("docexpiry", "roadTax")}
          >
            <span className="reportCardListLeft"></span>
            <span className="reportCardListMid">Road Tax</span>
            <span className="reportCardListRight">{roadTax.length}</span>
          </div>
        </div>
        {reportPopupDialog && (
          <ReportPopupDialog
            reportType={reportType}
            title={title}
            category={category}
            records={records[category] || []}
            toggleDialog={this.toggleDialog}
          />
        )}
      </React.Fragment>
    );
  }
}

export default DocExpiry;
