import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { withSnackbar } from "notistack";

import Btn from "@components/ui/button/Btn";
import { LABELS, NOTIFY, SUCCESS_MSG, ERRORS } from "common/consts";
import { API_Settings } from "apihandler/Settings";
import { commonJs } from "common/common";

import { FORMATS } from "common/consts";

import "./docs.css";
import "../../../vehiclelist/vehiclelist.css";

class VehicleDocs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roadTaxUpto: "",
    };
  }

  handleDateChange = (type, val) => {
    console.log("handleDateChange: ", type, val);
    if (type === "roadTaxUpto") {
      this.setState({
        roadTaxUpto: val,
      });
    }
  };

  saveSettings = () => {
    let self = this;
    const ownerName = document.getElementById("vdocOwnerName").value.trim();
    const inchargeName = document
      .getElementById("vdocInchargeName")
      .value.trim();
    const companyName = document.getElementById("vdocCompany").value.trim();
    const seriesName = document
      .getElementById("vdocVehicleSeries")
      .value.trim();
    const controllerName = document
      .getElementById("vdocVehicleController")
      .value.trim();
    const vehicleLength = document
      .getElementById("vdocVehicleLength")
      .value.trim();
    const ton = document.getElementById("vdocVehicleTon").value.trim();
    const tyre = document.getElementById("vdocVehicleTyre").value.trim();
    const roadTaxUpto = document.getElementById("vdocRoadTaxUpto").value.trim();
    const insuranceUpto = document
      .getElementById("vdocInsuranceUpto")
      .value.trim();
    const permitUpto = document.getElementById("vdocPermitUpto").value.trim();
    const chassisNumber = document
      .getElementById("vdocVehicleChassisNo")
      .value.trim();
    const engineNumber = document.getElementById("vdocEngineNo").value.trim();
    const driverServiceTime = document
      .getElementById("vdocDriverServiceTime")
      .value.trim();
    const driverLicenseNumber = document
      .getElementById("vdocDriverLicenseNo")
      .value.trim();
    const driverLicenseExpiryDate = document
      .getElementById("vdocDriverLicenseExpDate")
      .value.trim();
    const registrationUpto = document
      .getElementById("vdocRegistrationUpto")
      .value.trim();
    const insuranceCompanyName = document
      .getElementById("vdocInsuranceCompanyName")
      .value.trim();
    const insuranceType = document
      .getElementById("vdocInsuranceType")
      .value.trim();
    const policyNumber = document
      .getElementById("vdocPolicyNumber")
      .value.trim();
    const vehicleFitnessUpto = document
      .getElementById("vdocVehicleFitnessUpto")
      .value.trim();
    const permitNumber = document.getElementById("vdocPermitNo").value.trim();
    const goodsPermitDate = document
      .getElementById("vdocGoodsPermitDate")
      .value.trim();
    const goodsTaxDate = document
      .getElementById("vdocGoodsTaxDate")
      .value.trim();
    const minimumCapacity = document
      .getElementById("vdocMinCapacity")
      .value.trim();
    const maximumCapacity = document
      .getElementById("vdocMaxCapacity")
      .value.trim();

    let args = {
      imei: "356173061896648",
      ownerName,
      inchargeName,
      companyName,
      seriesName,
      controllerName,
      vehicleLength,
      ton,
      tyre,
      chassisNumber,
      engineNumber,
      driverServiceTime,
      driverLicenseNumber,
      driverLicenseExpiryDate,
      // tyre: "MRF",
      insuranceCompanyName,
      insuranceType,
      policyNumber,
      permitNumber,
      minimumCapacity,
      maximumCapacity,
      goodsPermitDate,
      goodsTaxDate,
      roadTaxUpto,
      insuranceUpto,
      permitUpto,
      registrationUpto,
      vehicleFitnessUpto,
    };
    API_Settings.editVehicleDocumentsDetails(args)
      .then((res) => {
        // self.props.setLoading({
        //   loadingPopover: false,
        // });
        if (res.Result === 200) {
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            SUCCESS_MSG.RECORD_SAVED,
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
          self.resetForm();
        } else {
          commonJs.showNotify(
            self.props,
            NOTIFY.ERROR,
            ERRORS.RECORD_NOT_SAVED,
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
        }
      })
      .catch((err) => {
        // self.props.setLoading({
        //   loadingPopover: false,
        // });
        console.log(err);
      });
  };

  render() {
    const { roadTaxUpto } = this.state;
    const { closePopup } = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <TextField
              id="vdocOwnerName"
              label="Owner Name"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col">
            <TextField
              id="vdocInchargeName"
              label="Incharge Name"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col">
            <TextField
              id="vdocCompany"
              label="Vehicle Company"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <TextField
              id="vdocVehicleSeries"
              label="Vehicle Series Name"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col">
            <TextField
              id="vdocVehicleController"
              label="Controller"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col">
            <TextField
              id="vdocVehicleLength"
              label="Vehicle Length"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <TextField
              id="vdocVehicleTon"
              label="Ton"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col">
            <TextField
              id="vdocVehicleTyre"
              label="Tyre"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col singleDatePickerWrapper">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {roadTaxUpto !== "" && (
                <lable className="label">Road Tax Upto</lable>
              )}
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format={FORMATS.DATE_PICKER_FORMAT}
                margin="normal"
                id="vdocRoadTaxUpto"
                value={roadTaxUpto}
                placeholder="Road Tax Upto"
                onChange={(date) => this.handleDateChange("roadTaxUpto", date)}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>

        <div className="row">
          <div className="col singleDatePickerWrapper">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {roadTaxUpto !== "" && (
                <lable className="label">Insurance Upto</lable>
              )}
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format={FORMATS.DATE_PICKER_FORMAT}
                margin="normal"
                id="vdocInsuranceUpto"
                value={roadTaxUpto}
                placeholder="Insurance Upto"
                onChange={(date) => this.handleDateChange("roadTaxUpto", date)}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="col singleDatePickerWrapper">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {roadTaxUpto !== "" && (
                <lable className="label">Permit Upto</lable>
              )}
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format={FORMATS.DATE_PICKER_FORMAT}
                margin="normal"
                id="vdocPermitUpto"
                value={roadTaxUpto}
                placeholder="Permit Upto"
                onChange={(date) => this.handleDateChange("roadTaxUpto", date)}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="col">
            <TextField
              id="vdocVehicleChassisNo"
              label="Chassis No."
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <TextField
              id="vdocEngineNo"
              label="Engine No."
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col">
            <TextField
              id="vdocDriverServiceTime"
              label="Driver Service Time"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col">
            <TextField
              id="vdocDriverLicenseNo"
              label="Driver License Number"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
        </div>

        <div className="row">
          <div className="col singleDatePickerWrapper">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {roadTaxUpto !== "" && (
                <lable className="label">Driver License Expiry Date</lable>
              )}
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format={FORMATS.DATE_PICKER_FORMAT}
                margin="normal"
                id="vdocDriverLicenseExpDate"
                value={roadTaxUpto}
                placeholder="Driver License Expiry Date"
                onChange={(date) => this.handleDateChange("roadTaxUpto", date)}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="col singleDatePickerWrapper">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {roadTaxUpto !== "" && (
                <lable className="label">Registration Upto</lable>
              )}
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format={FORMATS.DATE_PICKER_FORMAT}
                margin="normal"
                id="vdocRegistrationUpto"
                value={roadTaxUpto}
                placeholder="Registration Upto"
                onChange={(date) => this.handleDateChange("roadTaxUpto", date)}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="col">
            <TextField
              id="vdocInsuranceCompanyName"
              label="Insurance Company Name"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <TextField
              id="vdocInsuranceType"
              label="Insurance Type"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col">
            <TextField
              id="vdocPolicyNumber"
              label="Policy Number"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col singleDatePickerWrapper">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {roadTaxUpto !== "" && (
                <lable className="label">Vehicle Fitness Upto</lable>
              )}
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format={FORMATS.DATE_PICKER_FORMAT}
                margin="normal"
                id="vdocVehicleFitnessUpto"
                value={roadTaxUpto}
                placeholder="Vehicle Fitness Upto"
                onChange={(date) => this.handleDateChange("roadTaxUpto", date)}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <TextField
              id="vdocPermitNo"
              label="Permit No."
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col singleDatePickerWrapper">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {roadTaxUpto !== "" && (
                <lable className="label">5 Years Basic Goods Permit Date</lable>
              )}
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format={FORMATS.DATE_PICKER_FORMAT}
                margin="normal"
                id="vdocGoodsPermitDate"
                value={roadTaxUpto}
                placeholder="5 Years Basic Goods Permit Date"
                onChange={(date) => this.handleDateChange("roadTaxUpto", date)}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="col singleDatePickerWrapper">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {roadTaxUpto !== "" && (
                <lable className="label">Goods Tax Date</lable>
              )}
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format={FORMATS.DATE_PICKER_FORMAT}
                margin="normal"
                id="vdocGoodsTaxDate"
                value={roadTaxUpto}
                placeholder="Goods Tax Date"
                onChange={(date) => this.handleDateChange("roadTaxUpto", date)}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <TextField
              id="vdocMinCapacity"
              label="Minimum Capacity"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col">
            <TextField
              id="vdocMaxCapacity"
              label="Maximum Capacity"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col"></div>
        </div>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              width: "30%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Btn className="settingsBtn saveBtn" onClick={this.saveSettings}>
              {"save"}
            </Btn>
            <Btn
              className="settingsBtn closeBtn"
              onClick={() => closePopup(false)}
            >
              {"close"}
            </Btn>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(VehicleDocs);
