import { SdkClient } from "fgxsdk";
import { CONFIG } from "common/consts";

SdkClient.setUrl(CONFIG.BASE_URL);

export const API_Reports = {
  getProximityReport: async (args) => {
    args = {
      imei: "75655565756",
      zone: "6547",
      range: "10", // > 10 Kms
    };
    return {
      Result: 200,
      Response: {
        cols: [
          "Sl No.",
          "Reg No.",
          "Vehicle Type",
          "Last Update",
          "Location",
          "Kms",
        ],
        rows: [
          [
            "1",
            "08093056",
            "20ft",
            "2020-01-11 12:06:22",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
            "10.50",
          ],
          [
            "2",
            "08093056",
            "22ft",
            "2020-01-11 12:06:22",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
            "0.00",
          ],
        ],
        hasNext: true,
      },
    };
  },
  // Power Unplug Report: Shows the power unplug data
  getPowerUnplugReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      imei: "73383121230",
      fromDate: "2020-06-01 00:00:00",
      toDate: "2020-06-30 23:59:59",
      platform: "web", // Or it will be "app"
    };
    return {
      Result: 200,
      Response: {
        cols: [
          "Sl No.",
          "Vehicle No.",
          "Time",
          "Speed (Kmph)",
          "Power Supply",
          "Location",
        ],
        rows: [
          [
            "1",
            "KA02AF-3433-TEMP",
            "02-06-2020 12:43:43",
            "0",
            "OFF",
            '<a href="https://www.google.com/maps?q=13.063375,77.456312" target="_blank">Adakamaranahalli, Madanayakanahalli, Madhavara, Bangalore Urban, Karnataka, 560073, India</a>',
          ],
        ],
        hasNext: false,
      },
    };
  },
  // Underspeed Report
  getUnderspeedReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      fromDate: "2020-08-01 00:00:00",
      toDate: "2020-08-05 00:00:00",
      imei: "358480080005301",
      speedLimit: 10, // Speed limit
      platform: "web", // Or it will be "app"
    };
    return {
      Result: 200,
      Response: {
        cols: ["Sl No.", "Time", "Speed", "City", "State", "Location"],
        rows: [
          [
            "1",
            "2020-08-02 02:06:57",
            "4.0",
            "Bangalore",
            "Karnataka",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "2",
            "2020-08-02 02:07:07",
            "4.0",
            "Bangalore",
            "Karnataka",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
        ],
        hasNext: true,
      },
    };
  },
  //Temperature by Hour Report:
  getTemperatureByHourReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      imei: "73383121230",
      fromDate: "2020-07-12 00:00:00",
      toDate: "2020-07-12 23:59:59",
      platform: "web", // Or it will be "app"
    };
    return {
      Result: 200,
      Response: {
        cols: [
          "Date",
          "00",
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
        ],
        rows: [
          [
            "02-08-2020",
            "31.3",
            "30.6",
            "30.5",
            "30.3",
            "30",
            "29.8",
            "29.5",
            "29.3",
            "29.3",
            "29.7",
            "30.5",
            "31.4",
            "32.5",
            "33.6",
            "35",
            "36",
            "37",
            "37.6",
            "37.9",
            "37.5",
            "37",
            "36.6",
            "36",
            "35.5",
          ],
        ],
        hasNext: true,
      },
    };
  },
  //Temperature Exception Report:
  getTemperatureExceptionReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      imei: "73383121230",
      fromDate: "2020-07-12 00:00:00",
      toDate: "2020-07-12 23:59:59",
      platform: "web", // Or it will be "app"
    };
    return {
      Result: 200,
      Response: {
        cols: [
          "Sl No.",
          "Vehicle No.",
          "Start",
          "End",
          "AC On Duration",
          "AC On Movement (Kms)",
          "Start Location",
          "End Location",
          "Status",
        ],
        rows: [
          [
            "1",
            "HR55P6900",
            "02-08-2020 12:45:22 (31.3°C)",
            "02-08-2020 01:32:42 (30.8°C)",
            "47min 20s",
            "15.6",
            '<a href="https://www.google.com/maps?q=13.063375,77.456312" target="_blank">Adakamaranahalli, Madanayakanahalli, Madhavara, Bangalore Urban, Karnataka, 560073, India</a>',
            '<a href="https://www.google.com/maps?q=13.063375,77.456312" target="_blank">Adakamaranahalli, Madanayakanahalli, Madhavara, Bangalore Urban, Karnataka, 560073, India</a>',
            "",
          ],
          [
            "2",
            "HR55P6900",
            "02-08-2020 12:45:22 (31.3°C)",
            "02-08-2020 01:32:42 (30.8°C)",
            "47min 20s",
            "15.6",
            '<a href="https://www.google.com/maps?q=13.063375,77.456312" target="_blank">Adakamaranahalli, Madanayakanahalli, Madhavara, Bangalore Urban, Karnataka, 560073, India</a>',
            '<a href="https://www.google.com/maps?q=13.063375,77.456312" target="_blank">Adakamaranahalli, Madanayakanahalli, Madhavara, Bangalore Urban, Karnataka, 560073, India</a>',
            "",
          ],
        ],
        hasNext: true,
      },
    };
  },
  //ETA Report:
  getEtaReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      fromDate: "2020-07-12 00:00:00",
      toDate: "2020-07-12 23:59:59",
      platform: "web", // Or it will be "app"
    };
    return {
      Result: 200,
      Response: {
        cols: [
          "Date",
          "Shift",
          "Route Code",
          "Planned Vehicle",
          "Actual Vehicle",
          "Date & Time Out",
          "Date & Time In",
          "Planned Driver ID",
          "Actual Driver ID",
        ],
        rows: [
          [
            "02-08-2020",
            "",
            "",
            "",
            "",
            "02-08-2020 01:32:42",
            "04-08-2020 01:32:42",
            "",
            "",
          ],
          [
            "05-08-2020",
            "",
            "",
            "",
            "",
            "02-08-2020 01:32:42",
            "04-08-2020 01:32:42",
            "",
            "",
          ],
        ],
        hasNext: true,
      },
    };
  },
  // Load Sensor Report
  getLoadSensorReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      imei: "73383121230",
      fromDate: "2020-08-01 00:00:00",
      toDate: "2020-08-31 23:59:59",
      stopTime: 15,
    };
    return {
      Result: 200,
      Response: {
        cols: ["Sl No.", "Date", "Load Value", "Speed", "Location"],
        rows: [
          [
            "1",
            "2020-07-12 00:00:00",
            "15.5",
            "45",
            '<a href="https://www.google.com/maps?q=13.063375,77.456312" target="_blank">Adakamaranahalli, Madanayakanahalli, Madhavara, Bangalore Urban, Karnataka, 560073, India</a>',
          ],
          [
            "2",
            "2020-07-12 00:15:00",
            "14.6",
            "20",
            '<a href="https://www.google.com/maps?q=13.063375,77.456312" target="_blank">Adakamaranahalli, Madanayakanahalli, Madhavara, Bangalore Urban, Karnataka, 560073, India</a>',
          ],
        ],
        hasNext: true,
      },
    };
  },

  getTemperatureColdStorageGraphReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      imei: "73383121230",
      fromDate: "2020-08-01 00:00:00",
      toDate: "2020-08-31 23:59:59",
      platform: "web", // Or it will be "app"
    };
    return {
      Result: 200,
      Response: {
        cols: ["Sl No.", "Date", "Temperature (in °C)", "io72", "Ignition"],
        rows: [
          ["1", "2021-02-12 00:00:00", "15.5", "", ""],
          ["2", "2020-05-12 00:15:00", "10", "", ""],
          ["3", "2019-07-12 00:00:00", "5", "", ""],
          ["4", "2018-10-12 00:15:00", "7", "", ""],
          ["5", "2017-10-12 00:15:00", "1", "", ""],
        ],
        hasNext: true,
      },
    };
  },
};
