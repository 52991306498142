import React, { Component } from "react";
import { IconButton } from "@material-ui/core";
import { MoreVertRounded as Menu } from "@material-ui/icons";
import { connect } from "react-redux";

import Img from "components/ui/image/Img";
import TrackMenu from "../../tracktab/trackmenu/TrackMenu";

import { DEFAULTS, PRIVILEGES } from "common/consts";
import { commonJs } from "common/common";

import gprs from "images/leftBarImages/wifi.svg";
import gps from "images/leftBarImages/gps.svg";
import gprsoff from "images/leftBarImages/wifi_off.svg";
import gpsoff from "images/leftBarImages/gps_off.svg";
import tempIcon from "images/leftBarImages/temp.svg";

class SimpleVehicleRow extends Component {
  showVehicleMenu = (imei) => {
    let prevEl = document.querySelectorAll(".menulist.active");
    if (prevEl.length > 0) {
      if (prevEl[0].id !== "voptions-" + imei) {
        prevEl[0].classList.remove("active");
      }
    }
    document.getElementById("voptions-" + imei).classList.toggle("active");
  };

  selectVehicle = (imei) => {
    let selectedRow = localStorage.selectedRow;
    if (selectedRow !== undefined && selectedRow !== "") {
      let targetDiv = document.getElementById("vehiclerow-" + selectedRow);
      if (targetDiv !== null) {
        targetDiv.classList.remove("selected");
      }
    }
    document.getElementById("vehiclerow-" + imei).classList.add("selected");
    localStorage.selectedRow = imei;
    const { latitude, longitude } = this.props.vehicle;
    this.props.updateMapRegion(latitude, longitude, DEFAULTS.VEHICLE_MAX_ZOOM);
    commonJs.clickMarkerIcon(imei);
  };

  render() {
    const {
      slno,
      imei,
      vehicle,
      closeTrackMenu,
      onLoad,
      divstyle,
      switchTab,
      shareVehicle,
      loginReducer,
    } = this.props;
    const { privileges, role } = loginReducer;

    const { vstatus, vstatusText } = commonJs.getVehicleStatus(vehicle);

    const vsensors = commonJs.getVehicleSensors(vehicle.sensors);

    const hideTemp = commonJs.hasPrivilege(
      PRIVILEGES.HIDE_TEMP,
      role,
      privileges
    );

    return (
      <div
        id={"vehiclerow-" + imei}
        data-vehicle={imei}
        className={"vehiclerow minimized"}
        style={divstyle}
        onLoad={onLoad}
      >
        <div className="vehicledata" onClick={() => this.selectVehicle(imei)}>
          <ul>
            <li>
              <span className="vehiclename">{slno + ". " + vehicle.name}</span>
              <span className={"vehiclestatus " + vstatus}>{vstatusText}</span>
            </li>

            {/* GPRS */}
            <li title={vehicle.gprs ? "GPRS: ON" : "GPRS: OFF"}>
              <Img src={vehicle.gprs ? gprs : gprsoff} className="smallicon" />
            </li>

            {/* GPS */}
            <li title={vehicle.gps ? "GPS: ON" : "GPS: OFF"}>
              <Img src={vehicle.gps ? gps : gpsoff} className="smallicon" />
            </li>

            {/* Load All Sensors Values */}
            {vsensors.map((sensor, indx) => {
              if (sensor.type === "temp" && hideTemp === false) {
                return (
                  <li
                    key={"vehiclesensor-" + indx}
                    title={sensor.name + ": " + sensor.value}
                  >
                    <Img src={tempIcon} className="smallicon" />
                    <span className="text">{sensor.value}</span>
                  </li>
                );
              } else {
                return null;
              }
            })}
          </ul>
        </div>
        <div className="vehiclemenu">
          <IconButton onClick={() => this.showVehicleMenu(imei)} title="Menu">
            <Menu />
          </IconButton>
          <TrackMenu
            imei={imei}
            closeTrackMenu={closeTrackMenu}
            switchTab={switchTab}
            shareVehicle={shareVehicle}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SimpleVehicleRow);
