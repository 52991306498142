import { TRACK_ANIMATION_ACTIONS } from "../actions/TrackAnimationActions";
import { DEFAULTS } from "../common/consts";

let initialState = {
  trackAnimation: false,
  skipStops: false,
  trackIndex: 0,
  trackSpeed: DEFAULTS.TRACK_SPEED,
};

const trackAnimationReducer = (state = initialState, action) => {
  const { type, values } = action;
  switch (type) {
    case TRACK_ANIMATION_ACTIONS.TOGGLE_ANIMATION:
      return {
        ...state,
        trackAnimation: values.trackAnimation,
      };
    case TRACK_ANIMATION_ACTIONS.SET_TRACK_INDEX:
      return {
        ...state,
        trackIndex: values.trackIndex,
      };
    case TRACK_ANIMATION_ACTIONS.SET_TRACK_SPEED:
      return {
        ...state,
        trackSpeed: values.trackSpeed,
      };
    case TRACK_ANIMATION_ACTIONS.TOGGLE_SKIP_STOPS:
      return {
        ...state,
        skipStops: values.skipStops,
        trackIndex: values.trackIndex,
      };
    default:
      break;
  }
  return state;
};

export default trackAnimationReducer;
