import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { Visibility, Edit, Delete } from "@material-ui/icons";
import { connect } from "react-redux";

// React Grid
import { SortingState, IntegratedSorting } from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

import { API_Map } from "apihandler/Map";
import { POI_ACTIONS } from "actions/PoiActions";
import { ROLES } from "common/consts";

const getRowId = (row) => row.id;

var timer = null;

class RoutesGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      direction: "asc",
      routes: {
        rows: [],
        filteredRows: [],
        columns: [],
      },
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.loadRoutes();
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  routeSearch = (searchValue) => {
    const { routes } = this.state;
    const { rows, columns } = routes;
    clearTimeout(timer);
    var self = this;
    timer = setTimeout(() => {
      console.log("onrouteSearch: ", searchValue);
      let qry = searchValue.toUpperCase();
      var list = rows.filter((row) => {
        return row.name.toUpperCase().indexOf(qry) > -1;
      });
      self.setState({
        routes: { rows: rows, filteredRows: list, columns: columns },
      });
    }, 500);
  };

  getPointOnFilter = (pointId) => {
    const { routes } = this.state;
    const { rows } = routes;
    return rows.filter((r) => r.id === pointId);
  };

  zoneVisibilityToggle = (id) => {
    console.log("zoneVisibilityToggle: ", id);
  };

  generateZoneVisibleIcon = (id) => {
    return (
      <Tooltip title="View" placement="bottom">
        <IconButton
          onClick={() => this.zoneVisibilityToggle(id)}
          className="smallIcon"
        >
          <Visibility />
        </IconButton>
      </Tooltip>
    );
  };

  generateOptions = (route) => {
    const { id, name } = route;
    const { role } = this.props.loginReducer;
    return (
      <div className="options">
        {role !== ROLES.SUB_USER && (
          <Tooltip
            title="Edit"
            placement="bottom"
            onClick={() => this.props.toggleAddRouteDialog(route)}
          >
            <IconButton className="smallIcon">
              <Edit />
            </IconButton>
          </Tooltip>
        )}
        {role !== ROLES.SUB_USER && (
          <Tooltip
            title="Delete"
            placement="bottom"
            onClick={() => this.props.deleteRoute(id, name)}
          >
            <IconButton className="smallIcon">
              <Delete />
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  };

  reloadRoutes = () => {
    this.setState({
      routes: {
        loading: false,
        sorting: "asc",
        columns: [],
        rows: [],
        filteredRows: [],
      },
    });
    this.loadRoutes();
  };

  loadRoutes = () => {
    var self = this;
    API_Map.getAllRoutes().then((res) => {
      if (res.Result === 200) {
        let cols = [
          { name: "view", title: "Visible" },
          { name: "name", title: "Name" },
          { name: "options", title: "Options" },
        ];
        let rows = [];
        let qry = document
          .querySelector("input#routeSearch")
          .value.toUpperCase();
        let filteredRows = [];
        let fRows = res.Response.filter((row) => {
          return row.name.toUpperCase().indexOf(qry) > -1;
        });
        for (let i in fRows) {
          filteredRows.push({
            id: fRows[i].id,
            view: this.generateZoneVisibleIcon(fRows[i]),
            slno: Number(i) + 1,
            name: fRows[i].name,
            options: this.generateOptions(fRows[i]),
          });
        }

        res = res.Response;
        for (let i in res) {
          rows.push({
            id: res[i].id,
            view: this.generateZoneVisibleIcon(res[i].id),
            slno: Number(i) + 1,
            name: res[i].name,
            options: this.generateOptions(res[i]),
          });
        }
        self.setState({
          routes: {
            loading: false,
            sorting: "asc",
            columns: cols,
            rows: rows,
            filteredRows: filteredRows,
          },
        });

        // For Reducer
        self.props.setRoutes({
          routes: res,
        });
      }
    });
  };

  onRowClick = () => {
    console.log("onRowClick");
  };

  render() {
    const { rows, columns, filteredRows } = this.state.routes;

    const tableColumnExtensions = [
      { columnName: "view", width: 80, align: "center" },
      { columnName: "name", width: 200, align: "left" },
      { columnName: "options", width: 80, align: "center" },
    ];

    let searchOn = false;
    if (document.getElementById("routeSearch") != null) {
      searchOn =
        document.getElementById("routeSearch").value.trim() === ""
          ? false
          : true;
    }

    return columns.length > 0 ? (
      <div id="routesGrid" className="dataRGrid">
        <Grid
          rows={searchOn ? filteredRows : rows}
          columns={columns}
          getRowId={getRowId}
        >
          <SortingState
            defaultSorting={[{ columnName: "name", direction: "asc" }]}
            columnSortingEnabled={true}
            columnExtensions={[
              { columnName: "view", sortingEnabled: false },
              { columnName: "options", sortingEnabled: false },
            ]}
          />
          <IntegratedSorting />
          <VirtualTable
            columnExtensions={tableColumnExtensions}
            height={window.innerHeight - 180}
          />
          <TableHeaderRow showSortingControls />
        </Grid>
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: 100,
          alignItems: "center",
        }}
      >
        <p style={{ color: "#a1a1a1", fontSize: 14 }}>Loading...</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRoutes: (values) => {
      dispatch({
        type: POI_ACTIONS.SET_ROUTES,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoutesGrid);
