import { PAGE_ACTIONS } from "../actions/PageActions";
import { LOCAL_STORAGE } from "../common/consts";
import { commonJs } from "../common/common";

let initialState = {
  loading: true,
  loadingPopover: false,
  fullScreen: false,
  leftbar: true,
  leftMapMenu: true,
  leftReportsMenu: false,
  settingsMenu: false,
  selectedVehicle: "",
  vehicleEditPopup: false,
  trackBottomBar: false,
  latLng: {},
  liveVehiclePopup: false,
  etaPopup: false,
};

// Get Page Cache
var pageCache = commonJs.getItemFromLocal(LOCAL_STORAGE.PAGE_SETTINGS);
if (pageCache !== null) {
  if (pageCache.leftbar !== undefined) {
    initialState.leftbar = pageCache.leftbar;
  }
  if (pageCache.leftMapMenu !== undefined) {
    initialState.leftMapMenu = pageCache.leftMapMenu;
  }
  if (pageCache.leftReportsMenu !== undefined) {
    initialState.leftReportsMenu = pageCache.leftReportsMenu;
  }
}

const pageReducer = (state = initialState, action) => {
  const { type, values } = action;
  let pageLocalStorage = commonJs.getItemFromLocal(LOCAL_STORAGE.PAGE_SETTINGS);
  switch (type) {
    case PAGE_ACTIONS.TOGGLE_FULLSCREEN:
      return { ...state, fullScreen: values.fullScreen };

    case PAGE_ACTIONS.TOGGLE_TRACK_BOTTOM_BAR:
      return {
        ...state,
        trackBottomBar: values.trackBottomBar,
      };

    case PAGE_ACTIONS.TOGGLE_LEFTBAR:
      pageLocalStorage.leftbar = values.leftbar;
      commonJs.setItemToLocal(LOCAL_STORAGE.PAGE_SETTINGS, pageLocalStorage);
      return { ...state, leftbar: values.leftbar };

    case PAGE_ACTIONS.TOGGLE_MAP_MENU:
      pageLocalStorage.leftMapMenu = values.leftMapMenu;
      pageLocalStorage.leftReportsMenu = values.leftReportsMenu;
      commonJs.setItemToLocal(LOCAL_STORAGE.PAGE_SETTINGS, pageLocalStorage);
      return {
        ...state,
        leftMapMenu: values.leftMapMenu,
        leftReportsMenu: values.leftReportsMenu,
      };

    case PAGE_ACTIONS.TOGGLE_REPORTS_MENU:
      pageLocalStorage.leftReportsMenu = values.leftReportsMenu;
      commonJs.setItemToLocal(LOCAL_STORAGE.PAGE_SETTINGS, pageLocalStorage);
      return { ...state, leftReportsMenu: values.leftReportsMenu };

    case PAGE_ACTIONS.TOGGLE_SETTINGS_MENU:
      return { ...state, settingsMenu: values.settingsMenu };

    case PAGE_ACTIONS.TOGGLE_EDIT_VEHICLE_MENU:
      return {
        ...state,
        vehicleEditPopup: values.vehicleEditPopup,
        selectedVehicle: values.selectedVehicle,
      };

    case PAGE_ACTIONS.SET_MAP_LOADING:
      return { ...state, loading: values.loading };

    case PAGE_ACTIONS.SET_POPOVER_LOADING:
      return { ...state, loadingPopover: values.loadingPopover };

    case PAGE_ACTIONS.SET_LAT_LNG:
      return { ...state, latLng: values.latLng };

    case PAGE_ACTIONS.TOGGLE_LIVE_VEHICLE_POPUP:
      return { ...state, liveVehiclePopup: values.liveVehiclePopup };

    case PAGE_ACTIONS.ETA_POPUP:
      return { ...state, etaPopup: values.etaPopup };

    default:
      break;
  }
  return state;
};

export default pageReducer;
