import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { Link, Breadcrumbs, Tabs, Tab } from "@material-ui/core";

import Companies from "./companies/Companies";
import Privileges from "./privileges/Privileges";
import Roles from "./roles/Roles";
import Packages from "./packages/Packages";

import { LABELS, CPANEL_MENUS, CONFIG } from "common/consts";
import { COMMON_ACTIONS } from "actions/CommonActions";
import { REPORT_ACTIONS } from "actions/ReportActions";
import { API_Map } from "apihandler/Map";
import { API_UserLogin } from "apihandler/UserLogin";

class CPanelMasters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicles: [],
      activeTab: Number(localStorage.masterActiveTab) || 0,
    };
  }

  onMenuClick = (menu) => {
    if (menu === "home") {
      window.open("/cpanel", "_self");
    }
  };

  componentDidMount() {
    document.getElementById("root").classList.add("scroll");
    document.getElementById("root").classList.remove("login");
    this.getDashboardData();
  }

  getDashboardData = () => {
    const args = {};
    let self = this;
    args.pageNo = 0;
    args.pageLength = 100000;
    args.orderBy = "ASC";
    args.sortColumn = "";
    API_UserLogin.setToken(localStorage.token);
    API_Map.getAllVehicles(args)
      .then((res) => {
        self.props.setVehicles({
          vehicles: res,
        });
        self.props.setLoading({
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChangeTab = (event, value) => {
    this.setState({ activeTab: value });
    localStorage.masterActiveTab = value;
  };

  render() {
    const { role } = this.props.loginReducer;
    const { activeTab } = this.state;

    return (
      <div className="cpanelPageWrapper">
        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
          <Link
            color="inherit"
            href="/cpanel"
            onClick={() => this.onMenuClick("home")}
          >
            CPanel
          </Link>
          <p>{CPANEL_MENUS.MASTERS}</p>
        </Breadcrumbs>
        <div id="cpanelMastersWrapper">
          <Tabs
            value={activeTab}
            classes={{
              root: "mapTabs",
              indicator: "mapTabIndicator",
            }}
            scrollButtons="off"
            centered={false}
            onChange={this.handleChangeTab}
          >
            <Tab
              label={LABELS.COMPANIES}
              classes={{
                root: "maptab",
              }}
              className={activeTab === 0 ? "activeTab" : ""}
              key={"mastertab-" + 0}
              disabled={false}
            />
            {role === CONFIG.ROLE_SUPERADMIN && (
              <Tab
                label={LABELS.PRIVILEGES}
                classes={{
                  root: "maptab",
                }}
                className={activeTab === 1 ? "activeTab" : ""}
                key={"mastertab-" + 1}
                disabled={false}
              />
            )}
            {role === CONFIG.ROLE_SUPERADMIN && (
              <Tab
                label={LABELS.ROLES}
                classes={{
                  root: "maptab",
                }}
                className={activeTab === 2 ? "activeTab" : ""}
                key={"mastertab-" + 2}
                disabled={false}
              />
            )}

            {/* Package Management */}
            <Tab
              label={LABELS.PACKAGES}
              classes={{
                root: "maptab",
              }}
              className={activeTab === 3 ? "activeTab" : ""}
              key={"mastertab-" + 3}
              disabled={false}
            />
          </Tabs>

          {activeTab === 0 && <Companies />}
          {activeTab === 1 && <Privileges />}
          {activeTab === 2 && <Roles />}
          {activeTab === 3 && <Packages />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commonReducer: state.commonReducer,
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setVehicles: (values) => {
      dispatch({
        type: COMMON_ACTIONS.SET_VEHICLES,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: REPORT_ACTIONS.SET_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(CPanelMasters));
