import React, { Component } from "react";
import {
  TextField,
  FormControlLabel,
  Switch,
  IconButton,
} from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

import FleetDialog from "components/ui/dialogbox/FleetDialog";
import FleetDropDown from "components/ui/dropdown/FleetDropDown";
import FleetGrid from "components/ui/grid/FleetGrid";
import { LABELS, NOTIFY, SUCCESS_MSG, ERRORS } from "common/consts";
import { COMMON_ACTIONS } from "actions/CommonActions";
import { REPORT_ACTIONS } from "actions/ReportActions";
import { API_Settings } from "apihandler/Settings";
import { commonJs } from "common/common";

import { DEFAULTS } from "common/consts";
import { PAGE_ACTIONS } from "actions/PageActions";

class SensorPopup extends Component {
  constructor(props) {
    super(props);
    const { sensor } = this.props;
    let selectedType = "";
    if (sensor.sensorType) {
      const type = DEFAULTS.DEFAULT_SENSOR_TYPES.filter((t) => {
        return t.id === sensor.sensorType;
      });
      if (type.length > 0) {
        selectedType = type[0].id;
      }
    }
    this.state = {
      name: sensor.name || "",
      showInPopup: sensor.popup || false,
      selectedType: selectedType,
      param: "",
      selectedParamType: sensor.param || "",
      unitOfMeasurement: sensor.units || "",
      formula: sensor.formula || "",
      lowestValue: sensor.lv || "",
      highestValue: sensor.hv || "",
      fuelTankCapacity: sensor.fuelcapacity || "",
      voltage: sensor.fuelvoltage || "",
      calibration: sensor.calibration || [],
      calibrationValue: sensor.calibration_value || "",
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  saveSettings = () => {
    const self = this;
    const { voltage, calibration } = this.state;
    const { sensor, imei } = this.props;
    const sensorName = document.getElementById("sensorName").value.trim();
    const sensorType = document.getElementById("sensorType").value;
    const sensorParamType = document.getElementById("sensorParamType").value;
    const unitsOfMeasurement = document.getElementById("unitsOfMeasurement")
      .value;
    const formula = document.getElementById("formula").value;
    const highestValue = document.getElementById("highestValue").value;
    const lowestValue = document.getElementById("lowestValue").value;
    const fuelTankCapacity = document.getElementById("fuelTankCapacity").value;
    const showInPopup = document.getElementById("showInPopup").checked;
    const calibrationValue = document.getElementById("calibrationValue").value;
    // validations
    if (sensorName === "") {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Please enter sensor name..!",
        <button className="snackbar-btn">{LABELS.DISMISS}</button>
      );
      return;
    }
    if (sensorType === "") {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Please enter sensor type..!",
        <button className="snackbar-btn">{LABELS.DISMISS}</button>
      );
      return;
    }
    if (sensorParamType === "") {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Please enter parameter..!",
        <button className="snackbar-btn">{LABELS.DISMISS}</button>
      );
      return;
    }
    const args = {
      imei,
      sensorId: sensor.id || "",
      sensorName,
      sensorType: sensorType,
      parameter: sensorParamType,
      unit: unitsOfMeasurement,
      formula,
      highValue: highestValue,
      lowValue: lowestValue,
      fullTankCapacity: fuelTankCapacity,
      voltage,
      showInPopup: showInPopup ? "true" : "false",
      calibration,
      calibrationValue,
    };
    console.log("saveSettings: ", args);
    self.props.setLoading({
      loadingPopover: true,
    });
    API_Settings.manageSensorInfo(args)
      .then((res) => {
        self.props.setLoading({
          loadingPopover: false,
        });
        if (res.Result === 200) {
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            SUCCESS_MSG.RECORD_SAVED,
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
          self.props.toggleSensorPopup();
          self.props.reloadGrid();
          self.props.loadVehicles();
        } else {
          commonJs.showNotify(
            self.props,
            NOTIFY.ERROR,
            ERRORS.RECORD_NOT_SAVED,
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
        }
      })
      .catch((err) => {
        self.props.setLoading({
          loadingPopover: false,
        });
        console.log(err);
      });
  };

  addToCalibration = () => {
    const sensorX = document.getElementById("sensorX").value.trim();
    const sensorY = document.getElementById("sensorY").value.trim();
    let calibs = this.state.calibration;
    if (sensorX === "") {
      return false;
    }
    if (sensorY === "") {
      return false;
    }
    calibs.push({ id: calibs.length + 1, x: sensorX, y: sensorY });
    this.setState({
      calibration: calibs,
    });
    document.getElementById("sensorX").value = "";
    document.getElementById("sensorY").value = "";
  };

  removeFromCalibration = () => {
    let calibs = this.state.calibration;
    if (calibs.length > 0) {
      calibs.pop();
      this.setState({
        calibration: calibs,
      });
    }
  };

  render() {
    const {
      name,
      showInPopup,
      selectedType,
      selectedParamType,
      unitOfMeasurement,
      formula,
      lowestValue,
      highestValue,
      fuelTankCapacity,
      voltage,
      calibration,
      calibrationValue,
    } = this.state;
    const { sensorPopup, toggleSensorPopup, sensor, sensorParams } = this.props;

    console.log("sensorPopup: Render ", sensor, sensorParams);

    return (
      sensorPopup && (
        <FleetDialog
          id="sensorDialog"
          open={sensorPopup}
          onCloseSettings={toggleSensorPopup}
          title={sensor.name ? "Edit Sensor" : "Add Sensor"}
          maxWidth="sm"
          fullWidth={true}
          saveButton={true}
          cancelButton={true}
          cancelAction={toggleSensorPopup}
          saveAction={this.saveSettings}
          saveBtnLabel="SAVE"
          classNameWrapper="editSensorWrapper"
          draggable={false}
        >
          <div className="grid">
            <div className="row">
              <TextField
                id="sensorName"
                label="Name"
                classes={{ root: "txtfield" }}
                autoFocus={true}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={name}
              />
            </div>

            <div className="row mB20">
              <div className="col">
                <FleetDropDown
                  label="Sensor Type"
                  list={DEFAULTS.DEFAULT_SENSOR_TYPES}
                  selectedValue={selectedType}
                  id="sensorType"
                />
              </div>
              <div className="col">
                <FleetDropDown
                  label="Parameter"
                  list={sensorParams}
                  selectedValue={selectedParamType}
                  id="sensorParamType"
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <TextField
                  id="unitsOfMeasurement"
                  label="Units of Measurement"
                  classes={{ root: "txtfield" }}
                  variant="outlined"
                  size="small"
                  onMouseDown={(e) => e.stopPropagation()}
                  defaultValue={
                    unitOfMeasurement !== undefined ? unitOfMeasurement : ""
                  }
                />
              </div>
              <div className="col">
                <TextField
                  id="formula"
                  label="Formula"
                  classes={{ root: "txtfield" }}
                  variant="outlined"
                  size="small"
                  onMouseDown={(e) => e.stopPropagation()}
                  defaultValue={formula}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <TextField
                  id="lowestValue"
                  label="Lowest Value"
                  classes={{ root: "txtfield" }}
                  variant="outlined"
                  size="small"
                  onMouseDown={(e) => e.stopPropagation()}
                  defaultValue={lowestValue}
                />
              </div>
              <div className="col">
                <TextField
                  id="highestValue"
                  label="Highest Value"
                  classes={{ root: "txtfield" }}
                  variant="outlined"
                  size="small"
                  onMouseDown={(e) => e.stopPropagation()}
                  defaultValue={highestValue}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <TextField
                  id="fuelTankCapacity"
                  label="Fuel Tank Capacity (Ltrs.)"
                  classes={{ root: "txtfield" }}
                  variant="outlined"
                  size="small"
                  onMouseDown={(e) => e.stopPropagation()}
                  defaultValue={fuelTankCapacity}
                />
              </div>
              <div className="col">
                <TextField
                  id="sensorVoltage"
                  label="Voltage"
                  classes={{ root: "txtfield" }}
                  variant="outlined"
                  size="small"
                  onMouseDown={(e) => e.stopPropagation()}
                  defaultValue={voltage}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <TextField
                  id="calibrationValue"
                  label="Calibration Value"
                  classes={{ root: "txtfield" }}
                  variant="outlined"
                  size="small"
                  onMouseDown={(e) => e.stopPropagation()}
                  defaultValue={
                    calibrationValue !== undefined ? calibrationValue : ""
                  }
                />
              </div>
              <div className="col" style={{ paddingLeft: 6 }}>
                <FormControlLabel
                  control={
                    <Switch
                      classes={{
                        thumb: "switchThumb",
                        track: "switchTrack",
                        root: "switchRoot",
                      }}
                      id="showInPopup"
                      defaultChecked={showInPopup}
                    />
                  }
                  label="Show In Popup"
                  className="switch"
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="row">
                  <label className="label">Calibration</label>
                </div>
                <FleetGrid
                  columns={[
                    { name: "x", title: "X" },
                    { name: "y", title: "Y" },
                  ]}
                  rows={calibration}
                  id="sensorCalibrations"
                  childRef={(ref) => (this._calibrationGrid = ref)}
                />
                <div id="sensorCalibAddWrap">
                  <TextField
                    id="sensorX"
                    label="X"
                    classes={{ root: "txtfield" }}
                    variant="outlined"
                    size="small"
                    onMouseDown={(e) => e.stopPropagation()}
                    style={{ width: 60 }}
                  />
                  <TextField
                    id="sensorY"
                    label="Y"
                    classes={{ root: "txtfield" }}
                    variant="outlined"
                    size="small"
                    onMouseDown={(e) => e.stopPropagation()}
                    style={{ width: 60 }}
                  />
                  <IconButton onClick={this.addToCalibration}>
                    <Add />
                  </IconButton>
                  <IconButton
                    onClick={this.removeFromCalibration}
                    disabled={calibration.length === 0}
                  >
                    <Remove />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </FleetDialog>
      )
    );
  }
}
const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setVehicles: (values) => {
      dispatch({
        type: COMMON_ACTIONS.SET_VEHICLES,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_POPOVER_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(SensorPopup));
