import React, { Component } from "react";

import FleetDialog from "../dialogbox/FleetDialog";

class ConfirmDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmDialog: false,
      id: "",
      name: "",
      title: "Delete",
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  toggleConfirmDialog = (id, name, title = "Delete") => {
    const { showConfirmDialog } = this.state;
    if (id === "all") {
      this.setState({
        showConfirmDialog: !showConfirmDialog,
        id: "",
        name: "",
      });
    } else if (id === "close") {
      this.setState({
        showConfirmDialog: false,
      });
    } else {
      this.setState({
        showConfirmDialog: !showConfirmDialog,
        id,
        name,
        title,
      });
    }
  };

  render() {
    const { showConfirmDialog, id, name, title } = this.state;
    let subtitle = title;
    if (title !== "Delete") {
      subtitle = title.toLowerCase() + " of";
    } else {
      subtitle = title.toLowerCase();
    }
    return (
      <FleetDialog
        open={showConfirmDialog}
        onCloseSettings={() => this.toggleConfirmDialog("close")}
        title={id === "" ? title + " All" : title}
        maxWidth="xs"
        fullWidth={false}
        saveButton={true}
        cancelButton={true}
        cancelAction={() => this.toggleConfirmDialog("close")}
        saveAction={() => this.props.onOk(id)}
        saveBtnLabel="OK"
        disableBackdropClick={false}
        disableBackdrop={false}
        classNameWrapper="confirmDialog"
      >
        <p style={{ fontSize: 14 }}>
          Are you sure want to{" "}
          {id === ""
            ? subtitle + " all records..?"
            : subtitle + ' "' + name + '"..?'}
        </p>
      </FleetDialog>
    );
  }
}

export default ConfirmDialog;
