import React, { Component } from "react";
import { connect } from "react-redux";

import PointMarker from "./PointMarker";

import { API_Map } from "apihandler/Map";
import { MAP_CONTROLS_ACTIONS } from "actions/MapControlsActions";
import { POI_ACTIONS } from "actions/PoiActions";

class Points extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: this.props.zoom,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.getPoints();
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  updateZoom = (zoom) => {
    // this.setState({
    //   zoom: zoom,
    // });
    // this.updateZoomCss(zoom);
  };

  getPoints = () => {
    let self = this;
    API_Map.getAllPoints()
      .then((res) => {
        if (res.Result === 200) {
          self.props.setPoints({
            points: res["Response"],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateZoomCss = (zoom) => {
    // const pointsList = document.getElementsByClassName("pointLabel");
    // if (zoom < DEFAULTS.SHOW_LABELS_ZOOM) {
    //   // Hide all zone labels
    //   for (let i = 0; i < pointsList.length; i++) {
    //     if (!pointsList[i].classList.contains("hide")) {
    //       pointsList[i].classList.add("hide");
    //     }
    //   }
    // } else {
    //   // Show All
    //   for (let i = 0; i < pointsList.length; i++) {
    //     pointsList[i].classList.remove("hide");
    //   }
    // }
  };

  addPointMarker = (lat, lng) => {
    const { pointId } = this.props.mapControlsReducer;
    if (pointId === "") {
      this.props.setPointLatLng({
        pointLatLng: {
          lat,
          lng,
        },
      });
    }
  };

  render() {
    const { pointId, pointImg } = this.props.mapControlsReducer;
    const { points } = this.props.poiReducer;
    return points.map((p) => {
      return (
        <PointMarker
          key={"point" + p.id}
          point={p}
          pointId={pointId}
          pointImg={pointImg}
        />
      );
    });
  }
}

const mapStateToProps = (state) => {
  return {
    mapControlsReducer: state.mapControlsReducer,
    poiReducer: state.poiReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPoints: (values) => {
      dispatch({
        type: POI_ACTIONS.SET_POINTS,
        values,
      });
    },
    setPointLatLng: (values) => {
      dispatch({
        type: MAP_CONTROLS_ACTIONS.SET_POINT_LATLNG,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Points);
