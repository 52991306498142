import React, { Component } from "react";
import { Marker } from "react-leaflet";
import { connect } from "react-redux";
import L from "leaflet";

import { MAP_CONTROLS_ACTIONS } from "../../../../actions/MapControlsActions";

class MovingPoint extends Component {
  getPointMarker = (imgUrl) => {
    let markerUrl = imgUrl;
    const customMarker = L.icon({
      iconUrl: markerUrl,
      iconRetinaUrl: markerUrl,
      iconAnchor: null,
      popupAnchor: [0, -3],
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(22, 25),
      className: "pointMarker",
    });
    return customMarker;
  };

  onPointPlacement = (e) => {
    const { lat, lng } = e.target._latlng;
    if (document.querySelector("div#addPoint")) {
      let latLng = lat + "," + lng;
      document.querySelector("div#addPoint").setAttribute("latlng", latLng);
    }
    this.props.setPointLatLng({
      pointLatLng: e.target._latlng,
    });
  };

  render() {
    const { pointLatLng, pointImg } = this.props.mapControlsReducer;
    return pointLatLng.lat ? (
      <Marker
        position={[pointLatLng.lat, pointLatLng.lng]}
        icon={this.getPointMarker(pointImg.image)}
        key={"pointMovingMarker"}
        className={"point"}
        draggable={true}
        ondragend={this.onPointPlacement}
      />
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    mapControlsReducer: state.mapControlsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPointLatLng: (values) => {
      dispatch({
        type: MAP_CONTROLS_ACTIONS.SET_POINT_LATLNG,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MovingPoint);
