export const PAGE_ACTIONS = {
  TOGGLE_FULLSCREEN: "TOGGLE_FULLSCREEN",
  TOGGLE_LEFTBAR: "TOGGLE_LEFTBAR",
  TOGGLE_MAP_MENU: "TOGGLE_MAP_MENU",
  TOGGLE_REPORTS_MENU: "TOGGLE_REPORTS_MENU",
  TOGGLE_SETTINGS_MENU: "TOGGLE_SETTINGS_MENU",
  TOGGLE_EDIT_VEHICLE_MENU: "TOGGLE_EDIT_VEHICLE_MENU",
  SET_MAP_LOADING: "SET_MAP_LOADING",
  SET_POPOVER_LOADING: "SET_POPOVER_LOADING",
  TOGGLE_TRACK_BOTTOM_BAR: "TOGGLE_TRACK_BOTTOM_BAR",
  SET_LAT_LNG: "SET_LAT_LNG",
  TOGGLE_LIVE_VEHICLE_POPUP: "TOGGLE_LIVE_VEHICLE_POPUP",
  ETA_POPUP: "ETA_POPUP",
};
