import React, { Component } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  StepConnector,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { Loop as ReloadIcon, NotificationsNone } from "@material-ui/icons";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

import FleetDropDown from "components/ui/dropdown/FleetDropDown";
import UiDatePicker from "components/ui/uidatepicker/UiDatePicker";
import AutoComplete from "components/ui/autocomplete/AutoComplete";

import { FORMATS, DEFAULTS } from "common/consts";
import { commonJs } from "common/common";
import { dateFunction } from "common/datefunctions";
import { TMP_ACTIONS } from "actions/TmpActions";
import { MAP_ACTIONS } from "actions/MapActions";
import { API_Map } from "apihandler/Map";
import { API_Alerts } from "apihandler/Alerts";

import "./alerts.css";

class AlertsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alerts: null,
      alertType: DEFAULTS.DEFAULT_ALERT_TYPE,
      fdt: dateFunction.getDate("today", "from", FORMATS.SQL_DATE),
      tdt: dateFunction.getDate("today", "to", FORMATS.SQL_DATE),
      pageNo: 1,
      loading: true,
      hasNext: false,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.loadAlerts(true);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  onScrollAlerts = (e) => {
    console.log("scrolling...", e);
  };

  loadAlerts = (reset = false, pageNo = 1) => {
    const { alertType, fdt, tdt, alerts } = this.state;
    const { selectedVehicle } = this.props.mapReducer;

    let args = {
      fromDate: fdt,
      toDate: tdt,
      pageNo: reset ? 1 : pageNo,
      pageLength: 20,
      orderBy: "DESC",
      alertType: Number(alertType),
      sortColumn: "",
      imei: Number(selectedVehicle),
    };

    console.log("loadAlerts: ", args);

    let self = this;
    API_Alerts.getAlerts(args)
      .then((res) => {
        if (res.Result === 200) {
          let actualList = alerts;
          if (reset) {
            actualList = res.Response;
          } else {
            const newList = res.Response;
            for (let n = 0; n < newList.length; n++) {
              actualList.push(newList[n]);
            }
          }

          self.setState({
            alerts: actualList,
            hasNext: res.hasNext,
            pageNo,
            loading: false,
          });

          // self.updateAddress();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateAddress = () => {
    document.querySelectorAll(".latlng").forEach((latlng) => {
      let latlngArray = latlng.innerHTML.split(",");
      API_Map.getAddress(Number(latlngArray[0]), Number(latlngArray[1])).then(
        (res) => {
          if (res.Result === 200) {
            latlng.innerHTML = res.Response;
          }
        }
      );
    });
  };

  onChangeAlertType = (val) => {
    let self = this;
    this.setState(
      {
        alertType: val,
        alerts: null,
        loading: true,
        pageNo: 1,
      },
      () => {
        self.loadAlerts(true);
      }
    );
  };

  loadMore = () => {
    const { pageNo } = this.state;
    this.setState({
      loading: true,
      hasNext: false,
    });
    this.loadAlerts(false, pageNo + 1);
  };

  onClickAlert = ({ latitude, longitude, details, objectlogtime }, indx) => {
    this.props.updateMapRegion(latitude, longitude, DEFAULTS.TRACK_ZOOM);

    this.props.setTmpMarker({
      tmpMarker: {
        lat: latitude,
        lng: longitude,
        data: { title: details, angle: 0, time: objectlogtime },
      },
    });

    // Highlight selected alert
    document
      .querySelectorAll("div#alertList .stepWrapper.active")
      .forEach((item) => {
        item.classList.remove("active");
      });
    if (document.getElementById("alert-" + indx)) {
      document.getElementById("alert-" + indx).classList.add("active");
    }

    if (document.createEvent) {
      let evt = document.createEvent("MouseEvents");
      evt.initEvent("click", true, false);
      setTimeout(() => {
        let lat = latitude.toString().replace(".", "");
        let lng = longitude.toString().replace(".", "");
        if (document.querySelector("#tmpMarker-" + lat + "-" + lng + ">img")) {
          document
            .querySelector("#tmpMarker-" + lat + "-" + lng + ">img")
            .dispatchEvent(evt);
        }
      }, 1000 * 3);
    }
  };

  handleDateChange = (type, dt) => {
    let dtformat = "YYYY-MM-DD";
    dt = dateFunction.getFormatedDate(dt, FORMATS.SQL_DATE, dtformat);
    let self = this;
    this.setState(
      {
        fdt: dt + " 00:00:00",
        tdt: dt + " 23:59:59",
        alerts: null,
        loading: true,
        pageNo: 1,
      },
      () => {
        self.loadAlerts(true);
      }
    );
  };

  reloadAlerts = () => {
    this.setState({
      loading: true,
      alerts: null,
      hasNext: false,
    });
    this.loadAlerts(true);
  };

  onChangeVehicle = (value) => {
    this.setState({
      alerts: [],
      hasNext: false,
      pageNo: 1,
      loading: false,
    });
    if (value !== null) {
      this.props.setSelectedVehicle({
        selectedVehicle: value.id,
      });
    } else {
      this.props.setSelectedVehicle({
        selectedVehicle: "",
      });
    }
  };

  render() {
    const { alerts, alertType, loading, fdt, hasNext } = this.state;

    const { tmpVehicles, selectedVehicle } = this.props.mapReducer;

    let vehicles = tmpVehicles.map((v) => {
      return { id: v.imei, name: v.name };
    });

    let vehicle = tmpVehicles.filter((v) => {
      return v.imei === selectedVehicle;
    });
    if (vehicle.length > 0) {
      vehicle = {
        id: vehicle[0].imei,
        name: vehicle[0].name,
      };
    }

    return (
      <div id="alertList">
        <div id="alertsHeader">
          <div id="alertTopBar">
            <AutoComplete
              label={"Select Vehicle"}
              list={vehicles}
              id="trackVehicles"
              style={{ marginBottom: 15, marginTop: 15 }}
              onChange={this.onChangeVehicle}
              defaultValue={vehicle}
              multiple={false}
              size="small"
            />
          </div>
          <div id="alertBottomBar">
            <div id="alertLeftBar">
              <UiDatePicker
                label=""
                dt={fdt}
                type="from"
                disablePast={false}
                disableFuture={true}
                disabled={false}
                datepicker={true}
                handleDateChange={this.handleDateChange}
              />
            </div>
            <div id="alertRightBar">
              <FleetDropDown
                list={DEFAULTS.ALERT_TYPES}
                noemptySelection={true}
                selectedValue={alertType}
                onChange={this.onChangeAlertType}
                id="trackStops"
              />
              <IconButton onClick={this.reloadAlerts}>
                <ReloadIcon className="refreshIcon" />
              </IconButton>
            </div>
          </div>
        </div>
        <div className="trackTimeline scrollBar" id="scrollDiv">
          {alerts && (
            <Stepper
              orientation="vertical"
              nonLinear={true}
              className="stepper"
              connector={<StepConnector className="stepConnector" />}
            >
              {alerts.map((alert, i) => {
                const { objectlogtime, details, imei, vehiclename } = alert;
                let vehicleNo = vehiclename;
                if (vehicleNo === "") {
                  let filteredVehicle = commonJs.getVehicleFromArray(
                    tmpVehicles,
                    imei
                  );
                  if (filteredVehicle.length > 0) {
                    vehicleNo = filteredVehicle[0].name;
                  } else {
                    vehicleNo = imei;
                  }
                }
                return (
                  vehicleNo !== "" && (
                    <Step
                      key={"alertstep-" + i}
                      id={"alert-" + i}
                      active={true}
                      className="stepWrapper"
                      onClick={() => this.onClickAlert(alert, i)}
                    >
                      <StepLabel
                        classes={{
                          label: "stepLabel",
                        }}
                        icon={<NotificationsNone className="stepIcon" />}
                      >
                        <p className="stepSummary vehicleNum">{vehicleNo}</p>
                        <p className={"stepStatus"}>{details}</p>
                      </StepLabel>
                      <StepContent className="stepContent">
                        <p className="stepDate">
                          {dateFunction.getFormatedDate(
                            objectlogtime,
                            FORMATS.SQL_DATE,
                            FORMATS.DATE
                          ) +
                            " (" +
                            dateFunction.getDateDiffOneString(
                              dateFunction.getDateDiff(objectlogtime)
                            ) +
                            ")"}
                        </p>
                      </StepContent>
                    </Step>
                  )
                );
              })}
            </Stepper>
          )}

          {hasNext && (
            <p id="loadMoreAlert" onClick={this.loadMore}>
              Load More
            </p>
          )}

          {loading && <CircularProgress className={"primary miniloader"} />}

          {!hasNext && alerts && (
            <h4 id="totalAlertRecords">{"Total Alerts: " + alerts.length}</h4>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTmpMarker: (values) => {
      dispatch({
        type: TMP_ACTIONS.SET_TMP_MARKER,
        values,
      });
    },
    setSelectedVehicle: (values) => {
      dispatch({
        type: MAP_ACTIONS.SELECT_VEHICLE,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(AlertsTab));
