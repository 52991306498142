import { SdkClient } from "fgxsdk";
import { CONFIG } from "common/consts";

SdkClient.setUrl(CONFIG.BASE_URL);

export const API_Reports = {
  getAnalyticalReport: async (args) => {
    return {
      Result: 200,
      Response: {
        cols: [
          "Sl No",
          "Reg No",
          "Vehicle Type",
          "Start Time",
          "Start Location",
          "End Time",
          "End Location",
          "Total KM",
          "Running Hours",
          "Idle Hours",
          "Top Speed",
          "Avg Speed",
        ],
        rows: [],
      },
    };
  },
  getOverspeedReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      fromDate: "2020-08-01 00:00:00",
      toDate: "2020-08-05 00:00:00",
      platform: "web", // Or it will be "app"
      speed: 60, // Overspeed limit
    };
    return {
      Result: 200,
      Response: {
        cols: [
          "Sl No.",
          "Vehicle Name",
          "Time",
          "Speed",
          "City",
          "State",
          "Location",
        ],
        rows: [
          [
            "1",
            "KA526760",
            "2020-08-01 10:13:31",
            "61.00",
            "Bangalore",
            "Karnataka",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "2",
            "KA526760",
            "2020-08-01 10:13:31",
            "61.00",
            "Bangalore",
            "Karnataka",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
        ],
        hasNext: true,
      },
    };
  },
  getDrivingReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      imei: "73383121230",
      fromDate: "2019-05-01 00:00:00",
      toDate: "2019-05-31 23:59:59",
    };
    return {
      Result: 200,
      Response: {
        cols: ["Sl No.", "Time", "Location", "Speed", "Event", "Value"],
        rows: [
          [
            1,
            "15-05-2019 00:00:00",
            "Ramanagara district, Karnataka",
            "65.5",
            "Over Speed",
            "150",
          ],
          [
            2,
            "15-05-2019 00:00:00",
            "Ramanagara district, Karnataka",
            "75.5",
            "Over Speed",
            "200",
          ],
        ],
        hasNext: true,
      },
    };
  },
  getTemperatureReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      imei: "73383121230",
      groupId: "", // Get Vehicle of the group, if empty -> Ignore
      fromDate: "2020-07-12 00:00:00",
      toDate: "2020-07-12 23:59:59",
      stopTime: 15,
      tempType: "normal", // or it will be "" -> To display actual temperature
      platform: "web", // Or it will be "app"
    };
    // If platform is "app" => "adress" should be json
    // {
    //   address:"Bangalore East, Bangalore Urban, Karnataka, 560049, India",
    //   lat:13.050612,
    //   lng:77.752776
    // }
    return {
      Result: 200,
      Response: {
        cols: ["Sl No.", "Time", "Temperature (°C)", "Speed", "Location"],
        rows: [
          [
            "1",
            "2020-07-12 00:00:00",
            "15.5",
            "45",
            '<a href="https://www.google.com/maps?q=13.063375,77.456312" target="_blank">Adakamaranahalli, Madanayakanahalli, Madhavara, Bangalore Urban, Karnataka, 560073, India</a>',
          ],
          [
            "2",
            "2020-07-12 00:15:00",
            "14.6",
            "20",
            '<a href="https://www.google.com/maps?q=13.063375,77.456312" target="_blank">Adakamaranahalli, Madanayakanahalli, Madhavara, Bangalore Urban, Karnataka, 560073, India</a>',
          ],
        ],
        hasNext: true,
      },
    };
  },
  getFuelConsumptionReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      imei: "73383121230",
      fromDate: "2020-08-01 00:00:00",
      toDate: "2020-08-31 23:59:59",
    };
    return {
      Result: 200,
      Response: {
        cols: [
          "Sl No.",
          "Date",
          "Km Travel",
          "Opening",
          "Filled",
          "Consumption",
          "Closing",
          "Mileage",
        ],
        rows: [
          [
            "1",
            "02-08-2020",
            "420.76",
            "370.17",
            "0",
            "23.36",
            "393.53",
            "18.01",
          ],
          [
            "2",
            "02-08-2020",
            "420.76",
            "370.17",
            "0",
            "23.36",
            "393.53",
            "18.01",
          ],
        ],
        hasNext: true,
      },
    };
  },
  getAlcoholValuesReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      imei: "73383121230",
      fromDate: "2020-08-01 00:00:00",
      toDate: "2020-08-31 23:59:59",
    };
    return {
      Result: 200,
      Response: { cols: ["Status"], rows: [] },
    };
  },
  getTemperatureGraph: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      imei: "73383121230",
      groupId: "", // Get Vehicle of the group, if empty -> Ignore
      fromDate: "2020-07-12 00:00:00",
      toDate: "2020-07-12 23:59:59",
      stopTime: 15,
      tempType: "normal", // or it will be "" -> To display actual temperature
      platform: "web", // Or it will be "app"
    };
    // If platform is "app" => "adress" should be json
    // {
    //   address:"Bangalore East, Bangalore Urban, Karnataka, 560049, India",
    //   lat:13.050612,
    //   lng:77.752776
    // }
    return {
      Result: 200,
      Response: {
        cols: ["Sl No.", "Date", "Temperature (°C)", "Speed", "Location"],
        rows: [
          [
            "1",
            "2020-07-12 00:00:00",
            "15.5",
            "45",
            '<a href="https://www.google.com/maps?q=13.063375,77.456312" target="_blank">Adakamaranahalli, Madanayakanahalli, Madhavara, Bangalore Urban, Karnataka, 560073, India</a>',
          ],
          [
            "2",
            "2020-07-12 00:15:00",
            "14.6",
            "20",
            '<a href="https://www.google.com/maps?q=13.063375,77.456312" target="_blank">Adakamaranahalli, Madanayakanahalli, Madhavara, Bangalore Urban, Karnataka, 560073, India</a>',
          ],
          [
            "3",
            "2020-07-12 00:30:00",
            "12.4",
            "20",
            '<a href="https://www.google.com/maps?q=13.063375,77.456312" target="_blank">Adakamaranahalli, Madanayakanahalli, Madhavara, Bangalore Urban, Karnataka, 560073, India</a>',
          ],
          [
            "4",
            "2020-07-12 00:45:00",
            "13.6",
            "20",
            '<a href="https://www.google.com/maps?q=13.063375,77.456312" target="_blank">Adakamaranahalli, Madanayakanahalli, Madhavara, Bangalore Urban, Karnataka, 560073, India</a>',
          ],
        ],
        hasNext: true,
      },
    };
  },
};
