import React, { Component } from "react";
import { TextField, Switch, FormControlLabel } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import FleetDialog from "components/ui/dialogbox/FleetDialog";
import AutoComplete from "components/ui/autocomplete/AutoComplete";

import { commonJs } from "common/common";
import { DEFAULTS, FORMATS, LABELS, NOTIFY } from "common/consts";
import { dateFunction } from "common/datefunctions";
import { CPANEL_ACTIONS } from "actions/CpanelActions";
import { API_CPanel } from "apihandler/CPanel";

class VehicleDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {},
      showDialog: false,
      fromDate: new Date(),
      toDate: new Date(new Date().getFullYear + "-12-31"),
      selectedUsers: [],
      selectedManager: DEFAULTS.DEFAULT_MANAGER,
      selectedGroup: DEFAULTS.DEFAULT_GROUP,
      active: true,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  toggleDialog = (record) => {
    const { showDialog } = this.state;
    if (record.id === undefined) {
      if (record.add) {
        this.setState({
          showDialog: !showDialog,
          record: {},
          selectedGroup: DEFAULTS.DEFAULT_GROUP,
          selectedManager: DEFAULTS.DEFAULT_MANAGER,
          selectedUsers: [],
        });
      } else {
        // Close
        this.setState({
          showDialog: !showDialog,
        });
      }
    } else {
      // Edit -> Open
      let group = this.props.groups.filter((g) => g.id === record.groupId);
      let selectedGroup = DEFAULTS.DEFAULT_GROUP;
      if (group.length > 0) {
        selectedGroup = group[0];
      }
      let manager = this.props.managers.filter((m) => m.id === record.manager);
      let selectedManager = DEFAULTS.DEFAULT_MANAGER;
      if (manager.length > 0) {
        selectedManager = manager[0];
      }
      const { users } = this.props;
      let selectedUsers = [];
      for (let i = 0; i < record.users.length; i++) {
        let user = users.filter((u) => u.id === record.users[i]);
        if (user.length > 0) {
          selectedUsers.push({
            id: user[0].id,
            name: user[0].name,
          });
        }
      }
      this.setState({
        showDialog: !showDialog,
        record: record,
        active: record.active,
        selectedGroup,
        selectedManager,
        selectedUsers,
      });
    }
  };

  saveRecord = () => {
    const {
      selectedUsers,
      selectedGroup,
      selectedManager,
      active,
    } = this.state;
    const vname = document.getElementById("cvlistVehicleName").value.trim();
    const imei = document.getElementById("cvlistVehicleImei").value.trim();
    const sim1 = document.getElementById("cvlistVehicleSimNumber").value.trim();
    const sim2 = document
      .getElementById("cvlistVehicleSimNumber2")
      .value.trim();
    const managerId = selectedManager.id || null;
    const groupId = selectedGroup.id || null;
    let fromDate = document
      .getElementById("cvlistVehicleFromDate")
      .value.trim();
    let toDate = document.getElementById("cvlistVehicleToDate").value.trim();
    const position = document
      .getElementById("cvlistVehiclePositionNo")
      .value.trim();
    const deviceType = document
      .getElementById("cvlistVehicleDeviceType")
      .value.trim();
    const supplierName = document
      .getElementById("cvlistVehicleSupplierName")
      .value.trim();
    let purchaseDate = document
      .getElementById("cvlistVehiclePurchaseDate")
      .value.trim();

    let self = this;
    if (vname === "") {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Vehicle Name should not be empty..!"
      );
      return false;
    }
    if (imei === "") {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "IMEI No. should not be empty..!"
      );
      return false;
    }
    if (sim1 === "") {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "SIM No.1 should not be empty..!"
      );
      return false;
    }
    if (fromDate === "" || toDate === "") {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Active From & To are should not be empty..!"
      );
      return false;
    }

    fromDate = dateFunction.getFormatedDate(
      fromDate,
      FORMATS.DATE,
      FORMATS.SQL_DATE
    );
    toDate = dateFunction.getFormatedDate(
      toDate,
      FORMATS.DATE,
      FORMATS.SQL_DATE
    );

    let sharedUsers = selectedUsers.map((u) => {
      return u.id;
    });

    const args = {
      name: vname,
      imei: imei,
      simNumber: sim1,
      simNumber2: sim2,
      groupId: groupId,
      users: sharedUsers,
      manager: managerId,
      active: active,
      activeFrom: fromDate,
      activeTill: toDate,
      positionNo: Number(position),
      deviceType: deviceType,
      supplier: supplierName,
      purchaseDate: purchaseDate,
    };

    console.log("saveRecord", args);

    this.props.setLoading({
      loading: true,
    });

    API_CPanel.manageVehicle(args)
      .then((res) => {
        console.log("manageVehicle: ", res);
        if (res.Result === 200) {
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            "Vehicle saved successfully..!",
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
          self.props.loadGrid();
          self.setState({
            showDialog: false,
            selectedUsers: [],
            selectedManager: DEFAULTS.DEFAULT_MANAGER,
            selectedGroup: DEFAULTS.DEFAULT_GROUP,
          });
          self.props.setLoading({
            loading: false,
          });
        } else {
          commonJs.showNotify(self.props, NOTIFY.ERROR, res.Reason);
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });

    return;
  };

  handleFromDateChange = (date) => {
    this.setState({
      fromDate: date,
    });
  };

  handleToDateChange = (date) => {
    this.setState({
      toDate: date,
    });
  };

  onChangeUsers = (val) => {
    this.setState({
      selectedUsers: val,
    });
  };

  onChangeManager = (val) => {
    this.setState({
      selectedManager: val,
    });
  };

  onChangeGroup = (val) => {
    this.setState({
      selectedGroup: val,
    });
  };

  onChangeActive = (val) => {
    this.setState({
      active: val,
    });
  };

  render() {
    const {
      record,
      showDialog,
      fromDate,
      toDate,
      selectedUsers,
      selectedManager,
      selectedGroup,
      active,
    } = this.state;

    const { users, managers, groups } = this.props;

    return (
      <FleetDialog
        id="cpanelVehicleDialog"
        open={showDialog}
        onCloseSettings={this.toggleDialog}
        title={record.id ? "Edit Vehicle" : "Add Vehicle"}
        maxWidth="md"
        fullWidth={true}
        cancelButton={true}
        cancelAction={this.toggleDialog}
        saveButton={true}
        saveAction={this.saveRecord}
        saveBtnLabel="OK"
        disableBackdropClick={true}
        disableBackdrop={true}
        classNameBody="zonePopupBody"
        classNameWrapper="zonePopupWrapper"
        draggable
      >
        <div className="grid">
          <div className="row">
            <div className="width48">
              <TextField
                id="cvlistVehicleName"
                label="Vehicle Name"
                classes={{ root: "txtfield" }}
                autoFocus={true}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={record.vehicleName || ""}
              />
            </div>
            <div className="width48">
              <TextField
                id="cvlistVehicleImei"
                label="IMEI"
                classes={{ root: "txtfield" }}
                autoFocus={false}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={record.imei || ""}
              />
            </div>
          </div>
          <div className="row">
            <div className="width48">
              <TextField
                id="cvlistVehicleSimNumber"
                label="SIM Number"
                classes={{ root: "txtfield" }}
                autoFocus={false}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={record.simNumber || ""}
              />
            </div>
            <div className="width48">
              <TextField
                id="cvlistVehicleSimNumber2"
                label="SIM Number 2"
                classes={{ root: "txtfield" }}
                autoFocus={false}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={record.simNumber2 || ""}
              />
            </div>
          </div>
          <div className="row">
            <AutoComplete
              label={"Users"}
              list={users}
              style={{ marginBottom: 18, width: "100%" }}
              onChange={this.onChangeUsers}
              defaultValue={selectedUsers}
              multiple={true}
              size="small"
            />
          </div>
          <div className="row">
            <div className="width48">
              <AutoComplete
                label={"Manager"}
                list={managers}
                style={{ marginBottom: 15, width: "100%" }}
                onChange={this.onChangeManager}
                defaultValue={selectedManager}
                multiple={false}
                size="small"
              />
            </div>
            <div className="width48">
              <AutoComplete
                label={"Group"}
                list={groups}
                style={{ marginBottom: 15, width: "100%" }}
                onChange={this.onChangeGroup}
                defaultValue={selectedGroup}
                multiple={false}
                size="small"
              />
            </div>
          </div>
          <div className="row">
            <div
              className="width48 singleDatePickerWrapper"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 15,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <label className="label" style={{ width: 185 }}>
                  {"Active From : "}
                </label>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format={FORMATS.DATE_PICKER_FORMAT}
                  margin="normal"
                  id="cvlistVehicleFromDate"
                  value={
                    record.activeFrom ? new Date(record.activeFrom) : fromDate
                  }
                  placeholder=""
                  onChange={this.handleFromDateChange}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div
              className="width48 singleDatePickerWrapper"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <label className="label" style={{ width: 185 }}>
                  {"Active Till : "}
                </label>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format={FORMATS.DATE_PICKER_FORMAT}
                  margin="normal"
                  id="cvlistVehicleToDate"
                  value={
                    record.activeTill ? new Date(record.activeTill) : toDate
                  }
                  placeholder=""
                  onChange={this.handleToDateChange}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className="row">
            <div className="width48">
              <TextField
                id="cvlistVehiclePositionNo"
                label="Position No."
                classes={{ root: "txtfield" }}
                autoFocus={false}
                variant="outlined"
                size="small"
                type="number"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={record.positionNo || "0"}
              />
            </div>
            <div className="width48">
              <TextField
                id="cvlistVehicleDeviceType"
                label="Device Type"
                classes={{ root: "txtfield" }}
                autoFocus={false}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={record.deviceType || ""}
              />
            </div>
          </div>
          <div className="row">
            <div className="width48">
              <TextField
                id="cvlistVehicleSupplierName"
                label="Supplier Name"
                classes={{ root: "txtfield" }}
                autoFocus={false}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={record.supplier || ""}
              />
            </div>
            <div className="width48">
              <TextField
                id="cvlistVehiclePurchaseDate"
                label="Purchase Date"
                classes={{ root: "txtfield" }}
                autoFocus={false}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={record.purchaseDate || ""}
              />
            </div>
          </div>
          <div className="row">
            <div className="width45">
              <FormControlLabel
                control={
                  <Switch
                    classes={{
                      thumb: "switchThumb",
                      track: "switchTrack",
                      root: "switchRoot",
                    }}
                    id="cvlistVehicleActive"
                    checked={active}
                  />
                }
                label="Active"
                className="switch"
                onChange={() => this.onChangeActive(!active)}
              />
            </div>
          </div>
        </div>
      </FleetDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (values) => {
      dispatch({
        type: CPANEL_ACTIONS.SET_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(VehicleDialog));
