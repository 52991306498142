import React, { Component } from "react";
import {
  List,
  ListItem,
  IconButton,
  Tooltip,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import { AccessTime as EtaIcon } from "@material-ui/icons";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

import FleetDialog from "components/ui/dialogbox/FleetDialog";
import Img from "components/ui/image/Img";
import Settings from "components/settings/Settings";
import SearchButton from "components/ui/searchbutton/SearchButton";
import EtaPopup from "./eta/EtaPopup";

import {
  LABELS,
  CONFIG,
  NOTIFY,
  ALERTS,
  CONFIRMS,
  DEFAULTS,
  PRIVILEGES,
} from "common/consts";
import { commonJs } from "common/common";
import { MAP_ACTIONS } from "actions/MapActions";
import { PAGE_ACTIONS } from "actions/PageActions";
import { TMP_ACTIONS } from "actions/TmpActions";

// Left Bar Icons
import FitMapIcon from "images/topBarImages/LeftMenu/fitmap.svg";
import FitMapExitIcon from "images/topBarImages/LeftMenu/fullscreen-exit.svg";
import LocateMarkerIcon from "images/topBarImages/LeftMenu/locatemarker.svg";
import PrintIcon from "images/topBarImages/LeftMenu/print.svg";

// Right Bar Icons
import LabelIcon from "images/topBarImages/RightMenu/label.svg";
import VehicleIcon from "images/topBarImages/RightMenu/vehicle.svg";
import PointIcon from "images/topBarImages/RightMenu/flag.svg";
import RouteIcon from "images/topBarImages/RightMenu/route.svg";
import ZoneIcon from "images/topBarImages/RightMenu/zones.svg";
import ClusterIcon from "images/topBarImages/RightMenu/clusters.svg";
import TrafficIcon from "images/topBarImages/RightMenu/traffic.svg";
import DataPointsIcon from "images/topBarImages/RightMenu/datapoints.svg";

import fleetLogo from "images/logo/logo.png";

import "./topbar.css";

class FixedTopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openProfileMenu: false,
      logoutDialog: false,
      fullScreen: commonJs.isFullScreen(),
      showPointDialog: false,
    };
    this.anchorEl = null;
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    document.addEventListener(
      "fullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "mozfullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "MSFullscreenChange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "webkitfullscreenchange",
      this.onFullScreenChange,
      false
    );
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
    document.removeEventListener(
      "fullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.removeEventListener(
      "mozfullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.removeEventListener(
      "MSFullscreenChange",
      this.onFullScreenChange,
      false
    );
    document.removeEventListener(
      "webkitfullscreenchange",
      this.onFullScreenChange,
      false
    );
  }

  onFullScreenChange = (e) => {
    if (window.innerHeight !== window.screen.height) {
      this.setState({
        fullScreen: false,
      });
    } else {
      this.setState({
        fullScreen: true,
      });
    }
  };

  topmenuHandler = (menu) => {
    const {
      fitmap,
      locateMarkerDialog,
      scale,
      mapLabels,
      mapVehicles,
      mapPoints,
      mapRoutes,
      mapZones,
      mapClusters,
      mapTraffic,
      dataPoints,
    } = this.props.mapReducer;

    switch (menu) {
      case "locate":
        this.props.setLocateMarker({
          locateMarkerDialog: !locateMarkerDialog,
        });
        break;
      case "fitmap":
        if (!fitmap) {
          this.props.setFitScreenStatus({
            fitmap: !fitmap,
          });
          // Fit Map -> Fit To Bounds
          this.props.mapFitBounds();
        } else {
          this.props.setFitScreenStatus({
            fitmap: !fitmap,
          });
        }
        break;
      case "printMap":
        // TODO: Print Map
        console.log("Print Map");
        break;
      case "scale":
        this.props.setScaleStatus({
          scale: !scale,
        });
        break;
      case "mapLabels":
        this.props.setMapLabelStatus({
          mapLabels: !mapLabels,
        });
        break;
      case "mapVehicles":
        this.props.setMapVehiclesStatus({
          mapVehicles: !mapVehicles,
        });
        break;
      case "mapPoints":
        this.props.setMapPointsStatus({
          mapPoints: !mapPoints,
        });
        break;
      case "mapRoutes":
        this.props.setMapRoutesStatus({
          mapRoutes: !mapRoutes,
        });
        break;
      case "mapZones":
        this.props.setMapZonesStatus({
          mapZones: !mapZones,
        });
        break;
      case "mapClusters":
        this.props.setMapClustersStatus({
          mapClusters: !mapClusters,
        });
        break;
      case "mapTraffic":
        this.props.setMapTrafficStatus({
          mapTraffic: !mapTraffic,
        });
        break;
      case "dataPoints":
        this.props.toggleDataPoints({
          dataPoints: !dataPoints,
        });
        break;
      case "myProfile":
        const { openProfileMenu } = this.state;
        this.setState({
          openProfileMenu: !openProfileMenu,
        });
        break;
      default:
        break;
    }
  };

  closeProfileMenu = () => {
    this.setState({
      openProfileMenu: false,
    });
  };

  toggleProfileMenu = () => {
    const { settingsMenu } = this.props.pageReducer;
    this.props.toggleSettingsMenu({
      settingsMenu: !settingsMenu,
    });
    this._settingsDialog.handleChange(null, 0);
  };

  toggleLogoutDialog = () => {
    const { logoutDialog } = this.state;
    this.setState({
      logoutDialog: !logoutDialog,
    });
  };

  logout = () => {
    this.setState({
      logoutDialog: false,
    });
    commonJs.showNotify(
      this.props,
      NOTIFY.SUCCESS,
      ALERTS.LOGOUT_SUCESSSFUL,
      <button className="snackbar-btn">{LABELS.DISMISS}</button>
    );
    commonJs.logout();
  };

  handleListKeyDown = () => {
    console.log("handleListKeyDown");
  };

  toggleShowPointDialog = () => {
    const { showPointDialog } = this.state;
    this.setState({
      showPointDialog: !showPointDialog,
    });
  };

  onShowPointKeyPress = (e) => {
    console.log("onShowPointKeyPress: ", e);
    if (e.keyCode === 13) {
      // Enter Key
      this.showPoint();
    } else {
      // regex
      // input update
    }
  };

  showPoint = () => {
    let points = document.getElementById("showpoint").value.split(",");
    if (points.length === 2) {
      let lat = +points[0];
      let lng = +points[1];
      if (!isNaN(lat) && !isNaN(lng)) {
        this.props.setTmpMarker({
          tmpMarker: {
            lat: lat,
            lng: lng,
            data: {
              title: lat + "," + lng,
              angle: 0,
            },
          },
        });
        this.props.setLatLng({
          latLng: {
            lat: lat,
            lng: lng,
          },
        });
        this.props.updateMapRegion(lat, lng, DEFAULTS.GOOGLE_SEARCH_MAX_ZOOM);
      } else {
        commonJs.showNotify(
          this.props,
          NOTIFY.WARNING,
          "The value should not be number..!"
        );
      }
    } else {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "The value should be latitude,longitude. Eg: 12.9635699,77.4989616"
      );
    }
  };

  toggleEtaMaster = () => {
    this._etaDialog.toggleEtaDialog();
  };

  render() {
    const { openProfileMenu, logoutDialog, showPointDialog } = this.state;
    const { loginReducer, mapReducer, pageReducer, onPrintMap } = this.props;
    const { logo, userName, role, privileges } = loginReducer;
    const {
      fitmap,
      mapLabels,
      mapVehicles,
      mapPoints,
      mapRoutes,
      mapZones,
      mapClusters,
      mapTraffic,
      dataPoints,
      mapType,
    } = mapReducer;
    const { leftMapMenu } = pageReducer;

    const showPois = commonJs.hasPrivilege(PRIVILEGES.POI, role, privileges);
    const showTrackOptions = commonJs.hasPrivilege(
      PRIVILEGES.TRACK,
      role,
      privileges
    );
    const showEta = commonJs.hasPrivilege(
      PRIVILEGES.ETA_MASTER,
      role,
      privileges
    );

    return (
      <div className={"topMapBar " + (fitmap ? "open" : "")}>
        <List
          component="nav"
          className={"topLeftMenu" + (leftMapMenu ? " leftbar" : "")}
        >
          <ListItem onClick={() => this.topmenuHandler("fitmap")}>
            <Tooltip
              className="tooltip"
              title={fitmap ? LABELS.EXIT_FIT_SCREEN : LABELS.FIT_SCREEN}
            >
              <IconButton className="topicon-pad">
                <Img src={fitmap ? FitMapExitIcon : FitMapIcon} />
              </IconButton>
            </Tooltip>
          </ListItem>
          <ListItem onClick={this.toggleShowPointDialog}>
            <Tooltip title={LABELS.LOCATE_MARKER}>
              <IconButton className="topicon-pad">
                <Img src={LocateMarkerIcon} />
              </IconButton>
            </Tooltip>
          </ListItem>
          <ListItem onClick={onPrintMap}>
            <Tooltip title={LABELS.PRINT_MAP}>
              <IconButton className="topicon-pad">
                <Img src={PrintIcon} />
              </IconButton>
            </Tooltip>
          </ListItem>
          {showEta && (
            <ListItem onClick={this.toggleEtaMaster}>
              <Tooltip title={LABELS.ETA_MASTER}>
                <IconButton className="topicon-pad" id="etaMasterIcon">
                  <EtaIcon />
                </IconButton>
              </Tooltip>
            </ListItem>
          )}
        </List>

        {/* TODO: !disabled */}
        <List component="nav" className="topRightMenu">
          <ListItem
            disabled={!mapLabels}
            onClick={() => this.topmenuHandler("mapLabels")}
          >
            <Tooltip title={LABELS.HIDE_UNHIDE_LABELS}>
              <IconButton className="topicon-pad">
                <Img src={LabelIcon} />
              </IconButton>
            </Tooltip>
          </ListItem>

          <ListItem
            disabled={!mapVehicles}
            onClick={() => this.topmenuHandler("mapVehicles")}
          >
            <Tooltip title={LABELS.HIDE_UNHIDE_VEHICLES}>
              <IconButton className="topicon-pad">
                <Img src={VehicleIcon} />
              </IconButton>
            </Tooltip>
          </ListItem>

          {showPois && (
            <ListItem
              disabled={!mapPoints}
              onClick={() => this.topmenuHandler("mapPoints")}
            >
              <Tooltip title={LABELS.HIDE_UNHIDE_POINTS}>
                <IconButton className="topicon-pad">
                  <Img src={PointIcon} />
                </IconButton>
              </Tooltip>
            </ListItem>
          )}

          {showPois && (
            <ListItem
              disabled={!mapRoutes}
              onClick={() => this.topmenuHandler("mapRoutes")}
            >
              <Tooltip title={LABELS.HIDE_UNHIDE_ROUTES}>
                <IconButton className="topicon-pad">
                  <Img src={RouteIcon} />
                </IconButton>
              </Tooltip>
            </ListItem>
          )}

          {showPois && (
            <ListItem
              disabled={!mapZones}
              onClick={() => this.topmenuHandler("mapZones")}
            >
              <Tooltip title={LABELS.HIDE_UNHIDE_ZONES}>
                <IconButton className="topicon-pad">
                  <Img src={ZoneIcon} />
                </IconButton>
              </Tooltip>
            </ListItem>
          )}

          <ListItem
            disabled={!mapClusters}
            onClick={() => this.topmenuHandler("mapClusters")}
          >
            <Tooltip
              title={LABELS.HIDE_UNHIDE_CLUSTERS}
              className="fleetTooltip"
            >
              <IconButton className="topicon-pad">
                <Img src={ClusterIcon} />
              </IconButton>
            </Tooltip>
          </ListItem>

          {/* Show Traffic only if Google Map */}
          {mapType > 0 && mapType < 4 && (
            <ListItem
              disabled={!mapTraffic}
              onClick={() => this.topmenuHandler("mapTraffic")}
            >
              <Tooltip title={LABELS.SHOW_TRAFFIC}>
                <IconButton className="topicon-pad">
                  <Img src={TrafficIcon} />
                </IconButton>
              </Tooltip>
            </ListItem>
          )}

          {showTrackOptions && (
            <ListItem
              disabled={!dataPoints}
              onClick={() => this.topmenuHandler("dataPoints")}
            >
              <Tooltip title={LABELS.SHOW_DATA_POINTS}>
                <IconButton className="topicon-pad">
                  <Img src={DataPointsIcon} />
                </IconButton>
              </Tooltip>
            </ListItem>
          )}

          {/* My Profile */}
          <ListItem
            id="myProfileBtn"
            onClick={() => this.topmenuHandler("myProfile")}
          >
            <Tooltip title={userName}>
              <IconButton className="topicon-pad avatar-wrapper">
                <Img
                  src={CONFIG.BASE_URL + "/" + (logo === "" ? fleetLogo : logo)}
                />
              </IconButton>
            </Tooltip>
          </ListItem>
          <Popper
            id="profileMenu"
            open={openProfileMenu}
            ref={(ref) => (this.anchorEl = ref)}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <ClickAwayListener onClickAway={this.closeProfileMenu}>
                  <Paper>
                    <MenuList
                      autofocusitem={openProfileMenu.toString()}
                      id="menu-list-grow"
                      onKeyDown={this.handleListKeyDown}
                    >
                      <MenuItem onClick={this.toggleProfileMenu}>
                        My account
                      </MenuItem>
                      <MenuItem onClick={this.toggleLogoutDialog}>
                        Logout
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </ClickAwayListener>
              </Grow>
            )}
          </Popper>
        </List>

        <FleetDialog
          open={logoutDialog}
          onCloseSettings={this.toggleLogoutDialog}
          title={LABELS.LOGOUT}
          maxWidth="sm"
          fullWidth={true}
          saveButton={true}
          cancelButton={true}
          cancelAction={this.toggleLogoutDialog}
          saveAction={this.logout}
          saveBtnLabel="OK"
          classNameWrapper="logoutWrapper"
        >
          <p>{CONFIRMS.CONFIRM_LOGOUT}</p>
        </FleetDialog>

        <Settings childRef={(ref) => (this._settingsDialog = ref)} />

        <FleetDialog
          open={showPointDialog}
          onCloseSettings={this.toggleShowPointDialog}
          title={"Find Location"}
          maxWidth="sm"
          fullWidth={true}
          saveButton={true}
          cancelButton={true}
          cancelAction={this.toggleShowPointDialog}
          saveAction={this.showPoint}
          saveBtnLabel="Show"
          classNameWrapper="showPointWrapper"
          draggable={true}
          disableBackdrop={true}
        >
          <SearchButton
            id="showpoint"
            placeholder="Latitude,Longitude"
            buttonClass="hideBtn"
            onKeyDown={this.onShowPointKeyPress}
          />
        </FleetDialog>

        <EtaPopup childRef={(ref) => (this._etaDialog = ref)} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
    mapReducer: state.mapReducer,
    pageReducer: state.pageReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSettingsMenu: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_SETTINGS_MENU,
        values,
      });
    },
    setFitScreenStatus: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_FITSCREEN,
        values,
      });
    },
    setLocateMarker: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_MAP_MARKER_DIALOG,
        values,
      });
    },
    setScaleStatus: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_MAP_SCALE,
        values,
      });
    },
    setMapLabelStatus: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_MAP_LABELS,
        values,
      });
    },
    setMapVehiclesStatus: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_MAP_VEHICLES,
        values,
      });
    },
    setMapPointsStatus: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_MAP_POINTS,
        values,
      });
    },
    setMapRoutesStatus: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_MAP_ROUTES,
        values,
      });
    },
    setMapZonesStatus: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_MAP_ZONES,
        values,
      });
    },
    setMapClustersStatus: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_MAP_CLUSTERS,
        values,
      });
    },
    setMapTrafficStatus: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_MAP_TRAFFIC,
        values,
      });
    },
    toggleDataPoints: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_MAP_DATA_POINTS,
        values,
      });
    },
    setLatLng: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_LAT_LNG,
        values,
      });
    },
    setTmpMarker: (values) => {
      dispatch({
        type: TMP_ACTIONS.SET_TMP_MARKER,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(FixedTopBar));
