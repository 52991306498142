import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import FleetDialog from "components/ui/dialogbox/FleetDialog";
import AutoComplete from "components/ui/autocomplete/AutoComplete";
import UiDatePicker from "components/ui/uidatepicker/UiDatePicker";

import { commonJs } from "common/common";
import { NOTIFY, FORMATS } from "common/consts";
import { MAP_ACTIONS } from "actions/MapActions";
import { MAP_CONTROLS_ACTIONS } from "actions/MapControlsActions";
import { API_Map } from "apihandler/Map";
import { dateFunction } from "common/datefunctions";

class DialogStop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {},
      showDialog: false,
      stops: null,
      selectedStop: [],
      selectedRoute: [],
      eta: dateFunction.getDate("today", "from", FORMATS.SQL_DATE),
      etd: dateFunction.getDate("today", "to", FORMATS.SQL_DATE),
    };
  }

  componentDidMount() {
    let self = this;
    const { childRef } = this.props;
    childRef(this);
    API_Map.getAllZones().then((res) => {
      if (res.Result === 200) {
        self.setState({
          stops: res.Response,
        });
      }
    });
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  toggleDialog = (rec) => {
    const { showDialog } = this.state;
    if (rec.id === undefined) {
      console.log("toggleDialog Add:  ", rec, !showDialog);
      // this.props.setPointEdit({
      //   pointId: "",
      // });
      if (rec.add) {
        // Adding a new point
        this.setState({
          showDialog: !showDialog,
          rec: {},
        });
      } else {
        // Close
        this.setState({
          showDialog: !showDialog,
        });
        // this.props.setPointLatLng({
        //   pointLatLng: {},
        // });
      }
    } else {
      // Edit -> Open
      this.setState({
        showDialog: !showDialog,
        rec: rec,
      });
      // this.props.setPointEdit({
      //   pointId: point.id,
      // });`
    }
  };

  saveRecord = () => {
    const { selectedStop, selectedRoute, eta, etd, record } = this.state;
    let self = this;

    if (!selectedStop.id) {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Please select stop name..!"
      );
      return false;
    } else if (!selectedRoute.id) {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Please select route name..!"
      );
      return false;
    }

    const args = {
      id: record.id || "",
      route: selectedRoute.id,
      stop: selectedStop.id,
      eta,
      etd,
    };
    console.log("saveRecord", args);

    this.setState({
      showDialog: false,
      record: {},
    });
  };

  onChangeStop = (event) => {
    const { options } = event.target;
    let values = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        values.push(options[i].value);
      }
    }
    this.setState({
      selectedStops: values,
    });
    console.log("onChangeStop: ", values);
  };

  handleEtaChange = (type, dt) => {
    let dtformat = "YYYY-MM-DD HH:mm";
    dt = dateFunction.getFormatedDate(dt, FORMATS.SQL_DATE, dtformat);
    this.setState({
      eta: dt,
    });
  };

  handleEtdChange = (type, dt) => {
    let dtformat = "YYYY-MM-DD HH:mm";
    dt = dateFunction.getFormatedDate(dt, FORMATS.SQL_DATE, dtformat);
    this.setState({
      etd: dt,
    });
  };

  onChangeRoute = (val) => {
    this.setState({
      selectedRoute: val,
    });
  };

  onChangeStop = (val) => {
    this.setState({
      selectedStop: val,
    });
  };

  render() {
    const {
      record,
      showDialog,
      stops,
      selectedStop,
      selectedRoute,
      eta,
      etd,
    } = this.state;

    return (
      <FleetDialog
        id="addStopPopup"
        open={showDialog}
        onCloseSettings={this.toggleDialog}
        title={record.id ? "Edit Stop" : "Add Stop"}
        maxWidth="xs"
        fullWidth={false}
        cancelButton={true}
        cancelAction={this.toggleDialog}
        saveButton={true}
        saveAction={this.saveRecord}
        saveBtnLabel="OK"
        disableBackdropClick={true}
        disableBackdrop={true}
        classNameBody="zonePopupBody"
        classNameWrapper="zonePopupWrapper"
        draggable
      >
        <div className="grid">
          <div className="row" style={{ marginBottom: 5 }}>
            <AutoComplete
              label={"Trip Name"}
              list={stops}
              id="stopTripName"
              style={{ marginBottom: 15, width: "100%" }}
              onChange={this.onChangeRoute}
              defaultValue={selectedRoute}
              multiple={false}
              size="small"
            />
          </div>
          <div className="row">
            <AutoComplete
              label={"Stop Name"}
              list={stops}
              id="etaStopName"
              style={{ marginBottom: 15, width: "100%" }}
              onChange={this.onChangeStop}
              defaultValue={selectedStop}
              multiple={false}
              size="small"
            />
          </div>
          <div className="row" style={{ marginTop: 4 }}>
            <UiDatePicker
              label="ETA"
              dt={eta}
              type="from"
              disablePast={false}
              disableFuture={false}
              disabled={false}
              datepicker={false}
              handleDateChange={this.handleEtaChange}
            />
          </div>
          <div className="row" style={{ marginTop: 4 }}>
            <UiDatePicker
              label="ETD"
              dt={etd}
              type="to"
              disablePast={false}
              disableFuture={false}
              disabled={false}
              datepicker={false}
              handleDateChange={this.handleEtdChange}
            />
          </div>
          <div className="row" style={{ marginTop: 8 }}>
            <TextField
              id="etaStopPosition"
              label="Stop Position"
              classes={{ root: "txtfield" }}
              autoFocus={true}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={record.position || ""}
            />
          </div>
        </div>
      </FleetDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleAddPoi: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_POI_CONTROL,
        values,
      });
    },
    setPointEdit: (values) => {
      dispatch({
        type: MAP_CONTROLS_ACTIONS.SET_POINT_EDIT,
        values,
      });
    },
    setPointLatLng: (values) => {
      dispatch({
        type: MAP_CONTROLS_ACTIONS.SET_POINT_LATLNG,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DialogStop));
