import React, { Component } from "react";
import { Paper, Tabs, Tab } from "@material-ui/core";

import FleetDialog from "components/ui/dialogbox/FleetDialog";
import EtaRoutes from "./routes/EtaRoutes";
import EtaTrips from "./trips/EtaTrips";
import EtaStops from "./stops/EtaStops";
import EtaVehicles from "./etavehicles/EtaVehicles";

import { LABELS } from "common/consts";

import "./eta.css";

class EtaPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      activeTab: 0,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  toggleEtaDialog = () => {
    const { showDialog } = this.state;
    this.setState({
      showDialog: !showDialog,
    });
  };

  handleChange = (event, value) => {
    this.setState({
      activeTab: value,
    });
  };

  render() {
    const { showDialog, activeTab } = this.state;
    console.log("showDialog: ", showDialog);
    return (
      <FleetDialog
        open={showDialog}
        onCloseSettings={this.toggleEtaDialog}
        title={LABELS.ETA_MASTER}
        maxWidth="md"
        fullWidth={true}
        saveButton={false}
        cancelButton={false}
        cancelAction={this.toggleEtaDialog}
        classNameWrapper="profileSettingsWrapper"
        draggable={false}
        disableBackdrop={true}
      >
        <Paper square className="etaTabContainer">
          <Tabs
            value={activeTab}
            classes={{
              root: "etaTab",
              indicator: "etaTabIndicator",
            }}
            scrollButtons="off"
            centered={false}
            onChange={this.handleChange}
          >
            <Tab
              label="Routes"
              classes={{
                root: "etatab",
              }}
              className={activeTab === 0 ? "activeTab" : ""}
              key={"etatab-" + 0}
              disabled={false}
            />
            <Tab
              label="Trips"
              classes={{
                root: "etatab",
              }}
              className={activeTab === 1 ? "activeTab" : ""}
              key={"etatab-" + 1}
              disabled={false}
            />
            <Tab
              label="Stops"
              classes={{
                root: "etatab",
              }}
              className={activeTab === 2 ? "activeTab" : ""}
              key={"etatab-" + 2}
              disabled={false}
            />
            <Tab
              label="Eta Vehicles"
              classes={{
                root: "etatab",
              }}
              className={activeTab === 3 ? "activeTab" : ""}
              key={"etatab-" + 3}
              disabled={false}
            />
          </Tabs>
          <div id="etaPopupWrapper">
            {activeTab === 0 && <EtaRoutes />}
            {activeTab === 1 && <EtaTrips />}
            {activeTab === 2 && <EtaStops />}
            {activeTab === 3 && <EtaVehicles />}
          </div>
        </Paper>
      </FleetDialog>
    );
  }
}

export default EtaPopup;
