import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  Edit,
  Add as AddIcon,
  Delete,
  Check as ActiveIcon,
  Close as NotActiveIcon,
  Description as ReportIcon,
} from "@material-ui/icons";
// React Grid
import { SortingState, IntegratedSorting } from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";
import { connect } from "react-redux";

import { API_Settings } from "apihandler/Settings";
import {
  COLORS,
  CONFIG,
  LABELS,
  PRIVILEGES,
  REPORT_NAMES,
} from "common/consts";
import { commonJs } from "common/common";

const getRowId = (row) => row.id;

var timer = null;

class VehiclesListGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      direction: "asc",
      recs: {
        rows: [],
        filteredRows: [],
        columns: [],
      },
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.loadRecords();
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  onFilterBy = (val = "") => {
    const { recs } = this.state;
    const { rows, columns } = recs;
    let filteredRows = [];
    if (val === "AllVehicles") {
      filteredRows = rows;
    } else if (val === "ActiveVehicles") {
      filteredRows = rows.filter((r) => {
        return r.activeValue === true;
      });
    } else if (val === "InactiveVehicles") {
      filteredRows = rows.filter((r) => {
        return r.activeValue === false;
      });
    }
    filteredRows = filteredRows.map((f, i) => {
      f.slno = i + 1;
      return f;
    });
    this.setState({
      recs: { rows, filteredRows, columns },
    });
  };

  onSearch = (searchValue) => {
    const { recs } = this.state;
    const { rows, filteredRows, columns } = recs;
    clearTimeout(timer);
    const self = this;
    timer = setTimeout(() => {
      let qry = searchValue.toUpperCase();
      let list = [];
      list = rows.filter((row) => {
        return row.vehicleName.toUpperCase().indexOf(qry) > -1;
      });
      list = list.map((l, i) => {
        l.slno = i + 1;
        return l;
      });
      self.setState({
        recs: { rows: rows, filteredRows: list, columns: columns },
      });
    }, 500);
  };

  generateOptions = (rec) => {
    const { hasAddVehicle } = this.props;
    return (
      <div className="options">
        {hasAddVehicle && (
          <Tooltip
            title="Edit"
            placement="bottom"
            onClick={() => this.props.togglePopupDialog(rec)}
          >
            <IconButton className="smallIcon">
              <Edit />
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  };

  generateReports = (imei) => {
    const { privileges, role } = this.props.loginReducer;
    const reports = [];
    if (commonJs.hasPrivilege(PRIVILEGES.REPORT_TRAVEL, role, privileges)) {
      reports.push({ url: "/reports?q=journey&", name: "Journey Report" });
    }
    if (commonJs.hasPrivilege(PRIVILEGES.REPORT_KM_SUMMARY, role, privileges)) {
      reports.push({
        url: "/current-month-report?",
        name: "Current Month Report",
      });
    }
    if (
      commonJs.hasPrivilege(PRIVILEGES.REPORT_ZONE_IN_OUT, role, privileges)
    ) {
      reports.push({ url: "/zoneinout?", name: "Zone In Out Report" });
    }
    if (commonJs.hasPrivilege(PRIVILEGES.REPORT_TEMP, role, privileges)) {
      reports.push({
        url: "/reports?q=temperature1&",
        name: "Temperature Report",
      });
    }
    if (commonJs.hasPrivilege(PRIVILEGES.REPORT_FUEL, role, privileges)) {
      reports.push({ url: "/fuelreport?", name: "Fuel Report" });
    }
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        {reports.map((report, i) => {
          return (
            <div className="options" key={"index-" + i}>
              <Tooltip
                title={report.name}
                placement="bottom"
                onClick={() => this.openReport(report.url, imei)}
              >
                <IconButton className="smallIcon">
                  <ReportIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        })}
      </div>
    );
  };

  openReport = (path = "", imei) => {
    window.open(CONFIG.REPORTS_URL + path + "v=" + imei, "_new");
  };

  loadReloadRecords = () => {
    this.setState({
      recs: {
        loading: false,
        sorting: "asc",
        columns: [],
        rows: [],
        filteredRows: [],
      },
    });
    this.loadRecords();
  };

  loadRecords = () => {
    const { uid } = this.props.loginReducer;
    const self = this;
    const args = {
      managerId: uid,
    };
    API_Settings.getAllUserVehicles(args).then((res) => {
      if (res.Result === 200) {
        let cols = [
          { name: "slno", title: "Sl No." },
          { name: "vehicleName", title: "Vehicle Name" },
          { name: "imei", title: "IMEI" },
          { name: "active", title: "Status" },
          { name: "activeTill", title: "Active Till" },
          { name: "options", title: "Options" },
          { name: "reports", title: "Reports" },
        ];
        let rows = [];
        res = res.Response;
        for (var i in res) {
          rows.push({
            id: res[i].imei,
            slno: Number(i) + 1,
            vehicleName: res[i].vehicleName,
            imei: res[i].imei,
            activeValue: res[i].active,
            active: res[i].active ? (
              <Tooltip title={LABELS.ACTIVE} placement="bottom">
                <ActiveIcon
                  style={{ fontSize: 18, color: COLORS.DARK_GREEN }}
                />
              </Tooltip>
            ) : (
              <Tooltip title={LABELS.INACTIVE} placement="bottom">
                <NotActiveIcon style={{ fontSize: 18, color: COLORS.RED }} />
              </Tooltip>
            ),
            activeTill: res[i].activeTill,
            options: this.generateOptions(res[i]),
            reports: this.generateReports(res[i].imei),
          });
        }
        self.setState({
          recs: {
            loading: false,
            sorting: "asc",
            columns: cols,
            rows: rows,
            filteredRows: [],
          },
        });
      }
    });
  };

  render() {
    const { rows, columns, filteredRows } = this.state.recs;
    const tableColumnExtensions = [
      { columnName: "slno", width: 80, align: "center" },
      { columnName: "imei", width: 120, align: "left" },
      { columnName: "vehicleName", width: 180, align: "left" },
      { columnName: "active", width: 100, align: "center" },
      { columnName: "activeTill", width: 100, align: "left" },
      { columnName: "options", width: 100, align: "center" },
      { columnName: "reports", align: "left" },
    ];
    const gridHeight = window.innerHeight / 1.5;
    console.log("filteredRows: ", rows, filteredRows);
    return columns.length > 0 ? (
      <div id="vehiclesListGrid" className="dataRGrid">
        <Grid
          rows={filteredRows.length > 0 ? filteredRows : rows}
          columns={columns}
          getRowId={getRowId}
        >
          <SortingState
            defaultSorting={[{ columnName: "name", direction: "asc" }]}
            columnSortingEnabled={true}
            //   onSortingChange={this.changeZoneSort}
            columnExtensions={[
              { columnName: "view", sortingEnabled: false },
              { columnName: "options", sortingEnabled: false },
              { columnName: "reports", sortingEnabled: false },
            ]}
          />
          <IntegratedSorting />
          <VirtualTable
            columnExtensions={tableColumnExtensions}
            height={gridHeight}
          />
          <TableHeaderRow showSortingControls />
        </Grid>
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: gridHeight,
          alignItems: "center",
        }}
      >
        <p style={{ color: "#a1a1a1", fontSize: 14 }}>Loading...</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VehiclesListGrid);
