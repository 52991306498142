import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import jQuery from "jquery";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.flash";
import "pdfmake/build/vfs_fonts";
import "pdfmake/build/pdfmake";

import FleetDialog from "components/ui/dialogbox/FleetDialog";

import { DEFAULTS } from "common/consts";
import { commonJs } from "common/common";
import { COMMON_ACTIONS } from "actions/CommonActions";
import { REPORT_ACTIONS } from "actions/ReportActions";
import { API_Map } from "apihandler/Map";

import "./css/styles.css";
import { API_CPanel } from "apihandler/CPanel";

var $ = jQuery;

class DashboardPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalLen: 0,
      loading: true,
    };
  }

  componentDidMount() {
    const { title } = this.props;
    if (title === "Total Devices") {
      this.loadReport();
    } else if (title === "Active Devices") {
      this.loadReport();
    } else if (title === "Inactive Devices") {
      this.loadReport();
    } else if (title === "Total Users") {
      this.loadUsersReport();
    } else if (title === "Total Companies") {
      this.loadCompanyReport();
    }
  }

  loadReport = () => {
    let self = this;
    let args = {};
    API_Map.getAllVehicles(args)
      .then((res) => {
        console.log("loadReport: ", res);
        let cols = [
          { title: "Sl No." },
          { title: "Vehicle No." },
          { title: "IMEI" },
          { title: "Status" },
        ];
        let rows = [];
        for (let i in res) {
          rows.push([Number(i) + 1, res[i].name, res[i].imei, "Active"]);
        }
        console.log("Vehicleslength", rows.length);
        // console.log("loadReport: rows:", cols, rows);

        // Reset Grid
        if ($.fn.dataTable.isDataTable("#reportGrid")) {
          let table = $("#reportGrid").DataTable();
          table.destroy();
        }
        $("#reportGrid").empty();

        let title = this.props.title;
        $("#reportGrid").DataTable({
          dom: "Bfrtip",
          buttons: [
            {
              extend: "copy",
              className: "reportBtn",
              title: title,
            },
            {
              extend: "pdf",
              className: "reportBtn",
              title: title,
            },
            {
              extend: "excel",
              className: "reportBtn",
              title: title,
            },
            {
              extend: "csv",
              className: "reportBtn",
              title: title,
              text: "CSV",
            },
            {
              extend: "print",
              className: "reportBtn",
              title: title,
              customize: (win) => {
                commonJs.getExportPrintCss(win);
              },
            },
          ],
          paging: false,
          scrollY: DEFAULTS.REPORT_GRID_HEIGHT,
          columns: cols,
          data: rows,
        });
        self.setState({ loading: false, totalLen: rows.length });
      })
      .catch((err) => {
        console.log("getReport: err:", err);
      });
  };

  loadUsersReport = () => {
    let self = this;
    let args = {};
    API_CPanel.getUsersDropdown(args)
      .then((res) => {
        console.log("loadUsersReport: ", res);
        if (res.Result === 200) {
          let cols = [
            { title: "Sl No." },
            { title: "Id" },
            { title: "UserName" },
            { title: "Status" },
          ];
          let rows = [];
          res = res.Response;
          for (let i in res) {
            rows.push([Number(i) + 1, res[i].id, res[i].userName, "Active"]);
          }
          console.log("Userslength", rows.length);
          // console.log("loadCompanyReport: rows:", cols, rows);

          // Reset Grid
          if ($.fn.dataTable.isDataTable("#reportGrid")) {
            let table = $("#reportGrid").DataTable();
            table.destroy();
          }
          $("#reportGrid").empty();

          let title = this.props.title;
          $("#reportGrid").DataTable({
            dom: "Bfrtip",
            buttons: [
              {
                extend: "copy",
                className: "reportBtn",
                title: title,
              },
              {
                extend: "pdf",
                className: "reportBtn",
                title: title,
              },
              {
                extend: "excel",
                className: "reportBtn",
                title: title,
              },
              {
                extend: "csv",
                className: "reportBtn",
                title: title,
                text: "CSV",
              },
              {
                extend: "print",
                className: "reportBtn",
                title: title,
                customize: (win) => {
                  commonJs.getExportPrintCss(win);
                },
              },
            ],
            paging: false,
            scrollY: DEFAULTS.REPORT_GRID_HEIGHT,
            columns: cols,
            data: rows,
          });
          self.setState({ loading: false, totalLen: rows.length });
        }
      })
      .catch((err) => {
        console.log("getReport: err:", err);
      });
  };

  loadCompanyReport = () => {
    let self = this;
    let args = {};
    API_CPanel.getCompanies(args)
      .then((res) => {
        console.log("loadCompanyReport: ", res);
        if (res.Result === 200) {
          let cols = [
            { title: "Sl No." },
            { title: "Id" },
            { title: "Company Name" },
            { title: "Total Users" },
            { title: "Total Vehicles" },
            { title: "Status" },
          ];
          let rows = [];
          res = res.Response;
          for (let i in res) {
            rows.push([
              Number(i) + 1,
              res[i].companyId,
              res[i].companyName,
              res[i].totalUsers,
              res[i].totalVehicles,
              "Active",
            ]);
          }
          console.log("Companylength", rows.length);
          // console.log("loadCompanyReport: rows:", cols, rows);

          // Reset Grid
          if ($.fn.dataTable.isDataTable("#reportGrid")) {
            let table = $("#reportGrid").DataTable();
            table.destroy();
          }
          $("#reportGrid").empty();

          let title = this.props.title;
          $("#reportGrid").DataTable({
            dom: "Bfrtip",
            buttons: [
              {
                extend: "copy",
                className: "reportBtn",
                title: title,
              },
              {
                extend: "pdf",
                className: "reportBtn",
                title: title,
              },
              {
                extend: "excel",
                className: "reportBtn",
                title: title,
              },
              {
                extend: "csv",
                className: "reportBtn",
                title: title,
                text: "CSV",
              },
              {
                extend: "print",
                className: "reportBtn",
                title: title,
                customize: (win) => {
                  commonJs.getExportPrintCss(win);
                },
              },
            ],
            paging: false,
            scrollY: DEFAULTS.REPORT_GRID_HEIGHT,
            columns: cols,
            data: rows,
          });
          self.setState({ loading: false, totalLen: rows.length });
        }
      })
      .catch((err) => {
        console.log("getReport: err:", err);
      });
  };

  render() {
    const { loading } = this.state;
    const { togglePopup, title } = this.props;
    const { totalLen } = this.state;
    console.log("totalLen", totalLen);
    return (
      <FleetDialog
        id="dashboardDialog"
        title={title + "  " + "(" + totalLen + ")"}
        open={true}
        onCloseSettings={() => togglePopup(false)}
        maxWidth="md"
        fullWidth={true}
        cancelButton={false}
        cancelAction={() => togglePopup(false)}
        saveButton={false}
        saveBtnLabel="OK"
        disableBackdropClick={true}
        disableBackdrop={true}
        classNameBody=""
        classNameWrapper=""
        draggable
      >
        {loading && <CircularProgress className={"primary miniloader"} />}
        <table
          id="reportGrid"
          className="mdl-data-table width100 paginated"
          cellSpacing="0"
          cellPadding="3"
        />
      </FleetDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setVehicles: (values) => {
      dispatch({
        type: COMMON_ACTIONS.SET_VEHICLES,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: REPORT_ACTIONS.SET_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DashboardPopup));
