import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import FleetDropDown from "components/ui/dropdown/FleetDropDown";
import Btn from "@components/ui/button/Btn";

import { LABELS, NOTIFY, SUCCESS_MSG, ERRORS } from "common/consts";
import { API_Settings } from "apihandler/Settings";
import { commonJs } from "common/common";
import { dateFunction } from "common/datefunctions";
import { PAGE_ACTIONS } from "actions/PageActions";

import "../../../vehiclelist/vehiclelist.css";

class FuelConsumption extends Component {
  constructor(props) {
    super(props);
    const fcrTxt = this.props.vehicleSettings.fcr.trim();
    let fcr = {
      measurement: "l100km",
      cost: "0",
      summer: "0",
      winter: "0",
      winter_sm: "12",
      winter_sd: "1",
      winter_em: "3",
      winter_ed: "1",
    };
    if (fcrTxt != "") {
      fcr = JSON.parse(fcrTxt);
    }
    this.state = {
      fcr,
      measurement: fcr.measurement,
      maxDaysFrom: 31,
      maxDaysTo: 31,
      months: [
        { id: "1", text: "January" },
        { id: "2", text: "February" },
        { id: "3", text: "March" },
        { id: "4", text: "April" },
        { id: "5", text: "May" },
        { id: "6", text: "June" },
        { id: "7", text: "July" },
        { id: "8", text: "August" },
        { id: "9", text: "September" },
        { id: "10", text: "October" },
        { id: "11", text: "November" },
        { id: "12", text: "December" },
      ],
      winterFromMonth: fcr.winter_sm || "",
      winterToMonth: fcr.winter_em || "",
    };
  }

  onChangeMeasure = (val) => {
    this.setState({
      measurement: val,
    });
  };

  onChangeWinterFrom = (val) => {
    this.setState({
      winterFromMonth: val,
    });
  };

  onChangeWinterTo = (val) => {
    this.setState({
      winterToMonth: val,
    });
  };

  onChangeFromMonth = (val) => {
    let maxDays = dateFunction.getDaysOfMonth(val);
    this.setState({
      maxDaysFrom: maxDays,
    });
  };

  onChangeToMonth = (val) => {
    let maxDays = dateFunction.getDaysOfMonth(val);
    this.setState({
      maxDaysTo: maxDays,
    });
  };

  saveSettings = () => {
    const { imei } = this.props;
    const measurement = document.getElementById("fuelConsMeasure").value.trim();
    const fuelTankCapacity = document
      .getElementById("fuelConsTankCapacity")
      .value.trim();
    const voltage = document.getElementById("fuelConsVoltage").value.trim();
    const costForLitre = document.getElementById("fuelConsCost").value.trim();
    const summerRate = document
      .getElementById("fuelConsSummerRate")
      .value.trim();
    const winterRate = document
      .getElementById("fuelConsWinterRate")
      .value.trim();
    const winterFromMonth = document
      .getElementById("fuelConsFromMonth")
      .value.trim();
    const winterFromDay = document
      .getElementById("fuelConsFromDay")
      .value.trim();
    const winterToMonth = document
      .getElementById("fuelConsToMonth")
      .value.trim();
    const winterToDay = document.getElementById("fuelConsToDay").value.trim();

    let args = {
      imei,
      measurement,
      fuelTankCapacity,
      voltage,
      costForLitre,
      summerRate,
      winterRate,
      winterFromMonth,
      winterFromDay,
      winterToMonth,
      winterToDay,
    };
    const self = this;
    self.props.setLoading({
      loadingPopover: true,
    });
    API_Settings.editVehicleFuelConsumptionDetails(args)
      .then((res) => {
        self.props.setLoading({
          loadingPopover: false,
        });
        if (res.Result === 200) {
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            SUCCESS_MSG.RECORD_SAVED,
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
          self.props.loadVehicles();
        } else {
          commonJs.showNotify(
            self.props,
            NOTIFY.ERROR,
            ERRORS.RECORD_NOT_SAVED,
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
        }
      })
      .catch((err) => {
        self.props.setLoading({
          loadingPopover: false,
        });
        console.log(err);
      });
  };

  render() {
    const {
      measurement,
      months,
      maxDaysFrom,
      maxDaysTo,
      winterFromMonth,
      winterToMonth,
    } = this.state;
    const { closePopup } = this.props;
    let daysFrom = [];
    for (let i = 1; i <= maxDaysFrom; i++) {
      daysFrom.push({
        id: i,
        text: i,
      });
    }

    let daysTo = [];
    for (let i = 1; i <= maxDaysTo; i++) {
      daysTo.push({
        id: i,
        text: i,
      });
    }
    return (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <FleetDropDown
              label="Measurement"
              list={[
                { id: "l100km", text: "l/100km" },
                { id: "mpg", text: "MPG" },
              ]}
              selectedValue={measurement}
              noemptySelection={true}
              id="fuelConsMeasure"
              onChange={this.onChangeMeasure}
            />
          </div>
          <div className="col">
            <TextField
              id="fuelConsTankCapacity"
              label="Fuel Tank Capacity (in Ltrs.)"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col">
            <TextField
              id="fuelConsVoltage"
              label="Voltage"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <TextField
              id="fuelConsCost"
              label={
                measurement === "l100km"
                  ? "Cost for 1 liter"
                  : "Cost for 1 gallon"
              }
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col">
            <TextField
              id="fuelConsSummerRate"
              label={
                measurement === "l100km"
                  ? "Summer rate (kilometers per 1 liter)"
                  : "Summer rate (miles per 1 gallon)"
              }
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col">
            <TextField
              id="fuelConsWinterRate"
              label={
                measurement === "l100km"
                  ? "Winter rate (kilometers per 1 liter)"
                  : "Winter rate (miles per 1 gallon)"
              }
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label className="label">Winter from (month, day):</label>
            <div style={{ width: "60%", float: "left", marginTop: 12 }}>
              <FleetDropDown
                label="Month"
                list={months}
                noemptySelection={false}
                id="fuelConsFromMonth"
                selectedValue={winterFromMonth}
                onChange={this.onChangeWinterFrom}
              />
            </div>
            <div
              style={{
                width: "35%",
                float: "left",
                marginTop: 12,
                paddingLeft: "5%",
              }}
            >
              <TextField
                id="fuelConsFromDay"
                label={"Day"}
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue=""
              />
            </div>
          </div>
          <div className="col">
            <label className="label" style={{ display: "flex" }}>
              Winter to (month, day):
            </label>
            <div style={{ width: "60%", float: "left", marginTop: 17 }}>
              <FleetDropDown
                label="Month"
                list={months}
                noemptySelection={false}
                id="fuelConsToMonth"
                selectedValue={winterToMonth}
                onChange={this.onChangeWinterTo}
              />
            </div>
            <div
              style={{
                width: "35%",
                float: "left",
                marginTop: 16,
                paddingLeft: "5%",
              }}
            >
              <TextField
                id="fuelConsToDay"
                label={"Day"}
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue=""
              />
            </div>
          </div>
          <div className="col"></div>
        </div>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              width: "30%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Btn className="settingsBtn saveBtn" onClick={this.saveSettings}>
              {"Save"}
            </Btn>
            <Btn
              className="settingsBtn closeBtn"
              onClick={() => closePopup(false)}
            >
              {"Close"}
            </Btn>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_POPOVER_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(FuelConsumption));
