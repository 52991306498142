import React, { Component } from "react";
import { TextField, Switch, FormControlLabel } from "@material-ui/core";
import ColorPicker from "material-ui-color-picker";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import FleetDialog from "components/ui/dialogbox/FleetDialog";

import { commonJs } from "common/common";
import { NOTIFY, COLORS, SUCCESS_MSG, ERRORS, LABELS } from "common/consts";
import { MAP_ACTIONS } from "actions/MapActions";
import { MAP_CONTROLS_ACTIONS } from "actions/MapControlsActions";
import { API_Map } from "apihandler/Map";
import { PAGE_ACTIONS } from "actions/PageActions";

class DialogRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      route: {
        id: "",
        color: COLORS.PRIMARY,
        routeVisible: true,
        nameVisible: true,
      },
      showDialog: false,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  closePolyline = () => {
    let e = document.createEvent("Event");
    e.initEvent("click", true, true);
    let cb = document.querySelector(
      'ul.leaflet-draw-actions li>a[title="Cancel drawing"]'
    );
    if (cb) {
      return !cb.dispatchEvent(e);
    } else {
      cb = document.querySelector(
        'ul.leaflet-draw-actions.leaflet-draw-actions-top>li>a[title="Cancel editing, discards all changes"]'
      );
      console.log(cb);
      if (cb) {
        return !cb.dispatchEvent(e);
      }
    }
    this.clearLayers();
    // this.props.setRouteLatLng({
    //   routeLatLng: {},
    // });
  };

  toggleAddRouteDialog = (route) => {
    const { showDialog } = this.state;
    if (route.id === undefined) {
      console.log("toggleroutePointDialog Add:  ", route, !showDialog);
      this.props.setRouteEdit({
        routeId: "",
      });
      if (route.add) {
        // Adding a new route
        this.setState({
          showDialog: !showDialog,
          route: {
            id: "",
            color: COLORS.PRIMARY,
            routeVisible: true,
            nameVisible: true,
          },
        });
        this.createPolygonLine();
      } else {
        // Close
        this.setState({
          showDialog: !showDialog,
        });
        this.closePolyline();
        this.props.setRouteLatLng({
          routeLatLng: {},
        });
      }
    } else {
      // Edit -> Open
      this.setState({
        showDialog: !showDialog,
        route: route,
      });
      this.props.setRouteEdit({
        routeId: route.id,
      });
      const points = route.points.map((r) => {
        return [r.latitude, r.longitude];
      });
      console.log("Route Edit Popup:", points);
      this.props.setRouteLatLng({
        routeLatLng: points,
      });
      let self = this;
      setTimeout(() => {
        self.editPolygonLine();
      }, 1000);
    }
  };

  saveRoute = () => {
    let e = document.createEvent("Event");
    e.initEvent("click", true, true);
    let cb = document.querySelector(
      'ul.leaflet-draw-actions li>a[title="Save changes"]'
    );
    if (cb) {
      cb.dispatchEvent(e);
    }
    let e2 = document.createEvent("Event");
    e2.initEvent("click", true, true);
    cb = document.querySelector(
      'ul.leaflet-draw-actions li>a[title="Finish drawing"]'
    );
    if (cb) {
      cb.dispatchEvent(e2);
    }

    const routeName = document.getElementById("routeName").value;
    const routeColor = document.querySelector(".txtfield.colorpicker input")
      .value;
    const routeRange = document.getElementById("routeRange").value;
    const routeVisible = document.getElementById("routeVisible").checked;
    const nameVisible = document.getElementById("nameVisible").checked;
    let latLngs = document.querySelector("div#addRoute").getAttribute("route");

    const { routeId } = this.props.mapControlsReducer;

    var self = this;
    if (routeName === "") {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Route name should not be empty..!"
      );
      return false;
    }
    if (routeId === "") {
      if (latLngs === "" || !latLngs) {
        commonJs.showNotify(
          self.props,
          NOTIFY.WARNING,
          "Please draw the route or Double click to finish Route line..!"
        );
        return false;
      }
    }

    // If Add -> LatLngs Should be drawn
    if (latLngs !== "") {
      latLngs = JSON.parse(latLngs);
    }

    console.log("latLngs: ", latLngs);

    let points = latLngs.map((l) => {
      return { latitude: l.lat, longitude: l.lng };
    });

    // Point on map -> Lat & Lng shouldn't be empty
    const args = {
      id: Number(routeId),
      name: routeName,
      color: routeColor,
      deviation: routeRange,
      nameVisible: nameVisible.toString(),
      routeVisible: routeVisible.toString(),
      points: points,
    };

    console.log("Add Route: ", args);

    API_Map.saveRoute(args)
      .then((res) => {
        if (res.Result === 200) {
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            SUCCESS_MSG.ROUTE_SAVED_SUCCESSFULLY,
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
          self.toggleAddRouteDialog(args.id);
          self.props.toggleLoading({
            loadingPopover: false,
          });
          self.props.reloadRoutes();
        } else {
          console.log("Route Creation Error: ", res.Reason);
          commonJs.showNotify(
            self.props,
            NOTIFY.ERROR,
            ERRORS.ROUTE_NOT_SAVED,
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
        }
      })
      .catch((err) => {
        console.log("Route Creation Error: ", err);
        commonJs.showNotify(
          self.props,
          NOTIFY.ERROR,
          ERRORS.ROUTE_NOT_SAVED,
          <button className="snackbar-btn">{LABELS.DISMISS}</button>
        );
      });

    return;
  };

  setColor = (color) => {
    console.log("setColor:", color);
    document.getElementById("routeColor").style.background = color;
    document.getElementById("routeColor").value = color.toString();
    let { route } = this.state;
    route.color = color;
    this.setState({ route });
  };

  createPolygonLine = () => {
    let e = document.createEvent("Event");
    e.initEvent("click", true, true);
    let cb = document.getElementsByClassName("leaflet-draw-draw-polyline");
    if (cb) {
      return !cb[0].dispatchEvent(e);
    }
  };

  editPolygonLine = () => {
    let e = document.createEvent("Event");
    e.initEvent("click", true, true);
    let cb = document.getElementsByClassName("leaflet-draw-edit-edit");
    if (cb) {
      return !cb[0].dispatchEvent(e);
    }
  };

  closeDrawPolyLine = () => {
    let e = document.createEvent("Event");
    e.initEvent("click", true, true);
    let cb = document.querySelector(
      'ul.leaflet-draw-actions li>a[title="Cancel drawing"]'
    );
    if (cb) {
      return !cb.dispatchEvent(e);
    } else {
      cb = document.querySelector(
        'ul.leaflet-draw-actions.leaflet-draw-actions-top>li>a[title="Cancel editing, discards all changes"]'
      );
      console.log(cb);
      if (cb) {
        return !cb.dispatchEvent(e);
      }
    }
    this.clearLayers();
    // this.props.setZoneEdit({ zoneVertices: [] });
  };

  clearLayers = () => {
    let e = document.createEvent("Event");
    e.initEvent("click", true, true);
    let cb = document.querySelector(".leaflet-draw-edit-remove");
    if (cb) {
      cb.dispatchEvent(e);
    }

    let e2 = document.createEvent("Event");
    e2.initEvent("click", true, true);
    cb = document.querySelector(
      "ul.leaflet-draw-actions.leaflet-draw-actions-bottom li:last-child>a"
    );
    if (cb) {
      cb.dispatchEvent(e2);
    }

    this.props.setRouteLatLng({
      routeLatLng: [],
    });
  };

  render() {
    const { route, showDialog } = this.state;
    console.log("route: ", route);
    return (
      <FleetDialog
        id="addRoute"
        open={showDialog}
        onCloseSettings={this.toggleAddRouteDialog}
        title={route.id !== "" ? "Edit Route" : "Add Route"}
        maxWidth="xs"
        fullWidth={false}
        cancelButton={true}
        cancelAction={this.toggleAddRouteDialog}
        saveButton={true}
        saveAction={this.saveRoute}
        saveBtnLabel="OK"
        disableBackdropClick={true}
        disableBackdrop={true}
        classNameBody="zonePopupBody"
        classNameWrapper="zonePopupWrapper"
        draggable
      >
        <div className="grid">
          <div className="row">
            <TextField
              id="routeName"
              label="Name"
              classes={{ root: "txtfield" }}
              autoFocus={true}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={route.name || ""}
            />
          </div>
          <div className="row" id="routeColorPicker">
            <ColorPicker
              id="routeColor"
              name="color"
              defaultValue={route.color || COLORS.PRIMARY}
              value={route.color || COLORS.PRIMARY}
              onChange={(color) => this.setColor(color)}
              classes={{ root: "txtfield colorpicker" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="row">
            <TextField
              id="routeRange"
              label="Deviation (kms)"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={route.name || ""}
            />
          </div>
          <div
            className="row"
            style={{ marginTop: -10, justifyContent: "flex-start" }}
          >
            <FormControlLabel
              control={
                <Switch
                  classes={{
                    thumb: "switchThumb",
                    track: "switchTrack",
                    root: "switchRoot",
                  }}
                  id="routeVisible"
                  defaultChecked={
                    route.routeVisible ? JSON.parse(route.routeVisible) : true
                  }
                />
              }
              label="Route Visible"
              className="switch"
            />
            <FormControlLabel
              control={
                <Switch
                  classes={{
                    thumb: "switchThumb",
                    track: "switchTrack",
                    root: "switchRoot",
                  }}
                  id="nameVisible"
                  defaultChecked={
                    route.routeNameVisible
                      ? JSON.parse(route.routeNameVisible)
                      : true
                  }
                />
              }
              label="Name Visible"
              className="switch"
            />
          </div>
        </div>
      </FleetDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
    mapControlsReducer: state.mapControlsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleAddPoi: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_POI_CONTROL,
        values,
      });
    },
    setRouteEdit: (values) => {
      dispatch({
        type: MAP_CONTROLS_ACTIONS.SET_ROUTE_EDIT,
        values,
      });
    },
    setRouteLatLng: (values) => {
      dispatch({
        type: MAP_CONTROLS_ACTIONS.SET_ROUTE_LATLNG,
        values,
      });
    },
    toggleLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_POPOVER_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DialogRoute));
