import React, { Component } from "react";
import { Paper, Tabs, Tab, IconButton } from "@material-ui/core";
import { ArrowLeft } from "@material-ui/icons";
import { connect } from "react-redux";

import VehiclesTab from "./vehicles";
import PoiTab from "./poitab/PoiTab";
import TrackTab from "./tracktab/TrackTab";
import AlertsTab from "./alertstab/AlertsTab";

import { MAP_ACTIONS } from "actions/MapActions";
import { LOCAL_STORAGE, CONFIG, PRIVILEGES, DEFAULTS } from "common/consts";
import { commonJs } from "common/common";
import { PAGE_ACTIONS } from "actions/PageActions";

import "./maptab.css";

var timer = null;
class MapPageTabs extends Component {
  constructor(props) {
    super(props);
    let activeTab = DEFAULTS.MAP_TAB;
    let tabCache = localStorage.getItem(LOCAL_STORAGE.MAP_TAB);
    if (tabCache != null) {
      tabCache = JSON.parse(tabCache);
      if (tabCache.activeTab) {
        activeTab = tabCache.activeTab;
      }
    }
    this.state = {
      activeTab: activeTab,
      cityToggle: false,
      searchVehicle: "",
      vehicleList: this.props.vehicleList,
      sortby: "vehicle asc",
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  handleSortChange = (event) => {
    const type = event.target.value.split(" ");
    if (type[0] === "vehicle") {
      var vehicles = this.props.mapReducer.tmpVehicles;
      if (this.props.mapReducer.filteredVehicles.length > 0) {
        vehicles = this.props.mapReducer.filteredVehicles;
      }
      vehicles.sort(function (a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (type[1] === "asc") {
          if (nameA < nameB)
            //sort string ascending
            return -1;
          if (nameA > nameB) return 1;
        } else {
          if (nameA > nameB)
            //sort string descending
            return -1;
          if (nameA < nameB) return 1;
        }
        return 0; //default return value (no sorting)
      });
      this.props.setFilteredVehicles({
        filteredVehicles: vehicles,
      });
    }
    this.setState({ sortby: event.target.value });
  };

  onTabChange = (event, value) => {
    let tabJson = localStorage[LOCAL_STORAGE.MAP_TAB];
    if (tabJson === undefined) {
      tabJson = {
        activeTab: value,
      };
    } else {
      tabJson = JSON.parse(tabJson);
      tabJson.activeTab = value;
    }
    localStorage[LOCAL_STORAGE.MAP_TAB] = JSON.stringify(tabJson);
    this.setState({ activeTab: value });

    if (value !== 3) {
      //TODO: Not Track Page, -> Hide Bottom Bar (ZIndex Issue)
      this.props.toggleBottomBar({
        trackBottomBar: false,
      });
    }
  };

  toggleCityPanel = () => {
    this.setState({
      cityToggle: !this.state.cityToggle,
    });
  };

  onVehicleSearch = (e) => {
    clearTimeout(timer);
    var self = this;
    const searchValue = e.target.value.trim();
    timer = setTimeout(() => {
      let qry = searchValue.toUpperCase();
      var list = self.props.mapReducer.tmpVehicles.filter((arr) => {
        return arr.name.toUpperCase().indexOf(qry) > -1;
      });
      if (list.length === 0 && qry !== "") {
        list.push(0);
      }
      self.props.setFilteredVehicles({
        filteredVehicles: list,
      });
    }, 500);
  };

  switchTab = (tabIndex, page = "") => {
    const self = this;
    this.setState(
      {
        activeTab: tabIndex,
      },
      () => {
        if (page === "track") {
          console.log("self: ", self, tabIndex, page);
          self._trackTab.showTrack();
        }
      }
    );
  };

  toggleMenu = () => {
    this.props.onMenuToggle("leftMapMenu");
  };

  render() {
    const { activeTab } = this.state;
    const { shareVehicle } = this.props;
    const { privileges, role } = this.props.loginReducer;
    let showAlerts = commonJs.hasPrivilege(PRIVILEGES.ALERTS, role, privileges);
    let showTrack = commonJs.hasPrivilege(PRIVILEGES.TRACK, role, privileges);
    let showPoi = commonJs.hasPrivilege(PRIVILEGES.POI, role, privileges);
    return (
      <div className="mapTabWrapper">
        <h4 className="mapTabTitle">
          {CONFIG.SITE_NAME} <small>v{CONFIG.VERSION}</small>
          <div id="leftbarHandler">
            <IconButton
              classes={{ root: "menucolor" }}
              className={"active"}
              onClick={this.toggleMenu}
            >
              <ArrowLeft />
            </IconButton>
          </div>
        </h4>
        <Paper square className="mapTabContainer">
          <Tabs
            value={activeTab}
            classes={{
              root: "mapTabs",
              indicator: "fleettabindicator",
            }}
            scrollButtons="off"
            centered={false}
            onChange={this.onTabChange}
          >
            <Tab
              label="Vehicles"
              value="vehicles"
              classes={{
                root: "maptab",
              }}
              className={activeTab === "vehicles" ? "activeTab" : ""}
              key={"maintab-" + 0}
              disabled={false}
            />

            {showPoi && (
              <Tab
                label="POI"
                value="poi"
                classes={{
                  root: "maptab",
                }}
                className={activeTab === "poi" ? "activeTab" : ""}
                key={"maintab-" + 1}
                disabled={false}
              />
            )}

            {showTrack && (
              <Tab
                label="Playback"
                value="track"
                classes={{
                  root: "maptab",
                }}
                className={activeTab === "track" ? "activeTab" : ""}
                key={"maintab-" + 2}
                disabled={false}
              />
            )}

            {showAlerts && (
              <Tab
                label="Alerts"
                value="alerts"
                classes={{
                  root: "maptab",
                  // label: "mapTabLabel"
                }}
                className={activeTab === "alerts" ? "activeTab" : ""}
                key={"maintab-" + 3}
                disabled={false}
              />
            )}
          </Tabs>

          {activeTab === "vehicles" && (
            <VehiclesTab
              shareVehicle={shareVehicle}
              updateMapRegion={this.props.updateMapRegion}
              switchTab={this.switchTab}
            />
          )}

          {showPoi && activeTab === "poi" && (
            <div className="tabContent">
              <PoiTab
                childRef={(ref) => (this._poiTab = ref)}
                zoneFitBounds={this.props.zoneFitBounds}
                updateMapRegion={this.props.updateMapRegion}
              />
            </div>
          )}

          {showTrack && activeTab === "track" && (
            <div className="tabContent">
              <TrackTab
                childRef={(ref) => (this._trackTab = ref)}
                updateMapRegion={this.props.updateMapRegion}
                trackFitBounds={this.props.trackFitBounds}
              />
            </div>
          )}

          {showAlerts && activeTab === "alerts" && (
            <AlertsTab
              childRef={(ref) => (this._alertsTab = ref)}
              updateMapRegion={this.props.updateMapRegion}
            />
          )}
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFilteredVehicles: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_FILTERED_VEHICLES,
        values,
      });
    },
    toggleBottomBar: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_TRACK_BOTTOM_BAR,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapPageTabs);
