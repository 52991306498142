import { MAP_ACTIONS } from "../actions/MapActions";
import { DEFAULTS, LOCAL_STORAGE } from "../common/consts";
import { commonJs } from "../common/common";

let initialState = {
  zoom: DEFAULTS.DEFAULT_ZOOM,
  initialLat: DEFAULTS.DEFAULT_LAT,
  initialLng: DEFAULTS.DEFAULT_LNG,
  mapType: DEFAULTS.DEFAULT_MAP,
  filteredVehicles: [],
  tmpVehicles: [], // tmpStorage
  signalFilter: DEFAULTS.DEFAULT_VEHICLE_STATUS,
  loadStatusFilter: DEFAULTS.DEFAULT_VEHICLE_LOAD_STATUS,
  selectedVehicle: "", // Should be imei
  fitmap: false,
  locateMarkerDialog: false,
  scale: true,
  mapLabels: true,
  mapVehicles: true,
  mapPoints: true,
  mapRoutes: true,
  mapZones: true,
  mapClusters: true,
  mapTraffic: false,
  dataPoints: false,
  markerAnimation: false,
  liveMarkerAnimation: false,
  userGroups: {},
  filteredGroups: [],
};

// Get Map Cache
var mapCache = commonJs.getItemFromLocal(LOCAL_STORAGE.MAP);
if (mapCache !== null) {
  if (mapCache.initialLat !== undefined) {
    initialState.initialLat = mapCache.initialLat;
  }
  if (mapCache.initialLng !== undefined) {
    initialState.initialLng = mapCache.initialLng;
  }
  if (mapCache.zoom !== undefined) {
    initialState.zoom = mapCache.zoom;
  }
  if (mapCache.mapType !== undefined) {
    initialState.mapType = mapCache.mapType;
  }
}

// Get Map Settings Cache
var mapSettings = commonJs.getItemFromLocal(LOCAL_STORAGE.MAP_SETTINGS);
if (mapSettings !== null) {
  if (mapSettings.scale !== undefined) {
    initialState.scale = mapSettings.scale;
  }
  if (mapSettings.mapLabels !== undefined) {
    initialState.mapLabels = mapSettings.mapLabels;
  }
  if (mapSettings.mapVehicles !== undefined) {
    initialState.mapVehicles = mapSettings.mapVehicles;
  }
  if (mapSettings.mapPoints !== undefined) {
    initialState.mapPoints = mapSettings.mapPoints;
  }
  if (mapSettings.mapRoutes !== undefined) {
    initialState.mapRoutes = mapSettings.mapRoutes;
  }
  if (mapSettings.mapZones !== undefined) {
    initialState.mapZones = mapSettings.mapZones;
  }
  if (mapSettings.mapClusters !== undefined) {
    initialState.mapClusters = mapSettings.mapClusters;
  }
  if (mapSettings.mapTraffic !== undefined) {
    initialState.mapTraffic = mapSettings.mapTraffic;
  }
  if (mapSettings.selectedVehicle !== undefined) {
    initialState.selectedVehicle = mapSettings.selectedVehicle;
  }
}

const mapReducer = (state = initialState, action) => {
  const { type, values } = action;

  let mapLocalStorage = commonJs.getItemFromLocal(LOCAL_STORAGE.MAP_SETTINGS);

  switch (type) {
    case MAP_ACTIONS.SET_GROUPS:
      return { ...state, userGroups: values.userGroups };

    case MAP_ACTIONS.SET_FILTERED_GROUPS:
      return { ...state, filteredGroups: values.filteredGroups };

    case MAP_ACTIONS.TOGGLE_LIVE_MARKER_ANIMATION:
      return { ...state, liveMarkerAnimation: values.liveMarkerAnimation };

    case MAP_ACTIONS.TOGGLE_MARKER_ANIMATION:
      return { ...state, markerAnimation: values.markerAnimation };

    case MAP_ACTIONS.SET_MAP_POSITION:
      mapLocalStorage.initialLat = values.initialLat;
      mapLocalStorage.initialLng = values.initialLng;
      if (mapLocalStorage.initialLat === "") {
        mapLocalStorage.initialLat = DEFAULTS.DEFAULT_LAT;
        mapLocalStorage.initialLng = DEFAULTS.DEFAULT_LNG;
      }
      commonJs.setItemToLocal(LOCAL_STORAGE.MAP_SETTINGS, mapLocalStorage);
      return {
        ...state,
        initialLat: mapLocalStorage.initialLat,
        initialLng: mapLocalStorage.initialLng,
      };

    case MAP_ACTIONS.TOGGLE_FITSCREEN:
      return { ...state, fitmap: values.fitmap };

    case MAP_ACTIONS.TOGGLE_MAP_MARKER_DIALOG:
      return { ...state, locateMarkerDialog: values.locateMarkerDialog };

    case MAP_ACTIONS.TOGGLE_MAP_SCALE:
      mapLocalStorage.scale = values.scale;
      commonJs.setItemToLocal(LOCAL_STORAGE.MAP_SETTINGS, mapLocalStorage);
      return { ...state, scale: values.scale };

    case MAP_ACTIONS.TOGGLE_MAP_LABELS:
      mapLocalStorage.mapLabels = values.mapLabels;
      commonJs.setItemToLocal(LOCAL_STORAGE.MAP_SETTINGS, mapLocalStorage);
      return { ...state, mapLabels: values.mapLabels };

    case MAP_ACTIONS.TOGGLE_MAP_VEHICLES:
      mapLocalStorage.mapVehicles = values.mapVehicles;
      commonJs.setItemToLocal(LOCAL_STORAGE.MAP_SETTINGS, mapLocalStorage);
      return { ...state, mapVehicles: values.mapVehicles };

    case MAP_ACTIONS.TOGGLE_MAP_POINTS:
      mapLocalStorage.mapPoints = values.mapPoints;
      commonJs.setItemToLocal(LOCAL_STORAGE.MAP_SETTINGS, mapLocalStorage);
      return { ...state, mapPoints: values.mapPoints };

    case MAP_ACTIONS.TOGGLE_MAP_ROUTES:
      mapLocalStorage.mapRoutes = values.mapRoutes;
      commonJs.setItemToLocal(LOCAL_STORAGE.MAP_SETTINGS, mapLocalStorage);
      return { ...state, mapRoutes: values.mapRoutes };

    case MAP_ACTIONS.TOGGLE_MAP_ZONES:
      mapLocalStorage.mapZones = values.mapZones;
      commonJs.setItemToLocal(LOCAL_STORAGE.MAP_SETTINGS, mapLocalStorage);
      return { ...state, mapZones: values.mapZones };

    case MAP_ACTIONS.TOGGLE_MAP_CLUSTERS:
      mapLocalStorage.mapClusters = values.mapClusters;
      commonJs.setItemToLocal(LOCAL_STORAGE.MAP_SETTINGS, mapLocalStorage);
      return { ...state, mapClusters: values.mapClusters };

    case MAP_ACTIONS.TOGGLE_MAP_TRAFFIC:
      mapLocalStorage.mapTraffic = values.mapTraffic;
      commonJs.setItemToLocal(LOCAL_STORAGE.MAP_SETTINGS, mapLocalStorage);
      return { ...state, mapTraffic: values.mapTraffic };

    case MAP_ACTIONS.TOGGLE_MAP_DATA_POINTS:
      return { ...state, dataPoints: values.dataPoints };

    case MAP_ACTIONS.INITIALIZE_MAP:
      return {
        ...state,
        position: values.position,
        zoom: values.zoom,
      };

    case MAP_ACTIONS.SET_TMP_VEHICLES:
      return {
        ...state,
        tmpVehicles: values.vehicles,
      };

    case MAP_ACTIONS.LOAD_VEHICLE_DATA:
      return { ...state, selectedVehicle: values.selectedVehicle };

    case MAP_ACTIONS.SET_FILTERED_VEHICLES:
      return { ...state, filteredVehicles: values.filteredVehicles };

    case MAP_ACTIONS.SET_MAP_TYPE:
      let mapCache = commonJs.getItemFromLocal(LOCAL_STORAGE.MAP);
      if (mapCache) {
        mapCache.mapType = values.mapType;
      } else {
        mapCache = { mapType: values.mapType };
      }
      commonJs.setItemToLocal(LOCAL_STORAGE.MAP, mapCache);
      return { ...state, mapType: values.mapType };

    case MAP_ACTIONS.SELECT_VEHICLE:
      mapSettings.selectedVehicle = values.selectedVehicle;
      commonJs.setItemToLocal(LOCAL_STORAGE.MAP, mapSettings);
      return { ...state, selectedVehicle: values.selectedVehicle };

    case MAP_ACTIONS.SET_SIGNALFILTER_STATUS:
      return { ...state, signalFilter: values.signalFilter };

    case MAP_ACTIONS.SET_LOADFILTER_STATUS:
      return { ...state, loadStatusFilter: values.loadStatusFilter };

    case MAP_ACTIONS.SET_FILTERS:
      return {
        ...state,
        signalFilter: values.signalFilter,
        loadStatusFilter: values.loadStatusFilter,
        filteredVehicles: values.filteredVehicles,
      };

    case MAP_ACTIONS.RESET_VEHICLES:
      return {
        ...state,
        tmpVehicles: values.vehicles,
      };

    default:
      break;
  }
  return state;
};

export default mapReducer;
