import React, { Component } from "react";
import { Visibility } from "@material-ui/icons";
import { connect } from "react-redux";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";

import VehicleRow from "./VehicleRow";
import SimpleVehicleRow from "./SimpleVehicleRow";

import { MAP_ACTIONS } from "actions/MapActions";
import { commonJs } from "common/common";
import { PAGE_ACTIONS } from "actions/PageActions";

import "./vehiclelist.css";

var vehicles = [];
class VehicleRows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      menu: false,
    };
    this._cache = new CellMeasurerCache({
      fixedWidth: true,
    });
  }

  selectVehicle = (imei) => {
    const { filteredVehicles, tmpVehicles } = this.props.mapReducer;
    var vlist = filteredVehicles;
    if (filteredVehicles.length === 0) {
      vlist = tmpVehicles;
    }
    const vehicle = commonJs.getVehicleData(vlist, imei);
    if (vehicle.length > 0) {
      console.log("selectVehicle: ", imei, vehicle[0].name);
      this.props.setMapInit({
        position: {
          lat: vehicle[0]["latitude"],
          lng: vehicle[0]["longitude"],
        },
      });
    }
    this.props.setSelectedVehicle({
      selectedVehicle: imei,
    });
  };

  closeTrackMenu = () => {
    console.log("showVehicleMenu: ");
  };

  loadMoreVehicles = () => {
    console.log("loadMoreVehicles...");
  };

  componentWillReceiveProps() {
    //Don't Delete
    // Clear the cache if row heights are recompute to be sure there are no "blank spaces" (some row are erased)
    this._cache.clearAll();
    // We need to recompute the heights
    this.virtualizedList && this.virtualizedList.recomputeRowHeights();
  }

  componentWillUnmount() {
    this._cache.clearAll();
  }

  hideThisVehicle = () => {
    // console.log("hideThisVehicle...");
  };

  loadLeftIcon = () => {
    return <Visibility />;
  };

  rowRenderer = ({ key, index, parent, isScrolling, isVisible, style }) => {
    const { detailedUi, shareVehicle } = this.props;
    return (
      <CellMeasurer
        cache={this._cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ measure }) => {
          return detailedUi ? (
            <VehicleRow
              slno={index + 1}
              onLoad={measure}
              divstyle={style}
              imei={vehicles[index].imei}
              vehicle={vehicles[index]}
              selectedVehicle={this.props.mapReducer.selectedVehicle}
              selectVehicle={this.selectVehicle}
              hideThisVehicle={this.hideThisVehicle()}
              loadLeftIcon={this.loadLeftIcon()}
              closeTrackMenu={this.closeTrackMenu}
              isVisible={isVisible}
              updateMapRegion={this.props.updateMapRegion}
              switchTab={this.props.switchTab}
              detailedUi={detailedUi}
              shareVehicle={shareVehicle}
            />
          ) : (
            <SimpleVehicleRow
              slno={index + 1}
              onLoad={measure}
              divstyle={style}
              imei={vehicles[index].imei}
              vehicle={vehicles[index]}
              selectedVehicle={this.props.mapReducer.selectedVehicle}
              selectVehicle={this.selectVehicle}
              hideThisVehicle={this.hideThisVehicle()}
              loadLeftIcon={this.loadLeftIcon()}
              closeTrackMenu={this.closeTrackMenu}
              isVisible={isVisible}
              updateMapRegion={this.props.updateMapRegion}
              switchTab={this.props.switchTab}
              detailedUi={detailedUi}
              shareVehicle={shareVehicle}
            />
          );
        }}
      </CellMeasurer>
    );
  };

  onVehicleScrolling = () => {
    console.log("onVehicleScrolling");
  };

  render() {
    const {
      filteredVehicles,
      signalFilter,
      loadStatusFilter,
    } = this.props.mapReducer;

    const { vehiclesList } = this.props.commonReducer;

    var vlist = commonJs.getVehicleList(vehiclesList, filteredVehicles);

    vehicles = commonJs.getVehicleFilteredList(
      vlist,
      signalFilter,
      loadStatusFilter
    );

    return (
      <React.Fragment>
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              width={width}
              rowHeight={this._cache.rowHeight}
              rowRenderer={this.rowRenderer}
              rowCount={vehicles.length}
              deferredMeasurementCache={this._cache}
              overscanRowCount={0}
              data={vehicles}
            />
          )}
        </AutoSizer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
    commonReducer: state.commonReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMapInit: (values) => {
      dispatch({
        type: MAP_ACTIONS.INITIALIZE_MAP,
        values,
      });
    },
    setSelectedVehicle: (values) => {
      dispatch({
        type: MAP_ACTIONS.SELECT_VEHICLE,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_MAP_LOADING,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleRows);
