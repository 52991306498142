import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import FleetDialog from "components/ui/dialogbox/FleetDialog";
import AutoComplete from "components/ui/autocomplete/AutoComplete";

import { commonJs } from "common/common";
import { NOTIFY } from "common/consts";
import { MAP_ACTIONS } from "actions/MapActions";
import { MAP_CONTROLS_ACTIONS } from "actions/MapControlsActions";
import { API_Map } from "apihandler/Map";

class DialogEtaVehicles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {},
      showDialog: false,
      stops: null,
      selectedTrip: [],
      selectedVehicle: [],
    };
  }

  componentDidMount() {
    let self = this;
    const { childRef } = this.props;
    childRef(this);
    API_Map.getAllZones().then((res) => {
      if (res.Result === 200) {
        self.setState({
          stops: res.Response,
        });
      }
    });
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  toggleDialog = (rec) => {
    const { showDialog } = this.state;
    if (rec.id === undefined) {
      console.log("toggleDialog Add:  ", rec, !showDialog);
      // this.props.setPointEdit({
      //   pointId: "",
      // });
      if (rec.add) {
        // Adding a new point
        this.setState({
          showDialog: !showDialog,
          rec: {},
        });
      } else {
        // Close
        this.setState({
          showDialog: !showDialog,
        });
        // this.props.setPointLatLng({
        //   pointLatLng: {},
        // });
      }
    } else {
      // Edit -> Open
      this.setState({
        showDialog: !showDialog,
        rec: rec,
      });
      // this.props.setPointEdit({
      //   pointId: point.id,
      // });`
    }
  };

  saveRecord = () => {
    const { selectedVehicle, selectedTrip, record } = this.state;

    let self = this;

    if (selectedTrip.length === 0) {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Please select the trip..!"
      );
      return false;
    } else if (!selectedVehicle.imei) {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Please select the vehicle..!"
      );
      return false;
    }

    const args = {
      id: record.id || "",
      imei: selectedVehicle.imei,
      tripId: selectedTrip[0].id,
    };

    console.log("args: ", args);

    this.setState({
      showDialog: false,
      record: {},
    });
  };

  onChangeVehicle = (event) => {
    const { options } = event.target;
    let values = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        values.push(options[i].value);
      }
    }
    this.setState({
      selectedStops: values,
    });
    console.log("onChangeVehicle: ", values);
  };

  onChangeTrip = (val) => {
    this.setState({
      selectedTrip: val,
    });
  };

  onChangeVehicle = (val) => {
    this.setState({
      selectedVehicle: val,
    });
  };

  render() {
    const {
      record,
      showDialog,
      stops,
      selectedVehicle,
      selectedTrip,
    } = this.state;

    const { tmpVehicles } = this.props.mapReducer;

    return (
      <FleetDialog
        id="addStopPopup"
        open={showDialog}
        onCloseSettings={this.toggleDialog}
        title={record.id ? "Edit Assigned Vehicle" : "Add Assigned Vehicle"}
        maxWidth="xs"
        fullWidth={false}
        cancelButton={true}
        cancelAction={this.toggleDialog}
        saveButton={true}
        saveAction={this.saveRecord}
        saveBtnLabel="OK"
        disableBackdropClick={true}
        disableBackdrop={true}
        classNameBody="zonePopupBody"
        classNameWrapper="zonePopupWrapper"
        draggable
      >
        <div className="grid">
          <div className="row" style={{ marginBottom: 15 }}>
            <AutoComplete
              label={"Trip Name"}
              list={stops}
              id="assignedTrip"
              style={{ marginBottom: 15, width: "100%" }}
              onChange={this.onChangeTrip}
              defaultValue={selectedTrip}
              multiple={true}
              size="small"
            />
          </div>
          <div className="row" style={{ marginBottom: 5 }}>
            <AutoComplete
              label={"Vehicle Name"}
              list={tmpVehicles}
              id="etaAssignedVehicle"
              style={{ marginBottom: 15, width: "100%" }}
              onChange={this.onChangeVehicle}
              defaultValue={selectedVehicle}
              multiple={false}
              size="small"
            />
          </div>
        </div>
      </FleetDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleAddPoi: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_POI_CONTROL,
        values,
      });
    },
    setPointEdit: (values) => {
      dispatch({
        type: MAP_CONTROLS_ACTIONS.SET_POINT_EDIT,
        values,
      });
    },
    setPointLatLng: (values) => {
      dispatch({
        type: MAP_CONTROLS_ACTIONS.SET_POINT_LATLNG,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DialogEtaVehicles));
