import React, { Component } from "react";
import {
  Popover,
  Button,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
} from "@material-ui/core";
import { MoreVert as MenuBarIcon } from "@material-ui/icons";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { withSnackbar } from "notistack";
import moment from "moment";
import { connect } from "react-redux";

import GraphExportOptions from "../graphoptions/GraphExportOptions";

import { FORMATS, NOTIFY, LABELS } from "common/consts";
import { dateFunction } from "common/datefunctions";
import { commonJs } from "common/common";
import { API_Reports } from "apihandler/Reports";

class GraphIdleVehicles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphData: [],
      loading: true,
      totalKms: null,
      fdt: dateFunction.getDate("today", "from", FORMATS.SQL_DATE),
      tdt: dateFunction.getDate("today", "to", FORMATS.SQL_DATE),
      showExportFiter: false,
      showMenuFiter: false,
    };
    this.chart = null;

    this._menus = [
      { name: "More than 24 Hours", id: 0 },
      {
        name: "16 to 24 Hours",
        id: 1,
      },
      {
        name: "6 to 16 Hours",
        id: 2,
      },
      {
        name: "Upto 6 Hours",
        id: 3,
      },
    ];

    this.list = [
      { name: "More than 24 Hours", data: [0] },
      {
        name: "16 to 24 Hours",
        data: [0],
      },
      {
        name: "6 to 16 Hours",
        data: [0],
      },
      {
        name: "Upto 6 Hours",
        data: [0],
      },
    ];
  }

  componentDidMount() {
    // API_UserLogin.setToken(localStorage.token);
    // this.getKmData();
  }

  exportChart = (type = "image/png", mode = "p", width = 100) => {
    const { fdt } = this.state;

    // POST parameter for Highcharts export server
    let data = {
      type: type,
    };

    let ext = type.split("/");
    if (ext[1]) {
      ext = ext[1];
    } else {
      ext = "pdf";
    }

    let title =
      "Idle Vehicles - " +
      dateFunction.getFormatedDate(
        fdt,
        FORMATS.SQL_DATE,
        FORMATS.REPORT_TITLE_DATE
      ) +
      "." +
      ext;

    let target = document.querySelector(
      "div#reportDashLeft #reportDashIdleGraph .highcharts-container"
    );
    let self = this;
    API_Reports.exportHighchart(data, title, target, mode, width)
      .then((res) => {
        if (type !== "print") {
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            "Ready to download...",
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
        }
      })
      .catch((err) => {
        commonJs.showNotify(
          self.props,
          NOTIFY.ERROR,
          "Something went wrong, please try again..!",
          <button className="snackbar-btn">{LABELS.DISMISS}</button>
        );
      });
  };

  getKmData = () => {
    const { fdt, tdt } = this.state;
    let args = {
      fromDate: fdt,
      toDate: tdt,
    };
    let self = this;
    API_Reports.getReport("kmgraph", args)
      .then((res) => {
        if (res.Result === 200) {
          let rows = res.Response.rows;
          let sum = 0;
          rows.map((row) => {
            if (row.length > 1) {
              row[1] = Number(row[1]);
              sum += row[1];
            }
            return row;
          });
          self.setState({
            graphData: rows,
            loading: false,
            totalKms: sum,
          });
        }
      })
      .catch((err) => {
        self.setState({
          loading: false,
        });
      });
  };

  toggleFilter = (type = "export") => {
    const { showExportFiter, showMenuFiter } = this.state;
    if (type === "export") {
      this.setState({
        showExportFiter: !showExportFiter,
      });
    } else if (type === "menu") {
      this.setState({
        showMenuFiter: !showMenuFiter,
      });
    } else {
      this.setState({
        showMenuFiter: false,
        showExportFiter: false,
      });
    }
  };

  onGraphFilter = (id) => {
    this.selectedPoi = id;
    this.props.onGraphFilter(id);
    this.setState({
      showMenuFiter: false,
      showExportFiter: false,
    });
  };

  render() {
    const { showExportFiter, showMenuFiter, fdt } = this.state;
    const { vehiclesList } = this.props.commonReducer;

    let idleForMorethan24Hours = 0;
    let idleFor16To24Hours = 0;
    let idleFor6To16Hours = 0;
    let idleForUpto6Hours = 0;
    for (let i = 0; i < vehiclesList.length; i++) {
      if (vehiclesList[i].status !== 3 && vehiclesList[i].status !== 0) {
        let dt1 = new Date();
        let dtt = new Date(vehiclesList[i].objectlogtime);
        dtt = moment(dtt);
        dtt = dt1 - dtt;
        let hrs = dtt / (3600 * 1000);
        if (hrs > 24) idleForMorethan24Hours++;
        if (hrs >= 16 && hrs <= 24) idleFor16To24Hours++;
        if (hrs > 6 && hrs < 16) idleFor6To16Hours++;
        if (hrs <= 6) idleForUpto6Hours++;
      }
    }

    this.list[0].data[0] = idleForMorethan24Hours;
    this.list[1].data[0] = idleFor16To24Hours;
    this.list[2].data[0] = idleFor6To16Hours;
    this.list[3].data[0] = idleForUpto6Hours;

    const title = "";

    const options = {
      chart: {
        animation: Highcharts.svg,
        zoomType: "x",
        type: "column",
        marginLeft: 30,
        marginRight: 0,
        marginTop: 30,
        height: "300px",
        options3d: {
          enabled: true,
          alpha: 0,
          beta: 0,
          depth: 15,
          viewDistance: 35,
        },
      },
      credits: false,
      title: {
        text: title,
        margin: 80,
        style: {
          fontSize: "14px",
          fontWeight: "500",
          color: "#2A2A39",
          fontFamily: "Roboto,Helvetica,Arial,sans-serif",
        },
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        categories: "",
        labels: {
          rotation: -45,
          style: {
            fontSize: "12px",
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
          style: {
            fontSize: "12px",
            color: "#B2B2B2",
          },
        },
      },
      tooltip: {
        headerFormat: "",
        pointFormat: "<p><b>{series.name}: {point.y}</b></p>",
      },
      exporting: {
        enabled: true,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: "none",
          },
        },
      },
      series: this.list,
    };

    return (
      <React.Fragment>
        <h4 className="subtitle">Idle Vehicles</h4>
        <ClickAwayListener
          touchEvent="onTouchStart"
          onClickAway={this.toggleFilter}
        >
          <div className="chartFilter">
            {/* Export Menu */}
            <GraphExportOptions
              showExportFiter={showExportFiter}
              exportChart={this.exportChart}
              toggleFilter={this.toggleFilter}
            />

            {/* Graph Menu */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.toggleFilter("menu")}
              buttonRef={(node) => {
                this._anchorMenu = node;
              }}
            >
              <MenuBarIcon />
            </Button>
            <Popover
              className="graphMenuPopover"
              open={showMenuFiter}
              anchorEl={this._anchorMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={this.toggleFilter}
            >
              <List component="nav" className="dashboardFilterMenu">
                {this._menus.map((m, indx) => {
                  return (
                    <ListItem
                      button
                      onClick={() => this.onGraphFilter(m.id)}
                      className={m.fdt === fdt ? "active" : ""}
                      key={"graphIdleV-" + indx}
                    >
                      <ListItemText primary={m.name} />
                    </ListItem>
                  );
                })}
              </List>
            </Popover>
          </div>
        </ClickAwayListener>
        <HighchartsReact
          ref={(ref) => (this.chart = ref)}
          highcharts={Highcharts}
          options={options}
          style={{ height: "100%" }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commonReducer: state.commonReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setVehicles: (values) => {
    //   dispatch({
    //     type: COMMON_ACTIONS.SET_VEHICLES,
    //     values,
    //   });
    // },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(GraphIdleVehicles));
