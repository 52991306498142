import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import FleetDialog from "../../ui/dialogbox/FleetDialog";
import AutoComplete from "../../ui/autocomplete/AutoComplete";
import UiDatePicker from "../../ui/uidatepicker/UiDatePicker";

import { commonJs } from "../../../common/common";
import { NOTIFY, FORMATS, SUCCESS_MSG } from "../../../common/consts";
import { dateFunction } from "../../../common/datefunctions";

import { API_Settings } from "../../../apihandler/Settings";

class DialogShare extends Component {
  constructor(props) {
    super(props);
    const { selectedVehicle, tmpVehicles } = this.props.mapReducer;
    let vehicle = {};
    if (selectedVehicle !== "") {
      let vdata = commonJs.getVehicleData(tmpVehicles, selectedVehicle);
      if (vdata.length > 0) {
        vehicle = {
          imei: selectedVehicle,
          name: vdata[0].name,
        };
      }
    }
    this.state = {
      users: [],
      selectedVehicle: vehicle,
      selectedUser: {},
      expireDt: dateFunction.getDate("thismonth", "to", FORMATS.SQL_DATE),
      record: {},
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  saveRecord = () => {
    let self = this;
    const { selectedVehicle, selectedUser, expireDt, record } = this.state;
    const alias = document.getElementById("alias").value.trim();

    // Form Validation
    if (!selectedUser || !selectedUser.id) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Please select the User..!"
      );
      return false;
    } else if (!selectedVehicle || !selectedVehicle.imei) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Please select the Vehicle..!"
      );
      return false;
    }

    let args = {
      update: record.id ? true : false,
      alias: alias,
      imei: selectedVehicle.imei,
      userId: [selectedUser.id],
      expireDate: expireDt,
    };
    API_Settings.manageShareVehicle(args)
      .then((res) => {
        console.log("manageShareVehicle: ", res);
        if (res.Result === 200) {
          self.props.setShowDialog(false);
          self.props.togglePopup({});
          self.props.reloadGrid();
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            SUCCESS_MSG.RECORD_SAVED,
            <button className="snackbar-btn">{"dismiss"}</button>
          );
        } else {
          commonJs.showNotify(
            self.props,
            NOTIFY.ERROR,
            "Error: " + res.Reason,
            <button className="snackbar-btn">{"dismiss"}</button>
          );
        }
      })
      .catch((err) => {
        commonJs.showNotify(
          self.props,
          NOTIFY.ERROR,
          SUCCESS_MSG.RECORD_NOT_SAVED,
          <button className="snackbar-btn">{"dismiss"}</button>
        );
        console.log("error", err);
      });
  };

  setData = (rec = {}) => {
    console.log("setData: ", rec);
    const selectedVehicle = {
      imei: rec.imei,
      name: rec.vehicleName,
    };
    const selectedUser = {
      id: rec.userId,
      name: rec.userName,
    };
    this.setState({
      record: rec,
      expireDt: rec.expireDate,
      selectedUser,
      selectedVehicle,
    });
  };

  onChangeVehicle = (val) => {
    this.setState({
      selectedVehicle: val,
    });
  };

  onChangeUser = (val) => {
    this.setState({
      selectedUser: val,
    });
  };

  handleDateChange = (type, dt) => {
    console.log("handleDateChange: ", type, dt);
    let dtformat = "YYYY-MM-DD HH:mm";
    dt = dateFunction.getFormatedDate(dt, FORMATS.SQL_DATE, dtformat);
    this.setState({
      expireDt: dt,
    });
  };

  render() {
    const {
      record,
      alias,
      selectedVehicle,
      selectedUser,
      expireDt,
    } = this.state;

    const { showDialog, mapReducer, users } = this.props;

    const { tmpVehicles } = mapReducer;

    return (
      showDialog && (
        <FleetDialog
          id="shareVehicleDialog"
          open={true}
          onCloseSettings={this.props.togglePopup}
          title={record.id ? "Edit Share Vehicle" : "Add Share Vehicle"}
          maxWidth="xs"
          fullWidth={false}
          cancelButton={true}
          saveButton={true}
          cancelAction={this.props.togglePopup}
          saveAction={this.saveRecord}
          saveBtnLabel="OK"
          disableBackdropClick={false}
          disableBackdrop={false}
          classNameBody="zonePopupBody"
          classNameWrapper="zonePopupWrapper settingsDialogBox"
          draggable
        >
          <div className="grid">
            <div className="row">
              <AutoComplete
                label={"Select User"}
                list={users}
                id="assignedUser"
                style={{ marginBottom: 15, width: "100%" }}
                onChange={this.onChangeUser}
                defaultValue={selectedUser}
                multiple={false}
                size="small"
                disabled={record.id ? true : false}
              />
            </div>
            <div className="row">
              <AutoComplete
                label={"Select Vehicle"}
                list={tmpVehicles}
                id="assignedVehicle"
                style={{ marginBottom: 15, width: "100%" }}
                onChange={this.onChangeVehicle}
                defaultValue={selectedVehicle}
                multiple={false}
                size="small"
                disabled={record.id ? true : false}
              />
            </div>
            <div className="row">
              <TextField
                id="alias"
                label="Alias"
                classes={{ root: "txtfield" }}
                autoFocus={true}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={record.alias || ""}
              />
            </div>
            <div className="row" style={{ marginBottom: 15 }}>
              <UiDatePicker
                label="Expires On:"
                id={"date"}
                dt={expireDt}
                type="from"
                disablePast={false}
                disableFuture={false}
                disabled={false}
                datepicker={false}
                // maxDate={dateFunction.getDate("thismonth", "from")}
                handleDateChange={this.handleDateChange}
              />
            </div>
          </div>
        </FleetDialog>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DialogShare));
