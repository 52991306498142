import React, { Component } from "react";
import L from "leaflet";
import { connect } from "react-redux";

import RouteLine from "./RouteLine";

import { DEFAULTS } from "common/consts";
import { MAP_CONTROLS_ACTIONS } from "actions/MapControlsActions";
import { POI_ACTIONS } from "actions/PoiActions";
import { API_Map } from "apihandler/Map";

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: this.props.zoom,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.getRoutes();
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  updateZoom = (zoom) => {
    this.setState({
      zoom: zoom,
    });
    this.updateZoomCss(zoom);
  };

  getRoutes = () => {
    let self = this;
    API_Map.getAllRoutes()
      .then((res) => {
        if (res.Result === 200) {
          self.props.setRoutes({
            routes: res["Response"],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getPointMarker = (url) => {
    let markerUrl = url;
    const customMarker = L.icon({
      iconUrl: markerUrl,
      iconRetinaUrl: markerUrl,
      iconAnchor: null,
      popupAnchor: [0, -3],
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
      iconSize: new L.Point(16, 16),
      className: "pointMarker",
    });
    return customMarker;
  };

  updateZoomCss = (zoom) => {
    const pointsList = document.getElementsByClassName("pointLabel");
    if (zoom < DEFAULTS.SHOW_LABELS_ZOOM) {
      // Hide all zone labels
      for (let i = 0; i < pointsList.length; i++) {
        if (!pointsList[i].classList.contains("hide")) {
          pointsList[i].classList.add("hide");
        }
      }
    } else {
      // Show All
      for (let i = 0; i < pointsList.length; i++) {
        pointsList[i].classList.remove("hide");
      }
    }
  };

  onPointDragEnd = (e) => {
    const { lat, lng } = e.target._latlng;
    if (document.querySelector("div#addPoint")) {
      let latLng = lat + "," + lng;
      document.querySelector("div#addPoint").setAttribute("latlng", latLng);
    }
  };

  addPointMarker = (lat, lng) => {
    const { pointId } = this.props.mapControlsReducer;
    if (pointId === "") {
      this.props.setPointLatLng({
        pointLatLng: {
          lat,
          lng,
        },
      });
    }
  };

  render() {
    const { routes } = this.props.poiReducer;
    return (
      routes.length > 0 &&
      routes.map((route, i) => {
        return <RouteLine route={route} key={"routeline-" + i} />;
      })
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapControlsReducer: state.mapControlsReducer,
    poiReducer: state.poiReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRoutes: (values) => {
      dispatch({
        type: POI_ACTIONS.SET_ROUTES,
        values,
      });
    },
    setPointLatLng: (values) => {
      dispatch({
        type: MAP_CONTROLS_ACTIONS.SET_POINT_LATLNG,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
