import React, { Component } from "react";
import {
  Popover,
  Button,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
} from "@material-ui/core";
import { MoreVert as MenuBarIcon } from "@material-ui/icons";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import GraphExportOptions from "../graphoptions/GraphExportOptions";

import { POI_ACTIONS } from "../../../../actions/PoiActions";
import { dateFunction } from "../../../../common/datefunctions";
import { API_Reports } from "../../../../apihandler/Reports";
import { FORMATS, NOTIFY, LABELS } from "../../../../common/consts";
import { API_Map } from "../../../../apihandler/Map";
import { commonJs } from "../../../../common/common";

class GraphPoi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphData: [],
      loading: true,
      totalKms: null,
      fdt: dateFunction.getDate("today", "from", FORMATS.SQL_DATE),
      tdt: dateFunction.getDate("today", "to", FORMATS.SQL_DATE),
      showExportFiter: false,
      showMenuFiter: false,
      zones: [],
    };
    this.chart = null;
    this.selectedPoi = "";
  }

  componentDidMount() {
    this.getGraphData();
  }

  getGraphData = () => {
    let self = this;
    API_Map.getAllZones().then((res) => {
      if (res.Result === 200) {
        let rows = res.Response;
        self.props.setZones({
          zones: rows,
        });
      }
    });
  };

  exportChart = (type = "image/png", mode = "p", width = 100) => {
    const { fdt } = this.state;

    // POST parameter for Highcharts export server
    let data = {
      type: type,
    };

    let ext = type.split("/");
    if (ext[1]) {
      ext = ext[1];
    } else {
      ext = "pdf";
    }

    let title =
      "Vehicles in Zones - " +
      dateFunction.getFormatedDate(
        fdt,
        FORMATS.SQL_DATE,
        FORMATS.REPORT_TITLE_DATE
      ) +
      "." +
      ext;

    let target = document.querySelector(
      "div#reportDashLeft #reportDashPoiGraph .highcharts-container"
    );
    let self = this;
    API_Reports.exportHighchart(data, title, target, mode, width)
      .then((res) => {
        if (type !== "print") {
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            "Ready to download...",
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
        }
      })
      .catch((err) => {
        commonJs.showNotify(
          self.props,
          NOTIFY.ERROR,
          "Something went wrong, please try again..!",
          <button className="snackbar-btn">{LABELS.DISMISS}</button>
        );
      });
  };

  toggleFilter = (type = "export") => {
    const { showExportFiter, showMenuFiter } = this.state;
    if (type === "export") {
      this.setState({
        showExportFiter: !showExportFiter,
      });
    } else if (type === "menu") {
      this.setState({
        showMenuFiter: !showMenuFiter,
      });
    } else {
      this.setState({
        showMenuFiter: false,
        showExportFiter: false,
      });
    }
  };

  onGraphFilter = (id = "") => {
    this.selectedPoi = id;
    this.props.onGraphFilter(id);
    this.setState({
      showMenuFiter: false,
      showExportFiter: false,
    });
  };

  render() {
    const { showExportFiter, showMenuFiter } = this.state;
    const vehicles = this.props.commonReducer.vehiclesList;
    const { zones } = this.props.poiReducer;
    let graphData = [];
    let list = commonJs.getPoiVehicles(vehicles, zones);
    this._menu = [];
    for (let poiId in list) {
      graphData.push({
        name: list[poiId].name,
        data: [list[poiId].vehicles.length],
      });
      this._menu.push({
        name: list[poiId].name + " (" + list[poiId].vehicles.length + ")",
        id: poiId,
      });
    }

    let title = "";

    const options = {
      chart: {
        animation: Highcharts.svg,
        marginRight: 0,
        marginLeft: 30,
        marginTop: 30,
        type: "column",
        height: "300px",
      },
      credits: false,
      title: {
        text: title,
        margin: 80,
        style: {
          fontSize: "14px",
          fontWeight: "500",
          color: "#2A2A39",
          fontFamily: "Roboto,Helvetica,Arial,sans-serif",
        },
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        categories: "",
        labels: {
          rotation: -45,
          style: {
            fontSize: "12px",
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "Count",
          style: {
            fontSize: "12px",
            color: "#B2B2B2",
          },
        },
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        headerFormat: "",
        pointFormat: "<p><b>{series.name}: {point.y}</b></p>",
      },
      exporting: {
        enabled: true,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: "none",
          },
        },
      },
      series: graphData,
    };

    return (
      <React.Fragment>
        <h4 className="subtitle">Vehicles in Zones</h4>
        <ClickAwayListener
          touchEvent="onTouchStart"
          onClickAway={this.toggleFilter}
        >
          <div className="chartFilter">
            {/* Export Menu */}
            <GraphExportOptions
              showExportFiter={showExportFiter}
              exportChart={this.exportChart}
              toggleFilter={this.toggleFilter}
            />

            {/* Graph Menu */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.toggleFilter("menu")}
              buttonRef={(node) => {
                this._anchorMenu = node;
              }}
            >
              <MenuBarIcon />
            </Button>
            <Popover
              className="graphMenuPopover"
              open={showMenuFiter}
              anchorEl={this._anchorMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={this.toggleFilter}
            >
              <List component="nav" className="dashboardFilterMenu">
                {this._menu.map((m, indx) => {
                  return (
                    <ListItem
                      button
                      onClick={() => this.onGraphFilter(m.id)}
                      className={""}
                      key={"poigraph-" + indx}
                    >
                      <ListItemText primary={m.name} />
                    </ListItem>
                  );
                })}
              </List>
            </Popover>
          </div>
        </ClickAwayListener>
        <div id="poiChart" style={{ height: 300 }}>
          <HighchartsReact
            ref={(ref) => (this.chart = ref)}
            highcharts={Highcharts}
            options={options}
            style={{ height: "100%" }}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commonReducer: state.commonReducer,
    poiReducer: state.poiReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setZones: (values) => {
      dispatch({
        type: POI_ACTIONS.SET_ZONES,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(GraphPoi));
