import React, { Component } from "react";
import { Tabs, Tab } from "@material-ui/core";

import PointsTab from "./pointstab/PointsTab";
import RoutesTab from "./routestab/RoutesTab";
import ZonesTab from "./zonestab/ZonesTab";

import "./tab.css";
import { LOCAL_STORAGE } from "../../../../../common/consts";

class PoiTab extends Component {
  constructor(props) {
    super(props);
    var activeTab = 0;
    let tabCache = localStorage.getItem(LOCAL_STORAGE.POI_TAB);
    if (tabCache != null) {
      tabCache = JSON.parse(tabCache);
      if (tabCache.activeTab) {
        activeTab = tabCache.activeTab;
      }
    }
    this.state = {
      activeTab: activeTab,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  handleChange = (event, value) => {
    let tabJson = localStorage[LOCAL_STORAGE.POI_TAB];
    if (tabJson == null) {
      tabJson = {
        activeTab: value,
      };
    } else {
      tabJson = JSON.parse(tabJson);
      tabJson.activeTab = value;
    }
    localStorage[LOCAL_STORAGE.POI_TAB] = JSON.stringify(tabJson);
    this.setState({ activeTab: value });
  };

  render() {
    const { activeTab } = this.state;
    return (
      <React.Fragment>
        <Tabs
          value={activeTab}
          onChange={this.handleChange}
          variant="fullWidth"
          scrollButtons="auto"
          className="poiTab"
          classes={{
            indicator: "poiTabIndicator",
            scrollButtons: "poiNavTabButtons",
          }}
        >
          <Tab label={"Zones"} classes={{ root: "tab" }} />
          <Tab label={"Points"} classes={{ root: "tab" }} />
          <Tab
            label={"Routes"}
            classes={{
              root: "tab",
              //  labelContainer: "poiTabLabel"
            }}
          />
        </Tabs>

        {activeTab === 0 && (
          <ZonesTab
            zoneFitBounds={this.props.zoneFitBounds}
            updateMapRegion={this.props.updateMapRegion}
          />
        )}

        {activeTab === 1 && (
          <PointsTab updateMapRegion={this.props.updateMapRegion} />
        )}

        {activeTab === 2 && (
          <RoutesTab
            zoneFitBounds={this.props.zoneFitBounds}
            updateMapRegion={this.props.updateMapRegion}
          />
        )}
      </React.Fragment>
    );
  }
}

export default PoiTab;
