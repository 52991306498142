import { reactLocalStorage } from "reactjs-localstorage";
import { SdkClient } from "fgxsdk";
import jQuery from "jquery";
import md5 from "crypto-js/md5";
import axios from "axios";

import {
  NOTIFY,
  UNITS,
  CONFIG,
  LOCAL_STORAGE,
  DEFAULTS,
  LABELS,
  ROLES,
  ERRORS,
  PRIVILEGES,
} from "./consts";
import { API_UserLogin } from "../apihandler/UserLogin";
import { dateFunction } from "common/datefunctions";

SdkClient.setUrl(CONFIG.BASE_URL);

var $ = jQuery;

export const commonJs = {
  callApi: async (path = "/", method = CONFIG.API_METHODS.GET, args = {}) => {
    try {
      const sessionToken = localStorage[LOCAL_STORAGE.TOKEN] || "";
      const config = {
        timeout: CONFIG.API_TIMEOUT,
        headers: {
          "fgx-api-session": sessionToken,
        },
      };
      let res = {};
      switch (method) {
        case CONFIG.API_METHODS.POST:
          res = await axios.post(CONFIG.CPANEL_API_URL + path, args, config);
          break;
        case CONFIG.API_METHODS.PUT:
          break;
        case CONFIG.API_METHODS.DELETE:
          res = await axios.delete(CONFIG.CPANEL_API_URL + path, {
            data: args,
            timeout: CONFIG.API_TIMEOUT,
            headers: {
              "fgx-api-session": sessionToken,
            },
          });
          break;
        default:
          // GET
          config.params = args;
          res = await axios.get(CONFIG.CPANEL_API_URL + path, config);
          break;
      }
      console.log("API: ", path, ": ", res, args);
      // 403 => Redirect to login
      if (res.status === 200) {
        return res.data;
      } else if (res.status === 403) {
        // Authentication Failed
        window.location = "/login";
        return {
          Result: 403,
          Response: {},
          Reason: "Authentication Failed: Please try login to continue..!",
        };
      }
      // Failed due to some other reason
      return {
        Result: 500,
        Response: {},
        Reason: ERRORS.INTERNAL_ERROR,
      };
    } catch (err) {
      err = err.toString();
      console.log("API: err: ", err);
      if (err.indexOf("status code 403") > -1) {
        // localStorage.removeItem(LOCAL_STORAGE.TOKEN);
        // window.location = "/login";
      }
      return {
        Result: 500,
        Response: {},
        Reason: ERRORS.INTERNAL_ERROR,
      };
    }
  },

  generateAuthUrl: (userId = "") => {
    let path = CONFIG.WEBSITE_URL + "/login?au=";
    let randomKey = userId + new Date().getTime();
    randomKey = md5(path + randomKey).toString();
    return path + randomKey;
  },

  getDefaultDismissBtn: () => {
    return <button className="snackbar-btn">{LABELS.DISMISS}</button>;
  },

  getExportPrintCss: (win) => {
    $(win.document.body).find("h1").remove();
    $(win.document.body).find("table").attr("cellpadding", "3");
    $(win.document.body).find("table").css("font-size", "10px");
    $(win.document.body)
      .find("table")
      .css(
        "cssText",
        "border-bottom: none;border-left: none;border-right: none;"
      );
    $(win.document.body).find("td").css("font-size", "8px");
    $(win.document.body).find("th").css("font-size", "8px");
    $(win.document.body)
      .find("tbody td")
      .css("cssText", "border: 1px solid #000;border-top: 0;border-right: 0;");
    $(win.document.body)
      .find("tbody td:last-child")
      .css("cssText", "border: 1px solid #000;border-top: 0;");
    $(win.document.body)
      .find("table th")
      .css("cssText", "border: 1px solid #000;border-right: 0;");
    $(win.document.body)
      .find("table th:last-child")
      .css("cssText", "border: 1px solid #000;");
  },

  callSomeone: (props) => {
    console.log("calling: ", props);
  },

  getConvertedVertices: (vertices) => {
    let conVertices = [];
    vertices.map((v, indx) => {
      conVertices.push([v.latitude, v.longitude]);
      return true;
    });
    return conVertices;
  },

  getVehicleList: (vlist, filteredList) => {
    var vehicleList = vlist;
    if (filteredList !== undefined) {
      if (filteredList.length > 0) {
        vehicleList = [];
        filteredList.map((v2) => {
          let flist = vlist.filter((v1) => {
            return v2.imei === v1.imei;
          });
          if (flist.length > 0) {
            vehicleList.push(flist[0]);
          }
          return true;
        });
      }
    }
    return vehicleList;
  },

  getVehicleCount: (vlist) => {
    var vcount = vlist.length;
    if (vcount > 0) {
      if (vlist[0].status === undefined) {
        vcount = 0;
      }
    }
    return vcount;
  },

  getVehicleFilteredList: (vlist, signalFilter, loadStatusFilter) => {
    let vehicles = [];
    var vcount = vlist.length;
    if (vcount > 0) {
      if (vlist[0].status === undefined) {
        vcount = 0;
      }
    }
    if (vcount > 0) {
      for (var v = 0; v < vcount; v++) {
        let vehicle = vlist[v];
        let showVehicle = false;
        if (signalFilter === 0 && loadStatusFilter === 0) {
          showVehicle = true;
        } else if (signalFilter !== 0 && loadStatusFilter !== 0) {
          if (
            signalFilter === vehicle.status + 1 &&
            vehicle.loadstatus ===
              DEFAULTS.DEFAULT_VEHICLE_LOAD_STATUS_LIST[loadStatusFilter]
          ) {
            showVehicle = true;
          }
        } else {
          if (
            signalFilter === vehicle.status + 1 ||
            vehicle.loadstatus ===
              DEFAULTS.DEFAULT_VEHICLE_LOAD_STATUS_LIST[loadStatusFilter]
          ) {
            showVehicle = true;
          }
        }
        if (showVehicle) {
          vehicles.push(vehicle);
        }
      }
    }
    return vehicles;
  },

  getVehicleStatus: (vehicle) => {
    let vstatus = "nosignal";
    let vstatusText = "NO DATA";
    if (vehicle.status === 3) {
      vstatus = "running";
      vstatusText = vehicle.speed.toFixed(1) + " " + UNITS.SPEED;
    } else if (vehicle.status === 2) {
      vstatus = "idle";
      vstatusText = "IDLE";
    } else if (vehicle.status === 1) {
      vstatus = "stopped";
      vstatusText = "STOP";
    }
    return { vstatus, vstatusText };
  },

  getVehicleStatusText: (vehicle) => {
    let text = "No Signal";
    if (vehicle.status === 3) {
      text = "Moving";
    } else if (vehicle.status === 2) {
      text = "Idle";
    } else if (vehicle.status === 1) {
      text = "Stopped";
    }
    return text;
  },

  getVehicleStatusInfo: (vehicle) => {
    console.log("getVehicleStatusInfo: ", vehicle);
    let result = {
      since: "",
      status: "",
    };
    if (vehicle.status === 0) {
      let secs = dateFunction.getDateDiff(
        vehicle.objectlogtime,
        new Date(),
        true
      );
      result.since = dateFunction.convDateToDiffObject(secs).days;
      result.status = "No Data";
    } else if (vehicle.status === 1 || vehicle.status === 2) {
      let longHault = 0;
      if (vehicle.idletime) {
        dateFunction.getDateDiff(vehicle.idletime, new Date(), true);
        result.since = dateFunction.convDateToDiffObject(longHault).days;
        result.status = "Haulted";
      } else {
        result.since = "";
        result.status = "Haulted";
      }
    } else if (vehicle.status === 3) {
      result.since = "";
      result.status = "Moving";
    }
    return result;
  },

  getVehicleIgnition: (vehicle) => {
    let vehicleIgn = false;
    if (vehicle.gps !== 0 || vehicle.gprs !== 0) {
      if (vehicle.power !== undefined) {
        if (vehicle.power <= 1) {
          vehicleIgn = vehicle.power;
        } else if (vehicle.ignition !== undefined) {
          vehicleIgn = vehicle.ignition;
        }
      } else if (vehicle.ignition !== undefined) {
        vehicleIgn = vehicle.ignition;
      }
    }
    return vehicleIgn;
  },

  getVehicleSensors: (sensors, params = undefined) => {
    let sensorsList = [];
    if (sensors.length > 0) {
      for (let i = 0; i < sensors.length; i++) {
        if (sensors[i].units.toUpperCase().indexOf("X") > -1) {
          let initVal = sensors[i].value;
          let finalVal = commonJs.calcString(initVal, sensors[i].units);
          let temps = sensors[i].sensorType.split("temp");
          if (temps.length > 1) {
            let tmp = {
              type: "temp",
              name: sensors[i].name,
              value: finalVal + " " + UNITS.TEMP,
            };
            sensorsList.push(tmp);
          } else if (sensors[i].sensorType === "fuel") {
            let tmp = {
              type: "fuel",
              name: sensors[i].name,
              value: finalVal.toFixed(1) + " " + UNITS.FUEL,
            };
            sensorsList.push(tmp);
          } else {
            let tmp = {
              type: sensors[i].sensorType,
              name: sensors[i].name,
              value: finalVal,
            };
            sensorsList.push(tmp);
          }
        } else {
          if (params !== undefined) {
            if (params[sensors[i].param]) {
              sensors[i].value = params[sensors[i].param];
            }
          }
          let temps = sensors[i].sensorType.split("temp");
          if (temps.length > 1) {
            let units = sensors[i].units;
            if (units === "" || units === "C") {
              units = UNITS.TEMP;
            }
            let tempValue = Number(sensors[i].value);
            if (sensors[i].param === "io25") {
              tempValue = tempValue / 100;
            }
            let tmp = {
              type: "temp",
              name: sensors[i].name,
              value: tempValue.toFixed(1) + " " + units,
            };
            sensorsList.push(tmp);
          } else if (sensors[i].sensorType === "fuel") {
            let calibration = sensors[i].calibration;
            let fc = sensors[i].fuelcapacity;
            let fv = sensors[i].fuelvoltage;
            let calibrationValue = sensors[i].calibration_value;
            let fuelval = commonJs.getFuelValue({
              value: sensors[i].value,
              calibration: calibration,
              fuelcapacity: fc,
              fuelvoltage: fv,
              calibrationValue: calibrationValue,
            });
            let units = sensors[i].units;
            if (units === "") {
              units = UNITS.FUEL;
            }
            let tmp = {
              type: "fuel",
              name: sensors[i].name,
              value: fuelval + " " + units,
            };
            sensorsList.push(tmp);
          } else if (sensors[i].sensorType === "humidity") {
            let tmp = {
              type: "humidity",
              name: sensors[i].name,
              value: sensors[i].value + " " + sensors[i].units,
            };
            sensorsList.push(tmp);
          } else if (sensors[i].sensorType === "alcohol") {
            let tmp = {
              type: "alcohol",
              name: sensors[i].name,
              value: sensors[i].value + " " + sensors[i].units,
            };
            sensorsList.push(tmp);
          } else {
            let tmp = {
              type: "other",
              name: sensors[i].name,
              value:
                Number(sensors[i].value).toFixed(1) + " " + sensors[i].units,
            };
            sensorsList.push(tmp);
          }
        }
      }
    }
    return sensorsList;
  },

  getVehicleData: (vehicles, imei) => {
    const vehicle = vehicles.filter((v) => {
      return v.imei === imei;
    });
    return vehicle;
  },

  openFullScreen: () => {
    var elem = document.getElementById("root");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  },

  exitFullScreen: () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  },

  isFullScreen: () => {
    if (!(window.innerHeight !== window.screen.height)) {
      return true;
    } else {
      return false;
    }
  },

  logout: () => {
    API_UserLogin.logout()
      .then((res) => {
        //TODO: Clear cache && reset login
        localStorage.removeItem(LOCAL_STORAGE.MAP_TAB);
        localStorage.removeItem(LOCAL_STORAGE.MAP_SETTINGS);
        window.location = "/";
      })
      .catch((err) => {
        window.location = "/";
      });
  },

  showNotify: function (props, variant, text, action, autoHideDuration) {
    if (props) {
      props.enqueueSnackbar(text, {
        variant: variant,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        action: action,
        autoHideDuration: autoHideDuration || NOTIFY.AUTO_HIDE_DURATION,
      });
    }
  },

  getItemFromLocal: (itemname) => {
    try {
      const item = reactLocalStorage.getObject(itemname);
      return item;
    } catch (err) {
      return;
    }
  },

  setItemToLocal: (itemname, value) => {
    reactLocalStorage.setObject(itemname, value);
  },

  getFuelValue: (sensordata) => {
    let actualValue = sensordata.value;
    let calibration = JSON.parse(sensordata.calibration);
    let fuelcapacity = sensordata.fuelcapacity;
    let fuelvoltage = sensordata.fuelvoltage;
    let calibrationValue = sensordata.calibrationValue || 1000;
    // console.log("vehiclesensor", calibrationValue)
    if (!isNaN(actualValue)) {
      actualValue = Number(actualValue);
      var fvalue = actualValue / calibrationValue;
      if (calibration.length > 0) {
        let prevX = 0;
        let prevY = 0;
        let cX = 0;
        let cY = 0;
        for (var i in calibration) {
          cX = Number(calibration[i].x);
          cY = Number(calibration[i].y);
          if (fvalue < cY) {
            break;
          }
          prevX = cX;
          prevY = cY;
        }
        var prevSum = 0;
        if (prevX > 0 || prevY > 0) {
          prevSum = prevX / prevY;
        }
        var sum = 0;
        if (cX > 0 || cY > 0) {
          sum = cX / cY;
        }
        fvalue = (fvalue * (prevSum + sum)) / 2;
        fvalue = commonJs.getTwoDecimalValue(fvalue);
      } else if (fuelcapacity > 0 && fuelvoltage > 0) {
        var fc = Number(fuelcapacity);
        var fv = Number(fuelvoltage);
        if (!isNaN(fvalue) && !isNaN(fc) && !isNaN(fv)) {
          if (fvalue !== 0.174) {
            // Ignition Off
            fvalue = fvalue / 1000;
            fvalue = (fv - fvalue) / fv;
            fvalue = (fvalue * fc).toFixed(2);
            fvalue = commonJs.getTwoDecimalValue(fvalue);
          } else {
            fvalue = "N/A";
          }
        } else {
          fvalue = "N/A";
        }
      } else {
        fvalue = Number(actualValue);
      }
    } else {
      fvalue = "N/A";
    }
    return fvalue;
  },

  isTempVehicle: (sensors) => {
    if (sensors.length > 0) {
      let isTemp = sensors.filter((val) => {
        return val.sensorType.indexOf("temp") > -1;
      });
      if (isTemp.length > 0) {
        return true;
      }
    }
    return false;
  },

  isFuelVehicle: (sensors) => {
    if (sensors.length > 0) {
      let isFuel = sensors.filter((val) => {
        return val.sensorType.indexOf("fuel") > -1;
      });
      if (isFuel.length > 0) {
        return true;
      }
    }
    return false;
  },

  isAlcoholVehicle: (sensors) => {
    if (sensors.length > 0) {
      let isAlcohol = sensors.filter((val) => {
        return val.sensorType.indexOf("alcohol") > -1;
      });
      if (isAlcohol.length > 0) {
        return true;
      }
    }
    return false;
  },

  getAddress: async (lat, lng) => {
    return SdkClient.getAddress({ lat: lat, lng: lng })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  /* Finds the distance between two map points, returns as string */
  getDistance: function (p1, p2) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = this.toRad(p2.lat - p1.lat);
    var dLng = this.toRad(p2.lng - p1.lng);
    var lat1 = this.toRad(p1.lat);
    var lat2 = this.toRad(p2.lat);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var dist = (R * c) / 1000; // In Kms
    return this.getTwoDecimalValue(dist);
  },

  /* Finds the midpoint of the Polygon, returns as boolean*/
  getMidPointofPolygon: function (vertices) {
    var sumX = 0;
    var sumY = 0;
    var count = 0;
    for (var i = 0; i < vertices.length; i++) {
      sumX += vertices[i].latitude;
      sumY += vertices[i].longitude;
      count++;
    }
    var pos = {
      latitude: sumX / count,
      longitude: sumY / count,
    };
    return pos;
  },

  /* Converts "degree" to "radians", returns as number */
  toRad: function (degree) {
    return (degree * Math.PI) / 180;
  },

  /* Converts "radians" to "degree", returns as number */
  toDeg: function (radian) {
    return (radian * 180) / Math.PI;
  },

  /* Converts "number" to two decimal points, returns as string */
  getTwoDecimalValue: (val) => {
    var intval = Math.floor(val);
    var deci = val - Math.floor(val);
    if (deci === 0) {
      return intval.toString() + ".00";
    } else {
      deci = deci.toFixed(2).toString();
      deci = deci.substr(1, deci.length);
      return intval + deci;
    }
  },

  /* Checks the IMEI valid or not, returns as boolean */
  isIMEIValid: function (imei) {
    var RegExp = /^[a-zA-Z0-9]{1,15}$/;
    if (imei.match(RegExp)) {
      return true;
    }
    return false;
  },

  isPointInPolygon: (vertices = [], lat, lng) => {
    let polygon = [];
    let point = [lat, lng];
    for (let i = 0; i < vertices.length; i++) {
      polygon.push([vertices[i].latitude, vertices[i].longitude]);
    }
    //A point is in a polygon if a line from the point to infinity crosses the polygon an odd number of times
    let odd = false;
    //For each edge (In this case for each point of the polygon and the previous one)
    for (let i = 0, j = polygon.length - 1; i < polygon.length; i++) {
      //If a line from the point into infinity crosses this edge
      if (
        polygon[i][1] > point[1] !== polygon[j][1] > point[1] &&
        point[0] <
          ((polygon[j][0] - polygon[i][0]) * (point[1] - polygon[i][1])) /
            (polygon[j][1] - polygon[i][1]) +
            polygon[i][0]
      ) {
        // Invert odd
        odd = !odd;
      }
      j = i;
    }
    //If the number of crossings was odd, the point is in the polygon
    return odd;
  },

  /* Checks the "number" valid or not, returns as boolean */
  isNumberValid: function (value) {
    var er = /^\d+(\.\d+)?$/;
    return er.test(value) ? true : false;
  },

  /* Checks the "email" valid or not, returns boolean */
  isEmailValid: function (email) {
    var RegExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (email.match(RegExp)) {
      return true;
    }
    return false;
  },

  getRandomInt: (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },

  getVehicleParamUnits: (sensors, param) => {
    let units = "";
    if (param === "temp") {
      units = UNITS.TEMP;
      return units;
    }
    let sensor = sensors.filter((s) => s.param === param);
    if (sensor.length > 0) {
      units = sensor[0]["units"];
    }
    return units;
  },

  // Formula Calculations
  calcString: (val = "", formulaStr = "") => {
    let rsltStr = formulaStr.toUpperCase().replace("X", val).trim();
    rsltStr = rsltStr.replace(/[^-()\d/*+.]/g, "");
    return eval(rsltStr);
  },

  sortNumber: (a, b) => {
    return a - b;
  },

  getSensorValue: (sensor = {}, actualValue = 0) => {
    let rslt = actualValue;
    console.log("getSensorValue: ", sensor);
    if (sensor.result_type === "logic") {
      rslt = actualValue;
    } else if (sensor.result_type === "value") {
      rslt = parseFloat(actualValue);
      if (sensor.param === "adc1" && sensor.sensorType === "fuel") {
        // Only for fuel
        let sensordata = sensor;
        sensordata.value = rslt;
        rslt = commonJs.getFuelValue(sensordata);
        return rslt;
      }
      if (sensor.formula !== "") {
        var formula = sensor.formula.toLowerCase();
        actualValue = commonJs.calcString(actualValue, formula);
      }
      // calibration
      var out_of_cal = true;
      var calibration = sensor.calibration;
      // function to get calibration Y value
      var calGetY = function (x) {
        var result = 0;
        for (var j = 0; j < calibration.length; j++) {
          if (calibration[j]["x"] === x) {
            result = parseFloat(calibration[j]["y"]);
          }
        }
        return result;
      };
      if (calibration.length >= 2) {
        // put all X values to separate array
        var x_arr = [];
        for (let i = 0; i < calibration.length; i++) {
          x_arr.push(parseFloat(calibration[i]["x"]));
        }
        x_arr.sort(commonJs.sortNumber);
        // loop and check if in cal
        for (let i = 0; i < x_arr.length; i++) {
          let x_low = x_arr[i];
          let x_high = x_arr[i + 1];
          if (actualValue >= x_low && actualValue <= x_high) {
            // get Y low and high
            var y_low = calGetY(x_low);
            var y_high = calGetY(x_high);
            // get coeficient
            let a = actualValue - x_low;
            let b = x_high - x_low;
            var coef = a / b;
            var c = y_high - y_low;
            coef = c * coef;
            actualValue = y_low + coef;
            out_of_cal = false;
            break;
          }
        }
        if (out_of_cal) {
          // check if lower than cal
          let x_low = x_arr[0];
          if (actualValue < x_low) {
            actualValue = calGetY(x_low);
          }
          // check if higher than cal
          let x_high = x_arr.slice(-1)[0];
          if (actualValue > x_high) {
            actualValue = calGetY(x_high);
          }
        }
      }
      rslt = Math.round(rslt * 100) / 100;
    } else if (sensor.result_type === "string") {
      rslt = actualValue;
    } else if (sensor.result_type === "percentage") {
      actualValue = parseFloat(actualValue);
      sensor.lv = parseFloat(sensor.lv);
      sensor.hv = parseFloat(sensor.hv);
      if (actualValue > sensor.lv && actualValue < sensor.hv) {
        let a = actualValue - sensor.lv;
        let b = sensor.hv - sensor.lv;
        rslt = Math.round((a / b) * 100);
      } else if (actualValue <= sensor.lv) {
        rslt = 0;
      } else if (actualValue >= sensor.hv) {
        rslt = 100;
      }
    }
    return rslt;
  },

  getVehicleFromArray: (vehicles, selectedImei) => {
    return vehicles.filter((v) => v.imei === selectedImei);
  },

  getDefaultSensorUnit: (sensorType) => {
    let sensorUnits = "";
    if (sensorType === "temp") {
      sensorUnits = UNITS.TEMP;
    } else if (sensorType === "fuel") {
      sensorUnits = UNITS.FUEL;
    }
    return sensorUnits;
  },

  getUniqueArray: (arr) => {
    let list = [];
    $.each(arr, function (i, el) {
      if ($.inArray(el, list) === -1) list.push(el);
    });
    return list;
  },

  getUrlParameter: (param) => {
    let url = new URL(window.location.href);
    return url.searchParams.get(param);
  },

  hasPrivilege: (privilege, role, privileges) => {
    if (role === ROLES.ADMIN || role === ROLES.SUPER_ADMIN) {
      if (privilege === PRIVILEGES.HIDE_TEMP) {
        return false;
      }
      return true;
    }
    return privileges.indexOf(privilege) > -1;
  },

  clickMarkerIcon: (imei) => {
    if (document.createEvent) {
      let evt = document.createEvent("MouseEvents");
      evt.initEvent("click", true, false);
      setTimeout(() => {
        if (document.querySelector("#movingmarker-" + imei + ">img")) {
          document
            .querySelector("#movingmarker-" + imei + ">img")
            .dispatchEvent(evt);
        }
      }, 1000 * 1.5);
    }
  },

  getVehicleTypeText: (vin = "") => {
    let vtype = "";
    if (vin.trim() !== "") {
      vtype = "(" + vin.trim() + ")";
    }
    return vtype;
  },

  convertNumberToIndianNumber: (num) => {
    num = num.toString();
    let numParts = num.split(".");
    num = numParts[0];
    let lastThree = num.substring(num.length - 3);
    let otherNumbers = num.substring(0, num.length - 3);
    if (otherNumbers !== "") lastThree = "," + lastThree;
    let rslt = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    if (numParts[1]) {
      rslt = rslt + "." + numParts[1];
    }
    return rslt;
  },

  getPoiVehicles: (vehicles = [], zones = []) => {
    let list = {};
    for (let v = 0; v < vehicles.length; v++) {
      const { latitude, longitude } = vehicles[v];
      for (let z = 0; z < zones.length; z++) {
        if (commonJs.isPointInPolygon(zones[z].vertices, latitude, longitude)) {
          if (!list[zones[z].id]) {
            list[zones[z].id] = {};
            list[zones[z].id].name = zones[z].name;
            list[zones[z].id].vehicles = [];
          }
          list[zones[z].id].vehicles.push(vehicles[v]);
          break;
        }
      }
    }
    return list;
  },
};
