import React from "react";
import ReactDOM from "react-dom";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { Notifications } from "react-push-notification";

import App from "./App";

import store from "./store";

import "typeface-roboto";

import "./index.css"; // Universal CSS

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}

ReactDOM.render(
  <SnackbarProvider maxSnack={4}>
    <Provider store={store}>
      <Notifications />
      <App />
    </Provider>
  </SnackbarProvider>,
  document.getElementById("root")
);
