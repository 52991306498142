import React, { Component } from "react";
import {
  TextField,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import ColorPicker from "material-ui-color-picker";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import IconsList from "./IconsList";
import FleetDropDown from "components/ui/dropdown/FleetDropDown";
import AutoComplete from "components/ui/autocomplete/AutoComplete";
import Btn from "@components/ui/button/Btn";

import { LABELS, NOTIFY, SUCCESS_MSG, ERRORS } from "common/consts";
import { API_Settings } from "apihandler/Settings";
import { commonJs } from "common/common";
import { DEFAULTS, COLORS } from "common/consts";
import { PAGE_ACTIONS } from "actions/PageActions";

import "../../vehiclelist/vehiclelist.css";

class EditVehicle extends Component {
  constructor(props) {
    super(props);

    // Get the Icon
    const { imei, vehicleSettings } = this.props;
    const { tmpVehicles } = this.props.mapReducer;
    let vicon = DEFAULTS.DEFAULT_VEHICLE_ICON;
    let vehicle = tmpVehicles.filter((arr) => {
      return arr.imei === imei;
    });
    let groupId = 0;
    if (vehicle.length > 0) {
      vicon = vehicle[0].icon;
      groupId = vehicle[0].groupId;
      vehicle = vehicle[0];
    } else {
      vehicle = {};
    }
    console.log("selected vehicle: ", vehicle);

    this.state = {
      routes: [],
      selectedRoute: {},
      trailers: [],
      selectedTrailer: {},
      iconPopup: false,
      icon: { value: vicon },
      groupId: groupId,
      odometerTypes: DEFAULTS.ODOMETER_TYPES,
      selectedOdometerType: vehicleSettings.odometertype || "gps",
      selectedEngineHours: vehicleSettings.enginehourstype || "acc",
      selectedGpsDevice: vehicleSettings.device || "acc",
      engineHoursTypes: DEFAULTS.ENGINE_HOUR_TYPES,
      gpsDevices: DEFAULTS.GPS_DEVICES,
      color: COLORS.GREEN,
      vehicleLoadStatus: vehicle.loadstatus || "",
      selectedVehicle: vehicle,
    };

    this.anchorEl = true;
  }

  componentDidMount() {
    const { icon } = this.state;
    let img = this._iconSheet.getIconImage(icon.value);
    if (img.length > 0) {
      this.setState({
        icon: img[0],
      });
    }
    document.getElementById("tailColor").style.background = this.state.color;
  }

  handleClick = () => {
    console.log("handleClick");
  };

  handleClose = () => {
    console.log("handleClose");
  };

  handleChange = () => {
    console.log("handleChange");
  };

  toggleIconSelector = () => {
    const { iconPopup } = this.state;
    this.setState({
      iconPopup: !iconPopup,
    });
  };

  onIconChange = (icon) => {
    console.log("onIconChange: ", icon.value);
    this.setState({
      icon: icon,
      iconPopup: false,
    });
  };

  onChangeGroup = (val) => {
    this.setState({
      groupId: val,
    });
  };

  onChangeOdometerType = (val) => {
    this.setState({
      selectedOdometerType: val,
    });
  };

  onChangeEngineHoursType = (val) => {
    this.setState({
      selectedEngineHours: val,
    });
  };

  onChangeGpsDevice = (val) => {
    this.setState({
      selectedGpsDevice: val,
    });
  };

  setColor = (color) => {
    document.getElementById("tailColor").style.background = color;
    document.getElementById("tailColor").value = color.toString();
    this.setState({
      color: color,
    });
  };

  onChangeRoute = (value) => {
    if (value !== null) {
      this.setState({
        selectedRoute: value,
      });
    }
  };

  onChangeTrailer = (value) => {
    if (value !== null) {
      this.setState({
        selectedTrailer: value,
      });
    }
  };

  onVehicleStatusChange = (event) => {
    this.setState({
      vehicleLoadStatus: event.target.value,
    });
  };

  saveSettings = () => {
    const { uid } = this.props.loginReducer;
    const name = document.getElementById("ev-VehicleName").value.trim();
    const imei = document.getElementById("ev-VehicleImei").value.trim();
    const model = document.getElementById("ev-VehicleModel").value.trim();
    const vehicleType = document.getElementById("ev-VehicleType").value.trim();
    const vehicleSize = document.getElementById("ev-VehicleSize").value.trim();
    const plateno = document.getElementById("ev-VehiclePlateNo").value.trim();
    const simno = document.getElementById("ev-VehicleSimNo").value.trim();
    const driverName = document.getElementById("ev-DriverName").value.trim();
    const driverNo = document.getElementById("ev-DriverNo").value.trim();
    const targetKms = document.getElementById("ev-TargetKms").value.trim();
    const groupId = document.getElementById("groupDropdown").value;
    const mapIcon = document.getElementById("mapIconDropdown").value;
    const iconUrl = document
      .getElementById("ev-VehicleIcon")
      .getAttribute("data-value");

    const tailColor = document.getElementById("tailColor").value.trim();
    const tailPoints = document
      .getElementById("ev-VehicleTailPoints")
      .value.trim();
    const gpsDevice = document.getElementById("gpsDevicesDropdown").value;
    const engineHoursType = document.getElementById("engineHoursDropdown")
      .value;
    const engineHours = document
      .getElementById("ev-VehicleEngineHrs")
      .value.trim();
    const odometerType = document.getElementById("odometerDropdown").value;
    const odometer = document.getElementById("ev-VehicleOdometer").value.trim();
    const routeId = document.getElementById("vehicleRoute").value;
    const trailerId = "";
    //document.getElementById("vehicleTrailer").value;
    let loadStatus = "";
    if (document.querySelector('input[name="ev-VehicleLoadStatus"]:checked')) {
      loadStatus = document.querySelector(
        'input[name="ev-VehicleLoadStatus"]:checked'
      ).value;
    }

    let args = {
      userId: uid,
      name,
      imei,
      model,
      vehicleType,
      vehicleSize,
      plateno,
      simno,
      driverName,
      driverNo,
      targetKms,
      groupId,
      mapIcon,
      iconUrl,
      tailColor,
      tailPoints,
      gpsDevice,
      engineHoursType,
      engineHours,
      odometerType,
      odometer,
      routeId,
      trailerId,
      loadStatus,
      // simno2,
    };

    const self = this;
    self.props.setLoading({
      loadingPopover: true,
    });
    API_Settings.editVehicleBasicDetails(args)
      .then((res) => {
        self.props.setLoading({
          loadingPopover: false,
        });
        if (res.Result === 200) {
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            SUCCESS_MSG.RECORD_SAVED,
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
          // Reload Vehicles
          self.props.loadVehicles();
        } else {
          commonJs.showNotify(
            self.props,
            NOTIFY.ERROR,
            ERRORS.RECORD_NOT_SAVED,
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
        }
      })
      .catch((err) => {
        self.props.setLoading({
          loadingPopover: false,
        });
        console.log(err);
      });
  };

  render() {
    const { imei, mapReducer, closePopup, vehicleSettings } = this.props;
    const {
      icon,
      iconPopup,
      groupId,
      odometerTypes,
      selectedOdometerType,
      engineHoursTypes,
      selectedEngineHours,
      gpsDevices,
      selectedGpsDevice,
      color,
      routes,
      selectedRoute,
      vehicleLoadStatus,
      trailers,
      selectedTrailer,
      selectedVehicle,
    } = this.state;

    const { tmpVehicles, userGroups } = mapReducer;

    let vname = "";
    const vehicle = tmpVehicles.filter((arr) => {
      return arr.imei === imei;
    });
    if (vehicle.length > 0) {
      vname = vehicle[0].name;
    }

    const groups = [];
    for (let gId in userGroups) {
      groups.push({ id: gId, text: userGroups[gId].name });
    }

    console.log("vehicleSettings: ", vehicleSettings);

    return (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <TextField
              id="ev-VehicleName"
              label="Vehicle Name"
              classes={{ root: "txtfield" }}
              autoFocus={true}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={vname}
            />
          </div>
          <div className="col">
            <TextField
              id="ev-VehicleImei"
              label="IMEI"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              value={imei}
              disabled
            />
          </div>
          <div className="col">
            <TextField
              id="ev-VehicleSimNo"
              label="SIM Card No."
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              defaultValue={selectedVehicle.simno || ""}
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <TextField
              id="ev-VehicleType"
              label="Vehicle Type"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              defaultValue={selectedVehicle.vin || ""}
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col">
            <TextField
              id="ev-VehicleModel"
              label="Transport Model"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              defaultValue={vehicleSettings.model || ""}
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col">
            <TextField
              id="ev-VehicleSize"
              label="Vehicle Size"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              defaultValue={vehicleSettings.vehiclesize || ""}
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <TextField
              id="ev-VehiclePlateNo"
              label="Plate No."
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              defaultValue={selectedVehicle.plateno || ""}
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col" id="vIconSelector">
            <label className="label">Icon:</label>
            <Button onClick={this.toggleIconSelector}>
              <img
                src={icon.img ? icon.img : ""}
                alt=""
                id="ev-VehicleIcon"
                data-value={icon.value}
                height={25}
              />
            </Button>
            <IconsList
              childRef={(ref) => (this._iconSheet = ref)}
              onIconChange={this.onIconChange}
              iconValue={icon}
              iconPopup={iconPopup}
            />
          </div>
          <div className="col">
            <FleetDropDown
              label="Show Icon On Map"
              list={[
                { id: "arrow", text: "Arrow" },
                { id: "icon", text: "Icon" },
              ]}
              selectedValue={selectedVehicle.mapicon || "Arrow"}
              noemptySelection={true}
              id="mapIconDropdown"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <ColorPicker
              id="tailColor"
              name="color"
              defaultValue={color}
              value={color}
              onChange={(color) => this.setColor(color)}
              classes={{ root: "txtfield colorpicker" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col">
            <TextField
              id="ev-VehicleTailPoints"
              label="Tail Points"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={vehicleSettings.tailpoints || ""}
            />
          </div>
          <div className="col">
            <FleetDropDown
              label="Group"
              list={groups}
              selectedValue={groupId}
              noemptySelection={true}
              id="groupDropdown"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <FleetDropDown
              label="GPS Device"
              list={gpsDevices}
              selectedValue={selectedGpsDevice}
              onChange={this.onChangeGpsDevice}
              noemptySelection={false}
              id="gpsDevicesDropdown"
            />
          </div>
          <div className="col">
            <FleetDropDown
              label="Engine Hours Type"
              list={engineHoursTypes}
              selectedValue={selectedEngineHours}
              onChange={this.onChangeEngineHoursType}
              noemptySelection={true}
              id="engineHoursDropdown"
            />
          </div>
          <div className="col">
            <TextField
              id="ev-VehicleEngineHrs"
              label="Engine hours (h)"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              defaultValue={vehicleSettings.enginehours || ""}
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <FleetDropDown
              label="Odometer Type"
              list={odometerTypes}
              selectedValue={selectedOdometerType}
              onChange={this.onChangeOdometerType}
              noemptySelection={true}
              id="odometerDropdown"
            />
          </div>
          <div className="col">
            <TextField
              id="ev-VehicleOdometer"
              label="Odometer (Km)"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              defaultValue={vehicleSettings.odometer || ""}
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col">
            <TextField
              id="ev-TargetKms"
              label="Target Kms"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              defaultValue={vehicleSettings.targetkms || ""}
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <TextField
              id="ev-DriverName"
              label="Driver Name"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              defaultValue={selectedVehicle.drivername || ""}
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col">
            <TextField
              id="ev-DriverNo"
              label="Driver Mobile No."
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              defaultValue={selectedVehicle.driverno || ""}
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="col">
            <AutoComplete
              label={"Select Route"}
              list={routes}
              id="vehicleRoute"
              style={{ marginBottom: 15, marginTop: 15 }}
              onChange={this.onChangeRoute}
              defaultValue={selectedRoute}
              multiple={false}
              size="small"
            />
          </div>
        </div>
        <div className="row">
          {/* <div className="col">
            <AutoComplete
              label={"Select Trailer"}
              list={trailers}
              id="vehicleTrailer"
              style={{ marginBottom: 15, marginTop: 15 }}
              onChange={this.onChangeTrailer}
              defaultValue={selectedTrailer}
              multiple={false}
              size="small"
            />
          </div> */}
          <div className="" style={{ width: "66%" }}>
            <FormControl component="fieldset" className="radioGroupInline">
              <FormLabel component="legend">Vehicle Load Status</FormLabel>
              <RadioGroup
                name="ev-VehicleLoadStatus"
                value={vehicleLoadStatus}
                onChange={this.onVehicleStatusChange}
              >
                <FormControlLabel
                  value="Empty"
                  control={<Radio />}
                  label="Empty"
                />
                <FormControlLabel
                  value="Placed"
                  control={<Radio />}
                  label="Placed"
                />
                <FormControlLabel
                  value="Loaded"
                  control={<Radio />}
                  label="Loaded"
                />
                <FormControlLabel
                  value="In Transit"
                  control={<Radio />}
                  label="In Transit"
                />
                <FormControlLabel
                  value="Other Issues"
                  control={<Radio />}
                  label="Other Issues"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: 10,
          }}
        >
          <div
            style={{
              width: "30%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Btn
              className="settingsBtn saveBtn font-12"
              onClick={this.saveSettings}
            >
              {LABELS.SAVE_CHANGES}
            </Btn>
            <Btn
              className="settingsBtn closeBtn"
              onClick={() => closePopup(false)}
            >
              {LABELS.CANCEL}
            </Btn>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_POPOVER_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(EditVehicle));
