import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import FleetDialog from "components/ui/dialogbox/FleetDialog";
import AutoComplete from "components/ui/autocomplete/AutoComplete";

import { commonJs } from "common/common";
import { NOTIFY } from "common/consts";
import { MAP_ACTIONS } from "actions/MapActions";
import { MAP_CONTROLS_ACTIONS } from "actions/MapControlsActions";
import { API_Map } from "apihandler/Map";

class DialogTrip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {},
      showDialog: false,
      stops: null,
      selectedRoute: [],
    };
  }

  componentDidMount() {
    let self = this;
    const { childRef } = this.props;
    childRef(this);
    API_Map.getAllZones().then((res) => {
      if (res.Result === 200) {
        self.setState({
          stops: res.Response,
        });
      }
    });
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  toggleDialog = (rec) => {
    const { showDialog } = this.state;
    if (rec.id === undefined) {
      console.log("toggleDialog Add:  ", rec, !showDialog);
      // this.props.setPointEdit({
      //   pointId: "",
      // });
      if (rec.add) {
        // Adding a new point
        this.setState({
          showDialog: !showDialog,
          rec: {},
        });
      } else {
        // Close
        this.setState({
          showDialog: !showDialog,
        });
        // this.props.setPointLatLng({
        //   pointLatLng: {},
        // });
      }
    } else {
      // Edit -> Open
      this.setState({
        showDialog: !showDialog,
        rec: rec,
      });
      // this.props.setPointEdit({
      //   pointId: point.id,
      // });`
    }
  };

  saveRecord = () => {
    const name = document.getElementById("etaTripName").value.trim();
    const desc = document.getElementById("etaTripDesc").value.trim();
    const { selectedRoute } = this.state;

    let self = this;

    if (name === "") {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Trip name should not be empty..!"
      );
      return false;
    } else if (!selectedRoute.id) {
      commonJs.showNotify(self.props, NOTIFY.WARNING, "Please select route..!");
      return false;
    }

    const args = {
      id: this.state.record.id || "",
      name: name,
      description: desc,
      routeId: selectedRoute.id,
    };

    console.log("saveRecord", args);

    this.setState({
      showDialog: false,
      rec: {},
    });
  };

  onChangeRoute = (val) => {
    this.setState({
      selectedRoute: val,
    });
  };

  render() {
    const { record, showDialog, stops, selectedRoute } = this.state;

    return (
      <FleetDialog
        id="addTripPopup"
        open={showDialog}
        onCloseSettings={this.toggleDialog}
        title={record.id ? "Edit Trip" : "Add Trip"}
        maxWidth="xs"
        fullWidth={false}
        cancelButton={true}
        cancelAction={this.toggleDialog}
        saveButton={true}
        saveAction={this.saveRecord}
        saveBtnLabel="OK"
        disableBackdropClick={true}
        disableBackdrop={true}
        classNameBody="zonePopupBody"
        classNameWrapper="zonePopupWrapper"
        draggable
      >
        <div className="grid">
          <div className="row">
            <TextField
              id="etaTripName"
              label="Trip Name"
              classes={{ root: "txtfield" }}
              autoFocus={true}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={record.name || ""}
            />
          </div>
          <div className="row">
            <TextField
              id="etaTripDesc"
              label="Trip Description"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={record.name || ""}
              rows={3}
              multiline
            />
          </div>
          <div className="row" style={{ marginBottom: 5 }}>
            <AutoComplete
              label={"Route"}
              list={stops}
              id="etaTripRoute"
              style={{ marginBottom: 15, width: "100%" }}
              onChange={this.onChangeRoute}
              defaultValue={selectedRoute}
              multiple={false}
              size="small"
            />
          </div>
        </div>
      </FleetDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleAddPoi: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_POI_CONTROL,
        values,
      });
    },
    setPointEdit: (values) => {
      dispatch({
        type: MAP_CONTROLS_ACTIONS.SET_POINT_EDIT,
        values,
      });
    },
    setPointLatLng: (values) => {
      dispatch({
        type: MAP_CONTROLS_ACTIONS.SET_POINT_LATLNG,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DialogTrip));
