import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip, Link, Breadcrumbs } from "@material-ui/core";
import {
  Replay as Reload,
  AddCircleOutline as Add,
  Delete,
  Edit,
  Check as ActiveIcon,
  Close as NotActiveIcon,
} from "@material-ui/icons";
import { connect } from "react-redux";

import UserDialog from "./UserDialog";
import SearchButton from "components/ui/searchbutton/SearchButton";
import ConfirmDialog from "components/ui/confirmdialog/ConfirmDialog";
import UserVehiclesDialog from "./UserVehiclesDialog";
import SubAccounts from "./SubAccounts";
import Btn from "components/ui/button/Btn";
import FleetGrid from "components/ui/grid/FleetGrid";

import { CPANEL_DESCRIPTION } from "common/consts";
import { REPORT_ACTIONS } from "actions/ReportActions";
import { API_CPanel } from "apihandler/CPanel";

class CPanelUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      columns: [
        { name: "slno", title: "Sl No." },
        { name: "id", title: "ID" },
        { name: "userName", title: "User Name" },
        { name: "companyName", title: "Company" },
        { name: "roleName", title: "User Role" },
        { name: "packageName", title: "Package" },
        { name: "registrationDate", title: "Reg. Date" },
        { name: "subAccBtn", title: "Sub Accounts" },
        { name: "vehiclesBtn", title: "Vehicles" },
        { name: "activeText", title: "Active" },
        { name: "options", title: "Options" },
      ],
      rows: null,
      filteredRows: [],
      userVehiclesDialog: false,
      subAccountsDialog: false,
      shareVehicleDialog: false,
      roles: null,
      privileges: null,
      companies: null,
      packages: null,
      managers: null,
      userId: null,
      userInfo: {},
      vehicles: null,
    };

    this._tableColumnWidths = [
      { columnName: "slno", width: 100 },
      { columnName: "id", width: 100 },
      { columnName: "userName", width: 200 },
      { columnName: "companyName", width: 150 },
      { columnName: "roleName", width: 150 },
      { columnName: "packageName", width: 150 },
      { columnName: "registrationDate", width: 150 },
      { columnName: "subAccBtn", width: 150 },
      { columnName: "vehiclesBtn", width: 150 },
      { columnName: "activeText", width: 100 },
      { columnName: "options", width: 150 },
    ];

    this._tableColumnExtensions = [
      { columnName: "slno", align: "center" },
      { columnName: "id", align: "left" },
      { columnName: "userName", align: "left" },
      { columnName: "companyName", align: "left" },
      { columnName: "roleName", align: "left" },
      { columnName: "packageName", align: "left" },
      { columnName: "registrationDate", align: "left" },
      { columnName: "subAccBtn", align: "center" },
      { columnName: "vehiclesBtn", align: "center" },
      { columnName: "activeText", align: "center" },
      { columnName: "options", align: "center" },
    ];

    this._timer = null;
  }

  onMenuClick = (menu) => {
    if (menu === "home") {
      window.open("/cpanel", "_self");
    }
  };

  componentDidMount() {
    document.getElementById("root").classList.add("scroll");
    document.getElementById("root").classList.remove("login");
    this.getMasters();
    this.props.setLoading({ loading: false });
  }

  loadGrid = (searchQry = "") => {
    let self = this;
    let args = {
      pageNo: 1,
      pageLength: 20,
      searchBy: "username",
      searchQry: searchQry,
    };
    console.log("loadGrid: ", args);
    API_CPanel.getAllUsers(args)
      .then((res) => {
        if (res.Result === 200) {
          let rows = res.Response;
          for (let r = 0; r < rows.length; r++) {
            rows[r].slno = r + 1;
            rows[r].name = rows[r].userName;
            rows[r].companyName = self.getCompanyName(rows[r].companyId);
            rows[r].roleName = self.getRoleName(rows[r].roleId);
            rows[r].packageName = self.getPkgName(rows[r].packageId);
            rows[r].options = self.getOptions(rows[r]);
            rows[r].subAccBtn = self.getSubAccountBtn(rows[r]);
            rows[r].vehiclesBtn = self.getVehiclesBtn(rows[r]);
            rows[r].activeText = rows[r].active ? (
              <ActiveIcon style={{ fontSize: 18, color: "#008000" }} />
            ) : (
              <NotActiveIcon style={{ fontSize: 18, color: "#ff0000" }} />
            );
          }
          console.log("rows: ", rows);

          let filteredRows = [];

          self.setState({
            rows: rows,
            filteredRows: filteredRows,
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getPkgName = (id) => {
    const { packages } = this.state;
    let name = "";
    let pkg = packages.filter((p) => p.id === id);
    if (pkg.length > 0) {
      name = pkg[0].name;
    }
    return name;
  };

  getRoleName = (id) => {
    const { roles } = this.state;
    let name = "";
    let role = roles.filter((r) => r.id === id);
    if (role.length > 0) {
      name = role[0].name;
    }
    return name;
  };

  getCompanyName = (id) => {
    const { companies } = this.state;
    let cname = "";
    let company = companies.filter((c) => c.id === id);
    if (company.length > 0) {
      cname = company[0].name;
    }
    return cname;
  };

  getMasters = () => {
    let self = this;
    API_CPanel.getCompanies().then((res) => {
      let list = [];
      if (res.Result === 200) {
        list = res.Response.map((c) => {
          return { id: c.companyId, name: c.companyName };
        });
      }
      self.getRoles(list);
    });
  };

  getRoles = (companies = []) => {
    let self = this;
    API_CPanel.getAllUserRoles().then((res) => {
      let roles = [];
      if (res.Result === 200) {
        roles = res.Response.map((r) => {
          return { id: r.id, name: r.role };
        });
      }
      self.getPackages(companies, roles);
    });
  };

  getPackages = (companies = [], roles = []) => {
    let self = this;
    API_CPanel.getAllPackages().then((res) => {
      let pkgs = [];
      if (res.Result === 200) {
        pkgs = res.Response.map((pkg) => {
          return { id: pkg.id, name: pkg.package };
        });
      }
      self.getManagers(companies, roles, pkgs);
    });
  };

  getManagers = (companies = [], roles = [], pkgs = []) => {
    let self = this;
    API_CPanel.getCompanyManagers().then((res) => {
      let managers = [];
      if (res.Result === 200) {
        managers = res.Response;
      }
      self.getVehicles(companies, roles, pkgs, managers);
    });
  };

  getVehicles = (companies = [], roles = [], pkgs = [], managers = []) => {
    this.getPrivileges(companies, roles, pkgs, managers, []);
    // let self = this;
    // const args = {};
    // args.pageNo = 0;
    // args.pageLength = 100000;
    // args.orderBy = "ASC";
    // args.sortColumn = "";
    // API_Map.getAllVehicles(args)
    //   .then((res) => {
    //     let vlist = res.map((v) => {
    //       return { id: v.imei, name: v.name };
    //     });
    //     self.getPrivileges(companies, roles, pkgs, managers, vlist);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  getPrivileges = (
    companies = [],
    roles = [],
    packages = [],
    managers = [],
    vehicles = []
  ) => {
    let self = this;
    API_CPanel.getAllUserPrivileges().then((res) => {
      let privileges = [];
      if (res.Result === 200) {
        privileges = res.Response.map((p) => {
          return { id: p.id, name: p.privilege };
        });
      }
      self.setState({
        companies,
        roles,
        privileges,
        managers,
        packages,
        vehicles,
      });
      self.loadGrid();
    });
  };

  setFilteredGridData = (rows) => {
    this.setState({
      filteredRows: rows,
    });
  };

  toggleDialog = (data) => {
    this._dialog.toggleDialog(data);
    console.log("data: ", data);
  };

  onSearch = (e) => {
    let qry = e.target.value.trim();
    let self = this;
    clearTimeout(this._timer);
    this._timer = setTimeout(() => {
      self.loadGrid(qry);
    }, 1500);
  };

  reload = () => {
    this.setState({
      rows: null,
    });
    let qry = document.getElementById("cpanelUserSearch").value.trim();
    this.loadGrid(qry);
  };

  onDelete = (id, name) => {
    this._dialogConfirm.toggleConfirmDialog(id, name);
  };

  deleteAll = () => {
    this._dialogConfirm.toggleConfirmDialog("");
  };

  toggleUserVehicleDialog = (userInfo = {}) => {
    const { userVehiclesDialog } = this.state;
    this.setState({
      userInfo,
      userVehiclesDialog: !userVehiclesDialog,
    });
    console.log("toggleUserVehicleDialog: ", userInfo);
  };

  toggleUserSubAccountDialog = (userId = null) => {
    const { subAccountsDialog } = this.state;
    this.setState({
      userId: { id: "11", name: "sdds" },
      subAccountsDialog: !subAccountsDialog,
    });
  };

  getOptions = (record) => {
    const { id, name } = record;
    return (
      <div className="options">
        <Tooltip
          title="Edit"
          placement="bottom"
          onClick={() => this.toggleDialog(record)}
        >
          <IconButton className="smallIcon">
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Delete"
          placement="bottom"
          onClick={() => this.onDelete(id, name)}
        >
          <IconButton className="smallIcon">
            <Delete />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  getSubAccountBtn = (record) => {
    const { id, name, subaccounts } = record;
    return (
      <Tooltip title="View Sub Account" placement="bottom">
        <Btn
          onClick={() => this.toggleUserSubAccountDialog({ id, name })}
          className="gridBtn"
        >
          <span>{subaccounts}</span>
        </Btn>
      </Tooltip>
    );
  };

  getVehiclesBtn = (record) => {
    const { id, name, vehicles } = record;
    return (
      <Tooltip title="Manage Vehicles" placement="bottom">
        <Btn
          onClick={() => this.toggleUserVehicleDialog({ id, name })}
          className="gridBtn"
        >
          <span>{vehicles}</span>
        </Btn>
      </Tooltip>
    );
  };

  render() {
    const {
      columns,
      rows,
      companies,
      roles,
      privileges,
      packages,
      managers,
      userId,
      userInfo,
      userVehiclesDialog,
      subAccountsDialog,
    } = this.state;

    console.log("users:", rows, userId);

    return (
      <div className="cpanelPageWrapper">
        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
          <Link
            color="inherit"
            href="/cpanel"
            onClick={() => this.onMenuClick("home")}
          >
            CPanel
          </Link>
          <p>Users</p>
        </Breadcrumbs>
        <p className="reportDescription">{CPANEL_DESCRIPTION.ADD_USER}</p>
        <div id="cpanelUsersWrapper">
          <ul className="gridActionBtns">
            <li>
              <SearchButton
                id="cpanelUserSearch"
                placeholder="Search By User Name"
                buttonClass="pad5 iconButton searchBtn"
                inputClass="SearchInput"
                iconwidth="18"
                iconheight="18"
                onChange={this.onSearch}
              />
            </li>
            <li>
              <Tooltip title="Reload" placement="bottom">
                <IconButton onClick={this.reload}>
                  <Reload />
                </IconButton>
              </Tooltip>
            </li>
            <li>
              <Tooltip title="Add User" placement="bottom">
                <IconButton onClick={() => this.toggleDialog({ add: true })}>
                  <Add />
                </IconButton>
              </Tooltip>
            </li>
            {/* <li>
                <Tooltip title="Delete" placement="bottom">
                  <IconButton onClick={this.deleteAll}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </li> */}
          </ul>

          <FleetGrid
            rows={rows}
            columns={columns}
            id="cpanelUsers"
            childRef={(ref) => (this._grid = ref)}
            height={220}
            setFilteredGridData={this.setFilteredGridData}
            tableColumnWidths={this._tableColumnWidths}
            tableColumnExtensions={this._tableColumnExtensions}
          />

          <UserDialog
            childRef={(ref) => (this._dialog = ref)}
            companies={companies}
            roles={roles}
            privileges={privileges}
            packages={packages}
            managers={managers}
          />

          <ConfirmDialog
            childRef={(ref) => (this._dialogConfirm = ref)}
            onOk={this.onDelete}
            id=""
            name="User"
          />

          {userVehiclesDialog && <UserVehiclesDialog userInfo={userInfo} />}

          {subAccountsDialog && <SubAccounts userInfo={userInfo} />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (values) => {
      dispatch({
        type: REPORT_ACTIONS.SET_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(CPanelUsers));
