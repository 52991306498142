import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { ArrowForward } from "@material-ui/icons";
import { Link, Breadcrumbs } from "@material-ui/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import jQuery from "jquery";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.flash";
import "pdfmake/build/vfs_fonts";
import "pdfmake/build/pdfmake";

import UiDatePicker from "components/ui/uidatepicker/UiDatePicker";
import AutoComplete from "components/ui/autocomplete/AutoComplete";
import Btn from "components/ui/button/Btn";
import FleetDropDown from "components/ui/dropdown/FleetDropDown";

import {
  NOTIFY,
  ALERTS,
  FORMATS,
  REPORT_NAMES,
  REPORT_DESCRIPTION,
  DEFAULTS,
} from "common/consts";
import { commonJs } from "common/common";
import { dateFunction } from "common/datefunctions";
import { COMMON_ACTIONS } from "actions/CommonActions";
import { REPORT_ACTIONS } from "actions/ReportActions";
import { API_Reports } from "apihandler/Reports/aviksha";
import { API_Map } from "apihandler/Map";
import { API_UserLogin } from "apihandler/UserLogin";

var $ = jQuery;

class FuelTimelineReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicles: [],
      graphData: [],
      fuelTimelineMin: DEFAULTS.SAMPLING_TIME,
      selectedVehicle: {},
      selectedMin: {
        text: "Every 1 Min",
        value: "1",
      },
      fdt: dateFunction.getDate("thismonth", "from", FORMATS.SQL_DATE),
      tdt: dateFunction.getDate("thismonth", "to", FORMATS.SQL_DATE),
    };
    this.chart = null;
  }

  componentDidMount() {
    document.getElementById("root").classList.remove("login");
    document.getElementById("root").classList.add("scroll");
    this.getInitialData();
  }

  getInitialData = () => {
    const args = {};
    let self = this;
    args.pageNo = 0;
    args.pageLength = 100000;
    args.orderBy = "ASC";
    args.sortColumn = "";
    API_UserLogin.setToken(localStorage.token);
    API_Map.getAllVehicles(args)
      .then((res) => {
        self.props.setVehicles({
          vehicles: res,
        });
        self.props.setLoading({
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleDateChange = (type, dt) => {
    if (type === "from") {
      this.setState({
        fdt: dt,
      });
    } else {
      this.setState({ tdt: dt });
    }
  };

  onChangeVehicle = (vehicle) => {
    this.setState({
      selectedVehicle: vehicle,
    });
  };

  onChangeEveryMin = (everyMin) => {
    this.setState({
      selectedMin: everyMin,
    });
  };

  loadReport = () => {
    const { selectedVehicle, selectedMin, fdt, tdt } = this.state;
    let self = this;

    if (!selectedVehicle.id) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        ALERTS.PLEASE_SELECT_VEHICLE,
        commonJs.getDefaultDismissBtn()
      );
      return;
    }

    this.props.setLoading({
      loading: true,
    });

    let args = {
      pageNo: 1,
      pageLength: DEFAULTS.DEFAULT_PAGE_LENGTH,
      imei: selectedVehicle.id,
      fromDate: fdt,
      toDate: tdt,
      stopTime: selectedMin.id,
    };

    API_Reports.getFuelTimelineReport(args)
      .then((res) => {
        self.props.setLoading({
          loading: false,
        });
        if (res.Result === 200) {
          const { rows, cols } = res.Response;
          console.log("FuelTimelineReport", res);
          let columns = [];
          for (var c = 0; c < cols.length; c++) {
            columns.push({ title: cols[c] });
          }

          //Generate Graph Data using "rows"
          let graphData = [];
          for (var r = 0; r < rows.length; r++) {
            let timeValue = new Date(rows[r][1]).getTime();
            let tempValue = Number(rows[r][2]);
            console.log("row: ", timeValue, tempValue);
            graphData.push([timeValue, tempValue]);
          }
          this.setState({
            graphData,
          });

          if ($.fn.dataTable.isDataTable("#reportGrid")) {
            let table = $("#reportGrid").DataTable();
            table.destroy();
          }
          $("#reportGrid").empty();

          let title =
            REPORT_NAMES.FUEL_TIMELINE_REPORT +
            " - " +
            selectedVehicle.name +
            " (" +
            dateFunction.getFormatedDate(
              fdt,
              FORMATS.SQL_DATE,
              FORMATS.REPORT_TITLE_DATE
            ) +
            "-" +
            dateFunction.getFormatedDate(
              tdt,
              FORMATS.SQL_DATE,
              FORMATS.REPORT_TITLE_DATE
            );

          $("#reportGrid").DataTable({
            dom: "Bfrtip",
            buttons: [
              {
                extend: "copy",
                className: "reportBtn",
                title: title,
              },
              {
                extend: "pdf",
                className: "reportBtn",
                title: title,
              },
              {
                extend: "csv",
                className: "reportBtn",
                title: title,
                text: "CSV",
              },
              {
                extend: "excel",
                className: "reportBtn",
                title: title,
              },
              {
                extend: "print",
                className: "reportBtn",
                title: title,
                customize: function (win) {
                  commonJs.getExportPrintCss(win);
                },
              },
            ],
            paging: true,
            scrollY: DEFAULTS.REPORT_GRID_HEIGHT,
            columns: columns,
            data: rows,
          });
        }
      })
      .catch((err) => {
        console.log("getfuelTimelineReport: err:", err);
      });
  };

  onFilterDate = (type) => {
    let fdt = dateFunction.getDate(type, "from", FORMATS.SQL_DATE);
    let tdt = dateFunction.getDate(type, "to", FORMATS.SQL_DATE);
    this.setState({
      fdt,
      tdt,
    });
  };

  getKmData = () => {
    const { fdt, tdt, selectedMin } = this.state;
    let args = {
      fromDate: fdt,
      toDate: tdt,
      stopTime: selectedMin.id,
    };
    let self = this;
    API_Reports.getFuelTimelineReport("getGraph", args)
      .then((res) => {
        if (res.Result === 200) {
          let rows = res.Response.rows;
          let sum = 0;
          rows.map((row) => {
            if (row.length > 1) {
              row[1] = Number(row[1]);
              sum += row[1];
            }
            console.log("rows", row);
            return row;
          });
          self.setState({
            graphData: rows,
            loading: false,
            totalTime: sum,
          });
        }
      })
      .catch((err) => {
        self.setState({
          loading: false,
        });
      });
  };

  render() {
    const {
      selectedVehicle,
      fuelTimelineMin,
      selectedMin,
      fdt,
      tdt,
      graphData,
    } = this.state;
    const { vehiclesList } = this.props.commonReducer;

    const title = "";
    const options = {
      chart: {
        zoomType: "x",
        height: "450px",
      },
      credits: false,
      title: {
        text: title,
      },
      subtitle: {
        text:
          document.ontouchstart === undefined
            ? "Click and drag in the plot area to zoom in"
            : "Pinch the chart to zoom in",
      },
      xAxis: {
        title: {
          text: "Date",
        },
        type: "datetime",
      },
      yAxis: {
        title: {
          text: "Fuel value",
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        area: {
          keys: ["x", "y", "marker"],
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, "#512DA8"],
              [1, "#FFF"],
            ],
          },
          marker: {
            radius: 2,
          },
          lineWidth: 1,
          lineColor: "#512DA8",
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          threshold: null,
        },
      },
      series: [
        {
          type: "area",
          name: "Fuel value",
          data: graphData,
          color: "#512DA8",
          dataLabels: {
            enabled: true,
            rotation: -90,
            // color: "#2d2d2d",
            align: "center",
            format: "{point.y:.2f}", // two decimal
            y: -15, // -30 pixels down from the top
            style: {
              fontSize: "12px",
            },
          },
        },
      ],
    };

    let vehicles = [];
    for (var i = 0; i < vehiclesList.length; i++) {
      vehicles.push({ id: vehiclesList[i].imei, name: vehiclesList[i].name });
    }

    return (
      <div className="reportPageWrapper height100">
        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
          <Link color="inherit" href="/">
            Home
          </Link>
          <p>{REPORT_NAMES.FUEL_TIMELINE_REPORT}</p>
        </Breadcrumbs>
        <p className="reportDescription">
          {REPORT_DESCRIPTION.FUEL_TIMELINE_REPORT}
        </p>
        <div className="reportFilters">
          <div className="width30 uidatepicker" style={{ paddingLeft: "0px" }}>
            <div className="datepickerTop">
              <UiDatePicker
                label="From Date :"
                dt={fdt}
                type="from"
                disablePast={false}
                disableFuture={true}
                disable={false}
                datepicker={false}
                handleDateChange={this.handleDateChange}
              />
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ArrowForward />
              </div>
              <UiDatePicker
                label="To Date :"
                dt={tdt}
                type="to"
                disablePast={false}
                disableFuture={false}
                disabled={false}
                // maxDate={dateFunction.getDate("thismonth", "to")}
                handleDateChange={this.handleDateChange}
              />
            </div>
            <div className="datepickerBottom">
              <ul>
                <li>Filters: </li>
                <li onClick={() => this.onFilterDate("today")}>Today</li>
                <li onClick={() => this.onFilterDate("yesterday")}>
                  Yesterday
                </li>
                <li onClick={() => this.onFilterDate("thismonth")}>
                  This Month
                </li>
                <li onClick={() => this.onFilterDate("lastmonth")}>
                  Last Month
                </li>
              </ul>
            </div>
          </div>
          <div className="width20" style={{ marginTop: 10, marginBottom: 10 }}>
            <AutoComplete
              label={"Select Vehicle"}
              list={vehicles}
              onChange={this.onChangeVehicle}
              defaultValue={selectedVehicle}
              multiple={false}
              size="small"
            />
          </div>
          <div
            className="width20"
            style={{ marginTop: 10, marginBottom: 10, paddingLeft: 10 }}
          >
            <FleetDropDown
              label={"Select Min"}
              list={fuelTimelineMin}
              noemptySelection={true}
              defaultValue={selectedMin}
              onChange={this.onChangeEveryMin}
              multiple={false}
              size="small"
            />
          </div>
          <div className="width10 padLeft-10">
            <Btn onClick={this.loadReport} className="primaryBtn">
              Submit
            </Btn>
          </div>
        </div>
        <HighchartsReact
          // ref={(ref) => (this.chart = ref)}
          highcharts={Highcharts}
          options={options}
          style={{ height: "100%" }}
        />

        <div className="reportGridWrapper">
          <table
            id="reportGrid"
            className="mdl-data-table width100 paginated"
            cellSpacing="0"
            cellPadding="3"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commonReducer: state.commonReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setVehicles: (values) => {
      dispatch({
        type: COMMON_ACTIONS.SET_VEHICLES,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: REPORT_ACTIONS.SET_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(FuelTimelineReport));
