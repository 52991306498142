import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import FleetDialog from "components/ui/dialogbox/FleetDialog";
import AutoComplete from "components/ui/autocomplete/AutoComplete";
import UiDatePicker from "components/ui/uidatepicker/UiDatePicker";

import { commonJs } from "common/common";
import { NOTIFY, FORMATS } from "common/consts";
import { dateFunction } from "common/datefunctions";
import { API_Settings } from "apihandler/Settings";

class DialogAddUserVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {},
      users: [],
      selectedVehicle: {},
      selectedUser: {},
      fdt: dateFunction.getDate("thismonth", "from", "YYYY-MM-DD HH:mm:ss"),
      tdt: dateFunction.getDate("thismonth", "to", "YYYY-MM-DD HH:mm:ss"),
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    const { uid } = this.props.loginReducer;
    const args = {
      managerId: uid,
    };
    let self = this;
    API_Settings.getAllSubAccounts(args).then((res) => {
      self.setState({
        users: res.Response.map((u) => {
          return { id: u.userId, name: u.userName };
        }),
      });
    });
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  saveRecord = () => {
    const { selectedVehicle, selectedUser, fdt, tdt } = this.state;
    const { uid } = this.props.loginReducer;

    // Form Validation
    if (!selectedUser || !selectedUser.id) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Please select the User..!"
      );
      return false;
    } else if (!selectedVehicle || !selectedVehicle.id) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Please select the Vehicle..!"
      );
      return false;
    }

    let args = {
      managerId: uid,
      userId: selectedUser.id,
      imei: selectedVehicle.id,
      accessFrom: fdt,
      accessTo: tdt,
    };

    let self = this;
    API_Settings.managerSubUserVehicle(args).then((res) => {
      if (res.Result === 200) {
        self.props.reloadGrid();
        self.props.togglePopup({});
      } else {
        commonJs.showNotify(self.props, NOTIFY.ERROR, res.Reason);
      }
    });
  };

  onChangeVehicle = (val) => {
    this.setState({
      selectedVehicle: val,
    });
  };

  onChangeUser = (val) => {
    this.setState({
      selectedUser: val,
    });
  };

  setData = (rec) => {
    this.setState({
      record: rec,
      selectedVehicle: { id: rec.imei, name: rec.vehicleName },
      selectedUser: { id: rec.userId, name: rec.userName },
      fdt: rec.activeFrom,
      tdt: rec.activeTill,
    });
  };

  handleDateChange = (type, dt) => {
    let dtformat = "YYYY-MM-DD HH:mm";
    dt = dateFunction.getFormatedDate(dt, FORMATS.SQL_DATE, dtformat);
    if (type === "from") {
      dt += ":00";
    } else {
      dt += ":59";
    }
    if (type === "from") {
      this.setState({
        fdt: dt,
      });
    } else {
      this.setState({
        tdt: dt,
      });
    }
  };

  render() {
    const {
      record,
      users,
      selectedVehicle,
      selectedUser,
      fdt,
      tdt,
    } = this.state;

    const { showDialog, mapReducer } = this.props;

    const { tmpVehicles } = mapReducer;

    return (
      showDialog && (
        <FleetDialog
          id="addUserVehicle"
          open={true}
          onCloseSettings={this.props.togglePopup}
          title={record.userId ? "Edit Assign Vehicle" : "Assign Vehicle"}
          maxWidth="xs"
          fullWidth={false}
          cancelButton={true}
          saveButton={true}
          cancelAction={this.props.togglePopup}
          saveAction={this.saveRecord}
          saveBtnLabel="OK"
          disableBackdropClick={false}
          disableBackdrop={false}
          classNameBody="zonePopupBody"
          classNameWrapper="zonePopupWrapper settingsDialogBox"
          draggable={false}
        >
          <div className="grid">
            <div className="row">
              <AutoComplete
                label={"Select User"}
                list={users}
                id="assignedUser"
                style={{ marginBottom: 15, width: "100%" }}
                onChange={this.onChangeUser}
                defaultValue={selectedUser}
                multiple={false}
                size="small"
              />
            </div>
            <div className="row">
              <AutoComplete
                label={"Select Vehicle"}
                list={tmpVehicles}
                id="assignedVehicle"
                style={{ marginBottom: 15, width: "100%" }}
                onChange={this.onChangeVehicle}
                defaultValue={selectedVehicle}
                multiple={false}
                size="small"
              />
            </div>
            <div className="row">
              <UiDatePicker
                label="Active From:"
                dt={fdt}
                type="from"
                disablePast={false}
                disableFuture={true}
                disabled={false}
                datepicker={true}
                handleDateChange={this.handleDateChange}
              />
            </div>
            <div className="row">
              <UiDatePicker
                label="Active Till:"
                dt={tdt}
                type="to"
                disablePast={false}
                disableFuture={false}
                disabled={false}
                datepicker={true}
                handleDateChange={this.handleDateChange}
              />
            </div>
          </div>
        </FleetDialog>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DialogAddUserVehicle));
