import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { Replay as Reload, AddCircleOutline as Add } from "@material-ui/icons";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import SearchButton from "components/ui/searchbutton/SearchButton";
import ZonesGrid from "./ZonesGrid";
import DialogZone from "./DialogZone";
import DialogConfirm from "./DialogConfirm";

import { NOTIFY, SUCCESS_MSG, LABELS, ERRORS, ROLES } from "common/consts";
import { commonJs } from "common/common";
import { PAGE_ACTIONS } from "actions/PageActions";
import { API_Map } from "apihandler/Map";

import "./zones.css";

class ZonesTab extends Component {
  zoneSearch = (e) => {
    this._zonesGrid.zoneSearch(e.target.value.trim());
  };

  deleteZone = (id, name = null) => {
    console.log("deleteZone: ", id, name);
    if (!name) {
      let self = this;
      self.props.toggleLoading({
        loadingPopover: true,
      });
      API_Map.deleteZone({ id })
        .then((res) => {
          if (res.Result === 200) {
            commonJs.showNotify(
              self.props,
              NOTIFY.SUCCESS,
              SUCCESS_MSG.ZONE_DELETED_SUCCESSFULLY,
              <button className="snackbar-btn">{LABELS.DISMISS}</button>
            );
            self._dialogConfirm.toggleConfirmDialog(id, name);
            self.props.toggleLoading({
              loadingPopover: false,
            });
            self.reloadZones();
          } else {
            console.log("Delete Zone Error: ", res.Reason);
            commonJs.showNotify(
              self.props,
              NOTIFY.ERROR,
              ERRORS.ZONE_NOT_DELETED,
              <button className="snackbar-btn">{LABELS.DISMISS}</button>
            );
            self.props.toggleLoading({
              loadingPopover: false,
            });
          }
        })
        .catch((err) => {
          console.log("Delete Zone Error: ", err);
          commonJs.showNotify(
            self.props,
            NOTIFY.ERROR,
            ERRORS.ZONE_NOT_DELETED,
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
          self.props.toggleLoading({
            loadingPopover: false,
          });
        });
    } else {
      this._dialogConfirm.toggleConfirmDialog(id, name);
    }
  };

  deleteAllZones = () => {
    this._dialogConfirm.toggleConfirmDialog("");
  };

  reloadZones = () => {
    this._zonesGrid.loadReloadZones();
  };

  render() {
    const { role } = this.props.loginReducer;
    return (
      <div className="zonesTab">
        <ul>
          <li>
            <SearchButton
              id="zoneSearch"
              placeholder="Search By Zone Name"
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
              onChange={this.zoneSearch}
            />
          </li>
          <li className="poiBtn">
            <Tooltip title="Reload" placement="bottom">
              <IconButton onClick={this.reloadZones}>
                <Reload />
              </IconButton>
            </Tooltip>
          </li>
          {role !== ROLES.SUB_USER && (
            <li className="poiBtn">
              <Tooltip title="Add Zone" placement="bottom">
                <IconButton
                  onClick={() =>
                    this._zonePopup.toggleAddZoneDialog({ add: true })
                  }
                >
                  <Add />
                </IconButton>
              </Tooltip>
            </li>
          )}
          {/* <li className="poiBtn">
            <Tooltip title="Delete All Zones" placement="bottom">
              <IconButton onClick={this.deleteAllZones}>
                <Delete />
              </IconButton>
            </Tooltip>
          </li> */}
        </ul>

        <ZonesGrid
          childRef={(ref) => (this._zonesGrid = ref)}
          toggleAddZoneDialog={(zone) =>
            this._zonePopup.toggleAddZoneDialog(zone)
          }
          deleteZone={this.deleteZone}
          zoneFitBounds={this.props.zoneFitBounds}
          updateMapRegion={this.props.updateMapRegion}
        />

        <DialogZone
          childRef={(ref) => (this._zonePopup = ref)}
          zoneFitBounds={this.props.zoneFitBounds}
          updateMapRegion={this.props.updateMapRegion}
          reloadZones={this.reloadZones}
        />

        <DialogConfirm
          childRef={(ref) => (this._dialogConfirm = ref)}
          deleteZone={this.deleteZone}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_POPOVER_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ZonesTab));
