import React from "react";
import { CONFIG } from "../../common/consts";
import "./style.css";

import AppStore from "../../images/app/AppStore.png";
import PlayStore from "../../images/app/PlayStore.png";
import Logo from "../../images/logo/logo.png";

export const DownloadApp = () => {
  return (
    <div className="downloadApp">
      <img src={Logo} className="logo" alt="" />
      <h4>{CONFIG.DOWNLOAD_APP}</h4>
      <ul>
        <li>
          <a
            href={CONFIG.APPSTORE_ID}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={AppStore} alt="" />
          </a>
        </li>
        <li>
          <a
            href={CONFIG.PLAYSTORE_ID}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={PlayStore} alt="" />
          </a>
        </li>
      </ul>
    </div>
  );
};
