import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import addNotification from "react-push-notification";

import FixedTopBar from "../layouts/topbar/FixedTopBar";
import LeftBar from "../layouts/leftbar/LeftBar";
import FleetMap from "./fleetmap";
import BottomBar from "./bottombar/BottomBar";
import LoadingDiv from "components/ui/loaders/LoadingDiv";

import {
  ALERTS,
  NOTIFY,
  LOCAL_STORAGE,
  DEFAULTS,
  FORMATS,
  CONFIG,
} from "common/consts";
import { commonJs } from "common/common";
import { dateFunction } from "common/datefunctions";
import { TRACK_ACTIONS } from "actions/TrackActions";
import { MAP_ACTIONS } from "actions/MapActions";
import { TMP_ACTIONS } from "actions/TmpActions";
import { API_UserLogin } from "apihandler/UserLogin";

import "./mappage.css";
import { API_Map } from "apihandler/Map";
import { API_Settings } from "apihandler/Settings";

class MapPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("root").classList.remove("login");
    var self = this;
    API_UserLogin.checkLogin()
      .then((res) => {
        if (!res.Response) {
          commonJs.showNotify(
            self.props,
            NOTIFY.WARNING,
            ALERTS.INTERNAL_SERVER_ERROR
          );
        }
      })
      .catch((err) => {
        commonJs.showNotify(self.props, NOTIFY.WARNING, ALERTS.SESSION_TIMEOUT);
        var userData = commonJs.getItemFromLocal(LOCAL_STORAGE.USER_DATA);
        userData.token = null;
        commonJs.setItemToLocal(LOCAL_STORAGE.USER_DATA, userData);
        window.location = "/";
      });

    navigator.serviceWorker.addEventListener("message", (message) => {
      const { data } = message;
      const showNotification = data.messageType === "push-received";
      console.log("[Web Push]: Show Notification: ", showNotification, message);
      if (!showNotification) {
        this.showAlert(data.notification);
      } else {
        addNotification({
          title: CONFIG.SITE_NAME,
          subtitle: data.notification.title,
          message: data.notification.body,
          icon: "/logo.png",
          position: "top-right", // One of top-left, top-middle, top-right, bottom-left, bottom-middle, bottom-right. Default: top-left
          onClick: (e) => {
            this.showAlert(data.notification);
          }, //optional, onClick callback.
          native: true, // when using native, your OS will handle theming.
          theme: "light", //Optional. One of darkblue, red, light, undefined. Default: undefined
          duration: 5000, //Optional. Duration of the push notification in ms. Default: 3000
          backgroundTop: "white", //optional, background color of top container.
          backgroundBottom: "darkgreen", //optional, background color of bottom container.
          colorTop: "black", //optional, font color of top container.
          colorBottom: "black", //optional, font color of bottom container.
          closeButton: "Close", //optional, text or html/jsx element for close text. Default: Close,
        });
      }
    });
  }

  showAlert = (data = {}) => {
    console.log("[Web Push]: Web Push Clicked: ", data);
    const lat = 26.221051;
    const lng = 73.627745;
    const title = data.body;
    const fixtime = "2021-08-14 12:47:54";
    const angle = 0;
    let alertObj = {
      data: {
        angle,
        time: fixtime,
        title,
      },
      lat,
      lng,
    };
    this.props.setTmpMarker({
      tmpMarker: alertObj,
    });
    let self = this;
    if (document.createEvent) {
      let evt = document.createEvent("MouseEvents");
      evt.initEvent("click", true, false);
      setTimeout(() => {
        self.updateMapRegion(lat, lng, DEFAULTS.TRACK_ZOOM);
        let markerId =
          lat.toString().replace(".", "") +
          "-" +
          lng.toString().replace(".", "");
        if (document.querySelector("#tmpMarker-" + markerId + ">img")) {
          document
            .querySelector("#tmpMarker-" + markerId + ">img")
            .dispatchEvent(evt);
        }
      }, 1000 * 3);
    }
  };

  onPrintMap = () => {
    this._fleetmap.printMap();
  };

  logout = () => {
    API_UserLogin.logout()
      .then((res) => {
        window.location = "/";
        //TODO: Clear cache && reset login
        commonJs.setItemToLocal("map", null);
      })
      .catch((err) => {
        window.location = "/";
      });
  };

  updateMapRegion = (lat, lng, zoom) => {
    this._fleetmap.updateRegion(lat, lng, zoom);
  };

  showTrack = (filter, imei) => {
    //Update Imei, Filter
    this.props.setSelectedVehicle({
      selectedVehicle: imei,
    });
    this.props.setTrackFilter({
      fdt: dateFunction.getDate(filter, "from", FORMATS.SQL_DATE),
      tdt: dateFunction.getDate(filter, "to", FORMATS.SQL_DATE),
      stopTime: DEFAULTS.DEFAULT_TRACK_STOPTIME,
      selectedTrackDuration: filter,
    });
    if (!document.querySelector(".mapTabWrapper")) {
      //Fix given when lefttab closed
      this._leftbar.onMenuToggle("leftMapMenu");
      let self = this;
      setTimeout(() => {
        self._leftbar._mapTabs.switchTab("track", "track");
      }, 500);
    } else {
      this._leftbar._mapTabs.switchTab("track", "track");
    }
  };

  trackFitBounds = () => {
    this._fleetmap.trackFitBounds();
  };

  mapFitBounds = () => {
    this._fleetmap.mapFitBounds();
  };

  zoneFitBounds = (data) => {
    this._fleetmap.zoneFitBounds(data);
  };

  shareVehicle = (imei) => {
    this._topbar._settingsDialog.handleChange(null, 5);
    this._topbar._settingsDialog.toggleSettingsDialog();
    let self = this;
    setTimeout(() => {
      self._topbar._settingsDialog.openShareVehicles();
    }, 1000 * 2);
  };

  render() {
    return (
      <div className={"mapPage"}>
        <LoadingDiv />

        <FixedTopBar
          onPrintMap={this.onPrintMap}
          mapFitBounds={this.mapFitBounds}
          showPoint={this.showPoint}
          updateMapRegion={this.updateMapRegion}
          childRef={(ref) => (this._topbar = ref)}
        />

        <LeftBar
          varient={DEFAULTS.LEFT_BAR_VARIENT}
          updateMapRegion={this.updateMapRegion}
          trackFitBounds={this.trackFitBounds}
          zoneFitBounds={this.zoneFitBounds}
          shareVehicle={this.shareVehicle}
          childRef={(ref) => (this._leftbar = ref)}
        />

        <FleetMap
          childRef={(ref) => (this._fleetmap = ref)}
          showTrack={this.showTrack}
          shareVehicle={this.shareVehicle}
        />

        <BottomBar updateMapRegion={this.updateMapRegion} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTmpMarker: (values) => {
      dispatch({
        type: TMP_ACTIONS.SET_TMP_MARKER,
        values,
      });
    },
    setTrackFilter: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_TRACK_FILTER,
        values,
      });
    },
    setSelectedVehicle: (values) => {
      dispatch({
        type: MAP_ACTIONS.SELECT_VEHICLE,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(MapPage));
