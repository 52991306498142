import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  Replay as Reload,
  AddCircleOutline as Add,
  Delete,
  Edit,
  Check as ActiveIcon,
  Close as NotActiveIcon,
} from "@material-ui/icons";
import { withSnackbar } from "notistack";

import FleetDialog from "components/ui/dialogbox/FleetDialog";
import FleetGrid from "components/ui/grid/FleetGrid";
import SearchButton from "components/ui/searchbutton/SearchButton";
import ConfirmDialog from "components/ui/confirmdialog/ConfirmDialog";
import AddUserVehicleDialog from "./AddUserVehicleDialog";

import { DEFAULTS, LABELS, NOTIFY } from "common/consts";
import { commonJs } from "common/common";
import { API_CPanel } from "apihandler/CPanel";

class UserVehiclesDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {},
      addVehicleDialog: true,
      draggable: true,
      columns: [
        {
          name: "slno",
          title: "Sl No.",
        },
        {
          name: "vehicleName",
          title: "Vehicle Name",
        },
        {
          name: "imei",
          title: "IMEI",
        },
        {
          name: "simNumber",
          title: "SIM Number",
        },
        {
          name: "activeText",
          title: "Active",
        },
        {
          name: "accessFrom",
          title: "Access From",
        },
        {
          name: "accessTill",
          title: "Access Till",
        },
        {
          name: "options",
          title: "Options",
        },
      ],
      rows: null,
      filteredRows: [],
    };
  }

  componentDidMount() {
    // this.loadGrid();
  }

  loadGrid = () => {
    const { userInfo } = this.props;
    let self = this;
    let args = {
      userId: userInfo.id, // 0 -> then all vehicles
      imei: "", // "" -> ignore if it is empty
      pageNo: 1,
      pageLength: 10000,
      searchQry: "", // "imei" and "vehicle name"
    };
    console.log("args: ", args);
    API_CPanel.getCpanelVehicleList(args)
      .then((res) => {
        console.log("loadGrid: ", res);
        if (res.Result === 200) {
          let rows = res.Response;
          for (let r = 0; r < rows.length; r++) {
            rows[r].slno = Number(r + 1);
            rows[r].id = rows[r].imei;
            rows[r].name = rows[r].vehicleName;
            rows[r].options = this.getOptions(rows[r]);
            rows[r].activeText = rows[r].active ? (
              <ActiveIcon style={{ fontSize: 18, color: "#008000" }} />
            ) : (
              <NotActiveIcon style={{ fontSize: 18, color: "#ff0000" }} />
            );
          }

          // Check if any text in Search Box
          let searchQry = document
            .getElementById("cpanelUserVehicleSearch")
            .value.trim()
            .toUpperCase();
          let filteredRows = [];
          if (searchQry.length > 0) {
            filteredRows = rows.filter((row) => {
              return (
                row.name.toUpperCase().indexOf(searchQry) > -1 ||
                row.imei.indexOf(searchQry) > -1
              );
            });
          }

          self.setState({
            rows: rows,
            filteredRows: filteredRows,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getOptions = (record) => {
    const { id, name } = record;
    return (
      <div className="options">
        <Tooltip
          title={LABELS.EDIT}
          placement={DEFAULTS.TOOLTIP_PLACEMENT}
          onClick={() => this.editDialog(record)}
        >
          <IconButton className="smallIcon">
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={LABELS.DELETE}
          placement={DEFAULTS.TOOLTIP_PLACEMENT}
          onClick={() => this.onDelete(id, name)}
        >
          <IconButton className="smallIcon">
            <Delete />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  editDialog = (record) => {
    this.setState({
      addVehicleDialog: true,
      record: record,
      draggable: false,
    });
  };

  onSearch = (e) => {
    let self = this;
    let qry = e.target.value.trim();
    clearTimeout(this._timer);
    this._timer = setTimeout(() => {
      if (qry === "") {
        self.setState({
          filteredRows: [],
        });
      } else {
        const { rows } = self.state;
        qry = qry.toUpperCase();
        let list = rows.filter((row) => {
          return (
            row.vehicleName.toUpperCase().indexOf(qry) > -1 ||
            row.imei.indexOf(qry) > -1
          );
        });
        if (list.length === 0) {
          list = [{ noRecords: true }];
        }
        self.setState({
          filteredRows: list,
        });
      }
    }, 1500);
  };

  reload = () => {
    this.setState({
      rows: null,
      filteredRows: [],
    });

    let self = this;
    setTimeout(() => {
      self.loadGrid();
    }, 1000 * DEFAULTS.GRID_RELOAD_TIMEOUT);
  };

  openAddVehicleDialog = () => {
    this.setState({
      addVehicleDialog: true,
      draggable: false,
    });
  };

  closeAddVehicleDialog = () => {
    this.setState({
      addVehicleDialog: false,
      draggable: true,
      record: {},
    });
  };

  toggleDialog = () => {
    this.props.toggleDialog();
  };

  onDelete = (id, name) => {
    this._dialogConfirm.toggleConfirmDialog(id, name);
  };

  deleteVehicle = (imei) => {
    const userId = this.props.userInfo.id;
    let args = {
      imei: imei,
      userId,
      cmd: "delete",
    };
    console.log("deleteVehicle: ", args);
    let self = this;
    API_CPanel.deleteVehicle(args).then((res) => {
      if (res.Result === 200) {
        commonJs.showNotify(
          self.props,
          NOTIFY.SUCCESS,
          "Vehicle deleted successfully..!"
        );
        self.reload();
      } else {
        commonJs.showNotify(
          self.props,
          NOTIFY.ERROR,
          "Vehicle not deleted: " + res.Reason
        );
      }
      self._dialogConfirm.toggleConfirmDialog("close");
    });
  };

  render() {
    const {
      columns,
      rows,
      filteredRows,
      draggable,
      addVehicleDialog,
      record,
    } = this.state;
    const { userInfo, vehicles } = this.props;

    let finalRows = rows;
    if (filteredRows.length > 0) {
      if (filteredRows[0].noRecords) {
        finalRows = [];
      } else {
        finalRows = filteredRows;
      }
    }

    return (
      <FleetDialog
        id="userVehiclesDialog"
        open={true}
        onCloseSettings={this.toggleDialog}
        title={"User Vehicles : " + userInfo.name}
        maxWidth="md"
        fullWidth={true}
        cancelButton={true}
        cancelAction={this.toggleDialog}
        saveButton={true}
        saveAction={this.saveRecord}
        saveBtnLabel="OK"
        disableBackdropClick={true}
        disableBackdrop={true}
        classNameWrapper="profileSettingsWrapper"
        draggable={draggable}
      >
        <ul className="gridActionBtns popupActionBtns">
          <li>
            <SearchButton
              id="cpanelUserVehicleSearch"
              placeholder="Search By Vehicle or IMEI"
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
              onChange={this.onSearch}
            />
          </li>
          <li>
            <Tooltip title="Reload" placement="bottom">
              <IconButton onClick={this.reload}>
                <Reload />
              </IconButton>
            </Tooltip>
          </li>
          <li>
            <Tooltip title="Add" placement="bottom">
              <IconButton
                onClick={() => this.openAddVehicleDialog({ add: true })}
              >
                <Add />
              </IconButton>
            </Tooltip>
          </li>
          {/* <li>
            <Tooltip title="Delete" placement="bottom">
              <IconButton onClick={this.deleteAll}>
                <Delete />
              </IconButton>
            </Tooltip>
          </li> */}
        </ul>

        <FleetGrid
          rows={finalRows}
          columns={columns}
          id="userVehiclesGrid"
          childRef={(ref) => (this._grid = ref)}
        />

        {/* Add Dialog */}
        {addVehicleDialog && (
          <AddUserVehicleDialog
            closeAddVehicleDialog={this.closeAddVehicleDialog}
            record={record}
            vehicles={vehicles}
            userInfo={userInfo}
          />
        )}

        <ConfirmDialog
          childRef={(ref) => (this._dialogConfirm = ref)}
          onOk={this.deleteVehicle}
        />
      </FleetDialog>
    );
  }
}

export default withSnackbar(UserVehiclesDialog);
