import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { Link, Breadcrumbs } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import jQuery from "jquery";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.flash";
import "pdfmake/build/vfs_fonts";
import "pdfmake/build/pdfmake";

import UiDatePicker from "components/ui/uidatepicker/UiDatePicker";
import AutoComplete from "components/ui/autocomplete/AutoComplete";
import Btn from "components/ui/button/Btn";

import {
  NOTIFY,
  ALERTS,
  FORMATS,
  REPORT_NAMES,
  REPORT_DESCRIPTION,
  DEFAULTS,
} from "common/consts";
import { commonJs } from "common/common";
import { dateFunction } from "common/datefunctions";
import { COMMON_ACTIONS } from "actions/CommonActions";
import { REPORT_ACTIONS } from "actions/ReportActions";
import { API_Reports } from "apihandler/Reports";
import { API_Map } from "apihandler/Map";
import { API_UserLogin } from "apihandler/UserLogin";

var $ = jQuery;

class KmReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicles: [],
      selectedVehicle: [],
      groups: [],
      selectedGroup: {},
      fdt: dateFunction.getDate("thismonth", "from", FORMATS.SQL_DATE),
      tdt: dateFunction.getDate("today", "to", FORMATS.SQL_DATE),
    };
  }

  componentDidMount() {
    document.getElementById("root").classList.add("scroll");
    document.getElementById("root").classList.remove("login");
    this.getInitialData();
    // this.getGroupData();
  }

  getInitialData = () => {
    const args = {};
    let self = this;
    args.pageNo = 0;
    args.pageLength = 100000;
    args.orderBy = "ASC";
    args.sortColumn = "";
    API_UserLogin.setToken(localStorage.token);
    API_Map.getAllVehicles(args)
      .then((res) => {
        self.props.setVehicles({
          vehicles: res,
        });
        self.props.setLoading({
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // getGroupData = () => {
  //   const args = {};
  //   let self = this;
  //   args.pageNo = 0;
  //   args.pageLength = 100000;
  //   args.orderBy = "ASC";
  //   args.sortColumn = "";
  //   API_UserLogin.setToken(localStorage.token);
  //   API_Map.getGroups(args)
  //     .then((res) => {
  //       if (res.Result === 200) {
  //         self.setState({
  //           groups: res.Response,
  //         });
  //       }
  //       self.props.setLoading({
  //         loading: false,
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  onChangeVehicle = (vehicle) => {
    this.setState({
      selectedVehicle: vehicle,
    });
  };

  // onChangeGroup = (group) => {
  //   this.setState({
  //     selectedGroup: group,
  //   });
  // };

  loadReport = () => {
    const { selectedVehicle, fdt, tdt } = this.state;
    let self = this;

    let imeis = [];
    let vnames = [];
    for (var i = 0; i < selectedVehicle.length; i++) {
      if (selectedVehicle[i].id === "") {
        const { vehiclesList } = this.props.commonReducer;
        for (var v = 0; v < vehiclesList.length; v++) {
          imeis.push(vehiclesList[v].imei);
        }
      } else {
        imeis.push(selectedVehicle[i].id);
        vnames.push(selectedVehicle[i].name);
      }
    }

    var uniqueImeis = commonJs.getUniqueArray(imeis);

    if (imeis.length === 0) {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        ALERTS.PLEASE_SELECT_VEHICLE,
        commonJs.getDefaultDismissBtn()
      );
      return;
    }
    // if (selectedGroup.length === 0) {
    //   commonJs.showNotify(
    //     this.props,
    //     NOTIFY.WARNING,
    //     ALERTS.PLEASE_SELECT_GROUP,
    //     commonJs.getDefaultDismissBtn()
    //   );
    //   return;
    // }

    this.props.setLoading({
      loading: true,
    });

    let args = {
      pageNo: 1,
      pageLength: DEFAULTS.DEFAULT_PAGE_LENGTH,
      fromDate: fdt,
      toDate: tdt,
      imeis: uniqueImeis,
      // group: selectedGroup.id,
    };

    API_Reports.getReport("currentmonth", args)
      .then((res) => {
        self.props.setLoading({
          loading: false,
        });
        if (res.Result === 200) {
          let { rows, cols } = res.Response;
          console.log("KmReport", res);
          let columns = [];
          for (var c = 0; c < cols.length; c++) {
            columns.push({ title: cols[c] });
          }

          if ($.fn.dataTable.isDataTable("#reportGrid")) {
            let table = $("#reportGrid").DataTable();
            table.destroy();
          }
          $("#reportGrid").empty();

          let title =
            "KM Report - " +
            (vnames.length > 0 ? vnames.toString() + " - " : "") +
            dateFunction.getFormatedDate(
              fdt,
              FORMATS.SQL_DATE,
              FORMATS.REPORT_TITLE_DATE
            ) +
            " - " +
            dateFunction.getFormatedDate(
              tdt,
              FORMATS.SQL_DATE,
              FORMATS.REPORT_TITLE_DATE
            );

          $("#reportGrid").dataTable({
            dom: "Bfrtip",
            buttons: [
              {
                extend: "copy",
                className: "reportBtn",
                title: title,
              },
              {
                extend: "excel",
                className: "reportBtn",
                title: title,
              },
              {
                extend: "csv",
                className: "reportBtn",
                title: title,
                text: "CSV",
              },
            ],
            paging: true,
            pageLength: 15,
            //  scrollY: DEFAULTS.REPORT_GRID_HEIGHT,
            columns: columns,
            data: rows,
          });
        }
      })
      .catch((err) => {
        console.log("getDailyTravel: err:", err);
      });
  };

  handleDateChange = (type, dt) => {
    if (type === "from") {
      this.setState({
        fdt: dt,
      });
    } else {
      this.setState({
        tdt: dt,
      });
    }
  };

  onFilterDate = (type) => {
    let fdt = dateFunction.getDate(type, "from", FORMATS.SQL_DATE);
    let tdt = dateFunction.getDate(type, "to", FORMATS.SQL_DATE);
    this.setState({
      fdt,
      tdt,
    });
  };

  render() {
    const { selectedVehicle, fdt, tdt } = this.state;
    const { vehiclesList } = this.props.commonReducer;

    let vehicles = [];
    vehicles.push({ id: "", name: "All Vehicles" });
    for (var i = 0; i < vehiclesList.length; i++) {
      vehicles.push({ id: vehiclesList[i].imei, name: vehiclesList[i].name });
    }

    return (
      <div className="reportPageWrapper height100">
        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
          <Link color="inherit" href="/">
            Home
          </Link>
          <p>{REPORT_NAMES.KM_REPORT}</p>
        </Breadcrumbs>
        <p className="reportDescription">{REPORT_DESCRIPTION.KM_REPORT}</p>
        <div className="reportFilters">
          <div className="width30 uidatepicker" style={{ paddingLeft: 0 }}>
            <div className="datepickerTop">
              <UiDatePicker
                label="From Date :"
                dt={fdt}
                type="from"
                disablePast={false}
                disableFuture={true}
                disabled={false}
                datepicker={false}
                // maxDate={dateFunction.getDate("thismonth", "to")}
                handleDateChange={this.handleDateChange}
              />
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ArrowForward />
              </div>
              <UiDatePicker
                label="To Date :"
                dt={tdt}
                type="to"
                disablePast={false}
                disableFuture={false}
                disabled={false}
                // maxDate={dateFunction.getDate("thismonth", "to")}
                handleDateChange={this.handleDateChange}
              />
            </div>
            <div className="datepickerBottom">
              <ul>
                <li>Filters: </li>
                <li onClick={() => this.onFilterDate("today")}>Today</li>
                <li onClick={() => this.onFilterDate("yesterday")}>
                  Yesterday
                </li>
                <li onClick={() => this.onFilterDate("thismonth")}>
                  This Month
                </li>
                <li onClick={() => this.onFilterDate("lastmonth")}>
                  Last Month
                </li>
              </ul>
            </div>
          </div>
          <div className="width20" style={{ marginTop: 10, marginBottom: 10 }}>
            <AutoComplete
              label={"Select Vehicles"}
              list={vehicles}
              id="trackVehicles"
              onChange={this.onChangeVehicle}
              defaultValue={selectedVehicle}
              multiple={true}
              size="small"
            />
          </div>
          {/* <div
            className="width20 padLeft-10"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <AutoComplete
              label={"Select Groups"}
              list={groups}
              onChange={this.onChangeGroup}
              defaultValue={selectedGroup}
              multiple={false}
              size="small"
            />
          </div> */}
          <div className="width10 padLeft-10">
            <Btn onClick={this.loadReport} className="primaryBtn">
              Submit
            </Btn>
          </div>
        </div>
        <div className="reportGridWrapper">
          <table
            id="reportGrid"
            className="mdl-data-table width100 paginated"
            cellSpacing="0"
            cellPadding="3"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commonReducer: state.commonReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setVehicles: (values) => {
      dispatch({
        type: COMMON_ACTIONS.SET_VEHICLES,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: REPORT_ACTIONS.SET_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(KmReport));
