import React, { Component } from "react";
import { TextField, Switch, FormControlLabel } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import PointIcons from "./PointIcons";
import FleetDialog from "../../../../../ui/dialogbox/FleetDialog";
import { commonJs } from "../../../../../../common/common";
import {
  NOTIFY,
  DEFAULTS,
  WARNINGS,
  SUCCESS_MSG,
  ERRORS,
  LABELS,
} from "../../../../../../common/consts";
import { MAP_ACTIONS } from "../../../../../../actions/MapActions";
import { MAP_CONTROLS_ACTIONS } from "../../../../../../actions/MapControlsActions";
import { API_Map } from "../../../../../../apihandler/Map";
import { PAGE_ACTIONS } from "../../../../../../actions/PageActions";

class DialogPoint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      point: {},
      showDialog: false,
      name: "",
      icon: "",
      description: "",
      range: "",
      haultTime: "",
      isHub: false,
      pointVisible: DEFAULTS.POI_POINT_VISIBLE,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  toggleAddPointDialog = (point) => {
    const { showDialog } = this.state;
    if (point.id === undefined) {
      console.log("toggleAddPointDialog Add:  ", point, !showDialog);
      this.props.setPointEdit({
        pointId: "",
      });
      if (point.add) {
        // Adding a new point
        this.setState({
          showDialog: !showDialog,
          point: {},
        });
      } else {
        // Close
        this.setState({
          showDialog: !showDialog,
        });
        this.props.setPointLatLng({
          pointLatLng: {},
        });
      }
    } else {
      // Edit -> Open
      this.setState({
        showDialog: !showDialog,
        point: point,
      });
      this.props.setPointEdit({
        pointId: point.id,
      });
      // Set Map Focus to the Point Marker
      const lat = point.latitude;
      const lng = point.longitude;
      const zoom = DEFAULTS.TRACK_ZOOM;
      this.props.updateMapRegion(lat, lng, zoom);
    }
  };

  addPoint = () => {
    const { pointImg } = this.props.mapControlsReducer;
    const { point } = this.state;
    const pointName = document.getElementById("pointName").value.trim();
    const pointDesc = document.getElementById("pointDesc").value.trim();
    const pointRange = document.getElementById("pointRange").value.trim();
    const pointHaultTime = Number(
      document.getElementById("pointHaultTime").value
    );
    const isHub = document.getElementById("isHub").checked;
    const pointVisible = document.getElementById("pointVisible").checked;
    let latLngs = document.querySelector("div#addPoint").getAttribute("latlng");

    let icon = pointImg.imageIndex;
    let lat = null;
    let lng = null;
    if (latLngs) {
      latLngs = latLngs.split(",");
      if (latLngs.length > 1) {
        lat = Number(latLngs[0]);
        lng = Number(latLngs[1]);
      }
    }

    var self = this;
    if (pointName === "") {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        WARNINGS.POINT_NAME_SHOULD_NOT_BE_EMPTY
      );
      return false;
    } else if (lat === null || lng === null) {
      if (point.id) {
        lat = point.latitude;
        lng = point.longitude;
      } else {
        commonJs.showNotify(
          self.props,
          NOTIFY.WARNING,
          WARNINGS.POINT_IS_NOT_PLACED_ON_MAP
        );
        return false;
      }
    }

    // Point on map -> Lat & Lng shouldn't be empty
    const args = {
      id: this.state.point.id || 0,
      name: pointName,
      description: pointDesc,
      range: pointRange,
      haltTime: pointHaultTime,
      isHub: isHub.toString(),
      pointVisible: pointVisible.toString(),
      icon,
      latitude: lat,
      longitude: lng,
    };

    console.log("addZone", args);

    this.props.toggleLoading({
      loadingPopover: true,
    });

    if (args.id !== "") {
      API_Map.savePoint(args)
        .then((res) => {
          if (res.Result === 200) {
            commonJs.showNotify(
              self.props,
              NOTIFY.SUCCESS,
              SUCCESS_MSG.POINT_SAVED_SUCCESSFULLY,
              <button className="snackbar-btn">{LABELS.DISMISS}</button>
            );
            self.props.toggleAddPointDialog(args.id);
            self.props.toggleLoading({
              loadingPopover: false,
            });
            self.props.reloadPoints();
          } else {
            console.log("Point Creation Error: ", res.Reason);
            commonJs.showNotify(
              self.props,
              NOTIFY.ERROR,
              ERRORS.POINT_NOT_SAVED,
              <button className="snackbar-btn">{LABELS.DISMISS}</button>
            );
          }
        })
        .catch((err) => {
          console.log("Point Creation Error: ", err);
          commonJs.showNotify(
            self.props,
            NOTIFY.ERROR,
            ERRORS.ZONE_NOT_SAVED,
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
        });
    }
    return;
  };

  render() {
    const { point, showDialog } = this.state;

    console.log("Point: ", point);

    return (
      <FleetDialog
        id="addPoint"
        open={showDialog}
        onCloseSettings={this.toggleAddPointDialog}
        title={point.id ? "Edit Point" : "Add Point"}
        maxWidth="xs"
        fullWidth={false}
        cancelButton={true}
        cancelAction={this.toggleAddPointDialog}
        saveButton={true}
        saveAction={this.addPoint}
        saveBtnLabel="OK"
        disableBackdropClick={true}
        disableBackdrop={true}
        classNameBody="zonePopupBody"
        classNameWrapper="zonePopupWrapper"
        draggable
      >
        <div className="grid">
          <div className="row">
            <TextField
              id="pointName"
              label="Name"
              classes={{ root: "txtfield" }}
              autoFocus={true}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={point.name || ""}
            />
          </div>
          <div className="row">
            <TextField
              id="pointDesc"
              label="Description"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={point.description || ""}
              rows={3}
              multiline
            />
          </div>
          <div className="row">
            <TextField
              id="pointRange"
              label="Range (In Kms)"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={point.range || ""}
            />
          </div>
          <div className="row">
            <TextField
              id="pointHaultTime"
              label="Hault Time (In Minutes)"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={point.haltTime || ""}
            />
          </div>
          <div
            className="row"
            style={{ marginTop: -10, justifyContent: "flex-start" }}
          >
            <FormControlLabel
              control={
                <Switch
                  classes={{
                    thumb: "switchThumb",
                    track: "switchTrack",
                    root: "switchRoot",
                  }}
                  id="isHub"
                  defaultChecked={point.isHub ? JSON.parse(point.isHub) : false}
                />
              }
              label="Is Hub"
              className="switch"
            />
            <FormControlLabel
              control={
                <Switch
                  classes={{
                    thumb: "switchThumb",
                    track: "switchTrack",
                    root: "switchRoot",
                  }}
                  id="pointVisible"
                  defaultChecked={
                    point.pointVisible
                      ? JSON.parse(point.pointVisible)
                      : DEFAULTS.POI_POINT_VISIBLE
                  }
                />
              }
              label="Point Visible"
              className="switch"
            />
          </div>
          <div className="row">
            <PointIcons childRef={(ref) => (this._pointIcons = ref)} />
          </div>
        </div>
      </FleetDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
    mapControlsReducer: state.mapControlsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleAddPoi: (values) => {
      dispatch({
        type: MAP_ACTIONS.TOGGLE_POI_CONTROL,
        values,
      });
    },
    setPointEdit: (values) => {
      dispatch({
        type: MAP_CONTROLS_ACTIONS.SET_POINT_EDIT,
        values,
      });
    },
    setPointLatLng: (values) => {
      dispatch({
        type: MAP_CONTROLS_ACTIONS.SET_POINT_LATLNG,
        values,
      });
    },
    toggleLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_POPOVER_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DialogPoint));
