import React, { Component } from "react";
import Button from "@material-ui/core/Button";

class Btn extends Component {
  render() {
    return (
      <Button
        variant={this.props.variant}
        color={this.props.color}
        className={this.props.className}
        id={this.props.id}
        size={this.props.size}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </Button>
    );
  }
}

export default Btn;
