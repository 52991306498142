import React, { Component } from "react";
import {
  SortingState,
  PagingState,
  CustomPaging,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";

import { ALERTS, DEFAULTS } from "common/consts";

const getRowId = (row) => row.id;

var timer = null;

class VehiclesGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
    timer = null;
  }

  onChangePageLength = (value) => {
    const { pageNo, totalRows } = this.props;
    let totalPages = Math.ceil(totalRows / value);
    let updatedCurrentPage = Math.min(pageNo, totalPages - 1);
    this.props.onChangePageLength(value, updatedCurrentPage);
  };

  onPageChange = (pageNo = 0) => {
    console.log("onPageChange: ", pageNo, this.props.pageNo);
    this.props.onPageClick(pageNo);
  };

  render() {
    const {
      id,
      rows,
      columns,
      tableColumnExtensions,
      tableColumnWidths,
      height,
      pageNo,
      pageLength,
      totalRows,
      onChangeSort,
      showSortingControls,
      sortDisabledColumns,
      sortingColumn,
      sortingType,
    } = this.props;

    let gridHeight = window.innerHeight - 180;
    if (height) {
      gridHeight = height;
    }

    let noRecords = true;
    if (rows) {
      noRecords = rows.length === 0;
    }

    console.log("pageNo: ", pageNo, pageLength, totalRows);

    return (
      <div id={id} className="fleetGrid">
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <SortingState
            sorting={[{ columnName: sortingColumn, direction: sortingType }]}
            columnExtensions={sortDisabledColumns}
            onSortingChange={onChangeSort}
          />

          <PagingState
            currentPage={pageNo}
            pageSize={pageLength}
            onCurrentPageChange={this.onPageChange}
            onPageSizeChange={this.onChangePageLength}
          />

          <VirtualTable
            columnExtensions={tableColumnExtensions || []}
            height={gridHeight}
            messages={{ noData: "Loading..." }}
          />

          <TableColumnResizing defaultColumnWidths={tableColumnWidths || []} />

          <TableHeaderRow showSortingControls={showSortingControls || false} />

          <CustomPaging totalCount={totalRows} />

          <PagingPanel
            pageSizes={[
              DEFAULTS.CPANEL_GRID_PAGE_LENGTH * 1,
              DEFAULTS.CPANEL_GRID_PAGE_LENGTH * 2,
              DEFAULTS.CPANEL_GRID_PAGE_LENGTH * 3,
            ]}
          />
        </Grid>
      </div>
    );
  }
}

export default VehiclesGrid;
