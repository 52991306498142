import React, { Component } from "react";
import { SwipeableDrawer, IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import {
  Description,
  Map,
  PowerSettingsNew,
  ZoomOutMap,
  FullscreenExit,
  SettingsOutlined,
  SupervisorAccountRounded as Supervisor,
} from "@material-ui/icons";

// Custom UI
import MapPageTabs from "./maptabs";
import FleetDialog from "components/ui/dialogbox/FleetDialog";

import {
  LABELS,
  CONFIRMS,
  NOTIFY,
  ALERTS,
  CONFIG,
  PRIVILEGES,
  ROLES,
} from "common/consts";
import { PAGE_ACTIONS } from "actions/PageActions";
import { commonJs } from "common/common";

// Custom CSS
import "./leftbar.css";

class LeftBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoutDialog: false,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);

    document.addEventListener(
      "fullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "mozfullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "MSFullscreenChange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "webkitfullscreenchange",
      this.onFullScreenChange,
      false
    );
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);

    document.removeEventListener(
      "fullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.removeEventListener(
      "mozfullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.removeEventListener(
      "MSFullscreenChange",
      this.onFullScreenChange,
      false
    );
    document.removeEventListener(
      "webkitfullscreenchange",
      this.onFullScreenChange,
      false
    );
  }

  onFullScreenChange = (e) => {
    const { fullScreen } = this.props.pageReducer;
    if (fullScreen && window.innerHeight !== window.screen.height) {
      this.props.toggleFullScreen({
        fullScreen: false,
      });
    } else {
      this.props.toggleFullScreen({
        fullScreen: true,
      });
    }
  };

  onMenuToggle = (menu) => {
    const { leftMapMenu, leftReportsMenu } = this.props.pageReducer;
    var self = this;
    if (menu === "leftMapMenu") {
      if (leftMapMenu && !leftReportsMenu) {
        self.props.setLeftMapMenu({ leftMapMenu: false, leftReportsMenu });
      } else {
        self.props.setLeftMapMenu({
          leftMapMenu: true,
          leftReportsMenu: false,
        });
      }
    } else if (menu === "fleetReportsMenu") {
      window.open(CONFIG.REPORTS_URL, "_blank");
    } else if (menu === "fleetStatusMenu") {
      window.open(CONFIG.FLEETSTATUS_URL, "_blank");
    } else if (menu === "cpanelMenu") {
      window.open(CONFIG.CPANEL_URL, "_blank");
    }
  };

  openSettings = () => {
    const { settingsMenu } = this.props.pageReducer;
    this.props.toggleSettingsMenu({
      settingsMenu: !settingsMenu,
    });
  };

  toggleFullscreen = (fullScreen = false) => {
    if (fullScreen) {
      commonJs.exitFullScreen();
    } else {
      commonJs.openFullScreen();
    }
    this.props.toggleFullScreen({
      fullScreen,
    });
  };

  toggleLogoutDialog = () => {
    const { logoutDialog } = this.state;
    this.setState({
      logoutDialog: !logoutDialog,
    });
  };

  logout = () => {
    this.props.setMapLoading({ loading: true });
    this.setState({
      logoutDialog: false,
    });
    commonJs.showNotify(
      this.props,
      NOTIFY.SUCCESS,
      ALERTS.LOGOUT_SUCESSSFUL,
      <button className="snackbar-btn">{LABELS.DISMISS}</button>
    );
    commonJs.logout();
  };

  render() {
    const {
      varient,
      loginReducer,
      mapReducer,
      pageReducer,
      updateMapRegion,
      trackFitBounds,
      zoneFitBounds,
      shareVehicle,
    } = this.props;

    const { fitmap } = mapReducer;

    const { fullScreen, leftMapMenu } = pageReducer;

    const { role, privileges } = loginReducer;

    const { logoutDialog } = this.state;

    const showSettings =
      role === ROLES.ADMIN ||
      role === ROLES.SUPER_ADMIN ||
      role === ROLES.MANAGER;

    const showReports = commonJs.hasPrivilege(
      PRIVILEGES.REPORTS,
      role,
      privileges
    );

    console.log("mapReducer:", this.props.mapReducer);

    return (
      <React.Fragment>
        <div className={"leftFixedMenu" + (fitmap ? " hide" : "")}>
          <ul className="topmenu">
            <li onClick={() => this.onMenuToggle("leftMapMenu")}>
              <Tooltip title={LABELS.MAP} placement="right">
                <IconButton
                  classes={{ root: "menucolor" }}
                  className={leftMapMenu ? "active" : ""}
                >
                  <Map />
                </IconButton>
              </Tooltip>
            </li>
            {showReports && (
              <li onClick={() => this.onMenuToggle("fleetReportsMenu")}>
                <Tooltip title={LABELS.REPORTS} placement="right">
                  <IconButton
                    classes={{ root: "menucolor" }}
                    className={this.leftReportsMenu ? "active" : ""}
                  >
                    <Description />
                  </IconButton>
                </Tooltip>
              </li>
            )}
            {/* <li onClick={() => this.onMenuToggle("fleetStatusMenu")}>
              <Tooltip title={LABELS.FLEET_STATUS} placement="right">
                <IconButton classes={{ root: "menucolor" }}>
                  <LocalShippingOutlined />
                </IconButton>
              </Tooltip>
            </li> */}
          </ul>

          <ul className="bottommenu">
            {(role === "admin" || role === "superadmin") && (
              <li onClick={() => this.onMenuToggle("cpanelMenu")}>
                <Tooltip title={LABELS.CPANEL} placement="right">
                  <IconButton classes={{ root: "menucolor" }}>
                    <Supervisor />
                  </IconButton>
                </Tooltip>
              </li>
            )}
            {showSettings && (
              <li>
                <Tooltip title={LABELS.SETTINGS} placement="right">
                  <IconButton
                    classes={{ root: "menucolor" }}
                    onClick={this.openSettings}
                  >
                    <SettingsOutlined />
                  </IconButton>
                </Tooltip>
              </li>
            )}
            <li>
              <Tooltip title={LABELS.FULL_SCREEN} placement="right">
                <IconButton
                  classes={{ root: "menucolor" }}
                  onClick={() => this.toggleFullscreen(fullScreen)}
                >
                  {fullScreen ? <FullscreenExit /> : <ZoomOutMap />}
                </IconButton>
              </Tooltip>
            </li>
            <li>
              <Tooltip title={LABELS.LOGOUT} placement="right">
                <IconButton
                  classes={{ root: "menucolor" }}
                  onClick={this.toggleLogoutDialog}
                >
                  <PowerSettingsNew />
                </IconButton>
              </Tooltip>
            </li>
          </ul>
        </div>

        <SwipeableDrawer
          open={fitmap ? false : leftMapMenu}
          onClose={() => this.onMenuToggle("leftMapMenu", false)}
          onOpen={() => this.onMenuToggle("leftMapMenu", true)}
          variant={varient}
          className={"leftdrawer" + (this.leftMapMenu ? " open" : "")}
          classes={{ paper: "leftdrawer-menu" }}
          BackdropProps={{
            invisible: true,
          }}
          hideBackdrop={true}
          tabIndex={9}
        >
          <MapPageTabs
            shareVehicle={shareVehicle}
            updateMapRegion={updateMapRegion}
            trackFitBounds={trackFitBounds}
            zoneFitBounds={zoneFitBounds}
            onMenuToggle={this.onMenuToggle}
            childRef={(ref) => (this._mapTabs = ref)}
          />
        </SwipeableDrawer>

        <FleetDialog
          open={logoutDialog}
          onCloseSettings={this.toggleLogoutDialog}
          title={LABELS.LOGOUT}
          maxWidth="sm"
          fullWidth={true}
          saveButton={true}
          cancelButton={true}
          cancelAction={this.toggleLogoutDialog}
          saveAction={this.logout}
          saveBtnLabel="OK"
          classNameWrapper="logoutWrapper"
        >
          <p>{CONFIRMS.CONFIRM_LOGOUT}</p>
        </FleetDialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
    pageReducer: state.pageReducer,
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSettingsMenu: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_SETTINGS_MENU,
        values,
      });
    },
    setLeftBar: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_LEFTBAR,
        values,
      });
    },
    setLeftMapMenu: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_MAP_MENU,
        values,
      });
    },
    toggleFullScreen: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_FULLSCREEN,
        values,
      });
    },
    setMapLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_MAP_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(LeftBar));
