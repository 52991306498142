import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { Visibility, Edit, Delete } from "@material-ui/icons";
import { connect } from "react-redux";

// React Grid
import { SortingState, IntegratedSorting } from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

import { API_Map } from "apihandler/Map";
import { DEFAULTS, ROLES } from "common/consts";
import { POI_ACTIONS } from "actions/PoiActions";

const getRowId = (row) => row.id;

var timer = null;

class PointsGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      direction: "asc",
      points: {
        rows: [],
        filteredRows: [],
        columns: [],
      },
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.loadPoints();
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  pointSearch = (searchValue) => {
    const { points } = this.state;
    const { rows, columns } = points;
    clearTimeout(timer);
    let self = this;
    timer = setTimeout(() => {
      let qry = searchValue.toUpperCase();
      let list = rows.filter((row) => {
        return row.name.toUpperCase().indexOf(qry) > -1;
      });
      self.setState({
        points: { rows: rows, filteredRows: list, columns: columns },
      });
    }, 500);
  };

  getPointOnFilter = (pointId) => {
    const { points } = this.state;
    const { rows } = points;
    return rows.filter((r) => r.id === pointId);
  };

  zoneVisibilityToggle = (point) => {
    const { latitude, longitude } = point;
    this.props.updateMapRegion(latitude, longitude, DEFAULTS.POI_POINT_ZOOM);
  };

  generateVisibleIcon = (rec) => {
    return (
      <Tooltip title="View" placement="bottom">
        <IconButton
          onClick={() => this.zoneVisibilityToggle(rec)}
          className="smallIcon"
        >
          <Visibility />
        </IconButton>
      </Tooltip>
    );
  };

  generateOptions = (point) => {
    const { id, name } = point;
    const { role } = this.props.loginReducer;
    return (
      <div className="options">
        {role !== ROLES.SUB_USER && (
          <Tooltip
            title="Edit"
            placement="bottom"
            onClick={() => this.props.toggleAddPointDialog(point)}
          >
            <IconButton className="smallIcon">
              <Edit />
            </IconButton>
          </Tooltip>
        )}
        {role !== ROLES.SUB_USER && (
          <Tooltip
            title="Delete"
            placement="bottom"
            onClick={() => this.props.deletePoint(id, name)}
          >
            <IconButton className="smallIcon">
              <Delete />
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  };

  reloadPoints = () => {
    this.setState({
      points: {
        loading: false,
        sorting: "asc",
        columns: [],
        rows: [],
        filteredRows: [],
      },
    });
    this.loadPoints();
  };

  loadPoints = () => {
    var self = this;
    API_Map.getAllPoints().then((res) => {
      if (res.Result === 200) {
        let cols = [
          { name: "view", title: "Visible" },
          { name: "name", title: "Name" },
          { name: "options", title: "Options" },
        ];
        let rows = [];

        let qry = "";
        if (document.querySelector("input#pointSearch")) {
          qry = document.querySelector("input#pointSearch").value.toUpperCase();
        }
        let filteredRows = [];
        let fRows = res.Response.filter((row) => {
          return row.name.toUpperCase().indexOf(qry) > -1;
        });
        for (let i in fRows) {
          filteredRows.push({
            id: fRows[i].id,
            view: this.generateVisibleIcon(fRows[i]),
            slno: Number(i) + 1,
            name: fRows[i].name,
            options: this.generateOptions(fRows[i]),
          });
        }

        res = res.Response;
        for (let i in res) {
          rows.push({
            id: res[i].id,
            view: this.generateVisibleIcon(res[i]),
            slno: Number(i) + 1,
            name: res[i].name,
            options: this.generateOptions(res[i]),
          });
        }
        self.setState({
          points: {
            loading: false,
            sorting: "asc",
            columns: cols,
            rows: rows,
            filteredRows: filteredRows,
          },
        });

        // For Reducer
        self.props.setPoints({
          points: res,
        });
      }
    });
  };

  onRowClick = () => {
    console.log("onRowClick");
  };

  render() {
    const { rows, columns, filteredRows } = this.state.points;

    const tableColumnExtensions = [
      { columnName: "view", width: 80, align: "center" },
      { columnName: "name", width: 200, align: "left" },
      { columnName: "options", width: 80, align: "center" },
    ];

    let searchOn = false;
    if (document.getElementById("pointSearch") != null) {
      searchOn =
        document.getElementById("pointSearch").value.trim() === ""
          ? false
          : true;
    }

    return columns.length > 0 ? (
      <div id="pointsGrid" className="dataRGrid">
        <Grid
          rows={searchOn ? filteredRows : rows}
          columns={columns}
          getRowId={getRowId}
        >
          <SortingState
            defaultSorting={[{ columnName: "name", direction: "asc" }]}
            columnSortingEnabled={true}
            columnExtensions={[
              { columnName: "view", sortingEnabled: false },
              { columnName: "options", sortingEnabled: false },
            ]}
          />
          <IntegratedSorting />
          <VirtualTable
            columnExtensions={tableColumnExtensions}
            height={window.innerHeight - 180}
          />
          <TableHeaderRow showSortingControls />
        </Grid>
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: 100,
          alignItems: "center",
        }}
      >
        <p style={{ color: "#a1a1a1", fontSize: 14 }}>Loading...</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPoints: (values) => {
      dispatch({
        type: POI_ACTIONS.SET_POINTS,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PointsGrid);
