import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { ArrowForward } from "@material-ui/icons";
import { Link, Breadcrumbs } from "@material-ui/core";
import jQuery from "jquery";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.flash";
import "pdfmake/build/vfs_fonts";
import "pdfmake/build/pdfmake";

import UiDatePicker from "components/ui/uidatepicker/UiDatePicker";
import Btn from "components/ui/button/Btn";

import {
  FORMATS,
  REPORT_NAMES,
  REPORT_DESCRIPTION,
  DEFAULTS,
} from "common/consts";
import { commonJs } from "common/common";
import { dateFunction } from "common/datefunctions";
import { REPORT_ACTIONS } from "actions/ReportActions";
import { API_Reports } from "apihandler/Reports/pooja";

var $ = jQuery;

class EtaReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fdt: dateFunction.getDate("thismonth", "from", FORMATS.SQL_DATE),
      tdt: dateFunction.getDate("today", "to", FORMATS.SQL_DATE),
    };
  }

  componentDidMount() {
    document.getElementById("root").classList.remove("login");
    document.getElementById("root").classList.add("scroll");
  }

  handleDateChange = (type, dt) => {
    if (type === "from") {
      this.setState({
        fdt: dt,
      });
    } else {
      this.setState({ tdt: dt });
    }
  };

  loadReport = () => {
    const { fdt, tdt } = this.state;
    let self = this;

    this.props.setLoading({
      loading: true,
    });

    let args = {
      pageNo: 1,
      pageLength: 30,
      fromDate: fdt,
      toDate: tdt,
      platform: "web",
    };
    API_Reports.getEtaReport(args)
      .then((res) => {
        self.props.setLoading({
          loading: false,
        });
        if (res.Result === 200) {
          const { rows, cols } = res.Response;
          console.log("getEtaReport", res);
          let columns = [];
          for (var c = 0; c < cols.length; c++) {
            columns.push({ title: cols[c] });
          }
          if ($.fn.dataTable.isDataTable("#reportGrid")) {
            let table = $("#reportGrid").DataTable();
            table.destroy();
          }
          $("#reportGrid").empty();

          let title =
            REPORT_NAMES.ETA_REPORT +
            " - " +
            " (" +
            dateFunction.getFormatedDate(
              fdt,
              FORMATS.SQL_DATE,
              FORMATS.REPORT_TITLE_DATE
            ) +
            "-" +
            dateFunction.getFormatedDate(
              tdt,
              FORMATS.SQL_DATE,
              FORMATS.REPORT_TITLE_DATE
            );

          $("#reportGrid").DataTable({
            dom: "Bfrtip",
            buttons: [
              {
                extend: "copy",
                className: "reportBtn",
                title: title,
              },
              {
                extend: "csv",
                className: "reportBtn",
                title: title,
                text: "CSV",
              },
              {
                extend: "excel",
                className: "reportBtn",
                title: title,
              },
              {
                extend: "print",
                className: "reportBtn",
                title: title,
                customize: function (win) {
                  commonJs.getExportPrintCss(win);
                },
              },
            ],
            paging: true,
            scrollY: DEFAULTS.REPORT_GRID_HEIGHT,
            columns: columns,
            data: rows,
          });
        }
      })
      .catch((err) => {
        console.log("getEtaReport: err:", err);
      });
  };

  onFilterDate = (type) => {
    let fdt = dateFunction.getDate(type, "from", FORMATS.SQL_DATE);
    let tdt = dateFunction.getDate(type, "to", FORMATS.SQL_DATE);
    this.setState({
      fdt,
      tdt,
    });
  };

  render() {
    const { fdt, tdt } = this.state;

    return (
      <div className="reportPageWrapper height100">
        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
          <Link color="inherit" href="/">
            Home
          </Link>
          <p>{REPORT_NAMES.ETA_REPORT}</p>
        </Breadcrumbs>
        <p className="reportDescription">{REPORT_DESCRIPTION.ETA_REPORT}</p>
        <div className="reportFilters">
          <div className="width30 uidatepicker" style={{ padding: "0px" }}>
            <div className="datepickerTop">
              <UiDatePicker
                label="From Date :"
                dt={fdt}
                type="from"
                disablePast={false}
                disableFuture={true}
                disable={false}
                datepicker={false}
                handleDateChange={this.handleDateChange}
              />
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ArrowForward />
              </div>
              <UiDatePicker
                label="To Date :"
                dt={tdt}
                type="to"
                disablePast={false}
                disableFuture={false}
                disabled={false}
                // maxDate={dateFunction.getDate("thismonth", "to")}
                handleDateChange={this.handleDateChange}
              />
            </div>
            <div className="datepickerBottom">
              <ul>
                <li>Filters: </li>
                <li onClick={() => this.onFilterDate("today")}>Today</li>
                <li onClick={() => this.onFilterDate("yesterday")}>
                  Yesterday
                </li>
                <li onClick={() => this.onFilterDate("thismonth")}>
                  This Month
                </li>
                <li onClick={() => this.onFilterDate("lastmonth")}>
                  Last Month
                </li>
              </ul>
            </div>
          </div>
          <div className="width10" style={{ paddingLeft: 20 }}>
            <Btn onClick={this.loadReport} className="primaryBtn">
              Submit
            </Btn>
          </div>
        </div>
        <div className="reportGridWrapper">
          <table
            id="reportGrid"
            className="mdl-data-table width100 paginated"
            cellSpacing="0"
            cellPadding="3"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (values) => {
      dispatch({
        type: REPORT_ACTIONS.SET_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(EtaReport));
