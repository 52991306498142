import { SdkClient } from "fgxsdk";
import { CONFIG } from "../common/consts";
import axios from "axios";
import { jsPDF } from "jspdf";

SdkClient.setUrl(CONFIG.BASE_URL);

export const API_Reports = {
  getDocExpiryList: async () => {
    return {
      Result: 200,
      Response: {
        drivingLicense: [
          {
            slno: 1,
            imei: "387326327642",
            vehicleName: "KA-3545",
            expiryDate: "2021-07-01",
          },
          {
            slno: 2,
            imei: "387326327642",
            vehicleName: "KA-3545",
            expiryDate: "2021-05-01",
          },
        ],
        registration: [
          {
            slno: 1,
            imei: "387326327642",
            vehicleName: "KA-3545",
            expiryDate: "2021-05-01",
          },
        ],
        vehicleInsurance: [
          {
            slno: 1,
            imei: "387326327642",
            vehicleName: "KA-3545",
            expiryDate: "2021-05-01",
          },
        ],
        vehicleFitness: [
          {
            slno: 1,
            imei: "387326327642",
            vehicleName: "KA-3545",
            expiryDate: "2021-05-01",
          },
        ],
        vehiclePermit: [
          {
            slno: 1,
            imei: "387326327642",
            vehicleName: "KA-3545",
            expiryDate: "2021-05-01",
          },
        ],
        roadTax: [
          {
            slno: 1,
            imei: "387326327642",
            vehicleName: "KA-3545",
            expiryDate: "2021-05-01",
          },
        ],
      },
    };
  },

  getYesterdayTravelSummary: async () => {
    return {
      Result: 200,
      Response: {
        totalKms: 140.5,
        movingDuration: 400, // In Seconds
        idleDuration: 800, // In Seconds
        avgSpeed: 40,
        fleetUtilization: "50%",
      },
    };
  },

  getReport2: async (name, args) => {
    return SdkClient.getReport(name, args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  getReport: async (name, args) => {
    return axios
      .post(CONFIG.BASE_URL + "/api/v2/reports/" + name, args, {
        timeout: 30000,
        withCredentials: true,
        headers: { "fgx-api-session": localStorage.token },
        // responseType: "blob",
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {});
  },

  exportHighchart: async (
    data = {},
    title = "",
    target,
    mode = "p",
    width = 100
  ) => {
    let print = data.type === "print";
    let chartHtml = target.innerHTML;
    let svgBlob = new Blob([chartHtml], {
      type: "image/svg+xml;charset=utf-8",
    });
    let svgUrl = URL.createObjectURL(svgBlob);

    if (print) {
      let printWinindow = window.open(
        "",
        "_blank",
        "width=1000,height=1000,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no"
      );
      printWinindow.document.open();
      printWinindow.document.write(
        "<html><head><style>@page {size: A4;margin:0px;}</style><title>" +
          title +
          "</title></head><body>" +
          chartHtml +
          "</html>"
      );
      printWinindow.document.close();
      printWinindow.focus();
      printWinindow.print();
      printWinindow.close();
    } else if (data.type === "application/pdf") {
      let image = new Image();
      image.src = "data:image/svg+xml;base64," + window.btoa(chartHtml);
      window.btoa(unescape(encodeURIComponent(chartHtml)));
      image.onload = function () {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;

        let context = canvas.getContext("2d");
        context.drawImage(image, 0, 0);

        // Generate PDF
        let pdf = new jsPDF(mode, "mm", "a4");
        let imgData = canvas.toDataURL("image/png", 1.0);
        // due to lack of documentation; try setting w/h based on unit
        pdf.addImage(imgData, "PNG", 10, 10, width, 150); // 180x150 mm @ (10,10)mm
        // pdf.addImage(image, 10, 10, image.width, image.height).then(() => {
        pdf.save(title);
      };
    } else if (data.type === "image/png" || data.type === "image/jpeg") {
      let image = new Image();
      image.src = "data:image/svg+xml;base64," + window.btoa(chartHtml);
      window.btoa(unescape(encodeURIComponent(chartHtml)));
      image.onload = function () {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;

        let context = canvas.getContext("2d");
        context.drawImage(image, 0, 0);

        let a = document.createElement("a");
        a.download = title;
        a.href = canvas.toDataURL(data.type);
        document.body.appendChild(a);
        a.click();
      };
    } else {
      let downloadLink = document.createElement("a");
      downloadLink.href = svgUrl;
      downloadLink.download = title;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
    return true;
  },

  getDrivingReport: async (args) => {
    return {
      Result: 200,
      Response: {
        cols: ["Sl No.", "Time", "Location", "Speed", "Event", "Value"],
        rows: [
          [
            1,
            "15-05-2019 00:00:00",
            "Ramanagara district, Karnataka",
            "65.5",
            "Over Speed",
            "150",
          ],
          [
            2,
            "15-05-2019 00:00:00",
            "Ramanagara district, Karnataka",
            "75.5",
            "Over Speed",
            "200",
          ],
        ],
      },
    };
  },

  getTripReport: async (args) => {
    return {
      Result: 200,
      Response: {
        cols: [
          "Sl. No.",
          "Trip No.",
          "Start Zone",
          "Start Zone Out Time",
          "End Zone",
          "End Zone In Time",
          "Time Taken",
          "Time Taken (In hrs)",
          "Total Kms",
          "Journey Report",
        ],
        rows: [
          [
            "1",
            "1001",
            "AGRA",
            "2020-07-02 22:47:36",
            "KUNDLI",
            "2020-07-03 10:34:42",
            "11h 47min 6s",
            "11",
            "288.3",
            "/reports/journey",
          ],
          [
            "2",
            "1002",
            "AGRA",
            "2020-07-02 22:47:36",
            "KUNDLI",
            "2020-07-03 10:34:42",
            "11h 47min 6s",
            "11",
            "288.3",
            "/reports/journey",
          ],
        ],
      },
    };
  },

  // Location Check Report: Vehicle Positions Info
  getLocationCheckReport: async (args) => {
    return {
      Result: 200,
      Response: {
        cols: ["Sl No.", "Vehicle No.", "Location", "Time"],
        rows: [
          [
            "1",
            "KA53C8226",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
            "2020-07-11 00:00:01",
          ],
          [
            "2",
            "KA53C8226",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
            "2020-07-11 00:00:11",
          ],
        ],
        hasNext: true,
      },
    };
  },

  getLiveReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      imei: ["352093080472557"],
      platform: "web", // Or it will be "app"
    };
    // If platform is "app" => "adress" should be json
    // {
    //   address:"Bangalore East, Bangalore Urban, Karnataka, 560049, India",
    //   lat:13.050612,
    //   lng:77.752776
    // }
    return {
      Result: 200,
      Response: {
        cols: [
          "Sl No.",
          "Vehicle No.",
          "Vehicle Type",
          "Start Time",
          "Start Location",
          "End Time",
          "End Location",
          "Vehicle Status",
          "Total Distance(In Kms)",
          "Duration",
          "Route View",
        ],
        rows: [
          [
            "1",
            "KA01AL-3228-TEMP",
            "",
            "2020-07-01 00:00:00",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
            "2020-07-01 05:00:00",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
            "Stop",
            "0",
            "5hrs",
            "",
          ],
          [
            "2",
            "KA01AL-3228-TEMP",
            "",
            "2020-07-01 05:00:00",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
            "2020-07-01 08:00:00",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
            "Running",
            "80.5",
            "3hrs",
            "",
          ],
        ],
        hasNext: true,
      },
    };
  },

  getLiveNoSignalReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      platform: "web", // Or it will be "app"
    };
    // If platform is "app" => "adress" should be json
    // {
    //   address:"Bangalore East, Bangalore Urban, Karnataka, 560049, India",
    //   lat:13.050612,
    //   lng:77.752776
    // }
    return {
      Result: 200,
      Response: {
        cols: ["Sl No.", "Vehicle No.", "Time", "Since", "Location"],
        rows: [
          [
            "1",
            "0661",
            "2020-07-11 18:22:08",
            "22d 20h 21min 2s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "2",
            "08093056",
            "2020-01-11 12:06:22",
            "2d 20h 41min 9s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "3",
            "08093056",
            "2020-01-11 12:06:22",
            "2d 20h 41min 9s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "4",
            "08093056",
            "2020-01-11 12:06:22",
            "2d 20h 41min 9s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "5",
            "08093056",
            "2020-01-11 12:06:22",
            "2d 20h 41min 9s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "6",
            "08093056",
            "2020-01-11 12:06:22",
            "2d 20h 41min 9s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "7",
            "08093056",
            "2020-01-11 12:06:22",
            "2d 20h 41min 9s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "8",
            "08093056",
            "2020-01-11 12:06:22",
            "2d 20h 41min 9s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "9",
            "08093056",
            "2020-01-11 12:06:22",
            "2d 20h 41min 9s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "10",
            "08093056",
            "2020-01-11 12:06:22",
            "2d 20h 41min 9s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "11",
            "0661",
            "2020-07-11 18:22:08",
            "22d 20h 21min 2s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "12",
            "08093056",
            "2020-01-11 12:06:22",
            "2d 20h 41min 9s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "13",
            "08093056",
            "2020-01-11 12:06:22",
            "2d 20h 41min 9s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "14",
            "08093056",
            "2020-01-11 12:06:22",
            "2d 20h 41min 9s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "15",
            "08093056",
            "2020-01-11 12:06:22",
            "2d 20h 41min 9s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "16",
            "08093056",
            "2020-01-11 12:06:22",
            "2d 20h 41min 9s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "17",
            "08093056",
            "2020-01-11 12:06:22",
            "2d 20h 41min 9s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "18",
            "08093056",
            "2020-01-11 12:06:22",
            "2d 20h 41min 9s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "19",
            "08093056",
            "2020-01-11 12:06:22",
            "2d 20h 41min 9s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "20",
            "08093056",
            "2020-01-11 12:06:22",
            "2d 20h 41min 9s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
        ],
        hasNext: true,
      },
    };
  },
  //Point In Out Report: Shows point in out records
  getPointInOutReport: async (args) => {
    return {
      Result: 200,
      Response: {
        cols: ["Sl No.", "Point Name", "In Time", "Out Time", "Duration"],
        rows: [
          [
            "1",
            "New point 1",
            "2020-07-03 15:25:15",
            "2020-07-03 15:47:49",
            "22min 34s",
          ],
          [
            "2",
            "New point 2",
            "2020-07-03 15:49:18",
            "2020-07-03 16:39:37",
            "50min 19s",
          ],
        ],
        hasNext: true,
      },
    };
  },
  // Idle Report: Vehicle Idle Info
  getIdleReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      imei: "73383121230",
      fromDate: "2020-06-01 00:00:00",
      toDate: "2020-06-30 23:59:59",
      platform: "web", // Or it will be "app"
    };
    // If platform is "app" => "adress" should be json
    // {
    //   address:"Bangalore East, Bangalore Urban, Karnataka, 560049, India",
    //   lat:13.050612,
    //   lng:77.752776
    // }
    return {
      Result: 200,
      Response: {
        cols: ["Sl No.", "Idle From", "End Time", "Duration", "Idle Location"],
        rows: [
          [
            "1",
            "2020-06-01 18:22:08",
            "2020-06-02 18:22:08",
            "24h 0min 0s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "2",
            "2020-06-03 05:20:08",
            "2020-06-03 10:20:08",
            "5h 0min 0s",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
        ],
        hasNext: true,
      },
    };
  },

  // Vehicle Document Expiry Report:
  getVehicleDocExpiryReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      imei: "73383121230",
    };
    return {
      Result: 200,
      Response: {
        cols: [
          "Sl No.",
          "Vehicle No.",
          "Chassis No.",
          "Engine No.",
          "Insurance",
          "Permit",
          "Road Tax",
          "Goods Permit",
          "Goods Tax",
          "Driver License",
        ],
        rows: [
          [
            "1",
            "HR55M 1424",
            "WBE147151Z",
            "WBE147151Z",
            "2018-01-02",
            "2019-01-08",
            "2017-03-31",
            "2021-01-08",
            "N/A",
            "N/A",
          ],
          [
            "2",
            "HR55M1414",
            "01L62958384",
            "01L62958384",
            "2018-01-02",
            "2019-01-08",
            "2017-03-31",
            "2021-01-08",
            "N/A",
            "N/A",
          ],
        ],
        hasNext: false,
      },
    };
  },

  getAtoBTripReport: async (args = {}) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      fromDate: "2020-07-01 00:00:00",
      toDate: "2020-07-31 23:59:59",
      fromZoneId: "1101",
      toZoneId: "1102",
    };
    return {
      Result: 200,
      Response: {
        cols: [
          "Sl. No.",
          "Vehicle No.",
          "Zone Name",
          "Zone In Time",
          "Zone Out Time",
          "Zone Name",
          "Zone In Time",
          "Zone Out Time",
          "Time Taken",
          "Time Taken (In Hours)",
          "Total Kms",
          "View Route",
          "Journey Report",
        ],
        rows: [
          [
            "1",
            "KA51AB0410",
            "Bangalore",
            "2020-07-05 13:42:59",
            "2020-07-05 15:26:31",
            "MRF Trichy",
            "2020-07-06 17:15:43",
            "2020-07-07 17:43:44",
            "1d 1h 49min 12s",
            "25",
            "407.83",
            "",
            "",
          ],
          [
            "2",
            "KA51AB0410",
            "Bangalore",
            "2020-07-05 13:42:59",
            "2020-07-05 15:26:31",
            "MRF Trichy",
            "2020-07-06 17:15:43",
            "2020-07-07 17:43:44",
            "1d 1h 49min 12s",
            "25",
            "407.83",
            "",
            "",
          ],
        ],
        hasNext: false,
      },
    };
  },

  // AC On/Off Report: Ignition On/Off Info
  getIgnitionReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      imei: "73383121230",
      fromDate: "2020-07-01 00:00:00",
      toDate: "2020-07-30 23:59:59",
      platform: "web", // Or it will be "app"
    };
    // For App "adress" should be json
    // {
    //   address:"Bangalore East, Bangalore Urban, Karnataka, 560049, India",
    //   lat:13.050612,
    //   lng:77.752776
    // }
    return {
      Result: 200,
      Response: {
        cols: ["Sl No.", "Date", "Status", "Location"],
        rows: [
          [
            "1",
            "2020-07-01 07:10:22",
            "OFF",
            '<a href="https://www.google.com/maps?q=13.063375,77.456312" target="_blank">Adakamaranahalli, Madanayakanahalli, Madhavara, Bangalore Urban, Karnataka, 560073, India</a>',
          ],
          [
            "2",
            "2020-07-01 07:15:32",
            "ON",
            '<a href="https://www.google.com/maps?q=13.063375,77.456312" target="_blank">Adakamaranahalli, Madanayakanahalli, Madhavara, Bangalore Urban, Karnataka, 560073, India</a>',
          ],
        ],
        hasNext: false,
      },
    };
  },

  // Route Trip
  getRouteTripReport: async (args) => {
    // let args = {
    //   pageNo: 1,
    //   pageLength: 30,
    //   imei: "73383121230",
    //   fromDate: "2020-07-01 00:00:00",
    //   toDate: "2020-07-31 23:59:59",
    // };
    return {
      Result: 200,
      Response: {
        cols: [
          "Sl. No.",
          "Reg No.",
          "Vehicle Type",
          "Start Time",
          "Start Location",
          "End Time",
          "End Location",
          "Total KM",
          "Running Hours",
          "Idle Hours",
          "Total Speed",
          "Avg speed",
        ],
        rows: [],
        hasNext: false,
      },
    };
  },

  // A to B Vehicle Trip Report
  getAtoBVehicleTripReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      fromDate: "2020-07-01 00:00:00",
      toDate: "2020-07-31 23:59:59",
      fromZoneId: "1101",
      toZoneId: "1102",
      imei: "96064561650",
    };
    return {
      Result: 200,
      Response: {
        cols: ["Sl. No.", "Zone Name", "Zone In Time", "Zone Out Time"],
        rows: [
          ["1", "Bangalore", "2020-07-05 13:36:57", "2020-07-05 14:21:15"],
          ["2", "MRF Trichy", "2020-07-06 17:13:52", "2020-07-06 17:18:14"],
        ],
        hasNext: false,
      },
    };
  },

  getConsolidatedReport: async (args = {}) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      fromDate: "2020-07-01 00:00:00",
      toDate: "2020-07-31 23:59:59",
      fromZoneId: "1101",
      toZoneId: "1102",
    };
    return {
      Result: 200,
      Response: {
        cols: [
          "Sl. No.",
          "Reg No.",
          "Vehicle Type",
          "Start Time",
          "Start Location",
          "End Time",
          "End Location",
          "Total KM",
          "Running Hours",
          "Idle Hours",
          "Total Speed",
          "Avg speed",
        ],
        rows: [],
        hasNext: false,
      },
    };
  },

  getGroupwiseCurrentMonthReport: async (args = {}) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      fromDate: "2020-07-01 00:00:00",
      toDate: "2020-07-31 23:59:59",
      fromZoneId: "1101",
      toZoneId: "1102",
    };
    return {
      Result: 200,
      Response: {
        cols: [
          "Sl. No.",
          "Reg No.",
          "Vehicle Type",
          "Start Time",
          "Start Location",
          "End Time",
          "End Location",
          "Total KM",
          "Running Hours",
          "Idle Hours",
          "Total Speed",
          "Avg speed",
        ],
        rows: [],
        hasNext: false,
      },
    };
  },

  //KM Log Report: Shows the distance day wise for the given range
  getCurrentMonthReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      fromDate: "2020-07-01",
      toDate: "2020-07-03",
      groupId: "", // Get Vehicles of the group, If empty ignore
      imeis: ["356173061896648", "3172711044"], // If empty -> All Vehicles
    };
    return {
      Result: 200,
      Response: {
        cols: ["Sl No.", "Vehicle No.", "01", "02", "03", "Total"],
        rows: [
          ["1", "KA02AG-5403-TEMP", "125.5", "4.33", "150.25", "280.08"],
          ["2", "KA02AG-5403-TEMP", "100.00", "0.00", "0.00", "100.00"],
        ],
        hasNext: false,
      },
    };
  },

  // Road Trip Kms Report
  getRoadTripKmsReport: async (args) => {
    // args = {
    //   pageNo: 1,
    //   pageLength: 30,
    //   fromDate: "2020-07-01 00:00:00",
    //   toDate: "2020-07-31 23:59:59",
    //   imei: "96064561650",
    // };
    return {
      Result: 200,
      Response: {
        cols: [
          "Trip No.",
          "Hub",
          "Departure",
          "Arrival",
          "Round Trip Kms",
          "Journey Report",
          "Show on map",
        ],
        rows: [],
        hasNext: false,
      },
    };
  },

  //Non-Journey Report: Vehicle non-jouney details
  getNonJourneyReport: async (args) => {
    // args = {
    //   pageNo: 1,
    //   pageLength: 30,
    //   fromDate: "2020-07-11 00:00:00",
    //   toDate: "2020-07-12 00:00:00",
    //   imei: 73383121230,
    //   idleTime: 60, // Idle time in minutes
    //   platform: "web", // Or it will be "app"
    // };
    // If platform is "app" => "adress" should be json
    // {
    //   address:"Bangalore East, Bangalore Urban, Karnataka, 560049, India",
    //   lat:13.050612,
    //   lng:77.752776
    // }
    return {
      Result: 200,
      Response: {
        cols: [
          "Sl No.",
          "Vehicle No.",
          "Vehicle Type",
          "Last Running",
          "Last Contact",
          "Halting Since",
          "Location",
        ],
        rows: [
          [
            "1",
            "HR38X-9165",
            "",
            "2020-03-27 02:58:16",
            "2020-04-10 17:03:33",
            "4 months 7 days 15 hours 14 minutes 23 seconds",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
          [
            "2",
            "HR38X-9165",
            "",
            "2020-03-27 02:58:16",
            "2020-04-10 17:03:33",
            "4 months 7 days 15 hours 14 minutes 23 seconds",
            '<a href="https://www.google.com/maps?q=13.050612,77.752776" target="_blank">Nimbekaipura, Bangalore East, Bangalore Urban, Karnataka, 560049, India</a>',
          ],
        ],
        hasNext: true,
      },
    };
  },

  // Distance (Kms) by Hour Report
  getDistanceByHourReport: async (args) => {
    return {
      Result: 200,
      Response: {
        cols: [
          "Date",
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "Total Kms",
        ],
        rows: [
          ["2020-07-01", "0", "23.36", "15.6", "6.3", "32.88", "78.14"],
          ["2020-07-02", "0", "23.36", "15.6", "6.3", "32.88", "78.14"],
        ],
        hasNext: true,
      },
    };
  },

  // Monthly Analysis Report
  getMonthlyAnalysisReport: async (args) => {
    args = {
      pageNo: 1,
      pageLength: 30,
      month: "08",
      year: 2020,
    };
    // If platform is "app" => "adress" should be json
    // {
    //   address:"Bangalore East, Bangalore Urban, Karnataka, 560049, India",
    //   lat:13.050612,
    //   lng:77.752776
    // }
    return {
      Result: 200,
      Response: {
        cols: [
          "Sl No.",
          "Vehicle No.",
          "Vehicle Type",
          "Days count 0 to 10 Kms",
          "Days count 10 to 100 Kms",
          "Days count 100 to 250 Kms",
          "Total Days",
        ],
        rows: [
          ["1", "HR38U-0301", "", "3", "6", "4", "5", "18"],
          ["2", "HR38U-0301", "", "3", "6", "4", "5", "18"],
        ],
        hasNext: true,
      },
    };
  },
  getNightDriveReport: async (args) => {
    return {
      Result: 200,
      Response: {
        cols: [
          "Sl No",
          "Reg No",
          "Vehicle Type",
          "Start Time",
          "Start Location",
          "End Time",
          "End Location",
          "Total KM",
          "Running Hours",
          "Idle Hours",
          "Top Speed",
          "Avg Speed",
        ],
        rows: [],
      },
    };
  },
  getGridReport: async (args) => {
    args = {};
    return {
      Result: 200,
      Response: {
        cols: ["Sl No.", "vehicleno", "imei", "status"],
        // rows: [
        //   ["1", "02082020", "42076", "Active"],
        //   ["2", "02082020", "42076", "Active"],
        // ],
        hasNext: true,
      },
    };
  },
};
