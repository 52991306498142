import iconAircraftcarrier from "images/icons/points/aircraftcarrier.png";
import iconAirport from "images/icons/points/airport.png";
import iconApartment3 from "images/icons/points/apartment-3.png";
import iconArch from "images/icons/points/arch.png";
import iconBank from "images/icons/points/bank.png";
import iconBar from "images/icons/points/bar.png";
import iconBridgemodern from "images/icons/points/bridge_modern.png";
import iconBus from "images/icons/points/bus.png";
import iconCaraccident from "images/icons/points/caraccident.png";
import iconCarwash from "images/icons/points/carwash.png";
import iconCaution from "images/icons/points/caution.png";
import iconCctv from "images/icons/points/cctv.png";
import iconCoffee from "images/icons/points/coffee.png";
import iconConstruction from "images/icons/points/construction.png";
import iconCourt from "images/icons/points/court.png";
import iconFillingstation from "images/icons/points/fillingstation.png";
import iconFireexstinguisher from "images/icons/points/fireexstinguisher.png";
import iconFirstaid from "images/icons/points/firstaid.png";
import iconHome from "images/icons/points/home.png";
import iconMotel from "images/icons/points/motel-2.png";
import iconParkingMeter from "images/icons/points/parking-meter-export.png";
import iconParking from "images/icons/points/parking.png";
import iconRepair from "images/icons/points/repair.png";
import iconTires from "images/icons/points/tires.png";
import iconTollstation from "images/icons/points/tollstation.png";
import iconWifi from "images/icons/points/wifi.png";

export const IMAGES = {
  points: [
    { text: "", image: iconAircraftcarrier },
    { text: "", image: iconAirport },
    { text: "", image: iconApartment3 },
    { text: "", image: iconArch },
    { text: "", image: iconBank },
    { text: "", image: iconBar },
    { text: "", image: iconBridgemodern },
    { text: "", image: iconBus },
    { text: "", image: iconCaraccident },
    { text: "", image: iconCarwash },
    { text: "", image: iconCaution },
    { text: "", image: iconCctv },
    { text: "", image: iconCoffee },
    { text: "", image: iconConstruction },
    { text: "", image: iconCourt },
    { text: "", image: iconFillingstation },
    { text: "", image: iconFireexstinguisher },
    { text: "", image: iconFirstaid },
    { text: "", image: iconHome },
    { text: "", image: iconMotel },
    { text: "", image: iconParkingMeter },
    { text: "", image: iconParking },
    { text: "", image: iconRepair },
    { text: "", image: iconTires },
    { text: "", image: iconTollstation },
    { text: "", image: iconWifi },
  ],
};
