import { REPORT_ACTIONS } from "../actions/ReportActions";

let initialState = {
  loading: true,
  reportPopupDialog: false,
  vehiclesInPoi: [],
  docExpiryList: {},
};

const reportReducer = (state = initialState, action) => {
  const { type, values } = action;
  switch (type) {
    case REPORT_ACTIONS.SET_LOADING:
      return { ...state, loading: values.loading };
    case REPORT_ACTIONS.TOGGLE_REPORT_POPUP:
      return { ...state, reportPopupDialog: values.reportPopupDialog };
    case REPORT_ACTIONS.SET_VEHICLES_IN_POI:
      return { ...state, vehiclesInPoi: values.vehiclesInPoi };
    case REPORT_ACTIONS.SET_DOC_EXPIRY_LIST:
      return { ...state, docExpiryList: values.docExpiryList };
    default:
      break;
  }
  return state;
};

export default reportReducer;
