import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
// Leaflet
import { Tooltip, Marker, Polyline, Popup } from "react-leaflet";
import L from "leaflet";
import "react-leaflet-markercluster/dist/styles.min.css";
import "leaflet/dist/leaflet.css";

import MapPopup from "./MapPopup";
import MovingMarker from "./track/MovingMarker";

import { COLORS, UNITS } from "common/consts";
import { dateFunction } from "common/datefunctions";

// Images
import routeStart from "images/mapImages/markers/route_start.png";
import routeStop from "images/mapImages/markers/route_stop.png";
import routeEnd from "images/mapImages/markers/route_end.png";
import directionIcon from "images/mapImages/markers/direction-marker.png";

// Custom CSS
import "./styles/fleetmap.css";

class Trackline extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getMapMarker = (status, angle = 0) => {
    let markerUrl = routeStart;
    let markerSize = 16;
    if (status === "stop") {
      markerUrl = routeStop;
    } else if (status === "end") {
      markerUrl = routeEnd;
    } else if (status === "direction") {
      markerUrl = directionIcon;
      markerSize = 12;
    }

    const html =
      "<div class='trackMarker' style='transform: rotate(" +
      angle.toString() +
      "deg);'><img src='" +
      markerUrl +
      "' alt='' width='" +
      markerSize +
      "px'/></div>";

    const customMarker = L.divIcon({
      html: html,
      iconSize: new L.Point(markerSize, markerSize),
      iconAnchor: null,
      popupAnchor: [0, -3],
      className: "trackMarker m-" + status,
    });

    return customMarker;
  };

  getTracklinePoints = (s, index, marker = "stop") => {
    return (
      <Marker
        position={[s.latitude, s.longitude]}
        icon={this.getMapMarker(marker, s.angle)}
        className={"track-marker"}
        key={marker + "tmarker-" + index}
      >
        {marker === "stop" && (
          <Tooltip direction="right" offset={[10, -2]} permanent>
            <span>
              {dateFunction.convSecondsToString(
                (new Date(s.totime) - new Date(s.fromtime)) / 1000
              )}
            </span>
          </Tooltip>
        )}

        {marker === "direction" && (
          <Popup>
            <div className="markerPopup">
              <table>
                <tbody className={"dataPointBody"}>
                  <tr>
                    <td className="w40p pB5">Time</td>
                    <td className="w60p pB5 textOneLine">
                      {": "}&nbsp; {dateFunction.getFormatedDate(s.fromtime)}
                    </td>
                  </tr>
                  <tr>
                    <td className="w40p pB5">Position</td>
                    <td className="w60p pB5 textOneLine">
                      {": "}&nbsp;&nbsp;
                      <a
                        href={
                          "//maps.google.com/maps?q=" +
                          s.latitude +
                          "," +
                          s.longitude
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {Number(s.latitude).toFixed(6)}
                        °, {Number(s.longitude).toFixed(6)}°
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="w40p pB5">Speed</td>
                    <td className="w60p pB5 textOneLine">
                      {": "}&nbsp;&nbsp;
                      {Number(s.speed).toFixed(1) + " " + UNITS.SPEED}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Popup>
        )}

        {marker === "stop" && (
          <Popup>
            <div className="markerPopup">
              <table>
                <tbody className={"dataPointBody"}>
                  <tr>
                    <td colSpan="2">
                      <h4>{s.summary["details"]}</h4>
                    </td>
                  </tr>
                  <tr>
                    <td className="w40p pB5">Position</td>
                    <td className="w60p pB5 textOneLine">
                      {": "}&nbsp;&nbsp;
                      <a
                        href={
                          "//maps.google.com/maps?q=" +
                          s.latitude +
                          "," +
                          s.longitude
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {Number(s.latitude).toFixed(6)}
                        °, {Number(s.longitude).toFixed(6)}°
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="w40p pB5">Came</td>
                    <td className="w60p pB5 textOneLine">
                      {": "}&nbsp; {dateFunction.getFormatedDate(s.fromtime)}
                    </td>
                  </tr>
                  <tr>
                    <td className="w40p pB5">Left</td>
                    <td className="w60p pB5 textOneLine">
                      {": "}&nbsp; {dateFunction.getFormatedDate(s.totime)}
                    </td>
                  </tr>
                  <tr>
                    <td className="w40p pB5">Duration</td>
                    <td className="w60p pB5 textOneLine">
                      {": "}&nbsp;{" "}
                      {dateFunction.convSecondsToString(
                        (new Date(s.totime) - new Date(s.fromtime)) / 1000
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Popup>
        )}
      </Marker>
    );
  };

  render() {
    const { trackReducer, mapReducer } = this.props;

    const { tmpVehicles, selectedVehicle } = mapReducer;

    const { routePoints, routeSummary } = trackReducer;

    const positions = [];

    routePoints.map((r) => {
      positions.push([Number(r.latitude), Number(r.longitude)]);
      return r;
    });

    let vehicle  = tmpVehicles.filter((v) => {
      return v.imei === selectedVehicle;
    });

    if (vehicle.length > 0) {
      vehicle = vehicle[0];
    } else {
      return null;
    }

    let stops = routeSummary.filter((r) => {
      return r.status === 0;
    });

    // dataPoints = false -> Show Direction
    let directions = [];
    let pointsGap = 500; // TODO: Make it more accurate
    let totalPoints = Math.ceil(routePoints.length / pointsGap);
    if (totalPoints < 5 && routePoints.length < pointsGap) {
      pointsGap = Math.ceil(routePoints.length / 5);
    }

    for (let i = 50; i < routePoints.length - 50; i++) {
      if (i % pointsGap === 0) {
        // For Every "pointsGap" points
        if (routePoints[i].speed > 0) {
          directions.push(routePoints[i]);
        }
      }
    }

    return (
      positions.length > 0 && (
        <React.Fragment>
          <Polyline
            positions={positions}
            color={COLORS.RED}
            weight="3"
            ref={(ref) => {
              this._mapPolyline = ref;
            }}
          />

          <Marker
            position={positions[0]}
            icon={this.getMapMarker("start")}
            className="track-marker"
          >
            <MapPopup vehicle={vehicle} data={routePoints[0]} />
            <Tooltip direction="right" offset={[10, -2]} permanent>
              <span>{"Start Point"}</span>
            </Tooltip>
          </Marker>

          <Marker
            position={positions[positions.length - 1]}
            icon={this.getMapMarker("end")}
            className="track-marker"
          >
            <MapPopup
              vehicle={vehicle}
              data={routePoints[routePoints.length - 1]}
            />
            <Tooltip direction="right" offset={[10, -2]} permanent>
              <span>{"End Point"}</span>
            </Tooltip>
          </Marker>

          {stops.map((s, index) => {
            return this.getTracklinePoints(s, index, "stop");
          })}

          {directions.map((d, index) => {
            return this.getTracklinePoints(d, index, "direction");
          })}

          <MovingMarker vehicle={vehicle} />
        </React.Fragment>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trackReducer: state.trackReducer,
    mapReducer: state.mapReducer,
  };
};

export default connect(mapStateToProps)(withSnackbar(Trackline));
