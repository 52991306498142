import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import FleetDialog from "../../ui/dialogbox/FleetDialog";
import AutoComplete from "../../ui/autocomplete/AutoComplete";
import UiDatePicker from "../../ui/uidatepicker/UiDatePicker";

import { commonJs } from "../../../common/common";
import { NOTIFY, FORMATS, SUCCESS_MSG } from "../../../common/consts";
import { dateFunction } from "../../../common/datefunctions";

import { API_Settings } from "../../../apihandler/Settings";

class DialogMainGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGroup: [],
      record: {},
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  saveRecord = () => {
    const { selectedGroup, record } = this.state;
    const { uid } = this.props.loginReducer;
    const mainGroupName = document.getElementById("mainGroupName").value.trim();

    // Form Validation
    if (mainGroupName === "") {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Please enter main group name..!"
      );
      return false;
    }
    if (selectedGroup.length === 0) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Please select any group..!"
      );
      return false;
    }

    const args = {
      mainGroupId: record.id || "",
      mainGroupName,
      userId: uid,
      groups: selectedGroup.map((g) => {
        return g.id;
      }),
    };

    const self = this;
    API_Settings.manageMainGroup(args)
      .then((res) => {
        console.log("manageMainGroup: ", res);
        if (res.Result === 200) {
          self.props.setShowDialog(false);
          self.props.togglePopup({});
          self.props.reloadGrid();
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            SUCCESS_MSG.RECORD_SAVED,
            <button className="snackbar-btn">{"dismiss"}</button>
          );
        } else {
          commonJs.showNotify(
            self.props,
            NOTIFY.ERROR,
            "Error: " + res.Reason,
            <button className="snackbar-btn">{"dismiss"}</button>
          );
        }
      })
      .catch((err) => {
        commonJs.showNotify(
          self.props,
          NOTIFY.ERROR,
          SUCCESS_MSG.RECORD_NOT_SAVED,
          <button className="snackbar-btn">{"dismiss"}</button>
        );
        console.log("error", err);
      });
  };

  setData = (rec = {}) => {
    this.setState({
      record: rec,
      selectedGroup: rec.groups,
    });
  };

  onChangeGroup = (selectedGroup) => {
    this.setState({ selectedGroup });
  };

  render() {
    const { record, selectedGroup } = this.state;
    const { showDialog, groups } = this.props;
    return (
      showDialog && (
        <FleetDialog
          id="mainGroupDialog"
          open={true}
          onCloseSettings={this.props.togglePopup}
          title={record.id ? "Edit Main Group" : "Add Main Group"}
          maxWidth="sm"
          fullWidth={false}
          cancelButton={true}
          saveButton={true}
          cancelAction={this.props.togglePopup}
          saveAction={this.saveRecord}
          saveBtnLabel="OK"
          disableBackdropClick={false}
          disableBackdrop={false}
          classNameBody="zonePopupBody"
          classNameWrapper="zonePopupWrapper settingsDialogBox"
          draggable
        >
          <div className="grid">
            <div className="row">
              <TextField
                id="mainGroupName"
                label="Main Group Name"
                classes={{ root: "txtfield" }}
                autoFocus={true}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={record.name || ""}
              />
            </div>
            <div className="row">
              <AutoComplete
                label={"Select Sub Groups"}
                list={groups}
                id="selectedGroup"
                style={{ marginBottom: 15, width: "100%" }}
                onChange={this.onChangeGroup}
                defaultValue={selectedGroup}
                multiple={true}
                size="small"
                disabled={false}
              />
            </div>
          </div>
        </FleetDialog>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DialogMainGroup));
