import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  Replay as Reload,
  Add,
  Delete,
  LocalShipping as VehicleIcon,
} from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import SearchButton from "components/ui/searchbutton/SearchButton";
import ConfirmDialog from "components/ui/confirmdialog/ConfirmDialog";
import VehicleListGrid from "./VehicleListGrid";
import DialogAddVehicle from "./DialogAddVehicle";

import { API_Settings } from "apihandler/Settings";
import { MAP_ACTIONS } from "actions/MapActions";
import { commonJs } from "common/common";
import { LABELS, PRIVILEGES } from "common/consts";

class VehiclesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      popupDialog: false,
      showDialog: true,
    };
  }

  componentDidMount() {
    this.getUserGroups();
  }

  getUserGroups = () => {
    const args = {
      pageNo: 1,
      pageLength: 1000,
    };
    const self = this;
    API_Settings.getAllUserGroups(args).then((res) => {
      if (res.Result === 200) {
        let userGroups = { 0: { name: LABELS.UNGROUPED, vcount: 0 } };
        let list = res.Response;
        for (let i = 0; i < list.length; i++) {
          userGroups[list[i].id] = {
            name: list[i].name,
            vcount: 0,
          };
        }
        const { tmpVehicles } = self.props.mapReducer;
        for (let i = 0; i < tmpVehicles.length; i++) {
          if (userGroups[tmpVehicles[i].groupId]) {
            userGroups[tmpVehicles[i].groupId].vcount++;
          }
        }
        self.props.setUserGroups({ userGroups });
      }
    });
  };

  reloadGrid = () => {
    this._vehiclesListGrid.loadReloadRecords();
  };

  onSearch = (e) => {
    let val = e.target.value.trim();
    this._vehiclesListGrid.onSearch(val);
  };

  togglePopup = (rec = {}) => {
    const { popupDialog } = this.state;
    console.log("togglePopup: ", rec);
    if (rec.imei) {
      let self = this;
      this.setState({
        popupDialog: !popupDialog,
        showDialog: false,
      });
      setTimeout(() => {
        if (self._vehicleDialog) {
          self._vehicleDialog.setData(rec);
        }
        self.setState({
          showDialog: true,
        });
      }, 1000);
    } else {
      this.setState({
        popupDialog: !popupDialog,
        showDialog: true,
      });
    }
  };

  onDeleteRecord = (id, name) => {
    this._confirmDialog.toggleConfirmDialog(id, name);
  };

  onDeleteSuccess = (id) => {
    console.log("onDeleteSuccess: ", id);
    if (id) {
      API_Settings.deleteUserVehicle({
        imei: id,
      })
        .then((res) => {
          console.log("deleteUserVehicle: ", res);
          if (res.Result === 200) {
            this._confirmDialog.toggleConfirmDialog("close");
          } else {
            console.log("Not");
          }
        })
        .catch((err) => {
          console.log("Not");
        });
    } else {
      // API_Settings.deleteAllUserVehicles()
      //   .then((res) => {
      //     console.log("deleteAllUserVehicles: ", res);
      //     if (res.Result === 200) {
      //       this._confirmDialog.toggleConfirmDialog("close");
      //     } else {
      //       console.log("Not");
      //     }
      //   })
      //   .catch((err) => {
      //     console.log("Not");
      //   });
    }
  };

  onFilterBy = (val = "") => {
    this._vehiclesListGrid.onFilterBy(val);
  };

  render() {
    const { loading, popupDialog, showDialog } = this.state;
    const { role, privileges } = this.props.loginReducer;
    const hasAddVehicle = commonJs.hasPrivilege(
      PRIVILEGES.ADD_VEHICLE,
      role,
      privileges
    );
    return (
      <div id="vehiclesListGrid" className="settingsGrid">
        <ul>
          <li className="searchDiv">
            <SearchButton
              id="vehicleListSearch"
              placeholder="Search By Vehicle Name"
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
              onChange={this.onSearch}
            />
          </li>
          <li>
            <Tooltip title="Reload" placement="bottom">
              <IconButton onClick={this.reloadGrid}>
                <Reload />
              </IconButton>
            </Tooltip>
          </li>
          {hasAddVehicle && (
            <li title="Add Vehicle">
              <Tooltip title="Add Vehicle" placement="bottom">
                <IconButton onClick={this.togglePopup}>
                  <Add />
                </IconButton>
              </Tooltip>
            </li>
          )}
          <li title="All Vehicles">
            <Tooltip title="All Vehicles" placement="bottom">
              <IconButton onClick={() => this.onFilterBy("AllVehicles")}>
                <VehicleIcon className="" />
              </IconButton>
            </Tooltip>
          </li>
          <li title="Active Vehicles">
            <Tooltip title="Active Vehicles" placement="bottom">
              <IconButton onClick={() => this.onFilterBy("ActiveVehicles")}>
                <VehicleIcon className="allActiveIcon" />
              </IconButton>
            </Tooltip>
          </li>
          <li title="Inactive Vehicles">
            <Tooltip title="Inactive Vehicles" placement="bottom">
              <IconButton onClick={() => this.onFilterBy("InactiveVehicles")}>
                <VehicleIcon className="allInactiveIcon" />
              </IconButton>
            </Tooltip>
          </li>
          {/* <li>
            <Tooltip title="Delete All Vehicles" placement="bottom">
              <IconButton onClick={() => this.onDeleteRecord("all")}>
                <Delete />
              </IconButton>
            </Tooltip>
          </li> */}
        </ul>

        {!loading ? (
          <VehicleListGrid
            childRef={(ref) => (this._vehiclesListGrid = ref)}
            hasAddVehicle={hasAddVehicle}
            togglePopupDialog={this.togglePopup}
            onDeleteRecord={this.onDeleteRecord}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: window.innerHeight / 1.5,
              alignItems: "center",
            }}
          >
            <p style={{ color: "#a1a1a1", fontSize: 14 }}>Loading...</p>
          </div>
        )}

        {popupDialog && (
          <DialogAddVehicle
            childRef={(ref) => (this._vehicleDialog = ref)}
            togglePopup={this.togglePopup}
            reloadGrid={this.reloadGrid}
            showDialog={showDialog}
          />
        )}

        {!showDialog && (
          <div className="popover-loading">
            <CircularProgress className={"primary"} />
          </div>
        )}

        <ConfirmDialog
          childRef={(ref) => (this._confirmDialog = ref)}
          onOk={this.onDeleteSuccess}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserGroups: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_GROUPS,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(VehiclesList));
