import { LOGIN_ACTIONS } from "../actions/LoginActions";
import { LOCAL_STORAGE } from "../common/consts";
import { commonJs } from "../common/common";

// Login & Major Settings Details
let initialState = {
  uid: "",
  login: false,
  userName: "",
  userPassword: "",
  logo: "",
  role: "",
  privileges: [],
};

var userInfo = commonJs.getItemFromLocal(LOCAL_STORAGE.USER_DATA);
if (userInfo !== null) {
  var userSettings = commonJs.getItemFromLocal(LOCAL_STORAGE.USER_SETTINGS);
  initialState.userName = userInfo.userName;
  initialState.userPassword = userInfo.userPassword;
  initialState.uid = userSettings.id;
  initialState.logo = userSettings.logo;
  initialState.role = userSettings.role;
  initialState.privileges = userSettings.privileges;
}

const loginReducer = (state = initialState, action) => {
  const { type, values } = action;
  switch (type) {
    case LOGIN_ACTIONS.SET_LOGIN:
      return { ...state, login: values.login || false };
    case LOGIN_ACTIONS.SET_LOGIN_INFO:
      return {
        ...state,
        uid: values.id || "",
        login: values.login || false,
        userName: values.userName || "",
        logo: values.logo || "",
        role: values.role || "",
        privileges: values.privileges || [],
      };
    default:
      break;
  }
  return state;
};

export default loginReducer;
