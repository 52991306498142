import { COMMON_ACTIONS } from "../actions/CommonActions";

let initialState = {
  vehiclesList: [],
  animationVehiclesList: [],
};

const commonReducer = (state = initialState, action) => {
  const { type, values } = action;
  switch (type) {
    case COMMON_ACTIONS.SET_VEHICLES:
      return { ...state, vehiclesList: values.vehicles };
    case COMMON_ACTIONS.SET_ANIMATION_VEHICLES:
      return { ...state, animationVehiclesList: values.vehicles };
    default:
      break;
  }
  return state;
};

export default commonReducer;
