import React, { Component } from "react";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import "./txt.css";

class Txt extends Component {
  render() {
    return (
      <FormControl className={this.props.classname}>
        <InputLabel htmlFor={this.props.id} className="purple">
          {this.props.label}
        </InputLabel>
        <Input
          id={this.props.id}
          type={this.props.type}
          endAdornment={this.props.endAdornment}
          disabled={this.props.disabled}
          defaultValue={this.props.defaultValue}
          fullWidth={this.props.fullWidth}
          onKeyPress={this.props.onKeyPress}
          classes={{ root: "txt" }}
        />
      </FormControl>
    );
  }
}

export default Txt;
