import React, { Component } from "react";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import jQuery from "jquery";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.flash";
import FleetDialog from "components/ui/dialogbox/FleetDialog";

import { DEFAULTS, FORMATS, LABELS, UNITS } from "common/consts";
import { commonJs } from "common/common";
import { dateFunction } from "common/datefunctions";

var $ = jQuery;

class KmTravelled extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      rows: [],
    };
    this._args = {};
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
    if ($.fn.dataTable.isDataTable("#reportGrid")) {
      let table = $("#reportGrid").DataTable();
      table.destroy();
    }
    $("#reportGrid").empty();
  }

  toggleDialog = (showDialog = false, args = {}) => {
    this.setState({
      showDialog,
      loading: showDialog,
    });
    if (showDialog === true) {
      let self = this;
      this._args = args;
      let rows = [];
      let columns = [
        {
          title: "Sl No.",
        },
        {
          title: "Status",
        },
        {
          title: "Start",
        },
        {
          title: "End",
        },
        {
          title: "Duration",
        },
        {
          title: "Kms",
        },
        {
          title: "Stop Location",
        },
        {
          title: "Top Speed",
        },
        {
          title: "Avg. Speed",
        },
      ];
      const { routeSummary } = this.props.trackReducer;
      for (let r = 0; r < routeSummary.length; r++) {
        let row = routeSummary[r];
        let mapLocation = "";
        if (row.status === 0) {
          mapLocation =
            "<a target='_blank' href='//maps.google.com/maps?q=" +
            row.latitude +
            "," +
            row.longitude +
            "'>" +
            row.summary.details +
            "</a>";
        }
        rows.push([
          r + 1,
          row.status === 1 ? LABELS.RUNNING : LABELS.STOPPED,
          row.fromtime,
          row.totime,
          dateFunction.getDateDiff(row.fromtime, row.totime, true, true),
          row.summary.routelength,
          mapLocation,
          row.summary.top_speed,
          row.summary.avg_speed,
        ]);
      }

      console.log("Travelled: ", rows, columns);

      // Reset Grid
      if ($.fn.dataTable.isDataTable("#reportGrid")) {
        let table = $("#reportGrid").DataTable();
        table.destroy();
      }
      $("#reportGrid").empty();

      let vehicleName = "";
      if (document.querySelector("input#trackVehicles")) {
        vehicleName = document.querySelector("input#trackVehicles").value;
      }
      let title =
        LABELS.KM_TRAVELLED +
        " - " +
        vehicleName +
        " (" +
        dateFunction.getFormatedDate(
          args.fdt,
          FORMATS.SQL_DATE,
          FORMATS.REPORT_TITLE_DATE
        ) +
        " - " +
        dateFunction.getFormatedDate(
          args.tdt,
          FORMATS.SQL_DATE,
          FORMATS.REPORT_TITLE_DATE
        ) +
        ")";

      setTimeout(() => {
        $("#reportGrid").DataTable({
          dom: "Bfrtip",
          buttons: [
            {
              extend: "copy",
              className: "reportBtn",
              title: title,
            },
            {
              extend: "excel",
              className: "reportBtn",
              title: title,
            },
            {
              extend: "csv",
              className: "reportBtn",
              title: title,
              text: "CSV",
            },
            {
              extend: "print",
              className: "reportBtn",
              title: title,
              customize: (win) => {
                commonJs.getExportPrintCss(win);
              },
            },
          ],
          paging: true,
          pageLength: DEFAULTS.REPORTS_PAGE_LENGTH,
          columns: columns,
          data: rows,
        });
        self.setState({
          loading: false,
        });
      }, 1000);
    }
  };

  render() {
    const { showDialog, loading } = this.state;
    const { routeDetails } = this.props.trackReducer;

    let totalKms = 0;
    let topSpeed = 0;
    let avgSpeed = 0;
    let moveDuration = 0;
    let stopDuration = 0;
    if (routeDetails[0]) {
      totalKms = Number(routeDetails[0].route_length);
      topSpeed = Number(routeDetails[0].top_speed);
      avgSpeed = Number(routeDetails[0].avg_speed);
      moveDuration = Number(routeDetails[0].move_duration);
      stopDuration = Number(routeDetails[0].stop_duration);
    }

    let vehicleName = "";
    if (document.querySelector("input#trackVehicles")) {
      vehicleName = document.querySelector("input#trackVehicles").value;
    }

    return (
      <FleetDialog
        open={showDialog}
        onCloseSettings={() => this.toggleDialog(false)}
        title={LABELS.KM_TRAVELLED}
        maxWidth="md"
        classNameWrapper="dialogBoxWrapper"
        fullWidth={true}
        saveButton={false}
        cancelButton={false}
        draggable={true}
      >
        <table
          border="0"
          cellPadding="4"
          cellSpacing="0"
          style={{
            fontSize: 12,
            marginTop: -10,
            marginBottom: 10,
            marginLeft: -4,
          }}
        >
          <tr>
            <td className="bold">{LABELS.VEHICLE_NAME}</td>
            <td>:</td>
            <td>{vehicleName}</td>
          </tr>
          <tr>
            <td className="bold">Period</td>
            <td>:</td>
            <td>
              {dateFunction.getFormatedDate(
                this._args.fdt,
                FORMATS.SQL_DATE,
                FORMATS.REPORT_DATE_WITH_TIME
              ) +
                " - " +
                dateFunction.getFormatedDate(
                  this._args.tdt,
                  FORMATS.SQL_DATE,
                  FORMATS.REPORT_DATE_WITH_TIME
                )}
            </td>
          </tr>
          <tr>
            <td className="bold">{LABELS.STOP_DURATION}</td>
            <td>:</td>
            <td>{this._args.stopTime + " Minutes & Above"}</td>
          </tr>
        </table>

        {loading && (
          <div className="loaderWrapper" style={{ height: 300 }}>
            <CircularProgress className="primaryLoader" />
          </div>
        )}

        <table
          id="reportGrid"
          className="mdl-data-table width100 paginated"
          cellPadding="3"
          cellSpacing="0"
        />

        {!loading && (
          <table
            width="200px"
            border="0"
            cellPadding="4"
            cellSpacing="0"
            style={{ fontSize: 12, marginTop: 10, marginLeft: -4 }}
          >
            <tr>
              <td colSpan="3" className="bold">
                Travel Summary:
              </td>
            </tr>
            <tr>
              <td>Route length</td>
              <td>:</td>
              <td>{totalKms.toFixed(2) + " " + UNITS.DISTANCE}</td>
            </tr>
            <tr>
              <td>{LABELS.MOVE_DURATION}</td>
              <td>:</td>
              <td>{dateFunction.convSecondsToString(moveDuration)}</td>
            </tr>
            <tr>
              <td>{LABELS.STOP_DURATION}</td>
              <td>:</td>
              <td>{dateFunction.convSecondsToString(stopDuration)}</td>
            </tr>
            <tr>
              <td>{LABELS.TOP_SPEED}</td>
              <td>:</td>
              <td>{topSpeed + " " + UNITS.SPEED}</td>
            </tr>
            <tr>
              <td>Average Speed</td>
              <td>:</td>
              <td>{avgSpeed.toFixed(1) + " " + UNITS.SPEED}</td>
            </tr>
          </table>
        )}
      </FleetDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trackReducer: state.trackReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(KmTravelled);
