import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { Replay as Reload, Add, Delete } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import SearchButton from "components/ui/searchbutton/SearchButton";
import ConfirmDialog from "components/ui/confirmdialog/ConfirmDialog";
import UsersVehiclesListGrid from "./VehiclesGrid";
import DialogAddUserVehicle from "./DialogAddUserVehicle";

import { API_Settings } from "apihandler/Settings";
import { NOTIFY } from "common/consts";
import { commonJs } from "common/common";

class SubUserVehicles extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, popupDialog: false, showDialog: true };
  }

  reloadGrid = () => {
    this._userVehiclesListGrid.loadReloadRecords();
  };

  onSearch = (e) => {
    let val = e.target.value.trim();
    this._userVehiclesListGrid.onSearch(val);
  };

  togglePopup = (rec) => {
    const { popupDialog } = this.state;
    if (rec.userId) {
      let self = this;
      this.setState({
        popupDialog: !popupDialog,
        showDialog: false,
      });
      setTimeout(() => {
        self._userVehicleDialog.setData(rec);
        self.setState({
          showDialog: true,
        });
      }, 1000);
    } else {
      this.setState({
        popupDialog: !popupDialog,
        showDialog: true,
      });
    }
  };

  onDeleteRecord = (id, name) => {
    this._confirmDialog.toggleConfirmDialog(id, name);
  };

  onDeleteSuccess = (data = "") => {
    const self = this;
    API_Settings.deleteSharedVehicle({
      imei: data.split(",")[0],
      userId: data.split(",")[1],
    })
      .then((res) => {
        if (res.Result === 200) {
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            "Vehicle removed from the user..!"
          );
          self._confirmDialog.toggleConfirmDialog("close");
          self.reloadGrid();
        } else {
          commonJs.showNotify(
            self.props,
            NOTIFY.ERROR,
            "Vehicle not removed from the user..!"
          );
        }
      })
      .catch((err) => {
        console.log("Not");
      });
  };

  render() {
    const { loading, popupDialog, showDialog } = this.state;

    return (
      <div id="usersVehiclesListGrid" className="settingsGrid">
        <ul>
          <li className="searchDiv">
            <SearchButton
              id="userVehicleListSearch"
              placeholder="Search By Vehicle Name"
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
              onChange={this.onSearch}
            />
          </li>
          <li>
            <Tooltip title="Reload" placement="bottom">
              <IconButton onClick={this.reloadGrid}>
                <Reload />
              </IconButton>
            </Tooltip>
          </li>
          <li title="Add Vehicle">
            <Tooltip title="Add Vehicle" placement="bottom">
              <IconButton onClick={this.togglePopup}>
                <Add />
              </IconButton>
            </Tooltip>
          </li>
          {/* <li>
            <Tooltip title="Delete All Vehicles" placement="bottom">
              <IconButton onClick={() => this.onDeleteRecord("all")}>
                <Delete />
              </IconButton>
            </Tooltip>
          </li> */}
        </ul>

        {!loading ? (
          <UsersVehiclesListGrid
            childRef={(ref) => (this._userVehiclesListGrid = ref)}
            togglePopupDialog={this.togglePopup}
            onDeleteRecord={this.onDeleteRecord}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: window.innerHeight / 1.5,
              alignItems: "center",
            }}
          >
            <p style={{ color: "#a1a1a1", fontSize: 14 }}>Loading...</p>
          </div>
        )}

        {popupDialog && (
          <DialogAddUserVehicle
            childRef={(ref) => (this._userVehicleDialog = ref)}
            togglePopup={this.togglePopup}
            showDialog={showDialog}
            reloadGrid={this.reloadGrid}
          />
        )}

        {!showDialog && (
          <div className="popover-loading">
            <CircularProgress className={"primary"} />
          </div>
        )}

        <ConfirmDialog
          childRef={(ref) => (this._confirmDialog = ref)}
          onOk={this.onDeleteSuccess}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(SubUserVehicles));
