import { SdkClient } from "fgxsdk";

export const API_Alerts = {
  getAlerts: (args) => {
    return SdkClient.getAlerts(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("getZones: ", err);
        throw new Error(err);
      });
  },
};
