import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { COLORS, LABELS, UNITS } from "common/consts";

class TemperatureGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.chart = null;
  }

  componentDidMount() {
    document.getElementById("root").classList.remove("login");
    document.getElementById("root").classList.add("scroll");
  }

  render() {
    const { data } = this.props;

    let options = {};
    if (data != null) {
      let graphData = [];
      for (var i = 0; i < data.length; i++) {
        let dt = new Date(data[i][1]).getTime();
        let temp = Number(data[i][2]);
        graphData.push([dt, temp]);
      }

      options = {
        chart: {
          zoomType: "x",
          height: "450px",
        },
        credits: false,
        title: {
          text: "",
        },
        subtitle: {
          text:
            document.ontouchstart === undefined
              ? "Click and drag in the plot area to zoom in"
              : "Pinch the chart to zoom in",
        },
        xAxis: {
          type: "datetime",
        },
        yAxis: {
          title: {
            text: LABELS.TEMPERATURE + " (" + UNITS.TEMP + ")",
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          area: {
            keys: ["x", "y", "marker"],
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: [
                [0, COLORS.PRIMARY],
                [1, "#FFF"],
              ],
            },
            marker: {
              radius: 2,
            },
            lineWidth: 1,
            lineColor: COLORS.PRIMARY,
            states: {
              hover: {
                lineWidth: 1,
              },
            },
            threshold: null,
          },
        },
        series: [
          {
            type: "area",
            name: "Temperature",
            data: graphData,
            color: COLORS.PRIMARY,
          },
        ],
      };
    }

    return (
      data != null && (
        <HighchartsReact
          ref={(ref) => (this.chart = ref)}
          highcharts={Highcharts}
          options={options}
          style={{ height: "100%" }}
        />
      )
    );
  }
}

export default TemperatureGraph;
