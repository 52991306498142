import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { CircularProgress, Backdrop } from "@material-ui/core";

import ReportTopbar from "components/reports/layouts/ReportTopbar";
import ReportLeftBar from "components/reports/layouts/ReportLeftBar";

// Dashboard
import UserDashboard from "components/reports/dashboard";

// General
import VehiclesInfoReport from "components/reports/list/general/VehiclesInfoReport";

// Km Reports
import KmReport from "components/reports/list/km/KmReport";

// pooja
import AcOnOffReport from "components/reports/list/Pooja/AcOnOffReport";
import ProximityReport from "components/reports/list/Pooja/ProximityReport";
import PowerUnplugReport from "components/reports/list/Pooja/PowerUnplugReport";
import UnderspeedReport from "components/reports/list/Pooja/UnderspeedReport";
import TemperatureByHourReport from "components/reports/list/Pooja/TemperatureByHourReport";
import TemperatureExceptionReport from "components/reports/list/Pooja/TemperatureExceptionReport";
import EtaReport from "components/reports/list/Pooja/EtaReport";
import LoadSensorReport from "components/reports/list/Pooja/LoadSensorReport";
import TemperatureColdStorageGraphReport from "components/reports/list/Pooja/TemperatureColdStorageGraphReport";
import TemperatureGraph from "components/reports/list/Pooja/TemperatureColdStorageGraphReport/TemperatureGraph";
// aviksha
import SignalReport from "components/reports/list/Aviksha/SignalReport";
import TravelHistoryReport from "components/reports/list/Aviksha/TravelHistoryReport";
import RouteDeviationReport from "components/reports/list/Aviksha/RouteDeviationReport";
import NonPerformanceReport from "components/reports/list/Aviksha/NonPerformanceReport";
import GroupwiseTempByHourReport from "components/reports/list/Aviksha/GroupwiseTempByHourReport";
import EtaSummaryReport from "components/reports/list/Aviksha/EtaSummaryReport";
import FuelTimelineReport from "components/reports/list/Aviksha/FuelTimelineReport";
import TemperatureGraphReport from "components/reports/list/Aviksha/TemperatureGraphReport";

// Navya
import NightDriveReport from "components/reports/list/navya/NightDriveReport";
import AnalyticalReport from "./list/navya/AnalyticalReport";
import OverSpeedReport from "@components/reports/list/navya/OverSpeedReport";
import RoundTripGraphReport from "./list/navya/RoundTripGraphReport";
// Sensor Reports
import TemperatureReport from "components/reports/list/sensors/TemperatureReport";
import FuelReport from "components/reports/list/sensors/FuelReport";

// POI Reports
import ZoneInOutReport from "components/reports/list/poi/ZoneInOutReport";

// Travel Reports
import DailyTravelReport from "components/reports/list/travel/DailyTravelReport";
import JourneyReport from "components/reports/list/travel/JourneyReport";
import TravelReport from "components/reports/list/travel/TravelReport";

// Analysis Reports

// Performance Reports

import { ROLES } from "common/consts";
import { REPORT_ACTIONS } from "actions/ReportActions";

import "./styles/reports.css";
import "./styles/datatables.min.css";
import { CPANEL_ACTIONS } from "actions/CpanelActions";

class Reports extends Component {
  constructor(props) {
    super(props);
    const { role } = this.props.loginReducer;
    let initPage =
      role === ROLES.SUPER_ADMIN ||
      role === ROLES.ADMIN ||
      role === ROLES.MANAGER
        ? "dashboard"
        : "vehicles-information-report";
    this.state = {
      menu: initPage,
    };
  }

  componentDidMount() {
    document.getElementById("root").classList.add("scroll");
    document.getElementById("root").classList.remove("login");
  }

  onMenuChange = (menu = "vehicles-information-report") => {
    this.setState({
      menu,
    });
  };

  render() {
    const { menu } = this.state;
    const { loading } = this.props.reportReducer;
    return (
      <div className="reports">
        <ReportTopbar />
        <ReportLeftBar menu={menu} onMenuChange={this.onMenuChange} />

        {/* Dashboard */}
        {menu === "dashboard" && <UserDashboard />}

        {/* Vehicle Reports */}
        {menu === "vehicles-information-report" && <VehiclesInfoReport />}

        {/* Kms Reports */}
        {menu === "km-summary-report" && <KmReport />}

        {/* Sensor Reports */}
        {menu === "temperature-report" && <TemperatureReport />}
        {menu === "fuel-report" && <FuelReport />}

        {/* POI Reports */}
        {menu === "zone-in-out-report" && <ZoneInOutReport />}

        {/* Travel Reports */}
        {menu === "dailytravel-report" && <DailyTravelReport />}
        {menu === "journey-report" && <JourneyReport />}
        {menu === "travel-report" && <TravelReport />}

        {/* Analysis Reports */}
        {/* {menu === "analysis-reports" && <DrivingReport />} */}

        {/* Performance Reports */}

        <Backdrop className={"backdrop"} open={loading}>
          <CircularProgress className="whiteLoader" />
        </Backdrop>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
    reportReducer: state.reportReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (values) => {
      dispatch({
        type: REPORT_ACTIONS.SET_LOADING,
        values,
      });
    },
    setDashboard: (values) => {
      dispatch({
        type: CPANEL_ACTIONS.SET_DASHBOARD,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Reports));
