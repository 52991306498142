import React, { Component } from "react";
import { connect } from "react-redux";
import { Slider } from "@material-ui/core";

import { TRACK_ANIMATION_ACTIONS } from "../../../../actions/TrackAnimationActions";
import { DEFAULTS } from "../../../../common/consts";

class TrackSlider extends Component {
  onSliderChange = (event, newValue) => {
    const { routePoints } = this.props.trackReducer;
    let indx = Math.floor(routePoints.length * (newValue / 100));
    if (indx > routePoints.length - 1) {
      indx = routePoints.length - 1;
    }
    this.props.setTrackIndex({
      trackIndex: indx,
    });
    // Show In Map
    this.props.updateMapRegion(
      routePoints[indx].latitude,
      routePoints[indx].longitude,
      DEFAULTS.TRACK_ZOOM
    );
  };

  render() {
    const { trackIndex } = this.props.trackAnimationReducer;
    const { routePoints } = this.props.trackReducer;
    let percent = Math.floor((100 * trackIndex) / routePoints.length);
    percent = percent > 100 ? 100 : percent;

    return (
      <Slider
        onChange={this.onSliderChange}
        valueLabelDisplay="off"
        min={1}
        max={100}
        value={percent}
        classes={{
          root: "trackslidebar",
          thumb: "trackslidebarThumb",
          track: "trackslidebarTrack",
          rail: "trackslidebarRail",
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trackAnimationReducer: state.trackAnimationReducer,
    trackReducer: state.trackReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTrackIndex: (values) => {
      dispatch({
        type: TRACK_ANIMATION_ACTIONS.SET_TRACK_INDEX,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackSlider);
