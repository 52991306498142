import React, { Component } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  StepConnector,
} from "@material-ui/core";
import { connect } from "react-redux";

import { dateFunction } from "../../../../../../common/datefunctions";
import { UNITS, DEFAULTS } from "../../../../../../common/consts";

import "./timeline.css";

class TrackTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 1,
    };
  }

  updateMapRegion = (lat, lng, status = 0) => {
    let zoom = DEFAULTS.MAX_ZOOM;
    this.props.updateMapRegion(lat, lng, zoom);
  };

  render() {
    const { routeSummary } = this.props;

    return (
      <div className="trackTimeline scrollBar">
        <Stepper
          orientation="vertical"
          nonLinear={true}
          className="stepper"
          connector={<StepConnector className="stepConnector" />}
        >
          {routeSummary.map((r, i) => {
            let datediff = dateFunction.getDateDiff(r.fromtime, r.totime);
            datediff = dateFunction.getDateDiffString(datediff);
            return (
              <Step
                key={"trackstep-" + i}
                active={true}
                className={"step " + (r.status ? "running" : "idle")}
                onClick={() =>
                  this.updateMapRegion(r.latitude, r.longitude, r.status)
                }
              >
                <StepLabel
                  classes={{
                    label: "stepLabel",
                  }}
                >
                  <p
                    className={
                      "stepStatus " + (r.status ? "running" : "stopped")
                    }
                  >
                    <span className="statusLabel">
                      {r.status ? "Moving" : "Stopped"}
                    </span>
                    <span className="statusTime">{datediff}</span>
                  </p>
                </StepLabel>
                <StepContent className="stepContent">
                  <p className="stepDate">{r.fromtime + " - " + r.totime}</p>
                  {r.status === 1 ? (
                    <div className="stepRunningSummary">
                      <div>
                        <ul>
                          <li>Distance</li>
                          <li>
                            {r.summary.routelength + " " + UNITS.DISTANCE}
                          </li>
                        </ul>
                      </div>
                      <div>
                        <ul>
                          <li>Top Speed</li>
                          <li>{r.summary.top_speed + " " + UNITS.SPEED}</li>
                        </ul>
                      </div>
                      <div>
                        <ul>
                          <li>Avg. Speed</li>
                          <li>{r.summary.avg_speed + " " + UNITS.SPEED}</li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <p className="stepSummary">{r.summary.details}</p>
                  )}
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trackReducer: state.trackReducer,
  };
};

export default connect(mapStateToProps)(TrackTimeline);
