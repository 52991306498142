import React, { memo } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const PageLoader = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        height: "100%",
        backgroundColor: "#FFF",
        zIndex: 99999999,
        flexDirection: "column"
      }}
    >
      <CircularProgress className={"primary"} />
      {props.children || (
        <p
          style={{
            color: "#939393",
          }}
        >
          Loading...
        </p>
      )}
    </div>
  );
};

export default memo(PageLoader);
