import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Replay as Reload, Add, Edit, Delete } from "@material-ui/icons";
import { withSnackbar } from "notistack";

import SearchButton from "../../../../../../ui/searchbutton/SearchButton";
import SensorPopup from "./SensorPopup";
import DialogConfirm from "./SensorDeleteDialog";
import Btn from "@components/ui/button/Btn";
import { COMMON_ACTIONS } from "actions/CommonActions";
import { REPORT_ACTIONS } from "actions/ReportActions";
import { API_Settings } from "apihandler/Settings";

// React Grid
import { SortingState, IntegratedSorting } from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

import "../../vehiclelist/vehiclelist.css";
import { commonJs } from "common/common";
import { LABELS, NOTIFY } from "common/consts";

const getRowId = (row) => row.id;

var timer = null;

class VehicleSensors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      direction: "asc",
      rows: null,
      filteredRows: [],
      columns: [
        { name: "slno", title: "Sl No." },
        { name: "name", title: "Sensor Name" },
        { name: "type", title: "Report Type" },
        { name: "param", title: "Parameter" },
        { name: "options", title: "Options" },
      ],
      sensorParams: [],
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.getRows();
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  toggleDialog = (rec) => {
    console.log("sensor: toggleDialog: ", rec);
    this.props.toggleSensorPopup("edit", rec);
  };

  deleteRec = (id, name) => {
    this._dialogConfirm.toggleConfirmDialog(id, name);
  };

  generateRecOptions = (rec) => {
    return (
      <div className="options">
        <Tooltip
          title="Edit"
          placement="bottom"
          onClick={() => this.toggleDialog(rec)}
        >
          <IconButton className="smallIcon">
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Delete"
          placement="bottom"
          onClick={() => this.deleteRec(rec.id, rec.name)}
        >
          <IconButton className="smallIcon">
            <Delete />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  sensorSearch = (e) => {
    const searchValue = e.target.value.trim();
    const { rows, columns } = this.state;
    clearTimeout(timer);
    const self = this;
    timer = setTimeout(() => {
      let qry = searchValue.toUpperCase();
      const list = rows.filter((row) => {
        return row.name.toUpperCase().indexOf(qry) > -1;
      });
      self.setState({
        rows,
        filteredRows: list,
        columns,
      });
    }, 500);
  };

  getRows = () => {
    const { imei, mapReducer } = this.props;
    const { tmpVehicles } = mapReducer;
    let vehicle = tmpVehicles.filter((v) => v.imei === imei);
    if (vehicle.length > 0) {
      let sensors = [];
      let imei = vehicle[0].imei;
      this.props.setLoading({
        loading: true,
      });
      const self = this;
      API_Settings.getVehicleSensors({ imei }).then((res) => {
        self.props.setLoading({
          loading: false,
        });
        if (res.Result === 200) {
          sensors = res.Response.map((s, i) => {
            return {
              slno: i + 1,
              id: s.id,
              name: s.name,
              type: s.sensorType,
              param: s.param,
              data: s,
              options: this.generateRecOptions(s),
            };
          });
          const sensorParams = [];
          const attributes = JSON.parse(vehicle[0].attributes);
          for (let att in attributes) {
            sensorParams.push({
              id: att,
              text: att,
            });
          }
          self.setState({
            rows: sensors,
            sensorParams,
          });
        }
      });
    }
  };

  reloadGrid = () => {
    this.setState({
      loading: false,
      sorting: "asc",
      columns: [
        { name: "name", title: "Name" },
        { name: "type", title: "Report Type" },
        { name: "param", title: "Parameter" },
        { name: "options", title: "Options" },
      ],
      rows: null,
      filteredRows: [],
    });
    this.getRows();
  };

  deleteSensor = (id) => {
    const { imei } = this.props;
    let args = {
      sensorId: Number(id),
      imei,
    };
    const self = this;
    API_Settings.deleteSensor(args).then((res) => {
      if (res.Result === 200) {
        commonJs.showNotify(
          self.props,
          NOTIFY.SUCCESS,
          "Sensor deleted successfully..!",
          <button className="snackbar-btn">{LABELS.DISMISS}</button>
        );
        self._dialogConfirm.toggleConfirmDialog("close");
        self.reloadGrid();
      } else {
        commonJs.showNotify(
          self.props,
          NOTIFY.ERROR,
          "Sensor not deleted successfully..!",
          <button className="snackbar-btn">{LABELS.DISMISS}</button>
        );
      }
    });
  };

  render() {
    const {
      toggleDraggable,
      sensorPopup,
      toggleSensorPopup,
      sensor,
      closePopup,
      imei,
    } = this.props;
    const { loading, rows, columns, filteredRows, sensorParams } = this.state;
    const tableColumnExtensions = [
      { columnName: "slno", width: 80, align: "left" },
      { columnName: "name", width: 150, align: "left" },
      { columnName: "type", width: 150, align: "left" },
      { columnName: "param", width: 150, align: "left" },
      { columnName: "options", width: 80, align: "center" },
    ];

    let searchOn = false;
    if (document.getElementById("sensorSearch") != null) {
      searchOn =
        document.getElementById("sensorSearch").value.trim() === ""
          ? false
          : true;
    }

    console.log("sensorPopup: Rows: ", searchOn ? filteredRows : rows);

    return !loading ? (
      <div id="sensorsGrid" className="dataRGrid">
        <ul>
          <li>
            <SearchButton
              id="sensorSearch"
              placeholder="Search By Sensor Name"
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
              onChange={this.sensorSearch}
            />
          </li>
          <li>
            <Tooltip title="Reload" placement="bottom">
              <IconButton onClick={this.reloadGrid}>
                <Reload />
              </IconButton>
            </Tooltip>
          </li>
          <li title="Add Sensor">
            <Tooltip title="Add Sensor" placement="bottom">
              <IconButton onClick={() => toggleSensorPopup({ add: true })}>
                <Add />
              </IconButton>
            </Tooltip>
          </li>
          {/* <li>
            <Tooltip title="Delete All Sensors" placement="bottom">
              <IconButton
                onClick={() => this._dialogConfirm.toggleConfirmDialog("")}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </li> */}
        </ul>
        {!rows ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: window.innerHeight / 2,
              alignItems: "center",
            }}
          >
            <p style={{ color: "#a1a1a1", fontSize: 14 }}>Loading...</p>
          </div>
        ) : (
          <Grid
            rows={searchOn ? filteredRows : rows}
            columns={columns}
            getRowId={getRowId}
          >
            <SortingState
              defaultSorting={[{ columnName: "name", direction: "asc" }]}
              columnSortingEnabled={true}
              //   onSortingChange={this.changeZoneSort}
              columnExtensions={[
                { columnName: "view", sortingEnabled: false },
                { columnName: "options", sortingEnabled: false },
              ]}
            />
            <IntegratedSorting />
            <VirtualTable
              columnExtensions={tableColumnExtensions}
              height={window.innerHeight / 2}
            />
            <TableHeaderRow showSortingControls />
          </Grid>
        )}
        {sensorPopup && (
          <SensorPopup
            childRef={(ref) => (this._sensorPopup = ref)}
            toggleDraggable={toggleDraggable}
            sensorPopup={true}
            toggleSensorPopup={toggleSensorPopup}
            sensor={sensor}
            imei={imei}
            sensorParams={sensorParams}
            reloadGrid={this.reloadGrid}
          />
        )}
        <DialogConfirm
          childRef={(ref) => (this._dialogConfirm = ref)}
          onOk={this.deleteSensor}
        />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              width: "30%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Btn className="settingsBtn saveBtn">{"save"}</Btn>
            <Btn
              className="settingsBtn closeBtn"
              onClick={() => closePopup(false)}
            >
              {"close"}
            </Btn>
          </div>
        </div>
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: 100,
          alignItems: "center",
        }}
      >
        <p style={{ color: "#a1a1a1", fontSize: 14 }}>Loading...</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setVehicles: (values) => {
      dispatch({
        type: COMMON_ACTIONS.SET_VEHICLES,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: REPORT_ACTIONS.SET_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(VehicleSensors));
