import React, { Component } from "react";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableColumnResizing,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import CircularProgress from "@material-ui/core/CircularProgress";

import { ALERTS } from "common/consts";

import "./grid.css";

const getRowId = (row) => row.id;

var timer = null;

class FleetGrid extends Component {
  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
    timer = null;
  }

  onSearch = (searchValue) => {
    const { rows } = this.props;
    clearTimeout(timer);
    var self = this;
    timer = setTimeout(() => {
      let qry = searchValue.toUpperCase();
      var list = rows.filter((row) => {
        return row.name.toUpperCase().indexOf(qry) > -1;
      });
      self.props.setFilteredGridData(list);
      console.log("qry: ", list);
    }, 500);
  };

  render() {
    const {
      rows,
      columns,
      tableColumnExtensions,
      tableColumnWidths,
      height,
      showSortingControls,
      paging,
      pageNo,
      pageLength,
    } = this.props;

    let gridHeight = window.innerHeight - 180;
    if (height) {
      gridHeight = height;
    }

    let noRecords = true;
    if (rows) {
      noRecords = rows.length === 0;
    }

    return (
      <div id={this.props.id} className="fleetGrid">
        {!rows ? (
          <div
            style={{
              minHeight: 150,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress className={"primary"} />
            <p
              style={{
                color: "#939393",
              }}
            >
              Loading...
            </p>
          </div>
        ) : noRecords ? (
          <div
            style={{
              minHeight: 150,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                color: "#939393",
              }}
            >
              {ALERTS.NO_RECORDS}
            </p>
          </div>
        ) : (
          <Grid rows={rows} columns={columns} getRowId={getRowId}>
            <SortingState
              sorting={[
                { columnName: "name", direction: this.props.pointSorting },
              ]}
              onSortingChange={this.props.changePointSort}
            />
            <IntegratedSorting />
            <VirtualTable
              columnExtensions={tableColumnExtensions || []}
              height={gridHeight}
            />
            <TableColumnResizing
              defaultColumnWidths={tableColumnWidths || []}
            />
            <TableHeaderRow
              showSortingControls={showSortingControls || false}
            />

            {paging && (
              <PagingState defaultCurrentPage={pageNo} pageSize={pageLength} />
            )}
            {paging && <IntegratedPaging />}
            {paging && <PagingPanel allowedPageSizes={[10, 20]} />}
          </Grid>
        )}
      </div>
    );
  }
}

export default FleetGrid;
