import React, { Component } from "react";
import {
  Popover,
  Button,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
} from "@material-ui/core";
import { MoreVert as MenuBarIcon } from "@material-ui/icons";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { withSnackbar } from "notistack";

import GraphExportOptions from "./graphoptions/GraphExportOptions";

import { dateFunction } from "common/datefunctions";
import { FORMATS, NOTIFY, LABELS } from "common/consts";
import { commonJs } from "common/common";
import { API_Reports } from "apihandler/Reports";
import { API_UserLogin } from "apihandler/UserLogin";

class KmGraph extends Component {
  constructor(props) {
    super(props);
    let fdt = dateFunction.getDate("thismonth", "from", FORMATS.SQL_DATE);
    let tdt = dateFunction.getDate("thismonth", "to", FORMATS.SQL_DATE);
    let today = dateFunction.getDate("today", "from", FORMATS.SQL_DATE);
    if (today === fdt) {
      fdt = dateFunction.getDate("lastmonth", "from", FORMATS.SQL_DATE);
      tdt = dateFunction.getDate("lastmonth", "to", FORMATS.SQL_DATE);
    }
    this.state = {
      graphData: [],
      loading: true,
      totalKms: null,
      fdt: fdt,
      tdt: tdt,
      showExportFiter: false,
      showMenuFiter: false,
    };
    this.chart = null;

    this._months = [];
    for (let i = 0; i < 6; i++) {
      this._months.push({
        fdt,
        tdt,
        name: dateFunction.getFormatedDate(
          fdt,
          FORMATS.SQL_DATE,
          FORMATS.MONTH_AND_YEAR_ONLY
        ),
      });
      fdt = dateFunction.getSubtractMonth(fdt, 1, "from");
      tdt = dateFunction.getSubtractMonth(tdt, 1, "to");
    }
  }

  componentDidMount() {
    API_UserLogin.setToken(localStorage.token);
    this.getKmData();
  }

  exportChart = (options, type = "image/png", mode = "p", width = 100) => {
    const { fdt } = this.state;

    //POST parameter for Highcharts export server
    let data = {
      options: JSON.stringify(options),
      type: type,
      async: true,
    };

    let ext = type.split("/");
    if (ext[1]) {
      ext = ext[1];
    } else {
      ext = "pdf";
    }

    let title =
      "KM Graph - " +
      dateFunction.getFormatedDate(
        fdt,
        FORMATS.SQL_DATE,
        FORMATS.MONTH_AND_YEAR_ONLY
      ) +
      "." +
      ext;

    let target = document.querySelector(
      "div#reportDashLeft #reportDashKmGraph .highcharts-container"
    );
    let self = this;
    API_Reports.exportHighchart(data, title, target, mode, width)
      .then(() => {
        if (type !== "print") {
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            "Ready to download...",
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
        }
      })
      .catch((err) => {
        commonJs.showNotify(
          self.props,
          NOTIFY.ERROR,
          "Something went wrong, please try again..!",
          <button className="snackbar-btn">{LABELS.DISMISS}</button>
        );
      });
  };

  getKmData = () => {
    const { fdt, tdt } = this.state;
    let args = {
      fromDate: fdt,
      toDate: tdt,
    };
    let self = this;
    API_Reports.getReport("kmgraph", args)
      .then((res) => {
        if (res.Result === 200) {
          let rows = res.Response.rows;
          let sum = 0;
          rows.map((row) => {
            if (row.length > 1) {
              row[1] = Number(row[1]);
              sum += row[1];
            }
            return row;
          });
          self.setState({
            graphData: rows,
            loading: false,
            totalKms: sum,
          });
        }
      })
      .catch((err) => {
        self.setState({
          loading: false,
        });
      });
  };

  toggleFilter = (type = "export") => {
    const { showExportFiter, showMenuFiter } = this.state;
    if (type === "export") {
      this.setState({
        showExportFiter: !showExportFiter,
      });
    } else if (type === "menu") {
      this.setState({
        showMenuFiter: !showMenuFiter,
      });
    } else {
      this.setState({
        showMenuFiter: false,
        showExportFiter: false,
      });
    }
  };

  onDateFilter = (filter, fdate, tdate) => {
    let self = this;
    let fdt = dateFunction.getDate(filter, "from", FORMATS.SQL_DATE);
    let tdt = dateFunction.getDate(filter, "to", FORMATS.SQL_DATE);
    if (filter === "setdate") {
      fdt = fdate;
      tdt = tdate;
    }
    this.setState(
      {
        showExportFiter: false,
        showMenuFiter: false,
        fdt: fdt,
        tdt: tdt,
        totalKms: null,
        graphData: [],
        loading: true,
      },
      () => {
        self.getKmData();
      }
    );
  };

  render() {
    const {
      graphData,
      totalKms,
      showExportFiter,
      showMenuFiter,
      fdt,
    } = this.state;

    const title = !totalKms
      ? ""
      : dateFunction.getFormatedDate(
          fdt,
          FORMATS.SQL_DATE,
          FORMATS.MONTH_ONLY
        ) +
        ": " +
        commonJs.convertNumberToIndianNumber(
          commonJs.getTwoDecimalValue(totalKms)
        ) +
        " Kms";

    const options = {
      chart: {
        animation: Highcharts.svg,
        marginRight: 10,
        marginLeft: 60,
        zoomType: "x",
        type: "column",
      },
      credits: false,
      title: {
        text: title,
        margin: 80,
        style: {
          fontSize: "14px",
          fontWeight: "500",
          color: "#2A2A39",
          fontFamily: "Roboto,Helvetica,Arial,sans-serif",
        },
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        type: "category",
        labels: {
          rotation: -45,
          style: {
            fontSize: "12px",
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "Distance Travelled (Kms)",
          style: {
            fontSize: "12px",
            color: "#B2B2B2",
          },
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        pointFormat: "Distance: <b>{point.y:.2f} Kms</b>",
      },
      exporting: {
        enabled: true,
      },
      series: [
        {
          name: "Kms",
          data: graphData,
          color: "#6b6bdec2",
          dataLabels: {
            enabled: true,
            rotation: -90,
            color: "#2d2d2d",
            align: "center",
            format: "{point.y:.2f}", // two decimal
            y: -15, // -30 pixels down from the top
            style: {
              fontSize: "12px",
            },
          },
        },
      ],
    };

    return (
      <React.Fragment>
        <h4 className="subtitle">Vehicles Travel Sheet (Real Time)</h4>
        <ClickAwayListener
          touchEvent="onTouchStart"
          onClickAway={this.toggleFilter}
        >
          <div className="chartFilter">
            {/* Export Menu */}
            <GraphExportOptions
              showExportFiter={showExportFiter}
              exportChart={this.exportChart}
              toggleFilter={this.toggleFilter}
            />

            {/* Graph Menu */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.toggleFilter("menu")}
              buttonRef={(node) => {
                this._anchorMenu = node;
              }}
            >
              <MenuBarIcon />
            </Button>
            <Popover
              className="graphMenuPopover"
              open={showMenuFiter}
              anchorEl={this._anchorMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={this.toggleFilter}
            >
              <List component="nav" className="dashboardFilterMenu">
                {this._months.map((m, indx) => {
                  return (
                    <ListItem
                      button
                      onClick={() => this.onDateFilter("setdate", m.fdt, m.tdt)}
                      className={m.fdt === fdt ? "active" : ""}
                      key={"kmgraphdt-" + indx}
                    >
                      <ListItemText primary={m.name} />
                    </ListItem>
                  );
                })}
              </List>
            </Popover>
          </div>
        </ClickAwayListener>
        <HighchartsReact
          ref={(ref) => (this.chart = ref)}
          // ref={"chart"}
          highcharts={Highcharts}
          options={options}
          style={{ height: "100%" }}
          id="trackChart"
        />
      </React.Fragment>
    );
  }
}

export default withSnackbar(KmGraph);
