import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  Replay as ReloadIcon,
  AddCircleOutline as AddIcon,
  Delete,
  Edit,
} from "@material-ui/icons";

import FleetGrid from "components/ui/grid/FleetGrid";
import SearchButton from "components/ui/searchbutton/SearchButton";
import ConfirmDialog from "components/ui/confirmdialog/ConfirmDialog";

class EtaRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: "slno", title: "Sl No." },
        { name: "routename", title: "Route Name" },
        { name: "trips", title: "Total Trips" },
        { name: "options", title: "Options" },
      ],
      rows: [
        {
          slno: "1",
          routename: "Route 1",
          trips: "3",
          id: "111",
          options: this.getOptions({ id: "111", name: "Route 1" }),
        },
        {
          slno: "2",
          routename: "Route 2",
          trips: "2",
          id: "112",
          options: this.getOptions({ id: "112", name: "Route 2" }),
        },
      ],
    };
  }

  getOptions = (rec) => {
    const { id, name } = rec;
    return (
      <div className="options">
        <Tooltip
          title="Edit"
          placement="bottom"
          onClick={() => this._routeDialog.toggleDialog(rec)}
        >
          <IconButton className="smallIcon">
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Delete"
          placement="bottom"
          onClick={() => this.deleteRecord(id, name)}
        >
          <IconButton className="smallIcon">
            <Delete />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  onSearch = (e) => {
    console.log("this._routesGrid: ", this._routesGrid);
    // this._routesGrid.onSearch(e.target.value.trim());
  };

  deleteRecord = (id, name) => {
    this._dialogConfirm.toggleConfirmDialog(id, name);
  };

  deleteAll = () => {
    this._dialogConfirm.toggleConfirmDialog("");
  };

  reload = () => {
    this._routesGrid.loadReloadPoints();
  };

  addRoute = () => {
    this._routeDialog.toggleDialog({ add: true });
  };

  render() {
    const { columns, rows } = this.state;
    return (
      <div id="etaRoutes">
        <ul id="gridFilters">
          <li>
            <SearchButton
              id="routeNameSearch"
              placeholder="Search By Route Name"
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
              onChange={this.onSearch}
            />
          </li>
          <li className="">
            <Tooltip title="Reload" placement="bottom">
              <IconButton onClick={this.reload}>
                <ReloadIcon />
              </IconButton>
            </Tooltip>
          </li>
          <li className="">
            <Tooltip title="Add Route" placement="bottom">
              <IconButton onClick={this.addRoute}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </li>
          <li className="">
            <Tooltip title="Delete" placement="bottom">
              <IconButton onClick={this.deleteAll}>
                <Delete />
              </IconButton>
            </Tooltip>
          </li>
        </ul>

        <FleetGrid
          columns={columns}
          rows={rows}
          childRef={(ref) => (this._routesGrid = ref)}
        />

        {/* <DialogRoute childRef={(ref) => (this._routeDialog = ref)} /> */}

        <ConfirmDialog
          childRef={(ref) => (this._dialogConfirm = ref)}
          showConfirmDialog={false}
          id="confirmRouteDelete"
          name="Route"
        />
      </div>
    );
  }
}

export default EtaRoutes;
