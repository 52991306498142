import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { Link, Breadcrumbs } from "@material-ui/core";

import KmGraph from "./KmGraph";
import GraphYesterday from "./graphs/GraphYesterday";
import GraphIdleVehicles from "./graphs/GraphIdleVehicles";
import GraphPoi from "./graphs/GraphPoi";
import VehiclesSummary from "./VehiclesSummary";
import YesterdayInfo from "./YesterdayInfo";
import DocExpiry from "./DocExpiry";

import { REPORT_DESCRIPTION } from "common/consts";
import { REPORT_ACTIONS } from "actions/ReportActions";

import "../styles/reports.css";

class UserDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("root").classList.add("scroll");
    document.getElementById("root").classList.remove("login");
    this.props.setLoading({
      loading: false,
    });
  }

  onMenuClick = (menu) => {
    if (menu === "home") {
      window.open("/", "_self");
    }
  };

  onGraphFilter = (id, type = "") => {
    if (type === "poi") {
      this._vehiclesSummary.toggleDialog("inpoi", id);
    } else if (type === "idle") {
      this._vehiclesSummary.toggleDialog("idlelist", id);
    } else if (type === "yesterday") {
      const { records } = this._yesterdayGraph;
      this._vehiclesSummary.toggleDialog("yesterday", id, records);
    }
  };

  render() {
    return (
      <div className="reportPageWrapper">
        <div className="reportMenuHeader">
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
            <Link
              color="inherit"
              href="/"
              onClick={() => this.onMenuClick("home")}
            >
              Home
            </Link>
            <p>Dashboard</p>
          </Breadcrumbs>
          <p className="reportDescription">{REPORT_DESCRIPTION.DASHBOARD}</p>
        </div>
        <div id="reportPageContainer">
          <div id="reportDashLeft">
            <div className="reportDashCard" id="reportDashKmGraph">
              <KmGraph />
            </div>
            <div className="reportDashSmallGraphs">
              <div className="reportDashCard" id="reportDashYesterdayGraph">
                <GraphYesterday
                  childRef={(ref) => (this._yesterdayGraph = ref)}
                  onGraphFilter={(id) => this.onGraphFilter(id, "yesterday")}
                />
              </div>
              <div className="reportDashCard" id="reportDashIdleGraph">
                <GraphIdleVehicles
                  onGraphFilter={(id) => this.onGraphFilter(id, "idle")}
                />
              </div>
              <div className="reportDashCard" id="reportDashPoiGraph">
                <GraphPoi
                  onGraphFilter={(id) => this.onGraphFilter(id, "poi")}
                />
              </div>
            </div>
          </div>
          <div id="reportDashRight">
            <div className="reportDashCard">
              <VehiclesSummary
                childRef={(ref) => (this._vehiclesSummary = ref)}
              />
            </div>
            <div className="reportDashCard">
              <YesterdayInfo />
            </div>
            <div className="reportDashCard">
              <DocExpiry />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commonReducer: state.commonReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (values) => {
      dispatch({
        type: REPORT_ACTIONS.SET_LOADING,
        values,
      });
    },
    toggleReportPopup: (values) => {
      dispatch({
        type: REPORT_ACTIONS.TOGGLE_REPORT_POPUP,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(UserDashboard));
