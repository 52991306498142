import React, { Component } from "react";

import VehiclesFilter from "./Filters";

import VehicleList from "./vehiclelist";

class VehiclesTab extends Component {
  render() {
    const { updateMapRegion, switchTab, shareVehicle } = this.props;
    return (
      <div className="tabContent">
        <VehiclesFilter />
        <VehicleList
          updateMapRegion={updateMapRegion}
          switchTab={switchTab}
          shareVehicle={shareVehicle}
        />
      </div>
    );
  }
}

export default VehiclesTab;
