import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip, Link, Breadcrumbs } from "@material-ui/core";
import {
  Replay as Reload,
  AddCircleOutline as Add,
  Delete,
  Edit,
  DepartureBoard as ClearHistoryIcon,
  Block as DeactivateIcon,
  LockOpen as ActivateIcon,
  Check as ActiveIcon,
  Close as NotActiveIcon,
} from "@material-ui/icons";
import { connect } from "react-redux";

import SearchButton from "components/ui/searchbutton/SearchButton";
import VehiclesGrid from "./VehiclesGrid";
import ConfirmDialog from "components/ui/confirmdialog/ConfirmDialog";
import VehicleDialog from "./VehicleDialog";

import {
  COLORS,
  CPANEL_DESCRIPTION,
  DEFAULTS,
  FORMATS,
  NOTIFY,
} from "common/consts";
import { commonJs } from "common/common";
import { REPORT_ACTIONS } from "actions/ReportActions";
import { API_Map } from "apihandler/Map";
import { API_CPanel } from "apihandler/CPanel";

import signalOnIcon from "images/leftBarImages/wifi.svg";
import signalOffIcon from "images/leftBarImages/wifi_off.svg";
import { dateFunction } from "common/datefunctions";

class CpanelVehicles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: "slno", title: "Sl No." },
        { name: "name", title: "Vehicle Name" },
        { name: "imei", title: "IMEI" },
        { name: "simNumber", title: "SIM Number" },
        { name: "activeText", title: "Device Status" },
        { name: "accessFrom", title: "Access From" },
        { name: "accessTill", title: "Access Till" },
        {
          name: "objectLogTime",
          title: "Last Connection",
          sortingEnabled: false,
        },
        { name: "status", title: "Signal Status" },
        {
          name: "usedaccounts",
          title: "Used in user account",
          sortingEnabled: false,
        },
        { name: "options", title: "Options" },
      ],
      rows: [],
      users: [],
      managers: [],
      groups: [],
      pageNo: 0,
      pageLength: DEFAULTS.CPANEL_GRID_PAGE_LENGTH,
      sortingColumn: "name",
      sortingType: "asc",
      totalRows: 0,
    };

    this._timer = null;

    this._tableColumnWidths = [
      { columnName: "slno", width: 80 },
      { columnName: "name", width: 150 },
      { columnName: "imei", width: 150 },
      { columnName: "simNumber", width: 120 },
      { columnName: "activeText", width: 120 },
      { columnName: "accessFrom", width: 140 },
      { columnName: "accessTill", width: 140 },
      { columnName: "objectLogTime", width: 140 },
      { columnName: "status", width: 120 },
      { columnName: "usedaccounts", width: 300 },
      { columnName: "options", width: 140 },
    ];

    this._tableColumnExtensions = [
      { columnName: "slno", align: "center" },
      { columnName: "name", align: "left" },
      { columnName: "imei", align: "left" },
      { columnName: "simNumber", align: "left" },
      { columnName: "activeText", align: "center" },
      { columnName: "accessFrom", align: "left" },
      { columnName: "accessTill", align: "left" },
      { columnName: "objectLogTime", align: "left" },
      { columnName: "status", align: "center" },
      { columnName: "usedaccounts", align: "left" },
      { columnName: "options", align: "center" },
    ];

    this._sortingDisabledColumns = [
      { columnName: "activeText", sortingEnabled: false },
      { columnName: "accessFrom", sortingEnabled: false },
      { columnName: "accessTill", sortingEnabled: false },
      { columnName: "objectLogTime", sortingEnabled: false },
      { columnName: "status", sortingEnabled: false },
      { columnName: "usedaccounts", sortingEnabled: false },
      { columnName: "options", sortingEnabled: false },
    ];
  }

  componentDidMount() {
    this.loadMasters();
  }

  loadMasters = () => {
    let self = this;
    API_CPanel.getUsersDropdown().then((res) => {
      let users = [];
      if (res.Result === 200) {
        users = res.Response.map((u) => {
          return { id: u.id, name: u.userName };
        });
      }
      self.loadManagers(users);
    });
  };

  loadManagers = (users = []) => {
    let self = this;
    API_CPanel.getCompanyManagers().then((res) => {
      let managers = [{ id: 0, name: "No Manager" }];
      if (res.Result === 200) {
        let list = res.Response;
        for (let l = 0; l < list.length; l++) {
          managers.push({
            id: list[l].id,
            name: list[l].name,
          });
        }
      }
      self.loadGroups(users, managers);
    });
  };

  loadGroups = (users = [], managers = []) => {
    let self = this;
    API_Map.getGroups().then((res) => {
      let groups = [{ id: 0, name: "Ungrouped", vcount: 0 }];
      if (res.Result === 200) {
        let list = res.Response;
        for (let l = 0; l < list.length; l++) {
          groups.push({
            id: list[l].id,
            name: list[l].name,
          });
        }
      }
      self.setState({
        users,
        managers,
        groups,
      });
      self.props.setLoading({
        loading: false,
      });
      self.loadRows();
    });
  };

  getOptions = (record) => {
    const { id, name, active } = record;
    return (
      <div className="options">
        <Tooltip
          title="Edit"
          placement="bottom"
          onClick={() => this.editDialog(record)}
        >
          <IconButton className="smallIcon">
            <Edit style={{ color: COLORS.PRIMARY }} />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Clear History"
          placement="bottom"
          onClick={() => this.onClearHistory(id, name)}
        >
          <IconButton className="smallIcon">
            <ClearHistoryIcon style={{ color: COLORS.RED }} />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={active ? "Deactivate" : "Activate"}
          placement="bottom"
          onClick={() => this.onDeactivate(id, name)}
        >
          <IconButton className="smallIcon">
            {active ? (
              <DeactivateIcon style={{ color: COLORS.RED }} />
            ) : (
              <ActivateIcon style={{ color: COLORS.PRIMARY }} />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Delete"
          placement="bottom"
          onClick={() => this.onDelete(id, name)}
        >
          <IconButton className="smallIcon">
            <Delete style={{ color: COLORS.RED }} />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  toggleDialog = () => {
    this._dialog.toggleDialog({ add: true });
  };

  editDialog = (record) => {
    this._dialog.toggleDialog(record);
  };

  onSearch = (e) => {
    let qry = e.target.value.trim();
    console.log("onSearch: ", qry);
    let self = this;
    clearTimeout(this._timer);
    this._timer = setTimeout(() => {
      self.loadRows(qry);
    }, 1500);
  };

  reload = () => {
    this.setState({
      rows: [],
    });
    this.loadRows();
  };

  loadRows = (searchQry = "") => {
    const { pageNo, pageLength, sortingColumn, sortingType } = this.state;
    let self = this;
    let args = {
      userId: 0, // 0 -> then all vehicles
      imei: "", // "" -> ignore if it is empty
      pageNo: pageNo,
      pageLength: pageLength,
      sortingColumn,
      sortingType,
      searchQry: searchQry, // "imei" and "vehicle name"
    };
    args.pageNo++;
    console.log("loadRows: args: ", args);
    API_CPanel.getCpanelVehicleList(args)
      .then((res) => {
        let rows = res.Response;
        for (let r = 0; r < rows.length; r++) {
          rows[r].slno = pageNo * pageLength + r + 1;
          rows[r].id = rows[r].imei;
          rows[r].name = rows[r].vehicleName;
          rows[r].status = self.getSignalIcon(rows[r].status);
          rows[r].active = Number(rows[r].active);
          rows[r].usedaccounts = self.getUserNames(rows[r].users);
          rows[r].activeText = rows[r].active ? (
            <ActiveIcon style={{ fontSize: 18, color: COLORS.DARK_GREEN }} />
          ) : (
            <NotActiveIcon style={{ fontSize: 18, color: COLORS.RED }} />
          );
          rows[r].objectLogTime = dateFunction.getFormatedDate(
            rows[r].objectLogTime,
            FORMATS.SQL_DATE,
            FORMATS.SQL_DATE
          );
          rows[r].options = self.getOptions(rows[r]);
        }
        if (pageNo === 0) {
          self.setState({
            rows,
            pageNo,
            totalRows: res.totalRows,
          });
        } else {
          self.setState({
            rows,
            pageNo,
          });
        }
      })
      .catch((err) => {
        console.log("loadRows: Err: ", err);
      });
  };

  getUserNames = (list = []) => {
    const { users } = this.state;
    let userNames = [];
    for (let i = 0; i < list.length; i++) {
      let user = users.filter((u) => u.id === list[i]);
      if (user.length > 0) {
        userNames.push(user[0].name);
      }
    }
    return <div title={userNames.toString()}>{userNames.toString()}</div>;
    return <Tooltip placement="bottom"></Tooltip>;
  };

  getSignalIcon = (status = 0) => {
    if (status === 0) {
      return <img src={signalOffIcon} width="15" alt="" />;
    }
    return <img src={signalOnIcon} width="15" alt="" />;
  };

  onDelete = (id, name) => {
    this._dialogConfirm.toggleConfirmDialog(id, name);
    this._cmd = "delete";
  };

  onClearHistory = (id, name) => {
    this._dialogConfirm.toggleConfirmDialog(id, name, "Clear History");
    this._cmd = "clearhistory";
  };

  onDeactivate = (id, name) => {
    this._dialogConfirm.toggleConfirmDialog(id, name, "Manage Activation");
    this._cmd = "activate";
  };

  deleteVehicle = (imei) => {
    let self = this;
    let args = { imei, cmd: this._cmd };
    console.log("deleteVehicle: ", args);
    API_CPanel.deleteVehicle(args).then((res) => {
      self._dialogConfirm.toggleConfirmDialog("close");
      let action = "deleted";
      if (self._cmd === "activate") {
        action = "active status changed";
      }
      if (self._cmd === "clearhistory") {
        action = "history cleared";
      }
      if (res.Result === 200) {
        commonJs.showNotify(
          self.props,
          NOTIFY.SUCCESS,
          "Vehicle is " + action + " successfully..!"
        );
        return;
      } else {
        commonJs.showNotify(
          self.props,
          NOTIFY.ERROR,
          "Error: Vehicle is not " + action + "..!"
        );
        return;
      }
    });
  };

  deleteAll = () => {
    this._dialogConfirm.toggleConfirmDialog("");
  };

  onPageClick = (pageNo = 0) => {
    let self = this;
    this.setState(
      {
        rows: [],
        pageNo,
      },
      () => {
        self.loadRows();
      }
    );
  };

  onChangePageLength = (pageLength = 0, pageNo = 0) => {
    let self = this;
    this.setState(
      {
        rows: [],
        pageLength,
        pageNo,
      },
      () => {
        self.loadRows();
      }
    );
  };

  onChangeSort = (sorting = []) => {
    let self = this;
    this.setState(
      {
        rows: [],
        sortingColumn: sorting[0].columnName,
        sortingType: sorting[0].direction,
      },
      () => {
        self.loadRows();
      }
    );
  };

  render() {
    const {
      rows,
      columns,
      users,
      managers,
      groups,
      pageNo,
      pageLength,
      totalRows,
      sortingColumn,
      sortingType,
    } = this.state;

    console.log("pageNo: home: ", pageNo, pageLength, totalRows);

    return (
      <div className="cpanelPageWrapper">
        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
          <Link
            color="inherit"
            href="/cpanel"
            onClick={() => this.onMenuClick("home")}
          >
            CPanel
          </Link>
          <p>Vehicles List</p>
        </Breadcrumbs>
        <p className="reportDescription">{CPANEL_DESCRIPTION.VEHICLE_LIST}</p>
        <div id="rolesWrapper">
          <ul className="gridActionBtns">
            <li>
              <SearchButton
                id="cpanelRoleSearch"
                placeholder="Search By Vehicle Name or IMEI"
                buttonClass="pad5 iconButton searchBtn"
                inputClass="SearchInput"
                iconwidth="18"
                iconheight="18"
                onKeyDown={this.onSearch}
              />
            </li>
            <li>
              <Tooltip title="Reload" placement="bottom">
                <IconButton onClick={this.reload}>
                  <Reload />
                </IconButton>
              </Tooltip>
            </li>
            <li>
              <Tooltip title="Add" placement="bottom">
                <IconButton onClick={this.toggleDialog}>
                  <Add />
                </IconButton>
              </Tooltip>
            </li>
            {/* <li>
                <Tooltip title="Delete" placement="bottom">
                  <IconButton onClick={this.deleteAll}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </li> */}
          </ul>

          <VehicleDialog
            childRef={(ref) => (this._dialog = ref)}
            users={users}
            managers={managers}
            groups={groups}
            loadGrid={this.reload}
          />

          <VehiclesGrid
            id="cpanelRoles"
            childRef={(ref) => (this._grid = ref)}
            rows={rows}
            columns={columns}
            tableColumnWidths={this._tableColumnWidths}
            tableColumnExtensions={this._tableColumnExtensions}
            sortDisabledColumns={this._sortingDisabledColumns}
            pageNo={pageNo}
            pageLength={pageLength}
            totalRows={totalRows}
            showSortingControls={true}
            sortingColumn={sortingColumn}
            sortingType={sortingType}
            onPageClick={this.onPageClick}
            onChangePageLength={this.onChangePageLength}
            onChangeSort={this.onChangeSort}
          />

          <ConfirmDialog
            childRef={(ref) => (this._dialogConfirm = ref)}
            onOk={this.deleteVehicle}
            id=""
            name="Role"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commonReducer: state.commonReducer,
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (values) => {
      dispatch({
        type: REPORT_ACTIONS.SET_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(CpanelVehicles));
