import React, { Component } from "react";
import { Polygon, Tooltip } from "react-leaflet";
import { connect } from "react-redux";

import { API_Map } from "apihandler/Map";
import { POI_ACTIONS } from "actions/PoiActions";

class Zones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: this.props.zoom,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.getZones();
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  getZones = () => {
    let self = this;
    API_Map.getAllZones()
      .then((res) => {
        if (res.Result === 200) {
          self.props.setZones({
            zones: res["Response"],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateZoom = (zoom) => {
    this.setState({
      zoom: zoom,
    });
    this.updateZoomCss(zoom);
  };

  updateZoomCss = (zoom) => {
    // const zonesList = document.getElementsByClassName("zoneLabel");
    // if (zoom < DEFAULTS.SHOW_LABELS_ZOOM) {
    //   // Hide all zone labels
    //   for (let i = 0; i < zonesList.length; i++) {
    //     if (!zonesList[i].classList.contains("hide")) {
    //       zonesList[i].classList.add("hide");
    //     }
    //   }
    // } else {
    //   // Show All
    //   for (let i = 0; i < zonesList.length; i++) {
    //     zonesList[i].classList.remove("hide");
    //   }
    // }
  };

  render() {
    const { zones } = this.props.poiReducer;

    return (
      zones.length > 0 &&
      zones.map((zone) => {
        let vertices = [];
        if (zone.vertices) {
          vertices = zone.vertices.map((v) => {
            return [v.latitude, v.longitude];
          });
        }
        return (
          <Polygon
            key={"mapZone-" + zone.id}
            positions={vertices}
            color={zone.color}
            className="zone"
          >
            <Tooltip
              direction="right"
              className={"zoneLabel"}
              title={zone.name}
              permanent={"true"}
              offset={[10, 10]}
            >
              <span style={{ color: zone.color }}>{"Zone: " + zone.name}</span>
            </Tooltip>
          </Polygon>
        );
      })
    );
  }
}

const mapStateToProps = (state) => {
  return {
    poiReducer: state.poiReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setZones: (values) => {
      dispatch({
        type: POI_ACTIONS.SET_ZONES,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Zones);
