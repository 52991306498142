import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

import Login from "./components/login/Login";
import MapPage from "./components/map/";
import Reports from "./components/reports";
import LiveTrack from "./components/livetrack/LiveTrack";
import CPanel from "./components/cpanel";
import PageLoader from "./components/ui/loaders/PageLoader";
import Error404 from "./components/error/Error404";
import { PrivacyPolicy } from "./components/privacypolicy";
import { DownloadApp } from "./components/downloadapp";

import {
  CONFIG,
  LOCAL_STORAGE,
  ALERTS,
  NOTIFY,
  PRIVILEGES,
} from "./common/consts";
import { commonJs } from "./common/common";
import { API_UserLogin } from "./apihandler/UserLogin";
import { LOGIN_ACTIONS } from "./actions/LoginActions";

import { messaging } from "./firebase";

import fleetLogo from "./images/logo/logo.png";

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      mobile: false,
    };
  }

  componentDidMount() {
    this.checkLogin();
    if (process.env.NODE_ENV === "production") {
      window.addEventListener("resize", this.resize.bind(this));
      this.resize();
    }
  }

  resize() {
    if (window.innerWidth <= 760) {
      if (window.location.pathname !== "/download-app") {
        window.location = "/download-app";
      }
    } else if (window.location.pathname === "/download-app") {
      window.location = "/";
    }
  }

  checkLogin = () => {
    var self = this;
    var userDataJson = commonJs.getItemFromLocal(LOCAL_STORAGE.USER_DATA);
    var userSettingsJson = commonJs.getItemFromLocal(
      LOCAL_STORAGE.USER_SETTINGS
    );
    if (
      userDataJson.login &&
      userSettingsJson !== undefined &&
      userDataJson !== undefined
    ) {
      API_UserLogin.setToken(userDataJson.token);
    }
    API_UserLogin.checkLogin()
      .then((res) => {
        userSettingsJson.login = res.Response;
        if (res.Response) {
          messaging
            .requestPermission()
            .then(async () => {
              const token = await messaging.getToken();
              console.log("[Web Push]: AppJs: token: ", token);
              API_UserLogin.registerDevice(token);
            })
            .catch((err) => {
              console.log("[Web Push]: AppJs: ", err);
            });
          self.props.setLoginInfo(userSettingsJson);
        }
        self.setState({
          loading: false,
        });
      })
      .catch((err) => {
        err = err.toString();
        if (err.indexOf("Network Error") > -1) {
          commonJs.showNotify(self.props, NOTIFY.WARNING, ALERTS.NO_INTERNET);
        }
      });
  };

  showLoadingDiv = () => {
    return <PageLoader />;
  };

  redirectToLandingPage = () => {
    const { role, privileges } = this.props.loginReducer;
    const mapPage = commonJs.hasPrivilege(PRIVILEGES.MAP, role, privileges);
    if (mapPage) {
      return <MapPage />;
    } else {
      window.open("/reports", "_self");
    }
  };

  redirectToLoginPage = () => {
    const { loading } = this.state;
    return <Login loading={loading} setPrivileges={this.setPrivileges} />;
  };

  isLoggedin = () => {
    const { login } = this.props.loginReducer;
    if (login) {
      window.location = "/";
    }
    let url = new URL(window.location.href);
    let au = url.searchParams.get("au");
    if (au) {
      console.log("Login Page: isLoggedin: ", au);
    }
    return <Login setPrivileges={this.setPrivileges} />;
  };

  openReports = (type) => {
    const { loading } = this.state;
    if (loading) {
      return <PageLoader />;
    }
    const { login, privileges } = this.props.loginReducer;
    if (!login) {
      return <Login setPrivileges={this.setPrivileges} />;
    }
    if (privileges.indexOf("rp") === -1) {
      return <Error404 />;
    }
    if (type === "reports") {
      return <Reports />;
    }
  };

  openCPanel = (type) => {
    const { loading } = this.state;
    const { login, role } = this.props.loginReducer;
    if (loading) {
      return <PageLoader />;
    }
    if (login) {
      if (type === "cpanel") {
        return role === CONFIG.ROLE_SUPERADMIN || role === CONFIG.ROLE_ADMIN ? (
          <CPanel />
        ) : (
          <Error404 />
        );
      }
    } else {
      return <PageLoader />;
    }
  };

  openLiveTrack = () => {
    const { login } = this.props.loginReducer;
    return <LiveTrack login={login} />;
  };

  openHomePage = () => {
    const { login } = this.props.loginReducer;
    const { loading } = this.state;
    if (!loading) {
      if (login) {
        return this.redirectToLandingPage();
      } else {
        return this.redirectToLoginPage();
      }
    } else {
      return this.showLoadingDiv();
    }
  };

  render() {
    const siteTitle = CONFIG.SITE_NAME + " v" + CONFIG.VERSION;
    return (
      <React.Fragment>
        <Helmet>
          <title>{siteTitle}</title>
          <link rel="icon" href={fleetLogo} type="image/x-icon" />
        </Helmet>
        <BrowserRouter>
          <Switch>
            <Route path="/" render={this.openHomePage} exact />
            <Route path="/privacy-policy" component={PrivacyPolicy} exact />
            <Route path="/download-app" component={DownloadApp} exact />
            <Route path="/login" render={this.isLoggedin} />
            <Route path="/livetrack" render={this.openLiveTrack} />
            <Route
              path="/reports"
              render={() => this.openReports("reports")}
              exact
            />
            <Route
              path="/cpanel"
              render={() => this.openCPanel("cpanel")}
              exact
            />
            <Route component={Error404} />
          </Switch>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (values) => {
      dispatch({
        type: LOGIN_ACTIONS.SET_LOGIN,
        values,
      });
    },
    setLoginInfo: (values) => {
      dispatch({
        type: LOGIN_ACTIONS.SET_LOGIN_INFO,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(App));
