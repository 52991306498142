import React, { Component } from "react";
import { Tooltip, Polyline } from "react-leaflet";

class RouteLine extends Component {
  render() {
    const { route } = this.props;
    const pathPoints = route.points;
    let path = pathPoints.map((p) => {
      return [p.latitude, p.longitude];
    });
    return (
      <Polyline positions={path} color={route.color} key={"route-" + route.id}>
        <Tooltip
          direction="right"
          offset={[10, -2]}
          className={"routeLabel"}
          permanent
        >
          <span>{route.name}</span>
        </Tooltip>
      </Polyline>
    );
  }
}

export default RouteLine;
