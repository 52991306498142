import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
// React Grid
import { SortingState, IntegratedSorting } from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";

import { API_Settings } from "apihandler/Settings";
import { connect } from "react-redux";

const getRowId = (row) => row.userId;

var timer = null;

class VehiclesGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      direction: "asc",
      rows: null,
      filteredRows: [],
      columns: [
        { name: "slno", title: "Sl No." },
        { name: "userName", title: "User Name" },
        { name: "vehicleName", title: "Vehicle Name" },
        { name: "accessFrom", title: "Access From" },
        { name: "accessTo", title: "Access To" },
        { name: "options", title: "Options" },
      ],
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.loadRecords();
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  onSearch = (searchValue) => {
    const { rows } = this.state;
    clearTimeout(timer);
    let self = this;
    timer = setTimeout(() => {
      let qry = searchValue.toUpperCase();
      let list = rows.filter((row) => {
        return row.vehicleName.toUpperCase().indexOf(qry) > -1;
      });
      list = list.map((l, i) => {
        l.slno = i + 1;
        return l;
      });
      self.setState({
        rows: rows,
        filteredRows: list,
      });
    }, 500);
  };

  generateOptions = (rec) => {
    const { imei, userId, vehicleName } = rec;
    return (
      <div className="options">
        <Tooltip
          title="Edit"
          placement="bottom"
          onClick={() => this.props.togglePopupDialog(rec)}
        >
          <IconButton className="smallIcon">
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Delete"
          placement="bottom"
          onClick={() =>
            this.props.onDeleteRecord(imei + "," + userId, vehicleName)
          }
        >
          <IconButton className="smallIcon">
            <Delete />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  loadReloadRecords = () => {
    let self = this;
    this.setState(
      {
        sorting: "asc",
        rows: null,
        filteredRows: [],
      },
      () => {
        self.loadRecords();
      }
    );
  };

  loadRecords = () => {
    const { uid } = this.props.loginReducer;
    let self = this;
    let args = {
      subuser: true,
      managerId: uid,
    };
    API_Settings.getAllUserVehicles(args).then((res) => {
      if (res.Result === 200) {
        let rows = [];
        res = res.Response;
        for (let i = 0; i < res.length; i++) {
          rows.push({
            id: res[i].imei,
            slno: Number(i) + 1,
            userId: res[i].userId,
            userName: res[i].userName,
            vehicleName: res[i].vehicleName,
            accessFrom: res[i].activeFrom,
            accessTo: res[i].activeTill,
            options: this.generateOptions(res[i]),
          });
        }
        self.setState({
          rows: rows,
          filteredRows: [],
        });
      }
    });
  };

  render() {
    const { rows, columns, filteredRows } = this.state;

    const tableColumnExtensions = [
      { columnName: "slno", width: 80, align: "center" },
      { columnName: "options", width: 80, align: "center" },
    ];

    let searchOn = false;
    if (document.getElementById("userVehicleListSearch") != null) {
      searchOn =
        document.getElementById("userVehicleListSearch").value.trim() === ""
          ? false
          : true;
    }

    const gridHeight = window.innerHeight / 1.5;

    return rows ? (
      <div id="userVehiclesGrid" className="dataRGrid">
        <Grid rows={searchOn ? filteredRows : rows} columns={columns}>
          <SortingState
            defaultSorting={[]}
            columnSortingEnabled={true}
            //   onSortingChange={this.changeZoneSort}
            columnExtensions={[
              { columnName: "view", sortingEnabled: false },
              { columnName: "options", sortingEnabled: false },
            ]}
          />
          <IntegratedSorting />
          <VirtualTable
            columnExtensions={tableColumnExtensions}
            height={gridHeight}
          />
          <TableHeaderRow showSortingControls />
        </Grid>
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: gridHeight,
          alignItems: "center",
        }}
      >
        <p style={{ color: "#a1a1a1", fontSize: 14 }}>Loading...</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VehiclesGrid);
