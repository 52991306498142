export const TRACK_ACTIONS = {
  SET_SELECTED_TRACK_DURATION: "SET_SELECTED_TRACK_DURATION",
  SET_TRACK_DATE: "SET_TRACK_DATE",
  SET_TRACK_FROM_DATE: "SET_TRACK_FROM_DATE",
  SET_TRACK_TO_DATE: "SET_TRACK_TO_DATE",
  SET_TRACK_STOP_TIME: "SET_TRACK_STOP_TIME",
  SET_TRACK_VEHICLE: "SET_TRACK_VEHICLE",
  SET_TRACK_DATA: "SET_TRACK_DATA",
  SET_TRACK_FILTER: "SET_TRACK_FILTER",
  SET_SKIP_STOPS: "SET_SKIP_STOPS",
};
