import React, { Component } from "react";
import { connect } from "react-redux";

import Img from "components/ui/image/Img";

// import iconAccessDenied from "../../../../../../images/icons/points/accesdenied.png";
// import iconAdministration from "../../../../../../images/icons/points/administration.png";
// import iconAdministrativeBoundary from "../../../../../../images/icons/points/administrativeboundary.png";
// import iconAgritourism from "../../../../../../images/icons/points/agritourism.png";
import iconAircraftcarrier from "../../../../../../images/icons/points/aircraftcarrier.png";
// import iconAirportapron from "../../../../../../images/icons/points/airport_apron.png";
// import iconAirportrunway from "../../../../../../images/icons/points/airport_runway.png";
// import iconAirportterminal from "../../../../../../images/icons/points/airport_terminal.png";
import iconAirport from "../../../../../../images/icons/points/airport.png";
import iconApartment3 from "../../../../../../images/icons/points/apartment-3.png";
import iconArch from "../../../../../../images/icons/points/arch.png";
// import iconArmy from "../../../../../../images/icons/points/army.png";
// import iconAward from "../../../../../../images/icons/points/award.png";
// import iconBadminton from "../../../../../../images/icons/points/badminton-2.png";
import iconBank from "../../../../../../images/icons/points/bank.png";
// import iconBarcoktail from "../../../../../../images/icons/points/bar_coktail.png";
// import iconBarjuice from "../../../../../../images/icons/points/bar_juice.png";
import iconBar from "../../../../../../images/icons/points/bar.png";
// import iconBarbecue from "../../../../../../images/icons/points/barbecue.png";
// import iconBarrier from "../../../../../../images/icons/points/barrier.png";
// import iconBats from "../../../../../../images/icons/points/bats.png";
// import iconBattleship from "../../../../../../images/icons/points/battleship-3.png";
// import iconBigcity from "../../../../../../images/icons/points/bigcity.png";
// import iconBinoculars from "../../../../../../images/icons/points/binoculars.png";
// import iconBoat from "../../../../../../images/icons/points/boat.png";
import iconBridgemodern from "../../../../../../images/icons/points/bridge_modern.png";
// import iconBridgeold from "../../../../../../images/icons/points/bridge_old.png";
// import iconBulldozer from "../../../../../../images/icons/points/bulldozer.png";
// import iconBunker from "../../../../../../images/icons/points/bunker-2-2.png";
import iconBus from "../../../../../../images/icons/points/bus.png";
// import iconBusstop from "../../../../../../images/icons/points/busstop.png";
// import iconBustour from "../../../../../../images/icons/points/bustour.png";
// import iconCabin from "../../../../../../images/icons/points/cabin-2.png";
// import iconCampfire from "../../../../../../images/icons/points/campfire-2.png";
// import iconCamping from "../../../../../../images/icons/points/camping-2.png";
// import iconCar from "../../../../../../images/icons/points/car.png";
import iconCaraccident from "../../../../../../images/icons/points/caraccident.png";
// import iconCarrental from "../../../../../../images/icons/points/carrental.png";
import iconCarwash from "../../../../../../images/icons/points/carwash.png";
// import iconCastle from "../../../../../../images/icons/points/castle-2.png";
// import iconCathedral from "../../../../../../images/icons/points/cathedral.png";
// import iconCatholicgrave from "../../../../../../images/icons/points/catholicgrave.png";
import iconCaution from "../../../../../../images/icons/points/caution.png";
import iconCctv from "../../../../../../images/icons/points/cctv.png";
// import iconCemetary from "../../../../../../images/icons/points/cemetary.png";
// import iconChapel from "../../../../../../images/icons/points/chapel-2.png";
// import iconChurch from "../../../../../../images/icons/points/church-2.png";
// import iconCircus from "../../../../../../images/icons/points/circus.png";
// import iconCitysquare from "../../../../../../images/icons/points/citysquare.png";
// import iconCitywalls from "../../../../../../images/icons/points/citywalls.png";
import iconCoffee from "../../../../../../images/icons/points/coffee.png";
// import iconCoins from "../../../../../../images/icons/points/coins.png";
// import iconCommentMapIcon from "../../../../../../images/icons/points/comment-map-icon.png";
// import iconCompost from "../../../../../../images/icons/points/compost.png";
// import iconComputers from "../../../../../../images/icons/points/computers.png";
// import iconCondominium from "../../../../../../images/icons/points/condominium.png";
// import iconConference from "../../../../../../images/icons/points/conference.png";
// import iconCongress from "../../../../../../images/icons/points/congress.png";
import iconConstruction from "../../../../../../images/icons/points/construction.png";
// import iconContract from "../../../../../../images/icons/points/contract.png";
// import iconConveniencestore from "../../../../../../images/icons/points/conveniencestore.png";
// import iconConvent from "../../../../../../images/icons/points/convent-2.png";
// import iconConversationMapIcon from "../../../../../../images/icons/points/conversation-map-icon.png";
// import iconCorral from "../../../../../../images/icons/points/corral.png";
// import iconCountry from "../../../../../../images/icons/points/country.png";
import iconCourt from "../../../../../../images/icons/points/court.png";
// import iconCowExport from "../../../../../../images/icons/points/cow-export.png";
// import iconCowabduction from "../../../../../../images/icons/points/cowabduction.png";
// import iconCraftstore from "../../../../../../images/icons/points/craftstore.png";
// import iconCrimescene from "../../../../../../images/icons/points/crimescene.png";
// import iconCross from "../../../../../../images/icons/points/cross-2.png";
// import iconCruiseship from "../../../../../../images/icons/points/cruiseship.png";
// import iconCurrencyexchange from "../../../../../../images/icons/points/currencyexchange.png";
// import iconCustoms from "../../../../../../images/icons/points/customs.png";
// import iconDaycare from "../../../../../../images/icons/points/daycare.png";
// import iconDirectiondown from "../../../../../../images/icons/points/direction_down.png";
// import iconDisability from "../../../../../../images/icons/points/disability.png";
// import iconDoublebendright from "../../../../../../images/icons/points/doublebendright.png";
// import iconDownloadicon from "../../../../../../images/icons/points/downloadicon.png";
// import iconDrugstore from "../../../../../../images/icons/points/drugstore.png";
// import iconElevator from "../../../../../../images/icons/points/elevator.png";
// import iconEmbassy from "../../../../../../images/icons/points/embassy.png";
// import iconExpert from "../../../../../../images/icons/points/expert.png";
// import iconFactory from "../../../../../../images/icons/points/factory.png";
// import iconFallingrocks from "../../../../../../images/icons/points/fallingrocks.png";
// import iconFarm from "../../../../../../images/icons/points/farm-2.png";
// import iconFastfood from "../../../../../../images/icons/points/fastfood.png";
// import iconFerry from "../../../../../../images/icons/points/ferry.png";
import iconFillingstation from "../../../../../../images/icons/points/fillingstation.png";
// import iconFinish from "../../../../../../images/icons/points/finish.png";
// import iconFirehydrant from "../../../../../../images/icons/points/fire-hydrant-2.png";
// import iconFire from "../../../../../../images/icons/points/fire.png";
import iconFireexstinguisher from "../../../../../../images/icons/points/fireexstinguisher.png";
// import iconFiremen from "../../../../../../images/icons/points/firemen.png";
// import iconFireworks from "../../../../../../images/icons/points/fireworks.png";
import iconFirstaid from "../../../../../../images/icons/points/firstaid.png";
// import iconFishing from "../../../../../../images/icons/points/fishing.png";
// import iconFlagexport from "../../../../../../images/icons/points/flag-export.png";
// import iconFooddeliveryservice from "../../../../../../images/icons/points/fooddeliveryservice.png";
// import iconFoodtruck from "../../../../../../images/icons/points/foodtruck.png";
// import iconFord from "../../../../../../images/icons/points/ford-2.png";
// import iconForest from "../../../../../../images/icons/points/forest.png";
// import iconForest2 from "../../../../../../images/icons/points/forest2.png";
// import iconHelicopter from "../../../../../../images/icons/points/helicopter.png";
// import iconHighschool from "../../../../../../images/icons/points/highschool.png";
// import iconHighway from "../../../../../../images/icons/points/highway.png";
// import iconHistoricalquarter from "../../../../../../images/icons/points/historicalquarter.png";
import iconHome from "../../../../../../images/icons/points/home.png";
// import iconHostel0star from "../../../../../../images/icons/points/hostel_0star.png";
// import iconHotel0star from "../../../../../../images/icons/points/hotel_0star.png";
// import iconHouse from "../../../../../../images/icons/points/house.png";
// import iconIcecream from "../../../../../../images/icons/points/icecream.png";
// import iconIcyroad from "../../../../../../images/icons/points/icy_road.png";
// import iconInformation from "../../../../../../images/icons/points/information.png";
// import iconJacuzzi from "../../../../../../images/icons/points/jacuzzi.png";
// import iconJapaneseFood from "../../../../../../images/icons/points/japanese-food.png";
// import iconJapaneseLantern from "../../../../../../images/icons/points/japanese-lantern.png";
// import iconJapaneseSake from "../../../../../../images/icons/points/japanese-sake.png";
// import iconJetfighter from "../../../../../../images/icons/points/jetfighter.png";
// import iconKarting from "../../../../../../images/icons/points/karting.png";
// import iconLandmark from "../../../../../../images/icons/points/landmark.png";
// import iconLighthouse from "../../../../../../images/icons/points/lighthouse-2.png";
// import iconLinedown from "../../../../../../images/icons/points/linedown.png";
// import iconLodging0star from "../../../../../../images/icons/points/lodging_0star.png";
// import iconMainroad from "../../../../../../images/icons/points/mainroad.png";
// import iconMetronetwork from "../../../../../../images/icons/points/metronetwork.png";
// import iconMilitary from "../../../../../../images/icons/points/military.png";
// import iconMine from "../../../../../../images/icons/points/mine.png";
// import iconModerntower from "../../../../../../images/icons/points/moderntower.png";
// import iconMosquee from "../../../../../../images/icons/points/mosquee.png";
// import iconMotorbike from "../../../../../../images/icons/points/motorbike.png";
// import iconMotorcycle from "../../../../../../images/icons/points/motorcycle.png";
import iconMotel from "../../../../../../images/icons/points/motel-2.png";
// import iconMural from "../../../../../../images/icons/points/mural.png";
// import iconMuseumArcheological from "../../../../../../images/icons/points/museum_archeological.png";
// import iconMuseumNaval from "../../../../../../images/icons/points/museum_naval.png";
// import iconObservatory from "../../../../../../images/icons/points/observatory.png";
// import iconOilpumpjack from "../../../../../../images/icons/points/oilpumpjack.png";
// import iconPalace from "../../../../../../images/icons/points/palace-2.png";
// import iconParkandride from "../../../../../../images/icons/points/parkandride.png";
import iconParkingMeter from "../../../../../../images/icons/points/parking-meter-export.png";
import iconParking from "../../../../../../images/icons/points/parking.png";
// import iconPenguin from "../../../../../../images/icons/points/penguin-2.png";
// import iconPhones from "../../../../../../images/icons/points/phones.png";
// import iconPirates from "../../../../../../images/icons/points/pirates.png";
// import iconPizzaria from "../../../../../../images/icons/points/pizzaria.png";
// import iconPlanecrash from "../../../../../../images/icons/points/planecrash.png";
// import iconPlanetarium from "../../../../../../images/icons/points/planetarium-2.png";
// import iconPleasurepier from "../../../../../../images/icons/points/pleasurepier.png";
// import iconPolice from "../../../../../../images/icons/points/police.png";
// import iconPostal from "../../../../../../images/icons/points/postal.png";
// import iconPowerlinepole from "../../../../../../images/icons/points/powerlinepole.png";
// import iconPoweroutage from "../../../../../../images/icons/points/poweroutage.png";
// import iconPowerplant from "../../../../../../images/icons/points/powerplant.png";
// import iconPowersubstation from "../../../../../../images/icons/points/powersubstation.png";
// import iconPrayer from "../../../../../../images/icons/points/prayer.png";
// import iconPresentation from "../../../../../../images/icons/points/presentation.png";
// import iconPriceTag from "../../../../../../images/icons/points/price-tag-export.png";
// import iconPrinter from "../../../../../../images/icons/points/printer-2.png";
// import iconPrison from "../../../../../../images/icons/points/prison.png";
// import iconPublicart from "../../../../../../images/icons/points/publicart.png";
// import iconPyramid from "../../../../../../images/icons/points/pyramid.png";
// import iconRadar from "../../../../../../images/icons/points/radar.png";
// import iconRadiation from "../../../../../../images/icons/points/radiation.png";
// import iconRainy from "../../../../../../images/icons/points/rainy.png";
// import iconRecycle from "../../../../../../images/icons/points/recycle.png";
// import iconRegroup from "../../../../../../images/icons/points/regroup.png";
import iconRepair from "../../../../../../images/icons/points/repair.png";
// import iconRestaurant from "../../../../../../images/icons/points/restaurant.png";
// import iconSegway from "../../../../../../images/icons/points/segway.png";
// import iconShooting from "../../../../../../images/icons/points/shooting.png";
// import iconSolarenergy from "../../../../../../images/icons/points/solarenergy.png";
// import iconSportutilityvehicle from "../../../../../../images/icons/points/sportutilityvehicle.png";
// import iconStar3 from "../../../../../../images/icons/points/star-3.png";
// import iconSteamtrain from "../../../../../../images/icons/points/steamtrain.png";
// import iconStop from "../../../../../../images/icons/points/stop.png";
// import iconStrike from "../../../../../../images/icons/points/strike.png";
// import iconSubmarine from "../../../../../../images/icons/points/submarine-2.png";
// import iconSugarshack from "../../../../../../images/icons/points/sugar-shack.png";
// import iconSupermarket from "../../../../../../images/icons/points/supermarket.png";
// import iconTaxi from "../../../../../../images/icons/points/taxi.png";
// import iconTaxiway from "../../../../../../images/icons/points/taxiway.png";
// import iconTennis from "../../../../../../images/icons/points/tennis.png";
// import iconTerrace from "../../../../../../images/icons/points/terrace.png";
// import iconText from "../../../../../../images/icons/points/text.png";
// import iconTheater from "../../../../../../images/icons/points/theater.png";
// import iconTheft from "../../../../../../images/icons/points/theft.png";
import iconTires from "../../../../../../images/icons/points/tires.png";
// import iconToilets from "../../../../../../images/icons/points/toilets.png";
import iconTollstation from "../../../../../../images/icons/points/tollstation.png";
// import iconTools from "../../../../../../images/icons/points/tools.png";
// import iconTower from "../../../../../../images/icons/points/tower.png";
// import iconTrafficcamera from "../../../../../../images/icons/points/trafficcamera.png";
// import iconTrafficlight from "../../../../../../images/icons/points/trafficlight.png";
// import iconTrain from "../../../../../../images/icons/points/train.png";
// import iconTramway from "../../../../../../images/icons/points/tramway.png";
// import iconTruck3 from "../../../../../../images/icons/points/truck3.png";
// import iconTunnel from "../../../../../../images/icons/points/tunnel.png";
// import iconTweet from "../../../../../../images/icons/points/tweet.png";
// import iconUnderground from "../../../../../../images/icons/points/underground.png";
// import iconVan from "../../../../../../images/icons/points/van.png";
// import iconVespa from "../../../../../../images/icons/points/vespa.png";
// import iconVeterinary from "../../../../../../images/icons/points/veterinary.png";
// import iconVilla from "../../../../../../images/icons/points/villa.png";
// import iconWaiting from "../../../../../../images/icons/points/waiting.png";
// import iconWar from "../../../../../../images/icons/points/war.png";
// import iconWatercraft from "../../../../../../images/icons/points/watercraft.png";
// import iconWatertower from "../../../../../../images/icons/points/watertower.png";
import iconWifi from "../../../../../../images/icons/points/wifi.png";
// import iconWindturbine from "../../../../../../images/icons/points/windturbine.png";
// import iconWorkoffice from "../../../../../../images/icons/points/workoffice.png";
// import iconYouthhostel from "../../../../../../images/icons/points/youthhostel.png";
// import iconZoom from "../../../../../../images/icons/points/zoom.png";

import { MAP_CONTROLS_ACTIONS } from "../../../../../../actions/MapControlsActions";
import { IMAGES } from "../../../../../../common/images";

class PointIcons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      points: [
        // { text: "", photo: iconAccessDenied },
        // { text: "", photo: iconAdministration },
        // { text: "", photo: iconAdministrativeBoundary },
        // { text: "", photo: iconAgritourism },
        { text: "", photo: iconAircraftcarrier },
        // { text: "", photo: iconAirportapron },
        // { text: "", photo: iconAirportrunway },
        // { text: "", photo: iconAirportterminal },
        { text: "", photo: iconAirport },
        { text: "", photo: iconApartment3 },
        { text: "", photo: iconArch },
        // { text: "", photo: iconArmy },
        // { text: "", photo: iconAward },
        // { text: "", photo: iconBadminton },
        { text: "", photo: iconBank },
        // { text: "", photo: iconBarcoktail },
        // { text: "", photo: iconBarjuice },
        { text: "", photo: iconBar },
        // { text: "", photo: iconBarbecue },
        // { text: "", photo: iconBarrier },
        // { text: "", photo: iconBats },
        // { text: "", photo: iconBattleship },
        // { text: "", photo: iconBigcity },
        // { text: "", photo: iconBinoculars },
        // { text: "", photo: iconBoat },
        { text: "", photo: iconBridgemodern },
        // { text: "", photo: iconBridgeold },
        // { text: "", photo: iconBulldozer },
        // { text: "", photo: iconBunker },
        { text: "", photo: iconBus },
        // { text: "", photo: iconBusstop },
        // { text: "", photo: iconBustour },
        // { text: "", photo: iconCabin },
        // { text: "", photo: iconCampfire },
        // { text: "", photo: iconCamping },
        // { text: "", photo: iconCar },
        { text: "", photo: iconCaraccident },
        // { text: "", photo: iconCarrental },
        { text: "", photo: iconCarwash },
        // { text: "", photo: iconCastle },
        // { text: "", photo: iconCathedral },
        // { text: "", photo: iconCatholicgrave },
        { text: "", photo: iconCaution },
        { text: "", photo: iconCctv },
        // { text: "", photo: iconCemetary },
        // { text: "", photo: iconChapel },
        // { text: "", photo: iconChurch },
        // { text: "", photo: iconCircus },
        // { text: "", photo: iconCitysquare },
        // { text: "", photo: iconCitywalls },
        { text: "", photo: iconCoffee },
        // { text: "", photo: iconCoins },
        // { text: "", photo: iconCommentMapIcon },
        // { text: "", photo: iconCompost },
        // { text: "", photo: iconComputers },
        // { text: "", photo: iconCondominium },
        // { text: "", photo: iconConference },
        // { text: "", photo: iconCongress },
        { text: "", photo: iconConstruction },
        // { text: "", photo: iconContract },
        // { text: "", photo: iconConveniencestore },
        // { text: "", photo: iconConvent },
        // { text: "", photo: iconConversationMapIcon },
        // { text: "", photo: iconCorral },
        // { text: "", photo: iconCountry },
        { text: "", photo: iconCourt },
        // { text: "", photo: iconCowExport },
        // { text: "", photo: iconCowabduction },
        // { text: "", photo: iconCraftstore },
        // { text: "", photo: iconCrimescene },
        // { text: "", photo: iconCross },
        // { text: "", photo: iconCruiseship },
        // { text: "", photo: iconCurrencyexchange },
        // { text: "", photo: iconCustoms },
        // { text: "", photo: iconDaycare },
        // { text: "", photo: iconDirectiondown },
        // { text: "", photo: iconDisability },
        // { text: "", photo: iconDoublebendright },
        // { text: "", photo: iconDownloadicon },
        // { text: "", photo: iconDrugstore },
        // { text: "", photo: iconElevator },
        // { text: "", photo: iconEmbassy },
        // { text: "", photo: iconExpert },
        // { text: "", photo: iconFactory },
        // { text: "", photo: iconFallingrocks },
        // { text: "", photo: iconFarm },
        // { text: "", photo: iconFastfood },
        // { text: "", photo: iconFerry },
        { text: "", photo: iconFillingstation },
        // { text: "", photo: iconFinish },
        // { text: "", photo: iconFirehydrant },
        // { text: "", photo: iconFire },
        { text: "", photo: iconFireexstinguisher },
        // { text: "", photo: iconFiremen },
        // { text: "", photo: iconFireworks },
        { text: "", photo: iconFirstaid },
        // { text: "", photo: iconFishing },
        // { text: "", photo: iconFlagexport },
        // { text: "", photo: iconFooddeliveryservice },
        // { text: "", photo: iconFoodtruck },
        // { text: "", photo: iconFord },
        // { text: "", photo: iconForest },
        // { text: "", photo: iconForest2 },
        // { text: "", photo: iconHelicopter },
        // { text: "", photo: iconHighschool },
        // { text: "", photo: iconHighway },
        // { text: "", photo: iconHistoricalquarter },
        { text: "", photo: iconHome },
        // { text: "", photo: iconHostel0star },
        // { text: "", photo: iconHotel0star },
        // { text: "", photo: iconHouse },
        // { text: "", photo: iconIcecream },
        // { text: "", photo: iconIcyroad },
        // { text: "", photo: iconInformation },
        // { text: "", photo: iconJacuzzi },
        // { text: "", photo: iconJapaneseFood },
        // { text: "", photo: iconJapaneseLantern },
        // { text: "", photo: iconJapaneseSake },
        // { text: "", photo: iconJetfighter },
        // { text: "", photo: iconKarting },
        // { text: "", photo: iconLandmark },
        // { text: "", photo: iconLighthouse },
        // { text: "", photo: iconLinedown },
        // { text: "", photo: iconLodging0star },
        // { text: "", photo: iconMainroad },
        // { text: "", photo: iconMetronetwork },
        // { text: "", photo: iconMilitary },
        // { text: "", photo: iconMine },
        // { text: "", photo: iconModerntower },
        // { text: "", photo: iconMosquee },
        // { text: "", photo: iconMotorbike },
        // { text: "", photo: iconMotorcycle },
        { text: "", photo: iconMotel },
        // { text: "", photo: iconMural },
        // { text: "", photo: iconMuseumArcheological },
        // { text: "", photo: iconMuseumNaval },
        // { text: "", photo: iconObservatory },
        // { text: "", photo: iconOilpumpjack },
        // { text: "", photo: iconPalace },
        // { text: "", photo: iconParkandride },
        { text: "", photo: iconParkingMeter },
        { text: "", photo: iconParking },
        // { text: "", photo: iconPenguin },
        // { text: "", photo: iconPhones },
        // { text: "", photo: iconPirates },
        // { text: "", photo: iconPizzaria },
        // { text: "", photo: iconPlanecrash },
        // { text: "", photo: iconPlanetarium },
        // { text: "", photo: iconPleasurepier },
        // { text: "", photo: iconPolice },
        // { text: "", photo: iconPostal },
        // { text: "", photo: iconPowerlinepole },
        // { text: "", photo: iconPoweroutage },
        // { text: "", photo: iconPowerplant },
        // { text: "", photo: iconPowersubstation },
        // { text: "", photo: iconPrayer },
        // { text: "", photo: iconPresentation },
        // { text: "", photo: iconPriceTag },
        // { text: "", photo: iconPrinter },
        // { text: "", photo: iconPrison },
        // { text: "", photo: iconPublicart },
        // { text: "", photo: iconPyramid },
        // { text: "", photo: iconRadar },
        // { text: "", photo: iconRadiation },
        // { text: "", photo: iconRainy },
        // { text: "", photo: iconRecycle },
        // { text: "", photo: iconRegroup },
        { text: "", photo: iconRepair },
        // { text: "", photo: iconRestaurant },
        // { text: "", photo: iconSegway },
        // { text: "", photo: iconShooting },
        // { text: "", photo: iconSolarenergy },
        // { text: "", photo: iconSportutilityvehicle },
        // { text: "", photo: iconStar3 },
        // { text: "", photo: iconSteamtrain },
        // { text: "", photo: iconStop },
        // { text: "", photo: iconStrike },
        // { text: "", photo: iconSubmarine },
        // { text: "", photo: iconSugarshack },
        // { text: "", photo: iconSupermarket },
        // { text: "", photo: iconTaxi },
        // { text: "", photo: iconTaxiway },
        // { text: "", photo: iconTennis },
        // { text: "", photo: iconTerrace },
        // { text: "", photo: iconText },
        // { text: "", photo: iconTheater },
        // { text: "", photo: iconTheft },
        { text: "", photo: iconTires },
        // { text: "", photo: iconToilets },
        { text: "", photo: iconTollstation },
        // { text: "", photo: iconTools },
        // { text: "", photo: iconTower },
        // { text: "", photo: iconTrafficcamera },
        // { text: "", photo: iconTrafficlight },
        // { text: "", photo: iconTrain },
        // { text: "", photo: iconTramway },
        // { text: "", photo: iconTruck3 },
        // { text: "", photo: iconTunnel },
        // { text: "", photo: iconTweet },
        // { text: "", photo: iconUnderground },
        // { text: "", photo: iconVan },
        // { text: "", photo: iconVespa },
        // { text: "", photo: iconVeterinary },
        // { text: "", photo: iconVilla },
        // { text: "", photo: iconWaiting },
        // { text: "", photo: iconWar },
        // { text: "", photo: iconWatercraft },
        // { text: "", photo: iconWatertower },
        { text: "", photo: iconWifi },
        // { text: "", photo: iconWindturbine },
        // { text: "", photo: iconWorkoffice },
        // { text: "", photo: iconYouthhostel },
        // { text: "", photo: iconZoom },
      ],
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  onClickPoint = (i) => {
    document.querySelectorAll(".pointIconWrapper").forEach((item) => {
      item.classList.remove("active");
    });
    document.getElementById("pointicon-" + i).classList.add("active");
    let imgUrl = IMAGES.points[i].image;
    this.props.setPointImage({ pointImg: { imageIndex: i, image: imgUrl } });
  };

  render() {
    return (
      <div id="pointIcons">
        {IMAGES.points.map((p, i) => {
          return (
            <div
              className={"pointIconWrapper"}
              key={"pointPhoto-" + i}
              title={p.text}
              onClick={() => this.onClickPoint(i)}
              id={"pointicon-" + i}
            >
              <Img src={p.image} alt="" />
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPointImage: (values) => {
      dispatch({
        type: MAP_CONTROLS_ACTIONS.SET_POINT_IMG,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PointIcons);
