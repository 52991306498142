import React, { Component } from "react";
import { connect } from "react-redux";
import { Polygon } from "react-leaflet";

import { MAP_CONTROLS_ACTIONS } from "../../../../actions/MapControlsActions";

class EditZone extends Component {
  render() {
    const { zoneVertices } = this.props.mapControlsReducer;
    return zoneVertices.length > 0 && <Polygon positions={zoneVertices} />;
  }
}

const mapStateToProps = (state) => {
  return {
    mapControlsReducer: state.mapControlsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setZoneEdit: (values) => {
      dispatch({
        type: MAP_CONTROLS_ACTIONS.SET_ZONE_EDIT,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditZone);
