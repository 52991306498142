import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import { SortingState, IntegratedSorting } from "@devexpress/dx-react-grid";
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";
import { connect } from "react-redux";

import { API_Settings } from "../../../apihandler/Settings";

const getRowId = (row) => row.id;

var timer = null;

class MainGroupsGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      direction: "asc",
      recs: {
        rows: [],
        filteredRows: [],
        columns: [],
      },
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.loadRecords();
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  onSearch = (searchValue) => {
    const { recs } = this.state;
    const { rows, columns } = recs;
    clearTimeout(timer);
    var self = this;
    timer = setTimeout(() => {
      let qry = searchValue.toUpperCase();
      var list = rows.filter((row) => {
        return row.name.toUpperCase().indexOf(qry) > -1;
      });
      list = list.map((l, i) => {
        l.slno = i + 1;
        return l;
      });
      self.setState({
        recs: { rows: rows, filteredRows: list, columns: columns },
      });
    }, 500);
  };

  generateOptions = (rec) => {
    const { id, name } = rec;
    return (
      <div className="options">
        <Tooltip
          title="Edit"
          placement="bottom"
          onClick={() => this.props.togglePopupDialog(rec)}
        >
          <IconButton className="smallIcon">
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Delete"
          placement="bottom"
          onClick={() => this.props.onDeleteRecord(id, name)}
        >
          <IconButton className="smallIcon">
            <Delete />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  loadReloadRecords = () => {
    this.setState({
      recs: {
        loading: false,
        sorting: "asc",
        columns: [],
        rows: [],
        filteredRows: [],
      },
    });
    this.loadRecords();
  };

  loadRecords = () => {
    const self = this;
    const { uid } = this.props.loginReducer;
    const args = {
      userId: uid,
    };
    API_Settings.getMainGroups(args).then((res) => {
      console.log("getMainGroups: ", res);
      if (res.Result === 200) {
        let cols = [
          { name: "slno", title: "Sl No." },
          { name: "name", title: "Main Group" },
          { name: "groups", title: "Sub Groups" },
          { name: "options", title: "Options" },
        ];
        let rows = [];
        res = res.Response;
        for (var i in res) {
          const subGroups = res[i].groups.map((g) => {
            return g.name;
          });
          rows.push({
            slno: Number(i) + 1,
            id: res[i].id,
            name: res[i].name,
            groups: subGroups.toString(),
            options: this.generateOptions(res[i]),
          });
        }
        self.setState({
          recs: {
            loading: false,
            sorting: "asc",
            columns: cols,
            rows: rows,
            filteredRows: [],
          },
        });
      }
    });
  };

  render() {
    const { rows, columns, filteredRows } = this.state.recs;

    const tableColumnExtensions = [
      { columnName: "slno", width: 100, align: "center" },
      { columnName: "options", width: 100, align: "center" },
    ];

    let searchOn = false;
    if (document.getElementById("sharedVehiclesSearch") != null) {
      searchOn =
        document.getElementById("sharedVehiclesSearch").value.trim() === ""
          ? false
          : true;
    }

    const gridHeight = window.innerHeight / 1.5;

    return columns.length > 0 ? (
      <div id="subAccountsGrid" className="dataRGrid">
        <Grid
          rows={searchOn ? filteredRows : rows}
          columns={columns}
          getRowId={getRowId}
        >
          <SortingState
            defaultSorting={[{ columnName: "email", direction: "asc" }]}
            columnSortingEnabled={true}
            //   onSortingChange={this.changeZoneSort}
            columnExtensions={[
              { columnName: "poi", sortingEnabled: false },
              { columnName: "options", sortingEnabled: false },
            ]}
          />
          <IntegratedSorting />
          <VirtualTable
            columnExtensions={tableColumnExtensions}
            height={gridHeight}
          />
          <TableHeaderRow showSortingControls />
        </Grid>
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: gridHeight,
          alignItems: "center",
        }}
      >
        <p style={{ color: "#a1a1a1", fontSize: 14 }}>Loading...</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MainGroupsGrid);
