import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { ArrowForward } from "@material-ui/icons";
import { Link, Breadcrumbs, FormControlLabel, Radio } from "@material-ui/core";
import jQuery from "jquery";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.flash";
import "pdfmake/build/vfs_fonts";
import "pdfmake/build/pdfmake";

import UiDatePicker from "components/ui/uidatepicker/UiDatePicker";
import AutoComplete from "components/ui/autocomplete/AutoComplete";
import FleetDropDown from "../../../../ui/dropdown/FleetDropDown";
import Btn from "components/ui/button/Btn";
import RoundTripGraph from "./RoundTripGraph";

import { API_Map } from "apihandler/Map";
import { COMMON_ACTIONS } from "actions/CommonActions";
import { REPORT_ACTIONS } from "actions/ReportActions";
import { dateFunction } from "common/datefunctions";
import {
  NOTIFY,
  ALERTS,
  FORMATS,
  REPORT_NAMES,
  REPORT_DESCRIPTION,
  DEFAULTS,
  LABELS,
} from "common/consts";
import { commonJs } from "common/common";
import { API_UserLogin } from "apihandler/UserLogin";
import { API_Reports } from "apihandler/Reports/navya";

var $ = jQuery;

class RoundTripGraphReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicles: [],
      selectedVehicle: {},
      fdt: dateFunction.getDate("thismonth", "from", FORMATS.SQL_DATE),
      tdt: dateFunction.getDate("today", "to", FORMATS.SQL_DATE),
      roundGraph: [],
    };
  }

  componentDidMount() {
    document.getElementById("root").classList.remove("login");
    document.getElementById("root").classList.add("scroll");
    this.getInitialData();
  }

  onChange = (val) => {
    this.setState({ selectedTemMin: val });
  };

  getInitialData = () => {
    const args = {};
    let self = this;
    args.pageNo = 0;
    args.pageLength = 100000;
    args.orderBy = "ASC";
    args.sortColumn = "";
    API_UserLogin.setToken(localStorage.token);
    API_Map.getAllVehicles(args)
      .then((res) => {
        const vehicles = res.filter((v) => {
          return commonJs.isTempVehicle(v.sensors);
        });
        self.props.setVehicles({
          vehicles: vehicles,
        });
        self.props.setLoading({
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleDateChange = (type, dt) => {
    if (type === "from") {
      this.setState({
        fdt: dt,
      });
    } else {
      this.setState({ tdt: dt });
    }
  };

  onChangeVechicle = (vehicle) => {
    this.setState({
      selectedVehicle: vehicle,
    });
  };

  changeType = (type) => {
    this.setState({
      reportType: type,
    });
  };

  loadReport = () => {
    const {
      selectedVehicle,
      fdt,
      tdt,
      selectedTemMin,
      reportType,
      roundGraph,
    } = this.state;
    let self = this;

    if (!selectedVehicle.id) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        ALERTS.PLEASE_SELECT_VEHICLE,
        commonJs.getDefaultDismissBtn()
      );
      return;
    }
    let args = {
      pageNo: 1,
      pageLength: 30,
      imei: selectedVehicle.id,
      fromDate: fdt,
      toDate: tdt,
      groupId: "", // Get Vehicle of the group, if empty -> Ignore
      tempType: "normal", // or it will be "" -> To display actual temperature
      platform: "web", // Or it will be "app"
      reportType,
    };

    API_Reports.getTemperatureGraph(args)
      .then((res) => {
        self.props.setLoading({
          loading: false,
        });
        if (res.Result === 200) {
          const { rows, cols } = res.Response;
          let columns = [];
          for (var c = 0; c < cols.length; c++) {
            columns.push({ title: cols[c] });
          }
          console.log("getTemperatureGraph", rows);

          let roundGraph = [];

          for (let r = 0; r < rows.length; r++) {
            roundGraph.push([
              new Date(rows[r][1]).getTime(),
              Number(rows[r][2]),
            ]);
          }
          console.log("row:", roundGraph);

          self.setState({
            roundGraph: roundGraph,
          });

          console.log("roundGraph", roundGraph);

          if ($.fn.dataTable.isDataTable("#reportGrid")) {
            let table = $("#reportGrid").DataTable();
            table.destroy();
          }
          $("#reportGrid").empty();

          let title =
            REPORT_NAMES.TEMP_GRAPH +
            " - " +
            selectedVehicle.name +
            " (" +
            dateFunction.getFormatedDate(
              fdt,
              FORMATS.SQL_DATE,
              FORMATS.REPORT_TITLE_DATE
            ) +
            "-" +
            dateFunction.getFormatedDate(
              tdt,
              FORMATS.SQL_DATE,
              FORMATS.REPORT_TITLE_DATE
            );

          $("#reportGrid").DataTable({
            dom: "Bfrtip",
            buttons: [
              {
                extend: "copy",
                className: "reportBtn",
                title: title,
              },
              {
                extend: "pdf",
                className: "reportBtn",
                title: title,
              },
              {
                extend: "excel",
                className: "reportBtn",
                title: title,
              },
              {
                extend: "csv",
                className: "reportBtn",
                title: title,
                text: "CSV",
              },
              {
                extend: "print",
                className: "reportBtn",
                title: title,
                customize: (win) => {
                  commonJs.getExportPrintCss(win);
                },
              },
            ],
            paging: false,
            scrollY: DEFAULTS.REPORT_GRID_HEIGHT,
            columns: columns,
            data: rows,
          });
        }
      })
      .catch((err) => {
        console.log("getLocation: err:", err);
      });
  };

  onFilterDate = (type) => {
    let fdt = dateFunction.getDate(type, "from", FORMATS.SQL_DATE);
    let tdt = dateFunction.getDate(type, "to", FORMATS.SQL_DATE);
    this.setState({
      fdt,
      tdt,
    });
  };

  render() {
    const { selectedVehicle, fdt, tdt, roundGraph } = this.state;
    const { vehiclesList } = this.props.commonReducer;

    let vehicles = [];
    for (var i = 0; i < vehiclesList.length; i++) {
      vehicles.push({ id: vehiclesList[i].imei, name: vehiclesList[i].name });
    }

    return (
      <div className="reportPageWrapper height100">
        <Breadcrumbs aria-label="breadcrumb" className="breadcrumb">
          <Link color="inherit" href="/">
            Home
          </Link>
          <p>{REPORT_NAMES.ROUND_TRIP_GRAPH}</p>
        </Breadcrumbs>
        <p className="reportDescription">
          {REPORT_DESCRIPTION.ROUND_TRIP_GRAPH}
        </p>
        <div className="reportFilters">
          <div className="width30 uidatepicker" style={{ padding: "0px" }}>
            <div className="datepickerTop">
              <UiDatePicker
                label="From Date :"
                dt={fdt}
                type="from"
                disablePast={false}
                disableFuture={true}
                disable={false}
                datepicker={false}
                handleDateChange={this.handleDateChange}
              />
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ArrowForward />
              </div>
              <UiDatePicker
                label="To Date :"
                dt={tdt}
                type="to"
                disablePast={false}
                disableFuture={false}
                disabled={false}
                handleDateChange={this.handleDateChange}
              />
            </div>
            <div className="datepickerBottom">
              <ul>
                <li>Filters: </li>
                <li onClick={() => this.onFilterDate("today")}>Today</li>
                <li onClick={() => this.onFilterDate("yesterday")}>
                  Yesterday
                </li>
                <li onClick={() => this.onFilterDate("thismonth")}>
                  This Month
                </li>
                <li onClick={() => this.onFilterDate("lastmonth")}>
                  Last Month
                </li>
              </ul>
            </div>
          </div>
          <div
            className="width20"
            style={{ marginTop: 10, marginBottom: 10, paddingLeft: 5 }}
          >
            <AutoComplete
              label={"Select Vechicle"}
              list={vehicles}
              onChange={this.onChangeVechicle}
              defaultValue={selectedVehicle}
              multiple={false}
              size="small"
            />
          </div>
          <div className="width10" style={{ paddingLeft: 20 }}>
            <Btn onClick={this.loadReport} className="primaryBtn">
              Submit
            </Btn>
          </div>
        </div>
        <div className="reportGridWrapper">
          <RoundTripGraph data={roundGraph} />
          <table
            id="reportGrid"
            className="mdl-data-table width100 paginated"
            cellSpacing="0"
            cellPadding="3"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commonReducer: state.commonReducer,
    reportReducer: state.reportReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setVehicles: (values) => {
      dispatch({
        type: COMMON_ACTIONS.SET_VEHICLES,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: REPORT_ACTIONS.SET_LOADING,
        values,
      });
    },
    toggleReportPopup: (values) => {
      dispatch({
        type: REPORT_ACTIONS.TOGGLE_REPORT_POPUP,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(RoundTripGraphReport));
