import React, { Component } from "react";
import { FormControlLabel, Checkbox, TextField } from "@material-ui/core";

import { LABELS } from "common/consts";

import "./styles.css";

class MultiSelectionBox extends Component {
  constructor(props) {
    super(props);
    let sList = [];
    if (this.props.defaultAllSelected) {
      sList = this.props.list.map((l) => {
        return l.id;
      });
    } else {
      sList = this.props.selectedValues || [];
    }
    this.state = {
      list: this.props.list,
      filteredList: [],
      selectedList: sList,
    };
    this._selectedValues = sList;
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  onChangeList = (id = "", e) => {
    let sList = this.state.selectedList;
    if (e.target.checked) {
      if (sList.indexOf(id) === -1) {
        sList.push(id);
      }
    } else {
      sList = this.state.selectedList.filter((s) => s !== id);
    }
    this.setState({
      selectedList: sList,
    });
    this._selectedValues = sList;
  };

  onSelectAllChange = (e) => {
    const { list } = this.state;
    const isChecked = e.target.checked;
    // Select All
    if (isChecked) {
      let selectedList = list.map((l) => {
        return l.id;
      });
      this.setState({
        selectedList,
      });
      this._selectedValues = selectedList;
    } else {
      // Un Select All
      this.setState({
        selectedList: [],
      });
      this._selectedValues = [];
    }
  };

  onSearch = (e) => {
    let qry = e.target.value.toUpperCase();
    let self = this;
    clearTimeout(this._timeout);
    this._timeout = setTimeout(() => {
      const { list } = self.state;
      const filteredList = list.filter((l) => {
        return l.name.toUpperCase().indexOf(qry) > -1;
      });
      self.setState({
        filteredList,
      });
    }, 1500);
  };

  render() {
    const { list, filteredList, selectedList } = this.state;
    const { height } = this.props;
    const finalList = filteredList.length > 0 ? filteredList : list;
    return (
      <div className="multiSelectionWrapper">
        <div className="multiSelectionOptions">
          <div style={{ width: 100 }}>
            <FormControlLabel
              label={"Select All"}
              className={"checkBox"}
              control={
                <Checkbox
                  indeterminate={
                    selectedList.length > 0
                      ? selectedList.length !== list.length
                      : false
                  }
                />
              }
              checked={list.length === selectedList.length}
              onChange={(e) => this.onSelectAllChange(e)}
            />
          </div>
          <div>
            <TextField
              placeholder={LABELS.SEARCH_BY_NAME}
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              onChange={this.onSearch}
            />
          </div>
        </div>
        <div className="multiSelectionBox" style={{ height: height || 250 }}>
          {finalList.map((l) => {
            return (
              <FormControlLabel
                key={"chkbox-" + l.id}
                label={l.name}
                className={"checkBox"}
                control={<Checkbox id={l.id} value={l.id} />}
                checked={selectedList.indexOf(l.id) > -1}
                onChange={(e) => this.onChangeList(l.id, e)}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default MultiSelectionBox;
