import React, { Component } from "react";
import { connect } from "react-redux";
import { CircularProgress, Backdrop } from "@material-ui/core";

import CpanelTopbar from "components/cpanel/layouts/CpanelTopbar";
import CpanelLeftBar from "components/cpanel/layouts/CpanelLeftBar";

import Dashboard from "components/cpanel/dashboard";
import CPanelMasters from "components/cpanel/masters";
import CPanelUsers from "components/cpanel/users";
import CpanelVehicles from "components/cpanel/vehicles";

import { REPORT_ACTIONS } from "actions/ReportActions";

import "./cpanel.css";

class CPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "vehicles",
    };
  }

  componentDidMount() {
    document.getElementById("root").classList.add("scroll");
    document.getElementById("root").classList.remove("login");
  }

  onMenuChange = (menu = "") => {
    this.setState({
      menu,
    });
  };

  render() {
    const { menu } = this.state;
    const { loading } = this.props.reportReducer;
    return (
      <div className="reports">
        <CpanelTopbar />
        <CpanelLeftBar menu={menu} onMenuChange={this.onMenuChange} />

        {menu === "dashboard" && <Dashboard />}
        {menu === "masters" && <CPanelMasters />}
        {menu === "users" && <CPanelUsers />}
        {menu === "vehicles" && <CpanelVehicles />}

        <Backdrop className={"backdrop"} open={loading}>
          <CircularProgress className="whiteLoader" />
        </Backdrop>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reportReducer: state.reportReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (values) => {
      dispatch({
        type: REPORT_ACTIONS.SET_LOADING,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CPanel);
