import { SdkClient } from "fgxsdk";
import moment from "moment";
import { commonJs } from "../common/common";

import { CONFIG, LOCAL_STORAGE } from "../common/consts";
import { dateFunction } from "../common/datefunctions";

SdkClient.setUrl(CONFIG.BASE_URL);

export const API_Map = {
  getVehicleData: async (args) => {
    return SdkClient.getObject(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  getAllVehicles: async (opts, privileges = []) => {
    return SdkClient.getObjects(opts)
      .then((res) => {
        let vlist = [];
        let idlerun = false;
        let now = moment().format("YYYY-MM-DD HH:mm:ss");
        if (res.Result === 200) {
          if (privileges.indexOf("idlerun") > -1) {
            idlerun = true;
          }
          let arr = res.Response;
          for (let i in arr) {
            arr[i].visible = true;
            arr[i].index = i;
            arr[i].id = arr[i].imei;

            let ign = commonJs.getVehicleIgnition(arr[i]);

            // ONLY IF: If not No Signal
            // Stop -> Ignition off
            // Idle -> Ignition On & Speed 0
            if (arr[i].status > 0 && !idlerun) {
              if (arr[i].speed > CONFIG.RUNNING_SPEED_MIN) {
                arr[i].status = 3;
              } else if (!ign) {
                arr[i].status = 1;
              } else if (ign) {
                arr[i].status = 2;
              }
            }

            if (arr[i].status === 2 && idlerun) {
              // Amritsar User Has this feature
              let idleTime = arr[i].idletime;
              if (idleTime) {
                if (dateFunction.getDateDiffInSecs(idleTime, now) < 3600) {
                  arr[i].status = 3;
                  arr[i].idletime = null;
                } else {
                  arr[i].status = 2;
                }
              }
            }
            vlist.push(arr[i]);
          }
        } else {
          vlist = res.Reason;
        }
        return vlist;
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  getAllZones: async (args = {}) => {
    // return SdkClient.getZones()
    //   .then((res) => {
    //     return res;
    //   })
    //   .catch((err) => {
    //     console.log("getZones: ", err);
    //     throw new Error(err);
    //   });
    let userInfo = localStorage.getItem(LOCAL_STORAGE.USER_SETTINGS);
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
      args["userId"] = userInfo["id"];
    }
    const response = await commonJs.callApi("/v1/userzones", "GET", args);
    return response;
  },

  deleteZone: async (args) => {
    // return SdkClient.deleteZone(args)
    //   .then((res) => {
    //     return res;
    //   })
    //   .catch((err) => {
    //     console.log("deleteZone: ", err);
    //     throw new Error(err);
    //   });
    let userInfo = localStorage.getItem(LOCAL_STORAGE.USER_SETTINGS);
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
      args["userId"] = userInfo["id"];
    }
    const response = await commonJs.callApi("/v1/deletezone", "POST", args);
    return response;
  },

  getAllPoints: async () => {
    return SdkClient.getPoints()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("getPoints: ", err);
        throw new Error(err);
      });
  },

  savePoint: async (args) => {
    return SdkClient.savePoint(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("savePoint err: ", err);
        throw new Error(err);
      });
  },

  deletePoint: async (args) => {
    return SdkClient.deletePoint(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("deletePoint: ", err);
        throw new Error(err);
      });
  },

  getAllRoutes: async () => {
    return SdkClient.getRoutes()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("getRoutes: ", err);
        return err;
      });
  },

  saveRoute: async (args) => {
    return SdkClient.saveRoute(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("saveRoute err: ", err);
        throw new Error(err);
      });
  },

  deleteRoute: async (args) => {
    return SdkClient.deleteRoute(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("deleteRoute: ", err);
        throw new Error(err);
      });
  },

  saveZone: async (args) => {
    return SdkClient.saveZone(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("saveZone err: ", err);
        throw new Error(err);
      });
  },

  getGroups: async (args) => {
    return SdkClient.getGroups(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("getGroups: ", err);
      });
  },

  getAddress: async (lat, lng) => {
    // return SdkClient.getZones()
    //   .then((res) => {
    //     return res;
    //   })
    //   .catch((err) => {
    //     console.log("getZones: ", err);
    //     throw new Error(err);
    //   });
    return SdkClient.getAddress({ lat: lat, lng: lng })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("getAddress: ", err);
        throw new Error(err);
      });
  },

  getVehicleTrack: async (args) => {
    return SdkClient.getObjectTrack(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("track: ", err);
        throw new Error(err);
      });
  },

  getVehiclesNoSession: async (args) => {
    return SdkClient.getVehiclesNoSession(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("getVehiclesNoSession: ", err);
        throw new Error(err);
      });
  },

  getVehicleDataNoSession: async (args) => {
    return SdkClient.getVehicleDataNoSession(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("getVehicleDataNoSession: ", err);
        throw new Error(err);
      });
  },
};
