import React, { Component } from "react";
import { Chip } from "@material-ui/core";

class FleetChip extends Component {
  render() {
    return (
      <React.Fragment>
        <Chip
          avatar={this.props.avatar}
          label={this.props.label}
          onClick={this.props.onClick}
          className={this.props.className}
        />
      </React.Fragment>
    );
  }
}
export default FleetChip;
