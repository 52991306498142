import { TMP_ACTIONS } from "../actions/TmpActions";

let initialState = {
  tmpMarker: {}, // Show have the lat,lng & Other Info
};

const tmpReducer = (state = initialState, action) => {
  const { type, values } = action;
  switch (type) {
    case TMP_ACTIONS.SET_TMP_MARKER:
      return {
        ...state,
        tmpMarker: values.tmpMarker,
      };
    default:
      break;
  }
  return state;
};

export default tmpReducer;
