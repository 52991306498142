import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { withSnackbar } from "notistack";

import FleetDialog from "components/ui/dialogbox/FleetDialog";

import { commonJs } from "common/common";
import { ERRORS, NOTIFY, SUCCESS_MSG } from "common/consts";
import { API_Settings } from "apihandler/Settings";

class DialogAddGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recordId: "",
      groupName: "",
      groupDescription: "",
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  saveRecord = () => {
    const groupName = document.getElementById("groupName").value.trim();
    const groupDescription = document
      .getElementById("groupDescription")
      .value.trim();
    const { recordId } = this.state;

    // Form Validation
    if (groupName === "") {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Group Name should not be empty..!"
      );
      return false;
    }

    let args = {
      name: groupName,
      description: groupDescription,
      id: Number(recordId),
    };
    let self = this;
    API_Settings.addUserGroup(args).then((res) => {
      if (res.Result === 200) {
        self.props.togglePopup({ id: recordId, name: groupName });
        commonJs.showNotify(
          self.props,
          NOTIFY.SUCCESS,
          SUCCESS_MSG.RECORD_SAVED
        );
        self.props.reloadGrid();
      } else {
        commonJs.showNotify(self.props, NOTIFY.ERROR, ERRORS.RECORD_NOT_SAVED);
      }
    });
  };

  setData = (rec) => {
    this.setState({
      recordId: rec.id,
      groupName: rec.name,
      groupDescription: rec.description,
    });
    console.log("setData: ", rec);
  };

  render() {
    const { groupName, groupDescription, recordId } = this.state;

    const { showDialog } = this.props;

    return (
      showDialog && (
        <FleetDialog
          id="addUserGroup"
          open={true}
          onCloseSettings={this.props.togglePopup}
          title={recordId ? "Edit Group" : "Add Group"}
          maxWidth="xs"
          fullWidth={false}
          cancelButton={true}
          saveButton={true}
          cancelAction={this.props.togglePopup}
          saveAction={this.saveRecord}
          saveBtnLabel="OK"
          disableBackdropClick={false}
          disableBackdrop={false}
          classNameBody="zonePopupBody"
          classNameWrapper="zonePopupWrapper settingsDialogBox"
          draggable
        >
          <div className="grid">
            <div className="row">
              <TextField
                id="groupName"
                label="Group Name"
                classes={{ root: "txtfield" }}
                autoFocus={true}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={groupName}
              />
            </div>
            <div className="row">
              <TextField
                id="groupDescription"
                label="Group Description"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={groupDescription}
                multiline={true}
                rows={3}
              />
            </div>
          </div>
        </FleetDialog>
      )
    );
  }
}

export default withSnackbar(DialogAddGroup);
