import { SdkClient } from "fgxsdk";

export const API_CPanel = {
  getCompanies: (args = {}) => {
    return SdkClient.getCompanies(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("getCompanies: ", err);
        throw new Error(err);
      });
  },
  manageCompany: (args = {}) => {
    return SdkClient.manageCompany(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("manageCompany: ", err);
        throw new Error(err);
      });
  },

  getCompanyUsers: (args = {}) => {
    return SdkClient.getCompanyUsers(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("getCompanyUsers: ", err);
        throw new Error(err);
      });
  },

  getAllUsers: async (args = {}) => {
    return SdkClient.getAllUsers(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("getAllUsers: ", err);
        throw new Error(err);
      });
    return {
      Result: 200,
      Response: [
        {
          active: true,
          api: true,
          apiKey: "987654321hghgftf",
          companyId: 27,
          email: "demo@gmail.com",
          googleMap: 1,
          id: 0,
          info:
            '{"name":"Demo 123","company":"nagaraj","address":"Bangalore, Karnataka, India - 560034","post_code":"560034","city":"Bangalore","country":"Karnataka","phone1":"9876543210","email":"demo@gmail.com"}',
          latLng: "12.9716,77.5946",
          manager: 3452,
          maxPoints: 100,
          maxRoutes: 100,
          maxZones: 100,
          objectsAdd: "false",
          objectsLogtime: "",
          objectsNum: 100,
          packageId: 23001,
          password: "demo123",
          privileges: ["al10", "al", "rp3"],
          remarks: "Payment is received",
          roleId: 3,
          userName: "demo",
          userNotifications: ["Green Driving", "Sensor", "Zone"],
          subaccounts: 0,
          vehicles: 255,
          registrationDate: "2020-04-20",
        },
      ],
    };
  },

  getAllUserRoles: (args = {}) => {
    return SdkClient.getAllUserRoles(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("getAllUserRoles: ", err);
        throw new Error(err);
      });
  },
  manageRole: (args = {}) => {
    return SdkClient.manageRole(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("manageRole: ", err);
        throw new Error(err);
      });
  },

  getAllUserPrivileges: (args = {}) => {
    return SdkClient.getAllUserPrivileges(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("getAllUserPrivileges: ", err);
        throw new Error(err);
      });
  },
  managePrivilege: (args = {}) => {
    return SdkClient.managePrivilege(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("managePrivilege: ", err);
        throw new Error(err);
      });
  },

  manageUser: (args = {}) => {
    return SdkClient.manageUser(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("manageUser: ", err);
        throw new Error(err);
      });
  },

  getUsersDropdown: (args = {}) => {
    return SdkClient.getUsersDropdown(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("getUsersDropdown: ", err);
        throw new Error(err);
      });
  },

  getAllPackages: async () => {
    return {
      Result: 200,
      Response: [
        {
          id: "1100",
          package: "basic",
          description: "basic description",
          role: "admin",
          roleId: 2,
          privileges: "Alerts,Map",
          privilegeIds: ["al", "mp"],
        },
        {
          id: "1101",
          package: "starter",
          description: "starter description",
          role: "manager",
          roleId: 3,
          privileges: "Alerts,Map",
          privilegeIds: ["al", "mp"],
        },
        {
          id: "1101",
          package: "premium",
          description: "premium description",
          role: "guest",
          roleId: 6,
          privileges: "Alerts,Map",
          privilegeIds: ["al", "mp"],
        },
      ],
    };
  },
  managePackage: async (args = {}) => {
    return {
      Result: 200,
      Response: "OK",
    };
  },
  deletePackage: async (args = {}) => {
    return {
      Result: 200,
      Response: "OK",
    };
  },

  getPackageInfo: async (args) => {
    return {
      Result: 200,
      Response: {
        roleId: 3,
        privileges: ["al", "mp"],
      },
    };
  },

  getCompanyManagers: async () => {
    return {
      Result: 200,
      Response: [
        {
          id: 1101,
          name: "Manager 1",
        },
        {
          id: 1102,
          name: "Manager 2",
        },
      ],
    };
  },

  getCpanelVehicleList: async (args) => {
    return SdkClient.getCpanelVehicleList(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("getCpanelVehicleList: ", err);
        throw new Error(err);
      });
  },
  manageVehicle: async (args) => {
    return SdkClient.manageVehicle(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("manageVehicle: ", err);
        throw new Error(err);
      });
  },
  deleteVehicle: async (args) => {
    return SdkClient.deleteVehicle(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("deleteVehicle: ", err);
        throw new Error(err);
      });
  },
  shareVehicle: async (args) => {
    return {
      Result: 200,
      Response: "OK",
    };
  },

  // Get CPanel Summary -> Only for "admin" & "super_admin"
  getCpanelSummary: async () => {
    return SdkClient.getCpanelSummary()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("getSummary: ", err);
        throw new Error(err);
      });
  },

  getCpanelShare: async (args) => {
    return SdkClient.getCpanelShare(args)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("getCpanelShare", err);
        throw new Error(err);
      });
  },
};
