import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  Replay as Reload,
  AddCircleOutline as Add,
  Delete,
} from "@material-ui/icons";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import SearchButton from "components/ui/searchbutton/SearchButton";
import RoutesGrid from "./RoutesGrid";
import DialogRoute from "./DialogRoute";
import DialogConfirm from "./DialogConfirm";

import { API_Map } from "apihandler/Map";
import { PAGE_ACTIONS } from "actions/PageActions";
import { commonJs } from "common/common";
import {
  ERRORS,
  LABELS,
  NOTIFY,
  ROLES,
  SUCCESS_MSG,
} from "common/consts";

import "./routestab.css";

class RoutesTab extends Component {
  routeSearch = (e) => {
    this._routesGrid.routeSearch(e.target.value.trim());
  };

  deleteRoute = (id, name = null) => {
    console.log("deleteRoute: ", id, name);
    if (!name) {
      let self = this;
      self.props.toggleLoading({
        loadingPopover: true,
      });
      API_Map.deleteRoute("/" + id)
        .then((res) => {
          if (res.Result === 200) {
            commonJs.showNotify(
              self.props,
              NOTIFY.SUCCESS,
              SUCCESS_MSG.ROUTE_DELETED_SUCCESSFULLY,
              <button className="snackbar-btn">{LABELS.DISMISS}</button>
            );
            self._dialogConfirm.toggleConfirmDialog(id, name);
            self.props.toggleLoading({
              loadingPopover: false,
            });
            self.reloadRoutes();
          } else {
            console.log("Delete Point Error: ", res.Reason);
            commonJs.showNotify(
              self.props,
              NOTIFY.ERROR,
              ERRORS.ROUTE_NOT_DELETED,
              <button className="snackbar-btn">{LABELS.DISMISS}</button>
            );
            self.props.toggleLoading({
              loadingPopover: false,
            });
          }
        })
        .catch((err) => {
          console.log("Delete Point Error: ", err);
          commonJs.showNotify(
            self.props,
            NOTIFY.ERROR,
            ERRORS.ROUTE_NOT_DELETED,
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
          self.props.toggleLoading({
            loadingPopover: false,
          });
        });
    } else {
      this._dialogConfirm.toggleConfirmDialog(id, name);
    }
  };

  deleteAll = () => {
    this._dialogConfirm.toggleConfirmDialog("");
  };

  reloadRoutes = () => {
    this._routesGrid.reloadRoutes();
  };

  render() {
    const { role } = this.props.loginReducer;
    return (
      <div className="pointsTab">
        <ul>
          <li>
            <SearchButton
              id="routeSearch"
              placeholder="Search By Route Name"
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
              onChange={this.routeSearch}
            />
          </li>
          <li className="poiBtn">
            <Tooltip title="Reload" placement="bottom">
              <IconButton onClick={this.reloadRoutes}>
                <Reload />
              </IconButton>
            </Tooltip>
          </li>
          {role !== ROLES.SUB_USER && (
            <li className="poiBtn">
              <Tooltip title="Add Point" placement="bottom">
                <IconButton
                  onClick={() =>
                    this._routeDialog.toggleAddRouteDialog({ add: true })
                  }
                >
                  <Add />
                </IconButton>
              </Tooltip>
            </li>
          )}
          {/* <li className="poiBtn">
            <Tooltip title="Delete" placement="bottom">
              <IconButton onClick={this.deleteAll}>
                <Delete />
              </IconButton>
            </Tooltip>
          </li> */}
        </ul>

        <RoutesGrid
          childRef={(ref) => (this._routesGrid = ref)}
          toggleAddRouteDialog={(route) =>
            this._routeDialog.toggleAddRouteDialog(route)
          }
          deleteRoute={this.deleteRoute}
        />

        <DialogRoute
          childRef={(ref) => (this._routeDialog = ref)}
          updateMapRegion={this.props.updateMapRegion}
          reloadRoutes={this.reloadRoutes}
        />

        <DialogConfirm
          childRef={(ref) => (this._dialogConfirm = ref)}
          onOk={this.deleteRoute}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_POPOVER_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(RoutesTab));
