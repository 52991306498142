import React, { Component } from "react";
import { TextField, Switch, FormControlLabel } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import FleetDialog from "../../ui/dialogbox/FleetDialog";
import AutoComplete from "../../ui/autocomplete/AutoComplete";
import UiDatePicker from "components/ui/uidatepicker/UiDatePicker";

import { commonJs } from "../../../common/common";
import { NOTIFY, LABELS, FORMATS } from "../../../common/consts";
import { dateFunction } from "common/datefunctions";
import { PAGE_ACTIONS } from "actions/PageActions";
import { API_Settings } from "apihandler/Settings";

class DialogAddVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recordId: "",
      vehicleName: "",
      isActive: true,
      selectedGroup: {
        name: LABELS.UNGROUPED,
        id: 0,
      },
      fdt: dateFunction.getDate("thismonth", "from", "YYYY-MM-DD HH:mm:ss"),
      tdt: dateFunction.getDate("thisyear", "to", "YYYY-MM-DD HH:mm:ss"),
      record: {},
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  saveRecord = () => {
    const { selectedGroup, fdt, tdt, record } = this.state;
    const { uid } = this.props.loginReducer;
    const vehicleName = document.getElementById("vehicleName").value.trim();
    const vehicleImei = +document.getElementById("vehicleImei").value.trim();
    const vehicleSimNum = +document
      .getElementById("vehicleSimNum")
      .value.trim();
    const isActive = document.getElementById("isActive").checked;

    // Form Validation
    if (vehicleName === "") {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Vehicle Name should not be empty..!"
      );
      return false;
    } else if (vehicleImei === 0) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "IMEI No. should not be empty..!"
      );
      return false;
    } else if (isNaN(vehicleImei)) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "IMEI No. should be number..!"
      );
      return false;
    } else if (isNaN(vehicleSimNum)) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "SIM No. should be number..!"
      );
      return false;
    }

    let args = {
      add: record.imei ? false : true,
      managerId: uid,
      name: vehicleName,
      imei: vehicleImei,
      simNumber: vehicleSimNum,
      groupId: selectedGroup.id,
      active: isActive,
      accessFrom: fdt,
      accessTo: tdt,
    };
    const self = this;
    self.props.setLoading({
      loadingPopover: true,
    });
    API_Settings.addVehicle(args).then((res) => {
      self.props.setLoading({
        loadingPopover: false,
      });
      if (res.Result === 200) {
        commonJs.showNotify(
          self.props,
          NOTIFY.SUCCESS,
          args.add
            ? "Vehicle added successfully..!"
            : "Vehicle updated successfully..!",
          commonJs.getDefaultDismissBtn()
        );
        self.props.reloadGrid();
        self.props.togglePopup({});
      } else {
        commonJs.showNotify(
          self.props,
          NOTIFY.ERROR,
          "Error: " + res.Reason,
          commonJs.getDefaultDismissBtn()
        );
      }
    });
  };

  onChangeGroup = (val) => {
    this.setState({
      selectedGroup: val,
    });
  };

  setData = (rec) => {
    const { userGroups } = this.props.mapReducer;
    let selectedGroup = {
      id: 0,
      name: LABELS.UNGROUPED,
    };
    const groupId = Number(rec.groupId);
    if (userGroups[groupId]) {
      selectedGroup = {
        id: groupId,
        name: userGroups[groupId].name,
      };
    }
    this.setState({
      recordId: rec.imei,
      vehicleName: rec.vehicleName,
      isActive: rec.active,
      record: rec,
      fdt: rec.activeFrom,
      tdt: rec.activeTill,
      selectedGroup,
    });
  };

  onDateChange = (type, dt) => {
    let dtformat = "YYYY-MM-DD HH:mm";
    dt = dateFunction.getFormatedDate(dt, FORMATS.SQL_DATE, dtformat);
    if (type === "from") {
      dt += ":00";
    } else {
      dt += ":59";
    }
    if (type === "from") {
      this.setState({
        fdt: dt,
      });
    } else {
      this.setState({
        tdt: dt,
      });
    }
  };

  render() {
    const {
      vehicleName,
      isActive,
      selectedGroup,
      recordId,
      fdt,
      tdt,
      record,
    } = this.state;

    const { showDialog, mapReducer } = this.props;

    const { userGroups } = mapReducer;

    const groups = [{ id: 0, name: LABELS.UNGROUPED }];
    for (let gId in userGroups) {
      groups.push({
        id: Number(gId),
        name: userGroups[gId].name,
      });
    }

    return (
      showDialog && (
        <FleetDialog
          id="addVehicle"
          open={true}
          onCloseSettings={this.props.togglePopup}
          title={recordId ? "Edit Vehicle" : "Add Vehicle"}
          maxWidth="xs"
          fullWidth={false}
          cancelButton={true}
          saveButton={true}
          cancelAction={this.props.togglePopup}
          saveAction={this.saveRecord}
          saveBtnLabel="OK"
          disableBackdropClick={false}
          disableBackdrop={false}
          classNameBody="zonePopupBody"
          classNameWrapper="zonePopupWrapper settingsDialogBox"
          draggable
        >
          <div className="grid">
            <div className="row">
              <TextField
                id="vehicleName"
                label="Vehicle Name"
                classes={{ root: "txtfield" }}
                autoFocus={true}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={vehicleName}
              />
            </div>
            <div className="row">
              <TextField
                id="vehicleImei"
                label="IMEI"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={record.imei || ""}
                disabled={recordId === "" ? false : true}
              />
            </div>
            <div className="row">
              <TextField
                id="vehicleSimNum"
                label="SIM Number"
                classes={{ root: "txtfield" }}
                variant="outlined"
                size="small"
                onMouseDown={(e) => e.stopPropagation()}
                defaultValue={record.simNumber || ""}
              />
            </div>
            <div className="row">
              <AutoComplete
                label={"Group"}
                list={groups}
                id="addVehicleGroups"
                style={{ marginBottom: 15, width: "100%" }}
                onChange={this.onChangeGroup}
                defaultValue={selectedGroup}
                multiple={false}
                size="small"
              />
            </div>
            <div className="row" style={{ marginBottom: 15 }}>
              <UiDatePicker
                label="Active From:"
                dt={fdt}
                type="from"
                disablePast={false}
                disableFuture={true}
                disabled={false}
                datepicker={false}
                handleDateChange={this.onDateChange}
              />
            </div>
            <div className="row" style={{ marginBottom: 15 }}>
              <UiDatePicker
                label="Active To:"
                dt={tdt}
                type="to"
                disablePast={false}
                disableFuture={false}
                disabled={false}
                datepicker={false}
                handleDateChange={this.onDateChange}
              />
            </div>
            <div
              className="row"
              style={{ marginTop: -5, justifyContent: "flex-start" }}
            >
              <FormControlLabel
                control={
                  <Switch
                    classes={{
                      thumb: "switchThumb",
                      track: "switchTrack",
                      root: "switchRoot",
                    }}
                    id="isActive"
                    defaultChecked={isActive}
                  />
                }
                label="Is Active"
                className="switch"
              />
            </div>
          </div>
        </FleetDialog>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_POPOVER_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DialogAddVehicle));
