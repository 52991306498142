import React, { Component } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { Replay as Reload, Add, Delete } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

import SearchButton from "components/ui/searchbutton/SearchButton";
import ConfirmDialog from "components/ui/confirmdialog/ConfirmDialog";

import MainGroupsGrid from "./MainGroupsGrid";
import DialogMainGroup from "./DialogMainGroup";

import { API_Settings } from "apihandler/Settings";
import { commonJs } from "common/common";
import { NOTIFY, SUCCESS_MSG } from "common/consts";

class MainGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      popupDialog: false,
      showDialog: true,
      groups: [],
    };
  }

  componentDidMount() {
    this.getAllGroups();
  }

  componentWillUnmount() {}

  getAllGroups = () => {
    const args = {
      pageNo: 1,
      pageLength: 1000,
    };
    const self = this;
    API_Settings.getAllUserGroups(args).then((res) => {
      if (res.Result === 200) {
        const groups = res.Response.map((g) => {
          return {
            id: g.id,
            name: g.name,
          };
        });
        self.setState({
          groups,
        });
      }
    });
  };

  reloadGrid = () => {
    this._grid.loadReloadRecords();
  };

  onSearch = (e) => {
    let val = e.target.value.trim();
    this._grid.onSearch(val);
  };

  togglePopup = (rec) => {
    const { popupDialog } = this.state;
    if (rec.id) {
      let self = this;
      this.setState({
        popupDialog: !popupDialog,
        showDialog: false,
      });
      setTimeout(() => {
        if (self._popupDialog) {
          self._popupDialog.setData(rec);
        }
        self.setState({
          showDialog: true,
        });
      }, 5000);
    } else {
      this.setState({
        popupDialog: !popupDialog,
        showDialog: true,
      });
    }
  };

  onDeleteRecord = (id, name) => {
    this._confirmDialog.toggleConfirmDialog(id, name);
  };

  onDeleteSuccess = (id) => {
    this.setState({
      showDialog: false,
    });
    const self = this;
    const args = {
      mainGroupId: id,
    };
    API_Settings.deleteMainGroup(args)
      .then((res) => {
        self.setState({
          showDialog: true,
        });
        if (res.Result === 200) {
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            SUCCESS_MSG.RECORD_DELETED_SUCCESSFULLY,
            <button className="snackbar-btn">{"dismiss"}</button>
          );
          self._confirmDialog.toggleConfirmDialog("close");
          self.reloadGrid();
        } else {
          commonJs.showNotify(
            self.props,
            NOTIFY.ERROR,
            "Error: " + res.Reason,
            <button className="snackbar-btn">{"dismiss"}</button>
          );
        }
      })
      .catch((err) => {
        console.log("Not");
      });
  };

  setShowDialog = (showDialog = false) => {
    this.setState({
      showDialog,
    });
  };

  render() {
    const { loading, popupDialog, showDialog, groups } = this.state;
    return (
      <div id="sharedVehiclesGrid" className="settingsGrid">
        <ul>
          <li className="searchDiv">
            <SearchButton
              id="sharedVehiclesSearch"
              placeholder="Search By Group Name"
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
              onChange={this.onSearch}
            />
          </li>
          <li>
            <Tooltip title="Reload" placement="bottom">
              <IconButton onClick={this.reloadGrid}>
                <Reload />
              </IconButton>
            </Tooltip>
          </li>
          <li title="Add Vehicle">
            <Tooltip title="Add Vehicle" placement="bottom">
              <IconButton onClick={this.togglePopup}>
                <Add />
              </IconButton>
            </Tooltip>
          </li>
          {/* <li>
            <Tooltip title="Delete All Vehicles" placement="bottom">
              <IconButton onClick={() => this.onDeleteRecord("all")}>
                <Delete />
              </IconButton>
            </Tooltip>
          </li> */}
        </ul>

        {!loading ? (
          <MainGroupsGrid
            childRef={(ref) => (this._grid = ref)}
            togglePopupDialog={this.togglePopup}
            onDeleteRecord={this.onDeleteRecord}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: window.innerHeight / 1.5,
              alignItems: "center",
            }}
          >
            <p style={{ color: "#a1a1a1", fontSize: 14 }}>Loading...</p>
          </div>
        )}

        {popupDialog && (
          <DialogMainGroup
            childRef={(ref) => (this._popupDialog = ref)}
            togglePopup={this.togglePopup}
            showDialog={showDialog}
            groups={groups}
            reloadGrid={this.reloadGrid}
            setShowDialog={this.setShowDialog}
          />
        )}

        {!showDialog && (
          <div className="popover-loading">
            <CircularProgress className={"primary"} />
          </div>
        )}

        <ConfirmDialog
          childRef={(ref) => (this._confirmDialog = ref)}
          onOk={this.onDeleteSuccess}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(MainGroups));
