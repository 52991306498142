import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import FleetDialog from "../../ui/dialogbox/FleetDialog";
import UiDatePicker from "../../ui/uidatepicker/UiDatePicker";
import AutoComplete from "../../ui/autocomplete/AutoComplete";

import { dateFunction } from "../../../common/datefunctions";
import { FORMATS, LABELS, NOTIFY } from "../../../common/consts";
import { commonJs } from "../../../common/common";
import { CPANEL_ACTIONS } from "../../../actions/CpanelActions";
import { API_CPanel } from "../../../apihandler/CPanel";

class AddUserVehicleDialog extends Component {
  constructor(props) {
    super(props);
    const { record, userInfo } = this.props;
    let selectedVehicle = [];
    if (record.id) {
      selectedVehicle = { id: record.imei, name: record.vehicleName };
    }
    this.state = {
      accessFrom: dateFunction.getDate("thismonth", "from", FORMATS.SQL_DATE),
      accessTo: dateFunction.getDate("thismonth", "to", FORMATS.SQL_DATE),
      users: [userInfo],
      selectedUser: userInfo,
      selectedVehicle: selectedVehicle,
    };
  }

  componentDidMount() {
    let self = this;
  }

  onDateChange = (type, val) => {
    if (type === "from") {
      this.setState({
        accessFrom: val,
      });
    } else {
      this.setState({
        accessTo: val,
      });
    }
  };

  onChangeUser = (val) => {
    this.setState({
      selectedUser: val,
    });
  };

  onChangeVehicle = (val) => {
    this.setState({
      selectedVehicle: val,
    });
  };

  saveRecord = () => {
    const { accessFrom, accessTo, selectedUser, selectedVehicle } = this.state;
    let imeis = [];
    let self = this;

    if (selectedVehicle.length) {
      imeis = selectedVehicle.map((v) => {
        return v.id;
      });
    } else if (selectedVehicle.id) {
      imeis.push(selectedVehicle.id);
    }

    if (imeis.length === 0) {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        "Vehicle Name should not be empty..!"
      );
      return false;
    }

    let args = {
      accessFrom,
      accessTo,
      userId: selectedUser.id,
      imeis,
    };

    this.props.setLoading({
      loading: true,
    });

    console.log("shareVehicle: ", args);

    API_CPanel.shareVehicle(args).then((res) => {
      if (res.Result === 200) {
        commonJs.showNotify(
          self.props,
          NOTIFY.SUCCESS,
          "Vehicle saved successfully..!",
          <button className="snackbar-btn">{LABELS.DISMISS}</button>
        );
        self.props.closeAddVehicleDialog();
        self.props.setLoading({
          loading: false,
        });
      } else {
        commonJs.showNotify(self.props, NOTIFY.ERROR, res.Reason);
      }
    });
  };

  render() {
    const {
      accessFrom,
      accessTo,
      users,
      selectedUser,
      selectedVehicle,
    } = this.state;

    const { record, vehicles } = this.props;

    console.log(
      "userAddVehicleDialog: ",
      record,
      selectedUser,
      selectedVehicle,
      vehicles
    );

    return (
      <FleetDialog
        id="userAddVehicleDialog"
        open={true}
        onCloseSettings={this.props.closeAddVehicleDialog}
        title={"Assign Vehicle To User"}
        maxWidth="md"
        fullWidth={true}
        cancelButton={true}
        cancelAction={this.props.closeAddVehicleDialog}
        saveButton={true}
        saveAction={this.saveRecord}
        saveBtnLabel="OK"
        disableBackdropClick={true}
        disableBackdrop={true}
        classNameBody="zonePopupBody"
        classNameWrapper="zonePopupWrapper"
        draggable={true}
      >
        <div className="grid">
          <div className="row">
            <AutoComplete
              label={"Selected User"}
              list={users}
              style={{ marginBottom: 15, width: "100%" }}
              onChange={this.onChangeUser}
              defaultValue={selectedUser}
              multiple={false}
              disabled={true}
              size="small"
            />
          </div>
          <div className="row">
            <UiDatePicker
              label="Access From"
              dt={accessFrom}
              type="from"
              disablePast={false}
              disableFuture={false}
              disabled={false}
              datepicker={false}
              // maxDate={dateFunction.getDate("thismonth", "from")}
              handleDateChange={(type, dt) => this.onDateChange("from", dt)}
            />
          </div>
          <div className="row" style={{ marginTop: 20, marginBottom: 10 }}>
            <UiDatePicker
              label="Access To"
              dt={accessTo}
              type="to"
              disablePast={false}
              disableFuture={false}
              disabled={false}
              datepicker={false}
              // maxDate={dateFunction.getDate("thismonth", "from")}
              handleDateChange={(type, dt) => this.onDateChange("to", dt)}
            />
          </div>
          <div className="row">
            <AutoComplete
              label={"Vehicle"}
              list={vehicles}
              style={{ marginBottom: 15, marginTop: 15, width: "100%" }}
              onChange={this.onChangeVehicle}
              defaultValue={selectedVehicle}
              multiple={record.id ? false : true}
              disabled={record.id ? true : false}
              size="small"
            />
          </div>
        </div>
      </FleetDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (values) => {
      dispatch({
        type: CPANEL_ACTIONS.SET_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(AddUserVehicleDialog));
