import { CPANEL_ACTIONS } from "../actions/CpanelActions";

let initialState = {
  loading: true,
};

const cpanelReducer = (state = initialState, action) => {
  const { type, values } = action;
  switch (type) {
    case CPANEL_ACTIONS.SET_LOADING:
      return { ...state, loading: values.loading };
    default:
      break;
  }
  return state;
};

export default cpanelReducer;
