import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { COLORS, UNITS } from "common/consts";

class RoundTripGraph extends Component {
  render() {
    const { data } = this.props;
    const options = {
      chart: {
        zoomType: "x",
        height: "450px",
      },
      credits: false,
      title: {
        text: "",
      },
      subtitle: {
        text:
          document.ontouchstart === undefined
            ? "Click and drag in the plot area to zoom in"
            : "Pinch the chart to zoom in",
      },
      xAxis: {
        type: "datetime",
      },
      yAxis: {
        title: {
          text: "Temperature in " + UNITS.TEMP,
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        area: {
          keys: ["x", "y", "marker"],
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, COLORS.PRIMARY],
              [1, "#FFF"],
            ],
          },
          marker: {
            radius: 2,
          },
          lineWidth: 1,
          lineColor: COLORS.PRIMARY,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          threshold: null,
        },
      },
      series: [
        {
          type: "area",
          name: "Temperature",
          data: data,
          color: COLORS.PRIMARY,
        },
      ],
    };
    return (
      <HighchartsReact
        ref={(ref) => (this.chart = ref)}
        highcharts={Highcharts}
        options={options}
        style={{ height: "100%", width: "100%" }}
      />
    );
  }
}

export default RoundTripGraph;
