import React, { Component } from "react";
import {
  Popover,
  Button,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
} from "@material-ui/core";
import { MoreVert as MenuBarIcon } from "@material-ui/icons";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { withSnackbar } from "notistack";

import GraphExportOptions from "../graphoptions/GraphExportOptions";

import { dateFunction } from "../../../../common/datefunctions";
import { API_Reports } from "../../../../apihandler/Reports";
import { FORMATS, NOTIFY, LABELS } from "../../../../common/consts";
import { commonJs } from "../../../../common/common";
import { COMMON_ACTIONS } from "../../../../actions/CommonActions";
import { connect } from "react-redux";

class KmGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphData: [],
      loading: true,
      totalKms: null,
      fdt: dateFunction.getDate("yesterday", "from", FORMATS.SQL_DATE),
      tdt: dateFunction.getDate("yesterday", "to", FORMATS.SQL_DATE),
      showExportFiter: false,
      showMenuFiter: false,
    };
    this.chart = null;
    this._menus = [
      { name: "More than 250 KM", id: 0 },
      {
        name: "100 to 250 KM",
        id: 1,
      },
      {
        name: "10 to 100 KM",
        id: 2,
      },
      {
        name: "Upto 10 KM",
        id: 3,
      },
    ];
    this.list = [
      { name: "More than 250 KM", data: [0] },
      {
        name: "100 to 250 KM",
        data: [0],
      },
      {
        name: "10 to 100 KM",
        data: [0],
      },
      {
        name: "Upto 10 KM",
        data: [0],
      },
    ];
    this.records = [];
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
    this.getKmData(this.props.commonReducer.vehiclesList);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.commonReducer.vehiclesList.length !==
      this.props.commonReducer.vehiclesList.length
    ) {
      this.getKmData(nextProps.commonReducer.vehiclesList);
      return true;
    } else if (nextState.graphData.length !== this.state.graphData.length) {
      return true;
    } else if (
      nextState.showExportFiter !== this.state.showExportFiter ||
      nextState.showMenuFiter !== this.state.showMenuFiter
    ) {
      return true;
    }
    return false;
  }

  getKmData = (vehicles = []) => {
    const { fdt, tdt } = this.state;
    let args = {
      fromDate: fdt,
      toDate: tdt,
      imeis: vehicles.map((v) => {
        return v.imei;
      }),
    };
    if (args.imeis.length > 0) {
      let self = this;
      API_Reports.getReport("currentmonth", args)
        .then((res) => {
          if (res.Result === 200) {
            let rows = res.Response.rows;
            self.records = rows;
            for (let i = 0; i < rows.length; i++) {
              let kms = Number(rows[i][2]);
              if (kms <= 10) {
                self.list[3].data[0]++;
              } else if (kms <= 100) {
                self.list[2].data[0]++;
              } else if (kms <= 250) {
                self.list[1].data[0]++;
              } else {
                self.list[0].data[0]++;
              }
            }
            self.setState({
              graphData: self.list,
            });
          }
        })
        .catch((err) => {
          self.setState({
            loading: false,
          });
        });
    }
  };

  exportChart = (type = "image/png", mode = "p", width = 100) => {
    const { fdt } = this.state;

    // POST parameter for Highcharts export server
    let data = {
      type: type,
    };

    let ext = type.split("/");
    if (ext[1]) {
      ext = ext[1];
    } else {
      ext = "pdf";
    }

    let title =
      "Yesterday's Performance Graph - " +
      dateFunction.getFormatedDate(
        fdt,
        FORMATS.SQL_DATE,
        FORMATS.REPORT_TITLE_DATE
      ) +
      "." +
      ext;

    let target = document.querySelector(
      "div#reportDashLeft #reportDashYesterdayGraph .highcharts-container"
    );
    let self = this;
    API_Reports.exportHighchart(data, title, target, mode, width)
      .then((res) => {
        if (type !== "print") {
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            "Ready to download...",
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
        }
      })
      .catch((err) => {
        commonJs.showNotify(
          self.props,
          NOTIFY.ERROR,
          "Something went wrong, please try again..!",
          <button className="snackbar-btn">{LABELS.DISMISS}</button>
        );
      });
  };

  toggleFilter = (type = "export") => {
    const { showExportFiter, showMenuFiter } = this.state;
    if (type === "export") {
      this.setState({
        showExportFiter: !showExportFiter,
      });
    } else if (type === "menu") {
      this.setState({
        showMenuFiter: !showMenuFiter,
      });
    } else {
      this.setState({
        showMenuFiter: false,
        showExportFiter: false,
      });
    }
  };

  onGraphFilter = (id) => {
    this.props.onGraphFilter(id);
    this.setState({
      showMenuFiter: false,
      showExportFiter: false,
    });
  };

  render() {
    const { graphData, showExportFiter, showMenuFiter } = this.state;

    console.log("toggleFilter: ", showExportFiter, showMenuFiter);

    const title = "";
    const options = {
      chart: {
        type: "column",
        marginLeft: 30,
        marginRight: 0,
        marginTop: 30,
        height: "300px",
      },
      credits: false,
      title: {
        text: title,
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        categories: "",
      },
      yAxis: {
        min: 0,
      },
      tooltip: {
        headerFormat: "",
        pointFormat: "<p><b>{series.name}: {point.y}</b></p>",
      },
      exporting: {
        enabled: true,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: "none",
          },
        },
      },
      series: graphData,
    };

    return (
      <React.Fragment>
        <h4 className="subtitle">Yesterday's Performance</h4>
        <ClickAwayListener
          touchEvent="onTouchStart"
          onClickAway={this.toggleFilter}
        >
          <div className="chartFilter">
            {/* Export Menu */}
            <GraphExportOptions
              showExportFiter={showExportFiter}
              exportChart={this.exportChart}
              toggleFilter={this.toggleFilter}
            />

            {/* Graph Menu */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.toggleFilter("menu")}
              buttonRef={(node) => {
                this._anchorMenu = node;
              }}
            >
              <MenuBarIcon />
            </Button>
            <Popover
              className="graphMenuPopover"
              open={showMenuFiter}
              anchorEl={this._anchorMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={this.toggleFilter}
            >
              <List component="nav" className="dashboardFilterMenu">
                {this._menus.map((m, indx) => {
                  return (
                    <ListItem
                      button
                      onClick={() => this.onGraphFilter(m.id)}
                      className={""}
                      key={"graphYesterday-" + indx}
                    >
                      <ListItemText primary={m.name} />
                    </ListItem>
                  );
                })}
              </List>
            </Popover>
          </div>
        </ClickAwayListener>
        <HighchartsReact
          ref={(ref) => (this.chart = ref)}
          highcharts={Highcharts}
          options={options}
          style={{ height: 300 }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commonReducer: state.commonReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setVehicles: (values) => {
      dispatch({
        type: COMMON_ACTIONS.SET_VEHICLES,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(KmGraph));
