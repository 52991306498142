import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import FleetDropDown from "components/ui/dropdown/FleetDropDown";
import Btn from "components/ui/button/Btn";

import { LABELS, NOTIFY, SUCCESS_MSG, ERRORS } from "common/consts";
import { API_Settings } from "apihandler/Settings";
import { commonJs } from "common/common";
import { PAGE_ACTIONS } from "actions/PageActions";

import "../../../vehiclelist/vehiclelist.css";

class Accuracy extends Component {
  constructor(props) {
    super(props);
  }

  saveSettings = () => {
    const { imei } = this.props;
    const stops = document.getElementById("detectStopUsing").value;
    const minGpslev = document.getElementById("minGpsValue").value;
    const maxHdop = document.getElementById("accMaxHdop").value;
    const minFuelFillingsDiff = document.getElementById("accMinFuelDiff").value;
    const minFuelTheftsDiff = document.getElementById("accMinFuelThefts").value;
    const minMovingSpeed = document.getElementById("accMinMoveSpeed").value;
    const minDiffPoints = document.getElementById("accMinDrift").value;
    const args = {
      imei,
      stops,
      minMovingSpeed,
      minDiffPoints,
      minGpslev,
      maxHdop,
      minFuelFillingsDiff,
      minFuelTheftsDiff,
    };
    const self = this;
    self.props.setLoading({
      loadingPopover: true,
    });
    API_Settings.editVehicleAccuracyDetails(args)
      .then((res) => {
        self.props.setLoading({
          loadingPopover: false,
        });
        if (res.Result === 200) {
          commonJs.showNotify(
            self.props,
            NOTIFY.SUCCESS,
            SUCCESS_MSG.RECORD_SAVED,
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
          self.props.loadVehicles();
        } else {
          commonJs.showNotify(
            self.props,
            NOTIFY.ERROR,
            ERRORS.RECORD_NOT_SAVED,
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
        }
        console.log("saveRegistrationForm:", res);
      })
      .catch((err) => {
        self.props.setLoading({
          loadingPopover: false,
        });
        console.log(err);
      });
  };

  render() {
    const { vehicleSettings, closePopup } = this.props;
    let accuracy = {
      stops: "gps",
      min_moving_speed: "6",
      min_diff_points: "0.0005",
      use_gpslev: false,
      min_gpslev: "5",
      use_hdop: false,
      max_hdop: "3",
      min_ff: "10",
      min_ft: "10",
    };
    if (vehicleSettings.accuracy.trim() != "") {
      accuracy = JSON.parse(vehicleSettings.accuracy);
    }
    return (
      <React.Fragment>
        <div className="row">
          <div className="width48" style={{ marginBottom: 20 }}>
            <FleetDropDown
              label="Detect stops using"
              list={[
                { id: "gps", text: "GPS" },
                { id: "acc", text: "ACC" },
                { id: "gpsacc", text: "GPS + ACC" },
              ]}
              selectedValue={accuracy.stops}
              noemptySelection={true}
              id="detectStopUsing"
            />
          </div>
          <div className="width48">
            <TextField
              id="minGpsValue"
              label="Min. gpslev value (eliminates drifting, default 5)"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={accuracy.min_gpslev || "5"}
            />
          </div>
        </div>
        <div className="row"></div>
        <div className="row">
          <div className="width48">
            <TextField
              id="accMaxHdop"
              label="Max. hdop value (eliminates drifting, default 3)"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={accuracy.max_hdop || "3"}
            />
          </div>
          <div className="width48">
            <TextField
              id="accMinFuelDiff"
              label="Min. fuel difference to detect fuel fillings (default 10)"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={accuracy.min_ff || "10"}
            />
          </div>
        </div>
        <div className="row"></div>
        <div className="row">
          <div className="width48">
            <TextField
              id="accMinFuelThefts"
              label="Min. fuel difference to detect fuel thefts (default 10)"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={accuracy.min_ft || "10"}
            />
          </div>
        </div>
        <div className="row">
          <div className="width100">
            <TextField
              id="accMinMoveSpeed"
              label="Min. moving speed in km/h (affects stops and track accuracy, default 6)"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={accuracy.min_moving_speed || "6"}
            />
          </div>
        </div>
        <div className="row">
          <div className="width100">
            <TextField
              id="accMinDrift"
              label="Min. difference between track points (eliminates drifting, default 0.0005)"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={accuracy.min_diff_points || "0.0005"}
            />
          </div>
        </div>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              width: "30%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Btn className="settingsBtn saveBtn" onClick={this.saveSettings}>
              {"save"}
            </Btn>
            <Btn
              className="settingsBtn closeBtn"
              onClick={() => closePopup(false)}
            >
              {"close"}
            </Btn>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_POPOVER_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Accuracy));
