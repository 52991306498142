import React, { Component } from "react";

class Img extends Component {
  render() {
    return (
      <React.Fragment>
        <img
          src={this.props.src}
          width={this.props.width || "100%"}
          height={this.props.height || "auto"}
          className={this.props.className || ""}
          alt=""
        />
      </React.Fragment>
    );
  }
}

export default Img;
