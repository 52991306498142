import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

//Leaflet
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";

import "react-leaflet-markercluster/dist/styles.min.css";
import "leaflet/dist/leaflet.css";

// Custom CSS
import "./styles/fleetmap.css";

import { DEFAULTS, NOTIFY, ALERTS } from "../../../common/consts";
import { MAP_ACTIONS } from "../../../actions/MapActions";
import { API_Map } from "../../../apihandler/Map";
import { COMMON_ACTIONS } from "../../../actions/CommonActions";
import { PAGE_ACTIONS } from "../../../actions/PageActions";
import { commonJs } from "../../../common/common";
import FleetMarker from "./Marker";

const createClusterCustomIcon = (cluster) => {
  const count = cluster.getChildCount();
  let size = "LargeXL";
  if (count < 10) {
    size = "Small";
  } else if (count > 9 && count < 100) {
    size = "Medium";
  } else if (count > 99 && count < 1000) {
    size = "Large";
  }
  const options = {
    cluster: `markerCluster${size}`,
  };
  return L.divIcon({
    html: `<div>
        <span class="markerClusterLabel">${count}</span>
      </div>`,
    className: `${options.cluster}`,
  });
};

class FleetMapMarkers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this._timer = null;
  }

  componentDidMount = () => {
    var self = this;
    this.loadVehicles(true);
    this._timer = setInterval(() => {
      self.loadVehicles();
    }, DEFAULTS.VEHICLES_TIMER * 1000);
  };

  componentWillUnmount() {
    clearInterval(this._timer);
  }

  loadVehicles = async (reset = false) => {
    const self = this;
    const opts = {};
    opts.pageNo = 0;
    opts.pageLength = 100000;
    opts.orderBy = "ASC";
    opts.sortColumn = "";
    API_Map.getAllVehicles(opts)
      .then((res) => {
        self.props.setVehicles({
          vehicles: res,
        });
        // Save Temporary Copy
        if (reset === true) {
          // Update User Groups Count
          const { userGroups } = self.props.mapReducer;
          let groups = {};
          for (let gId in userGroups) {
            groups[gId] = userGroups[gId];
            groups[gId].vcount = 0;
          }
          for (let i = 0; i < res.length; i++) {
            if (groups[res[i].groupId]) {
              groups[res[i].groupId].vcount++;
            }
          }
          self.props.setTmpVehicles({
            vehicles: res,
          });
          self.props.setUserGroups({
            userGroups: groups,
          });
          self.props.setMapLoading({ loading: false });
        }
      })
      .catch((err) => {
        self.props.setMapLoading({ loading: false });
        err = err.toString();
        if (err.indexOf("Network Error") > -1) {
          commonJs.showNotify(self.props, NOTIFY.WARNING, ALERTS.NO_INTERNET);
        }
        // window.location = "/";
      });
  };

  loadMarkers = () => {
    const { tmpVehicles, filteredVehicles } = this.props.mapReducer;
    const vehiclesList =
      filteredVehicles.length > 0 ? filteredVehicles : tmpVehicles;

    const {
      signalFilter,
      loadStatusFilter,
      showTrack,
      shareVehicle,
    } = this.props;

    const vlist = commonJs.getVehicleFilteredList(
      vehiclesList,
      signalFilter,
      loadStatusFilter
    );

    return vlist.map((vehicle, indx) => {
      return (
        <FleetMarker
          imei={vehicle.imei}
          key={"marker-" + vehicle.imei + "-" + indx}
          showTrack={showTrack}
          shareVehicle={shareVehicle}
        />
      );
    });
  };

  render() {
    const { showClusters, mapVehicles } = this.props;

    const { markerAnimation } = this.props.mapReducer;

    // return mapVehicles && showClusters
    //   ? this.loadMarkers()
    //   : mapVehicles
    //   ? this.loadMarkers()
    //   : null;

    return mapVehicles && showClusters && !markerAnimation ? (
      <MarkerClusterGroup
        iconCreateFunction={createClusterCustomIcon}
        showCoverageOnHover={true}
      >
        {this.loadMarkers()}
      </MarkerClusterGroup>
    ) : mapVehicles ? (
      this.loadMarkers()
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserGroups: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_GROUPS,
        values,
      });
    },
    setSelectedVehicle: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_SELECTED_VEHICLE,
        values,
      });
    },
    setMapPosition: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_MAP_POSITION,
        values,
      });
    },
    setVehicles: (values) => {
      dispatch({
        type: COMMON_ACTIONS.SET_VEHICLES,
        values,
      });
    },
    setTmpVehicles: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_TMP_VEHICLES,
        values,
      });
    },
    setMapLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_MAP_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(FleetMapMarkers));
