import React, { Component } from "react";
import { Tabs, Tab, IconButton } from "@material-ui/core";
import {
  KeyboardArrowDown as ArrowDown,
  KeyboardArrowUp as ArrowUp,
  CloseRounded as CloseIcon,
} from "@material-ui/icons";
import { connect } from "react-redux";

import TrackGraph from "./TrackGraph";
import TrackMessages from "./TrackMessages";
import TrackControls from "./TrackControls";
import TrackInfo from "./TrackInfo";

import { PAGE_ACTIONS } from "actions/PageActions";
import { TRACK_ACTIONS } from "actions/TrackActions";
import { ROLES } from "common/consts";

class TrackBottomBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      hideBar: false,
    };
  }

  toggleTab = (event, value) => {
    this.setState({ activeTab: value });
  };

  toggleHideBar = () => {
    const { hideBar } = this.state;
    this.setState({
      hideBar: !hideBar,
    });
  };

  toggleTrackBar = () => {
    const { trackBottomBar } = this.props.pageReducer;
    this.props.toggleTrackBar({ trackBottomBar: !trackBottomBar });
    // this.props.setTrackData({
    //   routeDetails: [],
    //   routePoints: [],
    //   routeSummary: [],
    //   routeParkings: [],
    // });
  };

  render() {
    const { pageReducer } = this.props;
    const { activeTab, hideBar } = this.state;
    const { trackBottomBar } = pageReducer;
    const { role } = this.props.loginReducer;
    const showMessages = role === ROLES.SUPER_ADMIN || role === ROLES.ADMIN;
    return (
      trackBottomBar && (
        <div id="trackBottomContainer">
          <div id="trackPlaybackControls">
            <TrackControls updateMapRegion={this.props.updateMapRegion} />
          </div>
          <div id="trackbarCloseBtn" title={hideBar ? "Maximize" : "Minimize"}>
            <IconButton aria-label="hideunhide" onClick={this.toggleHideBar}>
              {hideBar ? <ArrowUp /> : <ArrowDown />}
            </IconButton>
            <IconButton aria-label="close" onClick={this.toggleTrackBar}>
              <CloseIcon />
            </IconButton>
          </div>
          <TrackInfo />
          <Tabs
            value={activeTab}
            onChange={this.toggleTab}
            variant="fullWidth"
            scrollButtons="auto"
            className="trackBottomBarTab"
            classes={{
              indicator: "editVehicleTabIndicator",
              scrollButtons: "editVehicleNavTabButtons",
            }}
          >
            <Tab
              label={"Graph"}
              classes={{ root: "tab " + (activeTab === 0 ? "activeTab" : "") }}
            />
            {showMessages && (
              <Tab
                label={"Messages"}
                classes={{
                  root: "tab " + (activeTab === 1 ? "activeTab" : ""),
                }}
              />
            )}
          </Tabs>

          {activeTab === 0 && !hideBar && (
            <TrackGraph updateMapRegion={this.props.updateMapRegion} />
          )}

          {activeTab === 1 && !hideBar && <TrackMessages />}
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pageReducer: state.pageReducer,
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleTrackBar: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_TRACK_BOTTOM_BAR,
        values,
      });
    },
    setTrackData: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_TRACK_DATA,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackBottomBar);
