import React, { Component } from "react";
import { FormControl, InputLabel, Select } from "@material-ui/core";

class FleetDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.anchorRef = null;
  }

  handleOpen = () => {
    console.log("handleOpen");
  };

  handleClose = () => {
    console.log("handleClose");
  };

  handleChange = event => {
    if (this.props.onChange) {
      this.props.onChange(event.target.value);
    }
  };

  handleListKeyDown = () => {
    console.log("handleListKeyDown");
  };

  handleToggle = () => {
    console.log("handleToggle");
    const { open } = this.state;
    this.setState({
      open: !open
    });
  };

  render() {
    const { id, label, list, selectedValue, noemptySelection } = this.props;
    // const { open } = this.state;
    return (
      <FormControl variant="outlined" className={"dropdown"} size="small">
        <InputLabel ref={this._inputLabel} htmlFor="outlined-age-native-simple">
          {label}
        </InputLabel>
        <Select
          native
          onChange={this.handleChange}
          inputProps={{
            name: label,
            id: id
          }}
          size="small"
          defaultValue={selectedValue}
        >
          {!noemptySelection && <option value="">{""}</option>}
          {list.map(l => {
            return (
              <option value={l.id} key={"option-" + id + "-" + l.id}>
                {l.text}
              </option>
            );
          })}
        </Select>
      </FormControl>
    );
  }
}

export default FleetDropDown;
