import React, { Component } from "react";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

//Leaflet
import { Tooltip, Marker, Popup } from "react-leaflet";
import L from "leaflet";

import "react-leaflet-markercluster/dist/styles.min.css";
import "leaflet/dist/leaflet.css";

// Images
import markerIcon from "images/mapImages/markers/marker_yellow.png";

// Custom CSS
import "./styles/fleetmap.css";
import { dateFunction } from "../../../common/datefunctions";
import { FORMATS, LABELS } from "../../../common/consts";

class TmpMarkers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  getMapMarker = (angle, lat, lng) => {
    let iconUrl = markerIcon;
    let customMarker = null;
    lat = lat.toString().replace(".", "");
    lng = lng.toString().replace(".", "");
    const html =
      "<div class='tmpMarker' id='tmpMarker-" +
      lat +
      "-" +
      lng +
      "' style='transform: rotate(" +
      angle.toString() +
      "deg);'><img src='" +
      iconUrl +
      "' alt=''/></div>";

    customMarker = L.divIcon({
      html: html,
      iconSize: new L.Point(16, 16),
      iconAnchor: null,
      popupAnchor: [0, -3],
      className: "mapMarker",
    });

    return customMarker;
  };

  markerClick = () => {
    console.log("markerClick");
  };

  componentDidMount = () => {
    console.log("markers: mount tmp markers");
  };

  componentWillUnmount() {
    console.log("markers: Unmount tmp markers");
  }

  loadTmpMarker = () => {
    const { tmpMarker } = this.props.tmpReducer;
    const pos = [tmpMarker.lat, tmpMarker.lng];
    return (
      <Marker
        position={pos}
        icon={this.getMapMarker(
          tmpMarker.data.angle,
          tmpMarker.lat,
          tmpMarker.lng
        )}
        key={"tmpMarker"}
        onclick={this.markerClick}
        className="mapMarker tmpMarker"
      >
        <Tooltip direction="right" offset={[10, -2]} permanent>
          <span>
            {Number(tmpMarker.lat).toFixed(6) +
              "," +
              Number(tmpMarker.lng).toFixed(6)}
          </span>
        </Tooltip>
        <Popup>
          <div className="markerPopup tmpMarkerPopup">
            <table>
              <tbody>
                <tr>
                  <td colSpan="2" className="bold title">
                    {tmpMarker.data.title}
                  </td>
                </tr>
                <tr>
                  <td>Time</td>
                  <td>
                    <span className="bold">:</span>
                    &nbsp;&nbsp;&nbsp;
                    {dateFunction.getFormatedDate(
                      tmpMarker.data.time,
                      FORMATS.SQL_DATE,
                      FORMATS.DATE
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Position</td>
                  <td>
                    <span className="bold">:</span>
                    &nbsp;&nbsp;&nbsp;
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      title={LABELS.CLICK_HERE_TO_OPEN_GOOGLE_MAP}
                      href={
                        "https://maps.google.com/maps?q=" +
                        tmpMarker.lat +
                        "," +
                        tmpMarker.lng
                      }
                    >
                      {Number(tmpMarker.lat).toFixed(6) +
                        "°, " +
                        Number(tmpMarker.lng).toFixed(6) +
                        "°"}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Popup>
      </Marker>
    );
  };

  render() {
    const { tmpMarker } = this.props.tmpReducer;
    return tmpMarker.lng ? this.loadTmpMarker() : null;
  }
}

const mapStateToProps = (state) => {
  return {
    tmpReducer: state.tmpReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(TmpMarkers));
