import React, { Component } from "react";
import ColorPicker from "material-ui-color-picker";
import { TextField, Switch, FormControlLabel } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";

import FleetDialog from "components/ui/dialogbox/FleetDialog";

import { commonJs } from "common/common";
import {
  DEFAULTS,
  ERRORS,
  LABELS,
  NOTIFY,
  SUCCESS_MSG,
  WARNINGS,
} from "common/consts";
import { MAP_CONTROLS_ACTIONS } from "actions/MapControlsActions";
import { API_Map } from "apihandler/Map";
import { PAGE_ACTIONS } from "actions/PageActions";

class DialogIncident extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zone: {},
      showDialog: false,
      color: DEFAULTS.POI_ZONE_COLOR,
      zoneVisible: DEFAULTS.POI_ZONE_VISIBLE,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  setColor = (color) => {
    console.log("setColor:", color);
    document.getElementById("zoneColor").style.background = color;
    document.getElementById("zoneColor").value = color.toString();
    this.setState({
      color: color,
    });
  };

  toggleAddZoneDialog = (zone) => {
    const { showDialog } = this.state;
    if (zone.id === undefined) {
      if (zone.add) {
        console.log("Zone Add:  ", zone);
        this.setState(
          {
            showDialog: !showDialog,
            zone,
          },
          () => {
            setTimeout(() => {
              if (document.getElementById("zoneColor")) {
                document.getElementById("zoneColor").style.background =
                  DEFAULTS.POI_ZONE_COLOR;
              }
            }, 500);
          }
        );
        this.createPolygon();
      } else {
        console.log("Zone Close:  ");
        this.setState({
          showDialog: !showDialog,
        });
        this.closeDrawZone();
      }
    } else {
      console.log("Zone Edit:  ", zone);
      // Edit -> Open
      this.setState({
        showDialog: !showDialog,
        zone,
        color: zone.color || DEFAULTS.POI_ZONE_COLOR,
      });

      // Set Map Focus to the Zone
      let zoneVertices = [];
      for (let v = 0; v < zone.vertices.length; v++) {
        zoneVertices.push({
          lat: zone.vertices[v].latitude,
          lng: zone.vertices[v].longitude,
        });
      }
      this.props.setZoneEdit({
        zoneVertices: zoneVertices,
      });

      let self = this;
      setTimeout(() => {
        const { zone } = self.state;
        document.getElementById("zoneColor").style.background = zone.color;
        document.getElementById("zoneColor").value = zone.color.toString();
        if (zoneVertices.length > 0) {
          self.props.zoneFitBounds();
          self.editPolygon();
        }
        document
          .querySelector("div#addZone")
          .setAttribute("vertices", JSON.stringify(zoneVertices));
        // if (localStorage.map) {
        //   let mapJson = JSON.parse(localStorage.map);
        //   if (mapJson.zoom) {
        //     console.log(mapJson, self.props);
        //     self.props.updateMapRegion(
        //       mapJson.initialLat,
        //       mapJson.initialLng,
        //       mapJson.zoom
        //     );
        //   }
        // }
      }, 1000);
    }
  };

  createPolygon = () => {
    let e = document.createEvent("Event");
    e.initEvent("click", true, true);
    let cb = document.getElementsByClassName("leaflet-draw-draw-polygon");
    if (cb) {
      return !cb[0].dispatchEvent(e);
    }
  };

  editPolygon = () => {
    let e = document.createEvent("Event");
    e.initEvent("click", true, true);
    let cb = document.getElementsByClassName("leaflet-draw-edit-edit");
    console.log("Zone editPolygon");
    if (cb) {
      return !cb[0].dispatchEvent(e);
    }
  };

  closeDrawZone = () => {
    let e = document.createEvent("Event");
    e.initEvent("click", true, true);
    let cb = document.querySelector(
      'ul.leaflet-draw-actions li>a[title="Cancel drawing"]'
    );
    if (cb) {
      cb.dispatchEvent(e);
    }

    cb = document.querySelector(
      'ul.leaflet-draw-actions.leaflet-draw-actions-top>li>a[title="Cancel editing, discards all changes"]'
    );
    if (cb) {
      cb.dispatchEvent(e);
    }

    console.log("Zone closeDrawZone");

    this.clearLayers();
  };

  clearLayers = () => {
    let e = document.createEvent("Event");
    e.initEvent("click", true, true);
    let cb = document.querySelector(".leaflet-draw-edit-remove");
    if (cb) {
      cb.dispatchEvent(e);
    }

    let e2 = document.createEvent("Event");
    e2.initEvent("click", true, true);
    cb = document.querySelector(
      "ul.leaflet-draw-actions.leaflet-draw-actions-bottom li:last-child>a"
    );
    if (cb) {
      cb.dispatchEvent(e2);
    }

    this.props.setZoneEdit({
      zoneVertices: [],
    });

    console.log("Zone clearLayers");
  };

  saveDrawZone = () => {
    const { zone } = this.state;
    console.log("Zone saveDrawZone: ", zone);
    if (!zone.id) {
      // Add New
      let vertices = JSON.parse(
        document.querySelector("div#addZone").getAttribute("vertices")
      );
      console.log("Zone saveDrawZone: vertices: ", vertices);
      if (vertices === null) {
        let e = document.createEvent("Event");
        e.initEvent("click", true, true);
        let cb = document.querySelector(
          'ul.leaflet-draw-actions li>a[title="Finish drawing"]'
        );
        if (cb) {
          cb.dispatchEvent(e);
        }
      }
    } else {
      // On Edit
      let e = document.createEvent("Event");
      e.initEvent("click", true, true);
      let cb = document.querySelector(
        'ul.leaflet-draw-actions li>a[title="Save changes"]'
      );
      if (cb) {
        cb.dispatchEvent(e);
      }
      let vertices = document
        .querySelector("div#addZone")
        .getAttribute("vertices");
      if (vertices === "") {
        let zoneVertices = [];
        for (let v = 0; v < zone.vertices.length; v++) {
          zoneVertices.push({
            lat: zone.vertices[v].latitude,
            lng: zone.vertices[v].longitude,
          });
        }
        document
          .querySelector("div#addZone")
          .setAttribute("vertices", JSON.stringify(zoneVertices));
      }
    }
    let self = this;
    setTimeout(() => {
      self.addZone();
    }, 1000);
  };

  addZone = () => {
    let self = this;
    const zoneName = document.getElementById("zoneName").value.trim();
    const zoneTime = Number(document.getElementById("zoneTime").value);
    const zoneIntegrationId = document
      .getElementById("zoneIntegrationId")
      .value.trim();
    const zoneLoadingTime = Number(
      document.getElementById("zoneLoadingTime").value
    );
    const color = document.querySelector(".txtfield.colorpicker input").value;
    const isHub = document.getElementById("isHub").checked;
    const zoneVisible = document.getElementById("zoneVisible").checked;
    const nameVisible = document.getElementById("nameVisible").checked;
    let vertices = document
      .querySelector("div#addZone")
      .getAttribute("vertices");
    if (vertices === null || vertices === "") {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        WARNINGS.ZONE_IS_INCOMPLETE
      );
      return false;
    }

    console.log("Zone vertices: ", vertices);

    vertices = JSON.parse(vertices);

    if (zoneName === "") {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        WARNINGS.ZONE_NAME_SHOULD_NOT_BE_EMPTY
      );
      return false;
    }

    if (vertices === null) {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        WARNINGS.ZONE_IS_INCOMPLETE
      );
      return false;
    } else if (vertices.length < 3) {
      commonJs.showNotify(
        self.props,
        NOTIFY.WARNING,
        WARNINGS.ZONE_POINTS_SHOULD_BE_GREATER_THAN_2
      );
      return false;
    }

    vertices = vertices.map((v) => {
      return { latitude: v.lat, longitude: v.lng };
    });

    const args = {
      id: this.state.zone.id || 0,
      name: zoneName,
      time: zoneTime,
      integrationId: zoneIntegrationId,
      loadingTime: zoneLoadingTime,
      color,
      isHub: isHub.toString(),
      zoneVisible: zoneVisible.toString(),
      nameVisible: nameVisible.toString(),
      vertices,
    };

    console.log("addZone", args);

    this.props.toggleLoading({
      loadingPopover: true,
    });

    // Save Polygon
    if (args.id !== "") {
      API_Map.saveZone(args)
        .then((res) => {
          if (res.Result === 200) {
            commonJs.showNotify(
              self.props,
              NOTIFY.SUCCESS,
              SUCCESS_MSG.ZONE_SAVED_SUCCESSFULLY,
              <button className="snackbar-btn">{LABELS.DISMISS}</button>
            );
            self.toggleAddZoneDialog(args.id);
            self.props.toggleLoading({
              loadingPopover: false,
            });
            self.props.reloadZones();
            // Reset the layers
            self.clearLayers();
          } else {
            console.log("Zone Creation Error: ", res.Reason);
            commonJs.showNotify(
              self.props,
              NOTIFY.ERROR,
              ERRORS.ZONE_NOT_SAVED,
              <button className="snackbar-btn">{LABELS.DISMISS}</button>
            );
          }
        })
        .catch((err) => {
          console.log("Zone Creation Error: ", err);
          commonJs.showNotify(
            self.props,
            NOTIFY.ERROR,
            ERRORS.ZONE_NOT_SAVED,
            <button className="snackbar-btn">{LABELS.DISMISS}</button>
          );
        });
    }
    return;
  };

  render() {
    const { zone, showDialog, color } = this.state;
    console.log("EditZone: ZoneDialog: ");
    return (
      <FleetDialog
        id="addZone"
        open={showDialog}
        onCloseSettings={this.toggleAddZoneDialog}
        title={zone.id ? "Edit Zone" : "Add Zone"}
        maxWidth="xs"
        fullWidth={false}
        cancelButton={true}
        cancelAction={this.toggleAddZoneDialog}
        saveButton={true}
        saveAction={this.saveDrawZone}
        saveBtnLabel="OK"
        disableBackdropClick={true}
        disableBackdrop={true}
        classNameBody="zonePopupBody"
        classNameWrapper="zonePopupWrapper"
        draggable
      >
        <div className="grid">
          <div className="row">
            <TextField
              id="zoneName"
              label="Zone Name"
              classes={{ root: "txtfield" }}
              autoFocus={true}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={zone.name || ""}
            />
          </div>
          <div className="row" id="zoneColorPicker">
            <ColorPicker
              id="zoneColor"
              name="color"
              defaultValue={color}
              value={color}
              onChange={(color) => this.setColor(color)}
              classes={{ root: "txtfield colorpicker" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
          <div className="row">
            <TextField
              id="zoneTime"
              label="Time"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={zone.time || ""}
            />
          </div>
          <div className="row">
            <TextField
              id="zoneIntegrationId"
              label="Integration Id"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={zone.integrationId || ""}
            />
          </div>
          <div className="row">
            <TextField
              id="zoneLoadingTime"
              label="Hault Time (In Minutes)"
              classes={{ root: "txtfield" }}
              variant="outlined"
              size="small"
              onMouseDown={(e) => e.stopPropagation()}
              defaultValue={zone.loadingTime || ""}
            />
          </div>
          <div className="row" style={{ marginTop: -10 }}>
            <FormControlLabel
              control={
                <Switch
                  classes={{
                    thumb: "switchThumb",
                    track: "switchTrack",
                    root: "switchRoot",
                  }}
                  id="isHub"
                  defaultChecked={zone.isHub ? JSON.parse(zone.isHub) : false}
                />
              }
              label="Is Hub"
              className="switch"
            />
            <FormControlLabel
              control={
                <Switch
                  classes={{
                    thumb: "switchThumb",
                    track: "switchTrack",
                    root: "switchRoot",
                  }}
                  id="zoneVisible"
                  defaultChecked={
                    zone.zoneVisible
                      ? JSON.parse(zone.zoneVisible)
                      : DEFAULTS.POI_ZONE_VISIBLE
                  }
                />
              }
              label="Zone Visible"
              className="switch"
            />
            <FormControlLabel
              control={
                <Switch
                  classes={{
                    thumb: "switchThumb",
                    track: "switchTrack",
                    root: "switchRoot",
                  }}
                  id="nameVisible"
                  defaultChecked={
                    zone.nameVisible
                      ? JSON.parse(zone.nameVisible)
                      : DEFAULTS.POI_ZONE_NAME_VISIBLE
                  }
                />
              }
              label="Name Visible"
              className="switch"
            />
          </div>
        </div>
      </FleetDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setZoneEdit: (values) => {
      dispatch({
        type: MAP_CONTROLS_ACTIONS.SET_ZONE_EDIT,
        values,
      });
    },
    toggleLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_POPOVER_LOADING,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DialogIncident));
